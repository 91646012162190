/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';

class GenericCardAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  open() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <Fragment>
        <dt role="heading" aria-level="3">
          <button
            id="accordion1-1"
            className={
              'accordion__trigger ' + (this.state.isOpen ? 'is-open' : '')
            }
            aria-expanded={this.state.isOpen}
            aria-controls="content1-1"
            onClick={this.open.bind(this)}
          >
            <svg
              className="icon accordion__trigger-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 687.13 684.58"
            >
              <polygon points="596 272.84 343.57 525.28 91.13 272.84 596 272.84" />
            </svg>
            <span>{this.props.title}</span>
          </button>
        </dt>
        <dd
          id="content1-1"
          className={
            'accordion__content ' + (this.state.isOpen ? 'is-open' : '')
          }
          role="region"
          aria-labelledby="accordion1-1"
        >
          <ul className="attributes-list">
            {this.props.children}
          </ul>
        </dd>
      </Fragment>
    );
  }
}

export default GenericCardAccordion;
