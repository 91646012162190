/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import 'chartjs-plugin-annotation';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  getThresholds,
  getSensorHistory,
  openConnection
} from 'services/IotService';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Refresh from '@material-ui/icons/Refresh';
// eslint-disable-next-line
import * as zoom from 'chartjs-plugin-zoom'; // NOSONAR
import { connect } from 'react-redux';
import { store } from 'utils/store';
class IotExpandChart extends Component {
  lastHourHistory = moment().subtract(1, 'hour').toISOString();
  lastDayHistory = moment().subtract(1, 'day').toISOString();
  lastMonthHistory = moment().subtract(1, 'month').toISOString();

  state = {
    selectedDate: this.lastDayHistory,

    thresholdDevice: [],

    showTreshold: false,

    initialized: false,

    //data used for chartjs
    sensorData: {
      datasets: [
        {
          //label: this.props.multiLang.propertyCard.sensorData,
          fill: false,
          lineTension: 0,
          backgroundColor: '#12ABBD',
          borderColor: '#0070AD',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#0070AD',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#0070AD',
          pointHoverBorderColor: '#ECECEC',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 20,
          data: []
        }
      ]
    },
    //option for chartjs
    chartOptions: {
      annotation: {
        drawTime: 'afterDatasetsDraw', // (default)
        events: ['click'],
        dblClickSpeed: 350, // ms (default)
        annotations: []
      },
      pan: {
        enabled: true,
        mode: 'x'
      },
      zoom: {
        enabled: true,
        mode: 'x',
        sensitivity: 3
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: ''
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              tooltipFormat: 'LLL',
              displayFormats: {
                hour: 'MMM D, h:mm A'
              }
            },
            ticks: {
              source: 'auto'
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Date'
            }
          }
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              stepSize: 3
            },
            scaleLabel: {
              display: true
            }
          }
        ]
      }
    }
  };

  // instance of chartjs context (used for update chart)
  chartReference = {};

  /**
   * update input value and update chart data
   * @param event
   */
  handleChangeDateInput = (event) => {
    var sensorData = { ...this.state.sensorData };
    sensorData.datasets[0].data = [];
    console.log(event.target.value);
    this.setState({ sensorData, selectedDate: event.target.value });
    this.getSensorHistory(event.target.value);
  };

  /**
   * Reset chart data and zoom from initial date in state by defaut, to the passed date if provided
   * @param event
   */
  resetChart = () => {
    var sensorData = { ...this.state.sensorData };
    sensorData.datasets[0].data = [];
    this.setState({ sensorData });
    this.getSensorHistory();
  };

  async componentDidMount() {
    this.getSensorHistory();
    if (!this.connection) {
      this.connection = openConnection(this.props.data.deviceId);
      let cloudConfig = this.props.cloudConfig;
      if (cloudConfig === 'AzureADDB2C') {
        this.connection.on('IoTUpdated', (data) => {
          console.log('IoTUpdated WS: ', this.state.initialized);
          if (data.deviceId === this.props.deviceId) {
            if (!this.state.initialized)
              this.initializeGraph(
                [data],
                data.type + ' - ' + data.unit,
                data.type + ' - ' + data.unit
              );
            this.updateChartData([data]);
          }
        });
      } else {
        this.connection.onmessage = (evt) => {
          console.log('New WS: ' + JSON.stringify(evt.data));
          var data = JSON.parse(evt.data);
          if (!this.state.initialized)
            this.initializeGraph(
              [data],
              data.type + ' - ' + data.unit,
              data.type + ' - ' + data.unit
            );
          this.updateChartData([data]);
        };
      }
    }
  }

  componentWillUnmount() {
    let config = store.getState().config;
    if (config.cloud !== 'AZURE') {
      this.connection.close();
    }
  }

  /**
   * get sensor data from server
   */
  getSensorHistory = (selectedDate = undefined) => {
    var startDate = selectedDate || this.state.selectedDate; // Pick by default the date in the argument
    console.log(startDate);
    getSensorHistory(this.props.data.deviceId, startDate, (error, data) => {
      if (error) {
        console.error('error : ', error);
        return;
      } else {
        if (data.length > 0) {
          console.log(data);
          this.initializeGraph(
            data,
            data[0].type + ' - ' + data[0].unit,
            data[0].type + ' - ' + data[0].unit
          );
        }
      }
    });
  };

  /**
   * initialize the graphe the first time we recieve data
   */
  initializeGraph = (data, title, yAxesLabel) => {
    // use chart instance context to reset zoom
    let config = store.getState().config;

    if (config.cloud !== 'AZURE') {
      this.chartReference.chartInstance.resetZoom();
    }
    var chartOptions = { ...this.state.chartOptions };
    var sensorData = { ...this.state.sensorData };

    //set chart title
    chartOptions.title.text = title; //data[0].type + ' - ' + data[0].unit;

    // set axes label
    chartOptions.scales.yAxes[0].scaleLabel.labelString = yAxesLabel; //data[0].type + ' - ' + data[0].unit;

    //update the chart
    //this.chartReference.chartInstance.update();

    sensorData.datasets[0].label = this.props.multiLang.propertyCard.sensorData;
    this.setState({ initialized: true, sensorData, chartOptions });
    this.updateChartData(data);
  };

  /**
   * get threshold device from server
   */
  getThresholdsDevice = () => {
    getThresholds(this.props.data.deviceId, (error, thresholdsData) => {
      if (error) {
        console.log('ERROR : ', error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.projectFile.errorWhenRetrievingThreshold
        );
      } else {
        this.parseThresholdsData(thresholdsData);
      }
    });
  };

  /**
   * update chart data by adding passed data
   * @param data
   */
  updateChartData = (data) => {
    let parseData = [];
    // for each data create time object used by data chart
    data.forEach((object) => {
      parseData.push({ t: moment(object.date), y: object.value });
    });
    var sensorData = { ...this.state.sensorData };
    sensorData.datasets[0].data = sensorData.datasets[0].data
      .concat(parseData)
      .sort((a, b) => {
        return a.t.isAfter(b.t);
      });
    this.setState({ sensorData });
  };

  /**
   * Parse threshold data to render thresholds in chart
   * @param thresholdsData
   */
  parseThresholdsData = (thresholdsData) => {
    var chartOptions = { ...this.state.chartOptions };

    thresholdsData.Items.forEach((element) => {
      // threshold data object used by chart
      let annotation = {
        drawTime: 'afterDraw', // overrides annotation.drawTime if set
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: element.ThresholdValue,
        borderColor: '#ff0024',
        borderWidth: 1,
        label: {
          backgroundColor: '#4c88ad',
          content: element.ThresholdComparator + ' ' + element.ThresholdValue,
          enabled: true
        }
      };
      // also persist annotation in state in case all chart is re render with a state update
      chartOptions.annotation.annotations.push(annotation);
      // Update current chart reference
      this.chartReference.chartInstance.options.annotation.annotations.push(
        annotation
      );
    });
    this.setState({ chartOptions });
    this.chartReference.chartInstance.update();
  };

  /**
   * handel Chckbox for showing threshold
   */
  handelShowTreshold = () => {
    let showThreshold = !this.state.showTreshold;
    this.setState({ showTreshold: !this.state.showTreshold });
    if (showThreshold) {
      this.getThresholdsDevice();
    } else {
      // also persist annotation in state in case all chart is re render with a state update
      var chartOptions = { ...this.state.chartOptions };
      chartOptions.annotation.annotations = [];
      this.setState({ chartOptions });

      // Update current chart reference
      this.chartReference.chartInstance.options.annotation.annotations = [];
      this.chartReference.chartInstance.update();
    }
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} style={{ marginLeft: 20 }}>
            <Grid item xs={12} sm={4}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel htmlFor='age-simple'>
                  {this.props.multiLang.propertyCard.periode}
                </InputLabel>
                <Select
                  style={{ verticalAlign: 'bottom' }}
                  value={this.state.selectedDate}
                  onChange={this.handleChangeDateInput}
                >
                  <MenuItem value={this.lastHourHistory}>
                    {this.props.multiLang.propertyCard.lastHour}
                  </MenuItem>
                  <MenuItem value={this.lastDayHistory}>
                    {this.props.multiLang.propertyCard.lastDay}
                  </MenuItem>
                  <MenuItem value={this.lastMonthHistory}>
                    {this.props.multiLang.propertyCard.lastMonth}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showTreshold}
                    onChange={this.handelShowTreshold}
                    value='checkedB'
                    color='primary'
                  />
                }
                label={this.props.multiLang.propertyCard.showThresholds}
              />
              <Fab
                variant='extended'
                size='small'
                color='primary'
                aria-label={this.props.multiLang.propertyCard.add}
                onClick={this.resetChart}
              >
                <Refresh />
              </Fab>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginLeft: 20, marginRight: 20 }}>
              <Line
                ref={(reference) => (this.chartReference = reference)}
                height={this.props.data.chartHeight}
                columns={this.columns}
                data={this.state.sensorData}
                options={this.state.chartOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  config: state.config,
  cloudConfig: state.config.authProvider
});

export default connect(mapStateToProps)(IotExpandChart);
