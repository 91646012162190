/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

/**
 * Implementation of custom extension
 */

import ViewMarkupPanel from './ViewMarkupPanel';
import CreateMarkupCardPanel from './CreateMarkupCardPanel';
import { store } from 'utils/store';
import { openModal } from 'actions/ModalAction';
import PropertyCardPanel from './PropertyCardPanel';
import { startReview } from 'services/ReviewService';

const Autodesk = window.Autodesk;

class MarkupExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.propertyPanel = new PropertyCardPanel(viewer.clientContainer.id);
  }

  onSelectionEvent(event) {
    this.propertyPanel.refreshData();
  }

  /**
   * Called when extension is loaded by viewer.
   */
  load() {
    //console.debug('Extension loaded');
    this.onSelectionBinded = this.onSelectionEvent.bind(this);
    this.viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.onSelectionBinded
    );

    if (this.viewer.toolbar) {
      this.createToolbar();
    }
    return true;
  }

  /**
   * Called when extension is unloaded by the viewer.
   */
  unload() {
    //console.debug('Extension unloaded');
    this.viewer.removeEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.onSelectionBinded
    );
    this.onSelectionBinded = null;
    this.destroyToolbar();
    if (this._panel) {
      this.viewer.removePanel(this._panel);
      this._panel.uninitialize();
      this._panel = null;
    }
    return true;
  }

  createToolbar() {
    this.btnComboReview = new Autodesk.Viewing.UI.ComboButton(
      'ButtonGroupExtension.comboReview'
    );
    this.btnComboReview.addClass('button-combo-review');
    this.btnComboReview.setToolTip(store.getState().multiLang.markup.review);

    if (this.hasReview()) {
      let state = store.getState().project.review.stateReview;

      this._btnSample = new Autodesk.Viewing.UI.Button('CreateMarkupPanel');
      this._btnSample.addClass('button-create-markup');
      this._btnSample.setToolTip(
        store.getState().multiLang.markup.createMarkup
      );
      this._btnSample.onClick = (e) => {
        this.onSample(e);
      };

      this._btnViewMarkup = new Autodesk.Viewing.UI.Button('ViewMarkupPanel');
      this._btnViewMarkup.addClass('button-view-markup');
      this._btnViewMarkup.setToolTip(
        store.getState().multiLang.markup.viewMarkup
      );
      this._btnViewMarkup.onClick = (e) => {
        this.viewMarkup(e);
      };
      if (state === 'UNDER_REVIEW') {
        this.btnComboReview.addControl(this._btnSample);
      }

      this.btnComboReview.addControl(this._btnViewMarkup);
    } else {
      this.btnStartReview = new Autodesk.Viewing.UI.Button(
        'ButtonGroupExtension.startReview'
      );
      this.btnStartReview.addClass('button-start-review');
      this.btnStartReview.setToolTip(
        store.getState().multiLang.markup.startReview
      );
      this.btnStartReview.onClick = (e) => {
        this.onClickStartReview(e);
      };
      this.btnComboReview.addControl(this.btnStartReview);
    }

    // add button to the goup
    const ctrlGroup = new Autodesk.Viewing.UI.ControlGroup(
      'MarkupExtension.ControlGroup'
    );
    ctrlGroup.addClass('toolbar-vertical-groupClass');
    this.viewer.toolbar.addControl(ctrlGroup);

    const ctrlGroupReview = new Autodesk.Viewing.UI.ControlGroup(
      'ButtonGroupExtensionViewable.ReviewControlGroup'
    );
    ctrlGroupReview.addControl(this.btnComboReview);

    this.viewer.toolbar.addControl(ctrlGroupReview);

    // add group to main toolbar
  }

  destroyToolbar() {
    const toolbar = this.viewer.getToolbar(false);

    if (toolbar) {
      const ctrlGroup = toolbar.getControl('MarkupExtension.ControlGroup');

      toolbar.removeControl(ctrlGroup);
    }
  }

  /**
   * Called when user clicks on custom button
   * @param {Object} e - event object
   */
  onSample(e) {
    const buttonGroup = new Autodesk.Viewing.UI.ControlGroup(
      'MarkupExtension.ControlGroup3'
    );
    var markupExtension = this.viewer.getExtension(
      'Autodesk.Viewing.MarkupsCore'
    );
    this._btnArrow = new Autodesk.Viewing.UI.Button('Arrow');
    this._btnArrow.addClass('button-arrow');
    this._btnArrow.setToolTip(store.getState().multiLang.markup.arrowMarkup);
    this._btnArrow.onClick = (e) => {
      var arrow = new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(
        markupExtension
      );
      markupExtension.changeEditMode(arrow);
    };

    this._btnCircle = new Autodesk.Viewing.UI.Button('Circle');
    this._btnCircle.addClass('button-circle');
    this._btnCircle.setToolTip(store.getState().multiLang.markup.circleMarkup);
    this._btnCircle.onClick = (e) => {
      var circle = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle(
        markupExtension
      );
      markupExtension.changeEditMode(circle);
    };

    this._btnCloud = new Autodesk.Viewing.UI.Button('Cloud');
    this._btnCloud.addClass('button-cloud');
    this._btnCloud.setToolTip(store.getState().multiLang.markup.cloudMarkup);
    this._btnCloud.onClick = (e) => {
      var cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud(
        markupExtension
      );
      markupExtension.changeEditMode(cloud);
    };

    this._btnFreehand = new Autodesk.Viewing.UI.Button('Freehand');
    this._btnFreehand.addClass('button-freehand');
    this._btnFreehand.setToolTip(
      store.getState().multiLang.markup.freehandMarkup
    );
    this._btnFreehand.onClick = (e) => {
      var freehand = new Autodesk.Viewing.Extensions.Markups.Core.EditModeFreehand(
        markupExtension
      );
      markupExtension.changeEditMode(freehand);
    };

    this._btnHighlight = new Autodesk.Viewing.UI.Button('Highlight');
    this._btnHighlight.addClass('button-highlight');
    this._btnHighlight.setToolTip(
      store.getState().multiLang.markup.hightlightMarkup
    );
    this._btnHighlight.onClick = (e) => {
      var highlight = new Autodesk.Viewing.Extensions.Markups.Core.EditModeHighlight(
        markupExtension
      );
      markupExtension.changeEditMode(highlight);
    };

    this._btnPen = new Autodesk.Viewing.UI.Button('Pen');
    this._btnPen.addClass('button-pen');
    this._btnPen.setToolTip(store.getState().multiLang.markup.penMarkup);
    this._btnPen.onClick = (e) => {
      var pen = new Autodesk.Viewing.Extensions.Markups.Core.EditModePen(
        markupExtension
      );
      markupExtension.changeEditMode(pen);
    };

    this._btnPolyline = new Autodesk.Viewing.UI.Button('Polyline');
    this._btnPolyline.addClass('button-polyline');
    this._btnPolyline.setToolTip(
      store.getState().multiLang.markup.polylineMarkup
    );
    this._btnPolyline.onClick = (e) => {
      var polyline = new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(
        markupExtension
      );
      markupExtension.changeEditMode(polyline);
    };

    this._btnRectangle = new Autodesk.Viewing.UI.Button('Rectangle');
    this._btnRectangle.addClass('button-rectangle');
    this._btnRectangle.setToolTip(
      store.getState().multiLang.markup.rectangleMarkup
    );
    this._btnRectangle.onClick = (e) => {
      var rectangle = new Autodesk.Viewing.Extensions.Markups.Core.EditModeRectangle(
        markupExtension
      );
      markupExtension.changeEditMode(rectangle);
    };

    this._btnText = new Autodesk.Viewing.UI.Button('Text');
    this._btnText.addClass('button-text');
    this._btnText.setToolTip(store.getState().multiLang.markup.textMarkup);
    this._btnText.onClick = (e) => {
      var text = new Autodesk.Viewing.Extensions.Markups.Core.EditModeText(
        markupExtension
      );
      markupExtension.changeEditMode(text);
    };

    buttonGroup.addControl(this._btnArrow);
    buttonGroup.addControl(this._btnCircle);
    buttonGroup.addControl(this._btnCloud);
    buttonGroup.addControl(this._btnFreehand);
    buttonGroup.addControl(this._btnHighlight);
    buttonGroup.addControl(this._btnPen);
    buttonGroup.addControl(this._btnPolyline);
    buttonGroup.addControl(this._btnRectangle);
    buttonGroup.addControl(this._btnText);

    markupExtension.enterEditMode();

    this.viewer.toolbar.addControl(buttonGroup);
    if (!this.CreateMarkupCardPanel) {
      this.CreateMarkupCardPanel = new CreateMarkupCardPanel(
        null,
        this.viewer.clientContainer.id
      );
    } else {
      this.CreateMarkupCardPanel.toggleVisibility();
    }
  }

  viewMarkup(e) {
    if (!this.viewMarkupPanel) {
      let unmount = () => {
        this.viewMarkupPanel = null;
      };
      this.viewMarkupPanel = new ViewMarkupPanel(
        unmount,
        this.viewer.clientContainer.id
      );
    }
  }

  onClickStartReview(e) {
    //var content = document.createElement('div');
    //content.innerHTML("plop");
    openModal(
      store.getState().multiLang.markup.review,
      store.getState().multiLang.markup.askDesignReview,
      this.callStartReview
    );
  }
  callStartReview() {
    var project = store.getState().project;

    startReview(
      project.projectId,
      project.modelId,
      project.revision,
      (error, data) => {
        if (error) {
          console.log(error);
          return store
            .getState()
            .snackBar.addSnackError(
              store.getState().multiLang.markup.startReviewError
            );
        }
        store
          .getState()
          .snackBar.addSnackSuccess(
            store.getState().multiLang.markup.reviewStarted
          );
      }
    );
  }

  hasReview() {
    const review = store.getState().project.review;

    return review != null;
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  'MarkupExtension',
  MarkupExtension
);
