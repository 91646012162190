/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Projects from 'components/user-settings/reflect-administration/projects/Projects';
import CustomizedCard from 'components/card/customized-card/CustomizedCard';
import Grid from '@material-ui/core/Grid';

class ReflectAdministration extends Component {
  state = {
    backgroundColor: '#FFF',
    titleColor: '#0070AD',
    descriptionColor: '#000',
    shadow: 0,
    selectedView: ''
  };

  cards = [
    {
      title: this.props.multiLang.administration.projects,
      description: this.props.multiLang.administration.createProjectDescription
    }
    // ,
    // {
    //   title: this.props.multiLang.administration.users,
    //   description: 'Create user and affect user in a group'
    // }
  ];

  onMouseOver = () =>
    this.setState({
      backgroundColor: '#12ABDB',
      titleColor: '#FFF',
      descriptionColor: '#FFF',
      shadow: 5
    });

  onMouseOut = () =>
    this.setState({
      backgroundColor: '#FFF',
      titleColor: '#0070AD',
      descriptionColor: '#000',
      shadow: 0
    });

  renderCreateProject = () => {
    this.setState({ goToProjectCreation: true });
  };

  reset = () => {
    this.setState({ selectedView: '' });
  };

  handleClick(cardTitle) {
    this.setState({ selectedView: cardTitle });
  }

  render() {
    switch (this.state.selectedView) {
      case this.props.multiLang.administration.projects:
        return <Projects close={this.reset} />;
      // case this.props.multiLang.administration.users:
      //   return <Users />;
      default:
        return (
          <Grid container spacing={3}>
            {this.cards.map((card, index) => {
              return (
                <CustomizedCard
                  key={index}
                  title={card.title}
                  description={card.description}
                  onClick={() => this.handleClick.bind(this, card.title)}
                />
              );
            })}
          </Grid>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReflectAdministration);
