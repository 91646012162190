/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

function hasRelatedItem(propertyData) {
  var hasRelated = false;
  Object.keys(propertyData).forEach((key) => {
    if (typeof propertyData[key] === "object" && key === "Related Items") {
      hasRelated = true;
    }
  });
  return hasRelated;
}

function parseModelData(propertyData) {
  if (!propertyData) {
    return null;
  }

  let contentCardData = { sections: [] };
  let groupAttributes = {};
  Object.keys(propertyData).forEach((dataset) => {
    if (
      dataset === "Related Items" ||
      dataset === "IOT" ||
      dataset === "Documents" ||
      dataset === "tagLabel"
    ) {
      return;
    }
    if (typeof propertyData[dataset] === "object") {
      var subobj = propertyData[dataset];
      Object.keys(subobj).forEach((attributeName) => {
        if (dataset === "properties") {
          if (attributeName === "Name") {
            contentCardData.title = subobj[attributeName];
          }
        } else {
          if (!groupAttributes[dataset]) {
            groupAttributes[dataset] = {
              title: dataset,
              user: propertyData[dataset]._user,
              importDate: propertyData[dataset]._importDate,
              datasetPosition: propertyData[dataset]._datasetPosition,
              attributes: []
            };
          }
          groupAttributes[dataset].attributes.push({
            attribute: attributeName,
            value: subobj[attributeName]
          });
        }
      });
    }
  });

  return Object.keys(groupAttributes).map((key) => groupAttributes[key]);
}

function parseModelDataForDataSet(propertyData, dataSet) {
  if (!propertyData) {
    return null;
  }
  let data = null;

  Object.keys(propertyData).forEach((key) => {
    if (propertyData[key] && typeof propertyData[key] === "object") {
      var subobj = propertyData[key];
      Object.keys(subobj).forEach((subkey) => {
        if (typeof subobj[subkey] === "object" && key === dataSet) {
          data = subobj;
        }
      });
    }
  });

  return data;
}

export { parseModelData, parseModelDataForDataSet, hasRelatedItem };
