/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { getGroupUsers } from 'services/GroupService';
import MUIDataTable from 'mui-datatables';
import { parseDate } from 'utils/DateHelper';

class ShowGroupDetails extends Component {
  state = {
    users: []
  };

  componentDidMount() {
    getGroupUsers(this.props.group[0].props.children[1])
      .then((users) => {
        let tableData = [];
        users.data.forEach((element) => {
          tableData.push([
            element.Username,
            element.email,
            parseDate(
              element.UserCreateDate,
              this.props.multiLang.projectFile.dateFormatISO
            )
          ]);
        });
        this.setState({ users: tableData });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  columnsIn = [
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.email,
      label: this.props.multiLang.administration.email,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.creationDate,
      label: this.props.multiLang.administration.creationDate,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    selectableRows: 'none'
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
        style={{ width: '100%' }}
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.multiLang.administration.groupDetail}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography color='primary'>
                      {this.props.multiLang.administration.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{this.props.group[1]}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography color='primary'>
                      {this.props.multiLang.administration.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>{this.props.group[2]}</Typography>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Paper>
          <MUIDataTable
            title={this.props.multiLang.administration.users}
            data={this.state.users}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(ShowGroupDetails);
