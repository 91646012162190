/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from '../../../theme/theme';
import _ from 'lodash';
import SwitchViewModal from 'components/nav-bar/switch-view-component/SwitchViewModal';
import { getViewsWithTag } from 'services/AssetService';
import { mainViewerAction } from 'actions/MainViewerAction';
import {
  Popover,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

class SwitchViewComponent extends Component {
  state = {
    availableViews: [],
    viewsList: [],
    switchViewEnchorEl: null,
    isSearchable: true,
    actualLayouts: [],
    actualLayoutNames: [],
    openModal: false,
    selectedFile: null,
    currentView: ''
  };

  viewerSrc = {
    FORGE_VIEWER: 'images/icons/ForgeViewer.svg',
    EMPTY_VIEWER: 'images/icons/SigViewer.svg'
  };

  componentDidMount() { }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        prevProps.focusedObjectsTagNames,
        this.props.focusedObjectsTagNames
      )
    ) {
      this.loadFiles();
    }
    // Set current view if changed
    if ((this.props.viewerType && this.props.revision !== prevProps.revision) || (this.props.viewerType && this.props.modelId !== prevProps.modelId)) {
      this.setState({ currentView: this.props.viewerType + '#' + this.props.modelId + '#' + this.props.revision });
    }

    // Reset switch state
    if (this.props.resetSwitch && this.props.resetSwitch !== prevProps.resetSwitch) {
      this.setState({ currentView: '', viewsList: [], availableViews: []});
    }
  }

  /**
   * Get file project list
   *
   */
  loadFiles = () => {
    if (this.props.projectId && this.props.focusedObjectsTagNames) {
      getViewsWithTag(this.props.projectId, this.props.focusedObjectsTagNames[0])
        .then((response) => {
          let data = response.data;
          if (data && data.length > 0) {
            this.filterViews(data, this.state.actualLayoutNames);
            this.setState({ availableViews: data });
          }
          else {
            this.setState({ availableViews: [], viewsList: [] });
          }
        })
        .catch((error) => {
          console.error(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.projectFile.loadFileError
          );
        });
    }
  };

  formLayoutName(file) {
    if (file.Type === 'FORGE_VIEWER') {
      //return id of view
      return file.Type + '#' + file.Id + '#' + file.Content.revisionNumber;
    } else {
      return file.Type;
    }
  }

  filterViews(views, currentLayouts) {
    // Add the view once
    let addedViews = new Set();
    //only show the layouts that don't exist
    let viewsList = views.filter(
      (file) => {
        if (this.state.currentView !== this.formLayoutName(file) && !addedViews.has(this.formLayoutName(file))) {
          addedViews.add(this.formLayoutName(file));
          return true;
        }
        return false;
      }
    );
    this.setState({ viewsList: viewsList });
  }

  getLayoutFromLS() {
    let layouts = [];
    if (global.localStorage) {
      try {
        layouts = JSON.parse(global.localStorage.getItem('rgl-8')).layout || [];
        if (layouts) {
          let layoutKeys = layouts.map((layout) => layout.i);
          this.filterViews(this.state.availableViews, layoutKeys); //update views to add in switchView menu
          this.setState({
            actualLayouts: layouts,
            actualLayoutNames: layoutKeys
          });
        }
      } catch (e) {
        /*Ignore*/
      }
    }
  }

  openSwitchViewMenu = (event) => {
    this.getLayoutFromLS();
    if (this.props.viewers.length > 0) {
      this.setState({ switchViewEnchorEl: event.currentTarget });
    }
  };

  closeSwitchViewMenu = () => {
    this.setState({ switchViewEnchorEl: null });
  };

  handleAddViewClick = (file) => {
    this.props.dispatch({
      type: mainViewerAction.addView,
      viewToAdd: {
        viewerType: file.Type,
        fileName: file.FileName,
        modelId: file.Id ? file.Id : null,
        data: file.Content,
        tag: file.Tag
      }
    });
    this.closeSwitchViewMenu();
  };

  openModal = (file) => {
    this.setState({ openModal: true, selectedFile: file });
    this.closeSwitchViewMenu();
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  onSwitchView = (index) => {
    this.closeModal();
    this.props.dispatch({
      type: mainViewerAction.switchView,
      viewToSwitch: {
        oldView: this.state.actualLayoutNames[index],
        newView: this.state.selectedFile.Type,
        fileName: this.state.selectedFile.FileName,
        model: this.state.selectedFile.Id ? this.state.selectedFile.Id : null,
        data: this.state.selectedFile.Content,
        tag: this.state.selectedFile.Tag
      }
    });
  };
  render() {
    return (
      <div>
        <button
          type='button'
          className='nav-item'
          disabled={!this.props.focusedObjectsTagNames}
          onClick={this.openSwitchViewMenu}
          aria-owns={
            this.state.switchViewEnchorEl ? 'switch-view-menu' : undefined
          }
          aria-haspopup='true'
        >
          <img src='/images/icons/switch.svg' alt='' width='25' height='25' />

          <span
            className='nav-item__text'
            style={
              !this.props.focusedObjectsTagNames
                ? { color: theme.color.disabledLink }
                : {}
            }
          >
            {this.props.multiLang.navBar.switchViewHeader}
          </span>
        </button>

        <Popover
          id='switch-view-menu'
          open={Boolean(this.state.switchViewEnchorEl)}
          anchorEl={this.state.switchViewEnchorEl}
          onClose={this.closeSwitchViewMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <MenuList>
            {this.state.viewsList.length > 0 &&
              this.state.viewsList.map((file, index) => (
                <MenuItem key={index}>
                  <ListItemIcon>
                    <span>
                      <img
                        src={
                          file.Type
                            ? this.viewerSrc[file.Type]
                            : this.viewerSrc[this.props.project.viewer]
                        }
                        alt=''
                        width='25'
                        height='25'
                      />
                    </span>
                  </ListItemIcon>
                  {this.props.viewerType === "SPLIT_VIEWER"
                    ? <ListItemText
                      onClick={this.openModal.bind(this, file)}
                      primary={file.FileName.split('.')[0]}
                    />
                    : <Link
                      key={index}
                      to={{
                        pathname: '/viewer',
                        search: `?viewer=${
                          file.Type ? file.Type : this.props.project.viewer
                          }&projectId=${this.props.project.projectId}&modelId=${
                          file.Id
                          }&revision=${file.Content.revisionNumber}`
                      }}
                      onClick={this.loadFiles}
                    >
                      {file.FileName.split('.')[0]}
                    </Link>
                  }
                  <IconButton
                    onClick={this.handleAddViewClick.bind(this, file)}
                  >
                    <AddToQueueIcon />
                  </IconButton>
                </MenuItem>
              ))}
            {this.state.viewsList.length === 0 && (
              <ListItemText
                style={{ padding: 25 }}
                primary={this.props.multiLang.navBar.noSwitchView}
              />
            )}
          </MenuList>
        </Popover>
        <SwitchViewModal
          openModal={this.state.openModal}
          layouts={this.state.actualLayouts}
          onCloseModal={this.closeModal}
          onSwitchView={this.onSwitchView}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewers: state.mainViewer.viewers,
  projectId: state.project.projectId,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  modelId: state.project.modelId,
  revision: state.project.revision,
  userRoles: state.userRole,
  snackBar: state.snackBar,
  project: state.project,
  viewerType: state.project.viewer,
  mainViewer: state.mainViewer,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(SwitchViewComponent);
