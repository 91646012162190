/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import React from "react";
import theme from "theme/theme";

export const ListMarkups = (props) => {
  return (
    <ListItem style={{ padding: "5px 10px" }}>
      <ListItemText
        disableTypography
        primary={props.order + " - " + props.title}
        className="card__annotation-text"
        onClick={props.openDetails}
      />
      <ListItemIcon
        onClick={() => props.deleteMarkup(props.markupId)}
        style={{
          fontWeight: "100",
          lineHeight: "1.4"
        }}
      >
        <DeleteIcon />
      </ListItemIcon>
      <ListItemIcon
        onClick={props.viewpoint}
        style={{
          fontWeight: "100",
          color: theme.color.blue,
          lineHeight: "1.4"
        }}
      >
        {props.viewed ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </ListItemIcon>
    </ListItem>
  );
};
