/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { updateFileOptions } from "services/FileService";
import { getBreakdowns } from "services/AssetService";
import BreakdownFilterDetailRow from "./BreakdownFilterDetailRow/BreakdownFilterDetailRow";
import "./ModelProperties.css";

class ModelProperties extends React.Component {
  state = {
    breakdowns: [],
    updatedRootTag: {}
  };

  async componentDidMount() {
    let data = await getBreakdowns(this.props.projectId);
    let breakdowns = data.data.result;
    breakdowns = breakdowns.filter(e => e.RootTagFilter);
    this.setState({ breakdowns }); // Name,Description,Default
  }

  closeModelPropertiesModal = () => {
    this.setState({updatedRootTag: {}})
    this.props.closeModelPropertiesModal();
  }

  save = () => {
    let options = {
      breakdownRootTag: {}
    };
    for (const breakdownName in this.state.updatedRootTag) {
      if (this.state.updatedRootTag[breakdownName] !== undefined) {
        options.breakdownRootTag[breakdownName] = this.state.updatedRootTag[breakdownName];
      }
    }

    if(Object.keys(options.breakdownRootTag).length > 0) {
      updateFileOptions(
        this.props.projectId,
        this.props.selectedFileId,
        options
      ).then(
        _ => {
          this.props.updateFileOptions(options, this.props.selectedFileId);
          this.closeModelPropertiesModal();
        },
        _ => {
          this.props.snackBar.addSnackError(
            this.props.multiLang.projectFile.errorWhileSavingOptions
          );
        }
      );
    } else {
      this.closeModelPropertiesModal();
    }
  };

  updateRootTag = (breakdownName, newValue) => {
    if (newValue === this.props.selectedFileOptions[breakdownName]) {
      this.setState({
        updatedRootTag: {
          ...this.state.updatedRootTag,
          [breakdownName]: undefined,
        }
      });
    } else {
      this.setState({
        updatedRootTag: {
          ...this.state.updatedRootTag,
          [breakdownName]: newValue,
        }
      });
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.modelPropertiesModalOpen}
        onClose={this.closeModelPropertiesModal}
        maxWidth="md"
        style={{ flexGrow: 0 }}
      >
        <DialogTitle id="form-dialog-title">
          {this.props.multiLang.projectFile.settings}&nbsp;{this.props.multiLang.projectFile.article}&nbsp;{this.props.selectedFileKey}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper
            style={{ padding: 20, margin: 10, flexGrow: 0, flexWrap: "wrap" }}
          >
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              {this.props.multiLang.projectFile.breakdowns}
            </Typography>
            <div style={{ margin: "10px" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {this.props.multiLang.projectFile.name}
                    </TableCell>
                    <TableCell>
                      {this.props.multiLang.projectFile.description}
                    </TableCell>
                    <TableCell>
                      {this.props.multiLang.projectFile.default}
                    </TableCell>
                    <TableCell>
                      {this.props.multiLang.projectFile.rootNode}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.breakdowns.map(breakdown =>
                    <BreakdownFilterDetailRow
                      key={breakdown.Name}
                      breakdown={breakdown}
                      previousValue={
                        this.state.updatedRootTag[breakdown.Name] || this.props.selectedFileOptions[breakdown.Name] || ""
                      }
                      updateRootTag={this.updateRootTag}
                    />
                  )}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
        <div style={{ margin: 10 }}>
          <DialogActions>
            <Button
              onClick={() => {
                this.closeModelPropertiesModal();
              }}
              color="primary"
            >
              {this.props.multiLang.administration.cancel}
            </Button>
            <Button onClick={this.save} color="primary" variant="contained">
              {this.props.multiLang.administration.save}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(ModelProperties);
