/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';

class AddGroup extends Component {
  state = {
    groupName: '',
    description: ''
  };

  handleGroupNameInputChange = (event) => {
    this.setState({ groupName: event.target.value });
  };

  handleDescriptionInputChange = (event) => {
    this.setState({ description: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.multiLang.administration.createGroup}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper style={{ padding: 20, margin: 10 }}>
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={this.state.groupName}
                      id='standard-name'
                      label={this.props.multiLang.administration.name}
                      fullWidth={true}
                      type='text'
                      onChange={(event) =>
                        this.handleGroupNameInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={this.state.description}
                      type='text'
                      id='standard-name'
                      label={this.props.multiLang.administration.description}
                      fullWidth={true}
                      onChange={(event) =>
                        this.handleDescriptionInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}></Grid>
                </Grid>
              </div>
            </form>
          </Paper>
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
          <Button
            disabled= {!this.state.groupName}
            variant='contained'
            color='primary'
            className='float-right'
            onClick={() =>
              this.props.add(
                this.state.groupName,
                this.state.description,
                this.props.projectid
              )
            }
          >
            {this.props.multiLang.propertyCard.create}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(AddGroup);
