/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from 'utils/store';
import * as Msal from 'msal';
import { authAction } from 'actions/AuthAction';

let msalInstance;

let initAuth = (callback) => {
  if (msalInstance) {
    return;
  }

  var config = store.getState().config;
  let { clientId, authUrlRedirect } = config;

  let url = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`;

  var msalConfig = {
    auth: {
      clientId,
      validateAuthority: false,
      redirectUri: url,
      postLogoutRedirectUri: url,
      authority: authUrlRedirect
    }
  };
  // Save requested url before the authentication process
  if (window.location.pathname.includes('imports')) localStorage.setItem('redirect', window.location.pathname);
  msalInstance = new Msal.UserAgentApplication(msalConfig);

  msalInstance.handleRedirectCallback(handleRedirectCallback);

  if (!msalInstance.getAccount()) {
    goToLoginPage();
  } else {
    const userId = msalInstance.getAccount().idTokenClaims.sub;

    // there no other way to get raw token. in the next Major version there will be a way. 1.1.X => 2.X.X
    const idToken = sessionStorage.getItem('msal.idtoken');
    store.dispatch({
      type: authAction.initToken,
      idToken: idToken,
      userId,
      headers: {
        Authorization: 'Bearer ' + idToken
      }
    });
    if (callback) {
      callback();
    }
  }
};

let handleRedirectCallback = (error, data) => {
  // handle redirect response or error
  if (error) {
    console.error('error', error);
    localStorage.removeItem('redirect');
    return logout();
  }
};

let acquireToken = (scopes) => {
  if (msalInstance.acquireTokenInProgress) return;

  var tokenRequest = {
    scopes
  };
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => {
      const userId = msalInstance.getAccount().accountIdentifier;
      store.dispatch({
        type: authAction.initToken,
        idToken: response.idToken.rawIdToken,
        userId,
        headers: {
          Authorization: 'Bearer ' + response.idToken
        }
      });
    })
    .catch((err) => {
      console.error(err);
      logout();
    });
};

let isAuthenticated = () => {
  return !!msalInstance.getAccount();
};

let goToLoginPage = () => {
  msalInstance.loginRedirect();
};

let logout = () => {
  msalInstance.logout();
};

let handleUnauthorized = () => {
  // Refreshing token
  if (msalInstance.getAccount().idTokenClaims.exp > Date.now()) {
    var config = store.getState().config;
    let { clientId } = config;
    acquireToken([clientId]);
  }
};

let handleTokenExpiration = () => {
  //token expired
  if (msalInstance.getAccount().idTokenClaims.exp < Date.now()) {
    logout();
  }
};

export default {
  initAuth,
  isAuthenticated,
  goToLoginPage,
  handleUnauthorized,
  handleTokenExpiration,
  logout
};
