/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AdvancedSearch from './AdvancedSearch';
import AdvancedSearchResult from './AdvancedSearchResult';
import SimpleSearch from './SimpleSearch';
import { getObjectsFromKeyword } from 'services/ObjectService';
import { mainViewerAction } from 'actions/MainViewerAction';
import { searchAction } from 'actions/SearchAction';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { searchData } from 'services/AdvancedSearchService';
import Grid from '@material-ui/core/Grid';

import './NavSearch.css';

const nbElementByPage = 5;
class NavSearch extends Component {
    constructor() {
        super();
        this.state = {
            resultOfSearch: [],
            anchorEl: null,
            active: 'SEARCH_INPUT_PAGE',
            searchInfo: {
                tagClass: '',
                attributeCondition: '',
                excludedWords: ''
            },
            advancedSearch: false
        };

        this.search = this.search.bind(this);
        this.toggleSearchMode = this.toggleSearchMode.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
        this.handleCloseSearch = this.handleCloseSearch.bind(this);
        this.handleOpenSearch = this.handleOpenSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.handleClickOnResult = this.handleClickOnResult.bind(this);
        this.handleSearchResultCallback = this.handleSearchResultCallback.bind(
            this
        );
    }

    /**
     * Callback for search functions
     */
    handleSearchResultCallback(error, data) {
        if (error) {
            console.error(error);
            return this.props.snackBar.addSnackError(
                this.props.multiLang.navBar.queryObjError
            );
        }
        let focusedObjectsTagNames = [];

        if (
            this.props.project.actualViewer === ('FORGE_VIEWER' || 'SPLIT_VIEWER')
        ) {
            data.searchResult.forEach((obj) => {
                if (obj.tag) {
                    focusedObjectsTagNames.push(obj.tag);
                }
            });
        }

        this.setState({
            resultOfSearch: data.searchResult,
            totalNbElements: data.totalNbElements,
            pageNumber: data.pageNumber
        });

        this.handleOpenSearch();
    }

    /**
     * Handle search request
     */
    search() {
        this.props.dispatch({
            type: searchAction.initSearchParameters,
            searchValue: this.state.searchValue ? this.state.searchValue : '',
            searchType: this.state.advancedSearch ? this.state.advancedSearch : false
        });

        if (this.state.advancedSearch) {
            this.advancedSearch();
        } else {
            this.simpleSearch();
        }
    }

    /**
     * Call avanced search service and put in state result
     * @param {int} newPage
     */
    advancedSearch(newPage) {
        const projectId = this.props.project.projectId;
        const className = this.state.searchValue.className;
        const attributeConditions = this.state.searchValue.attributeConditions;
        let pageNumber = newPage ? newPage : 0;
        if (!projectId || !className || !attributeConditions) {
            console.error(
                'There are parameters missing. Will not call the advanced search API'
            );
            return;
        }
        searchData(
            projectId,
            className,
            attributeConditions,
            nbElementByPage,
            pageNumber,
            this.handleSearchResultCallback
        );
    }

    /**
     * Call simple search service and put in state result
     * @param {int} newPage
     */
    simpleSearch(newPage) {
        const projectId = this.props.project.projectId;
        let pageNumber = newPage ? newPage : 0;
        if (!projectId || !this.state.searchValue) {
            console.error(
                'There are parameters missing. Will not call the search API'
            );
            return;
        }
        getObjectsFromKeyword(
            projectId,
            this.state.searchValue,
            nbElementByPage,
            pageNumber,
            this.handleSearchResultCallback
        );
    }

    handleClickOnResult(row) {
        let tags = [];
        tags.push(row.tag);
        this.props.dispatch({
            type: mainViewerAction.initTagNames,
            focusedObjectsTagNames: tags
        });
        this.handleCloseSearch();
        this.props.openCard('PROPERTY_CARD', { tag: row.tag }, 150, 150, {
            height: 500
        });
    }

    /**
     * Change search mode : Advance or Simple
     */
    toggleSearchMode() {
        this.setState({ advancedSearch: !this.state.advancedSearch });
    }

    openSemanticSearchPanel = () => {
        this.props.searchParams.openSemanticSearchPanel();
    };

    /**
     * Save in state search keyword.
     * For simple search it's a string.
     * For advance search it's an object.
     */
    onSearchValueChange(value) {
        this.setState({ searchValue: value });
    }

    /**
     * Open result search pop up
     */
    handleOpenSearch(event) {
        this.setState({
            anchorEl: this.searchRef
        });
    }

    /**
     * Close result search pop up
     */
    handleCloseSearch() {
        this.setState({
            anchorEl: null
        });
    }

    /**
     * Change page for result search pop up
     */
    onChangePage(newPage) {
        if (this.state.advancedSearch) {
            this.advancedSearch(newPage);
        } else {
            this.simpleSearch(newPage);
        }
    }

    render() {
        const resultWidth = this.searchRef ? this.searchRef.offsetWidth : null;

        const open = Boolean(this.state.anchorEl);

        return (
            <Fragment>
                <div className='header-search ml-auto'>
                    {this.props.isSearchable && (
                        <div
                            className='form-inline my-2 my-lg-0'
                            ref={(searchRef) => (this.searchRef = searchRef)}
                        >
                            <Grid container>
                                <Grid item>
                                    {this.state.advancedSearch && (
                                        <AdvancedSearch
                                            search={this.search}
                                            onChange={this.onSearchValueChange}
                                            inputSize={300}
                                        />
                                    )}
                                    {!this.state.advancedSearch && (
                                        <SimpleSearch
                                            search={this.search}
                                            onChange={this.onSearchValueChange}
                                            inputSize={300}
                                        />
                                    )}
                                </Grid>

                                <Grid item>
                                    <IconButton onClick={this.search}>
                                        <SearchIcon />
                                    </IconButton>
                                    {this.state.advancedSearch && (
                                        <IconButton onClick={this.toggleSearchMode}>
                                            <KeyboardArrowUp />
                                        </IconButton>
                                    )}
                                    {!this.state.advancedSearch && (
                                        <IconButton onClick={this.openSemanticSearchPanel}>
                                            <KeyboardArrowDown />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>

                            <Popover
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleCloseSearch}
                            >
                                <Paper style={{ width: resultWidth }}>
                                    <AdvancedSearchResult
                                        rows={this.state.resultOfSearch}
                                        onChangePage={this.onChangePage}
                                        nbElementByPage={nbElementByPage}
                                        pageNumber={this.state.pageNumber}
                                        totalNbElements={this.state.totalNbElements}
                                        onRowSelected={this.handleClickOnResult}
                                        handleCloseSearch={this.handleCloseSearch}
                                    />
                                </Paper>
                            </Popover>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    project: state.project,
    multiLang: state.multiLang,
    snackBar: state.snackBar,
    card: state.card,
    userId: state.auth.userId,
    s3: state.auth.s3,
    config: state.config,
    storeBucket: state.config.storeBucket,
    openCard: state.card.openCard,
    searchParams: state.searchParams
});

export default connect(mapStateToProps)(NavSearch);
