/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";

function startReview(projectId, modelId, revision, callback) {
    if (store.getState().config.cloud === "AZURE") {
        return;
    }
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.post(config.urlDesignReviewAPI + '/dataflow/modelreview/start', {
        projectid: projectId,
        modelid: modelId,
        revision: revision
    }, { headers: headers })
        .then(function (response) {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function setReviewStatus(reviewid, status, callback) {
    if (store.getState().config.cloud === "AZURE") {
        return;
    }
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.post(config.urlDesignReviewAPI + "/dataflow/modelreview/status", {
        reviewid: reviewid,
        status: status
    },
        { headers: headers })
        .then(function (response) {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getReview(projectId, modelId, revision, callback) {
    if (store.getState().config.cloud === "AZURE") {
        return;
    }
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlDesignReviewAPI + '/review', {
        params: {
            projectId: projectId,
            modelId: modelId,
            revision: revision
        },
        headers: headers
    })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch((error) => {
            return callback(error);
        });
}

export {
    startReview,
    setReviewStatus,
    getReview
};