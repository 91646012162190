/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Delete from '@material-ui/icons/Delete';
import DropZone from 'react-dropzone';
import { isModel, is1D } from 'utils/ModelHelper';
import { getMappingRule } from 'services/MappingRuleService';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import md5 from 'js-md5';
import { store } from 'utils/store';
import azure from 'azure-storage';
import LinearProgress from '@material-ui/core/LinearProgress';

const documentService = require('../../../services/DocumentService');

class UploadFileProjectModal extends Component {
  state = {
    fileToUpload: undefined,
    fileType: '',
    dataSourceType: undefined,
    listRuleId: undefined,
    dataset: 'NONE',
    isUploadInProgress: false,
    siteName: ''
  };

  /**
   * Wich type of file user doese upload
   * Getting mapping rule
   * **/
  changeSectionUploadToShow = (files) => {
    if (isModel(files)) {
      this.setState({ fileType: '2D3DModel', dataSourceType: '2D/3D' });
      this.setState({ fileToUpload: files });
      getMappingRule(this.props.projectId, '2D/3D', (err, data) => {
        if (err)
          this.props.snackBar.addSnackError(
            this.props.multiLang.fileUpload.errorWhileRetrievingMapping
          );
        else this.setState({ listRuleId: data });
      });
    } else if (is1D(files) && !this.props.fileId) {
      this.setState({ fileType: '1DModel', dataSourceType: '1D' });
      this.setState({ fileToUpload: files });
      getMappingRule(this.props.projectId, '1D', (err, data) => {
        if (err)
          this.props.snackBar.addSnackError(
            this.props.multiLang.fileUpload.errorWhileRetrievingMapping
          );
        else this.setState({ listRuleId: data });
      });
    } else if (is1D(files) && this.props.fileId) {
      this.props.snackBar.addSnackError(
        this.props.multiLang.fileUpload.errorSelectValidModel
      );
    } else {
      this.props.snackBar.addSnackError(
        this.props.multiLang.fileUpload.errorNonValidExtension
      );
    }
  };

  // handle drop file in drop area
  onDropFile = (acceptedFiles) => {
    this.changeSectionUploadToShow(acceptedFiles[0]);
  };

  //delete choosen file
  deleteFile = () => {
    this.setState({ fileToUpload: undefined });
  };

  // buiuld params to upload file to S3
  buildParams = () => {
    switch (this.state.fileType) {
      case '1DModel':
        return {
          Bucket: this.props.config.bucket,
          Key:
            this.getHashNameFile(this.state.fileToUpload.name) +
            '/' +
            this.props.projectId +
            '/' +
            this.state.dataset +
            '/' +
            this.state.fileToUpload.name,
          Body: this.state.fileToUpload,
          ACL: 'private',
          Metadata: {
            datasetid: this.state.dataset,
            sub: this.props.userId,
            fileid: this.props.fileId ? this.props.fileId : 'new_file', // if fileID is not present => new file else we upload e new revision of existing file
            datasourcetype: this.state.dataSourceType,
            viewername: '1D',
            location:
              this.getHashNameFile(this.state.fileToUpload.name) +
              '/' +
              this.props.projectId +
              '/' +
              this.state.dataset +
              '/' +
              this.state.fileToUpload.name
          },
          Tagging:
            'dataset=' + this.state.dataset + '&project=' + this.props.projectId
        };
      case '2D3DModel':
        return {
          Bucket: this.props.config.bucket,
          Key:
            this.getHashNameFile(this.state.fileToUpload.name) +
            '/' +
            this.props.projectId +
            '/' +
            this.state.dataset +
            '/' +
            this.state.fileToUpload.name,
          Body: this.state.fileToUpload,
          ACL: 'private',
          Tagging:
            'dataset=' +
            this.state.dataset +
            '&project=' +
            this.props.projectId,
          Metadata: {
            sub: this.props.userId,
            fileid: this.props.fileId ? this.props.fileId : 'new_file', // if fileID is not present => new file else we upload e new revision of existing file
            datasourcetype: this.state.dataSourceType,
            viewername: 'FORGE_VIEWER',
            datasetid: this.state.dataset,
            sitename: this.state.siteName
          }
        };
      default:
        return false;
    }
  };

  uploadFile = () => {
    this.setState({ isUploadInProgress: true })
    let cloudConfig = this.props.config.authProvider;
    switch (cloudConfig) {
      case 'AzureADDB2C':
        this.uploadAzureFile();
        break;
      case 'AWSCognito':
        this.uploadAwsFile();
        break;
      default:
        console.error('Cloud is not defined');
    }
  };

  uploadAwsFile = () => {
    // upload file to S3
    const props = this.props;
    const fileName = this.state.fileToUpload.name;
    // verify if user choose file and select a data source
    if (
      this.state.fileToUpload &&
      this.buildParams() &&
      this.state.dataset !== 'NONE'
    ) {
      this.props.s3.upload(this.buildParams(), function (err, data) {
        if (err) {
          console.error('erreur : ', err);
          props.snackBar.addSnackError(
            props.multiLang.projectFile.uploadfileError
          );
        } else {
          props.snackBar.addSnackSuccess(
            props.multiLang.projectFile.uploadOf +
            fileName +
            props.multiLang.projectFile.uploadComplete
          );
          props.onClose();
        }
      });
    }
  };

  uploadAzureFile = async () => {
    var config = store.getState().config;
    const props = this.props;
    const parms = this.buildParams();
    const fileName = this.state.fileToUpload.name;
    try {
      // get sas token
      let result = await documentService.getSasToken(
        config.uploadStorageAccountName,
        config.uploadContainerName
      );

      await documentService.uploadFile(
        config.uploadStorageUri,
        this.state.fileToUpload,
        config.uploadContainerName,
        this.state.fileToUpload.name,
        parms.Metadata,
        result.token,
        this.props.projectId
      );
    } catch (error) {
      console.error('Error : ', error);
      props.snackBar.addSnackError(
        props.multiLang.projectFile.uploadfileError
      );
    }
    this.props.onClose(false)
  };

  getHashNameFile = (fileName) => {
    return md5(fileName);
  };

  // handle dataset select
  handleDataSetChange = (event) => {
    this.setState({ dataset: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={this.props.isFileUploadModalOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          {this.props.multiLang.projectFile.uploadNewFile}{' '}
          {this.state.dataSourceType}{' '}
        </DialogTitle>{' '}
        {/*ou bien "upload nex Version"*/}
        <DropZone onDrop={(acceptedFiles) => this.onDropFile(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div>
              <Paper
                style={{
                  padding: 20,
                  margin: 10,
                  width: '500px',
                  height: '200px',
                  border: 'dashed #32a1ce',
                  backgroundColor: isDragActive ? '#0070ad3b' : ''
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div {...getRootProps()}>
                      <Grid container spacing={3}>
                        <input {...getInputProps()} />
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <CloudUpload
                            style={{ width: 70, height: 70, color: '#32a1ce' }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: 'center', color: '#32a1ce' }}
                        >
                          {isDragActive
                            ? this.props.multiLang.projectFile.DropHere
                            : this.props.multiLang.projectFile.DragNDropFile}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              {this.state.fileToUpload && (
                <Paper
                  style={{
                    padding: 20,
                    margin: 10,
                    maxWidth: '500px',
                    maxHeight: '200px'
                  }}
                >
                  <Grid container spacing={3} style={{ display: 'flex' }}>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      <Delete onClick={this.deleteFile} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      {this.state.fileToUpload.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      {this.state.isUploadInProgress && (
                        <LinearProgress style={{ width: 200 }} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor='age-simple'>
                          {this.props.multiLang.projectFile.selectMapping}*
                        </InputLabel>
                        <Select
                          value={this.state.dataset}
                          onChange={this.handleDataSetChange}
                        >
                          {this.state.listRuleId &&
                            this.state.listRuleId.map((element, index) => {
                              return (
                                <MenuItem key={index} value={element}>
                                  {element}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {this.state.fileType === '2D3DModel' &&
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label={this.props.multiLang.projectFile.siteName}
                          fullWidth={true}
                          type='text'
                          value={this.state.siteName}
                          onChange={(event) => this.setState({ siteName: event.target.value })}
                        />
                      </Grid>
                    }
                  </Grid>
                </Paper>
              )}
            </div>
          )}
        </DropZone>
        <DialogActions>
          <Button color='primary' onClick={this.uploadFile} disabled={(!this.state.fileToUpload) || (this.state.dataset === 'NONE') || (this.state.isUploadInProgress === true)} >
            {this.props.multiLang.projectFile.btnUploadFile}
          </Button>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  config: state.config,
  viewer: state.project.viewer,
  userId: state.auth.userId,
  s3: state.auth.s3
});

export default connect(mapStateToProps)(UploadFileProjectModal);
