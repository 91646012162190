/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";

function searchData(projectId, tagClasses, attributeCondition, pageSize, pageNumber, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    let promise = axios.get(config.urlSearchAPI + "/advancedSearch", {
        headers: headers,
        params: {
            projectId: projectId,
            tagClasses: tagClasses,
            attributeCondition: attributeCondition,
            pageSize: pageSize,
            pageNumber: pageNumber + 1
        }
    });

    if(callback) {
        promise.then(function (response) {
            if (!response.data)
                return callback("No data");
            return callback(null, response.data);
        });
        promise.catch(function (error) {
            callback(error);
        });
    } else return promise;
}

function searchValidateData(projectId, tagClasses, attributeCondition, pageSize, pageNumber, validate, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlSearchAPI + "/advancedSearch", {
        headers: headers,
        params: {
            projectId: projectId,
            tagClasses: tagClasses,
            attributeCondition: attributeCondition,
            pageSize: pageSize,
            pageNumber: 1,
            validate: validate
        }

    })
        .then(function (response) {
            if (!response.data) {
                return callback("No data");
            }
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

//Call Azure document search with pagination
function semanticSearch(query, pageSize, pageNumber, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;
    let promise = axios.post(config.urlSearchAPI + "/advanced", {query :query, top:pageSize, pageNumber: pageNumber}, {headers: headers} );
    if(callback) {
        promise.then(function (response) {
            if (!response.data)
                return callback("No data");
            return callback(null, response.data);
        });
        promise.catch(function (error) {
            callback(error);
        });
    } else return promise;

}



export {
    searchData, searchValidateData, semanticSearch
};