/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { parseModelData } from './Model-utils';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { parseDate, isDateISOCompliant } from 'utils/DateHelper';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import './ModelTab.css';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { getUserByFilter } from 'services/UserService';
import IconButton from '@material-ui/core/IconButton';

const AccordionSummary = withStyles({
  content: {
    color: '#0c4178',
    marginLeft: '30px !important'
  },

  expandIcon: {
    color: '#0c4178',
    left: '8px',
    right: 'unset',
    position: 'absolute'
  },

  root: {
    minHeight: 30,
    height: 22,
    '&$expanded': {
      minHeight: 30,
      maxHeight: 40
    }
  },
  expanded: {
    display: 'flex'
  }
})((props) => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = 'ExpansionPanelSummary';

class ModelTab extends Component {
  constructor() {
    super();
    this.state = {
      user: null
    };
  }

  onOpenInfoToolTip = (userId) => {
    if (userId) {
      getUserByFilter('userId', userId, (error, data) => {
        if (error) {
          console.error(error);
          this.setState({ user: 'N/A' });
        } else {
          this.setState({ user: data[0].name });
        }
      });
    } else {
      this.setState({ user: 'N/A' });
    }
  };

  renderValue = (attribute) => {
    // Retro compatible mode
    if (typeof attribute.value !== 'object') {
      if (isDateISOCompliant(attribute.value)) {
        return parseDate(
          attribute.value,
          this.props.multiLang.projectFile.dateFormatISO
        );
      }
      return attribute.value;
    }

    let { value, initialValue, unit, initialUnit, converted, valueType } = attribute.value;

    if (isDateISOCompliant(value) && valueType.toLowerCase() === 'date') {
      return parseDate(value, this.props.multiLang.projectFile.dateFormatISO);
    }

    if (converted && converted === 'true') {
      return `${initialValue} ${initialUnit}`;
    }

    return `${value} ${unit ? unit : ''}`;
  };

  render() {
    let sections = parseModelData(this.props.data);
    if (!sections)
      return (
        <Fragment>
          <div className='dp-notif-right'>
            <span className='dp-info-what'>
              {this.props.multiLang.propertyCard.noAttributes}
            </span>
          </div>
        </Fragment>
      );

    sections.sort((dataset1, dataset2) => dataset1.datasetPosition - dataset2.datasetPosition);
    return (
      <Fragment>
        <div className='tab-content'>
          <div className='expandedModel'>
            {sections.length > 0 &&
              sections.map((section, indexSection) => (
                <Accordion
                  key={indexSection}
                  className='expansionModel'
                  defaultExpanded
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container style={{ padding: 0 }}>
                      <Grid
                        item
                        xs={12}
                        sm={11}
                        style={{ height: 'auto', padding: 4 }}
                      >
                        {section.title}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ height: 'auto', right: 0 }}
                      >
                        <Tooltip
                          placement='right'
                          onOpen={() => {
                            this.onOpenInfoToolTip(section.user);
                          }}
                          title={
                            <Fragment>
                              <Typography style={{ color: 'white' }}>
                                User : {this.state.user}{' '}
                              </Typography>
                              <Typography style={{ color: 'white' }}>
                                Import Date :{' '}
                                {section.importDate
                                  ? parseDate(
                                    section.importDate,
                                    this.props.multiLang.projectFile
                                      .dateFormatISO
                                  )
                                  : 'N/A'}
                              </Typography>
                            </Fragment>
                          }
                        >
                          <IconButton
                            style={{
                              padding: 5,
                              float: 'right',
                              color: '#757575'
                            }}
                          >
                            <InfoIcon style={{ height: 16, width: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </AccordionSummary >
                  <AccordionDetails className='expansionDetailsModel'>
                    {section.attributes.map((attribute, index) => {
                      // Do not show attibutes if they are empty and user did not select the option to show hidden attributes
                      if (attribute.attribute[0] !== '_' && (this.props.showEmptyAttribute || this.renderValue(attribute).trim())) {
                        return (
                          <Grid key={index} container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              {attribute.attribute}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {this.renderValue(attribute)}
                            </Grid>
                          </Grid>
                        );
                      }
                      return <Fragment key={index}></Fragment>;
                    })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(ModelTab);
