/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import PropertyCardPanel from "./PropertyCardPanel";
import NavigationViewCardPanel from "./NavigationViewCardPanel";
import ViewMarkupPanel from "./ViewMarkupPanel";
import BreakdownNavigationPanel from "./BreakdownNavigationPanel";
import AttachTagPanel from "./AttachTagPanel";

import { store } from "utils/store";
import { mainViewerAction } from "actions/MainViewerAction";
import { getTagFromId } from "services/ObjectService";
import SharePanel from "./SharePanel";
const Autodesk = window.Autodesk;
const THREE = window.THREE;
const OBJECT_RELATION = "Has_An_Event";

export class DTPButtonGroupExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    let unmount = () => {
      this.viewMarkupPanel = null;
    };
    this.propertyPanel = new PropertyCardPanel(
      unmount,
      viewer.clientContainer.id
    );
    this.propertyPanel.isVisible = false;
    //this.modelTool = null;
    // Add listner on screen resize to reposition explode slider
    window.addEventListener("resize", () => this.positionSlider());
  }

  async onSelectionEvent(event) {
    //if the selection is triggered by other viewers
    if (sessionStorage.getItem("tagSelectionSource") === "others") {
      sessionStorage.removeItem("tagSelectionSource");
    } else {
      // this if avoid to trigger onSelectionEvent when user change Viewer
      if (
        store
          .getState()
          .mainViewer.viewers.find((v) => v.viewerType === "FORGE_VIEWER")
      ) {
        await this.setSelectedTags(event);
      }
      this.propertyPanel.refreshData();
    }
  }

  /**
   * Put selected Forge Object Tagss to Store
   */
  async setSelectedTags(event) {
    //store.dispatch({ type: mainViewerAction.initObjectIds, selectedIds: event.dbIdArray });
    let { projectId } = store.getState().project;
    let viewerInformation = event.target.clientContainer.id.split("#");
    let viewer = viewerInformation[0];
    let modelId = viewerInformation[1];
    let revision = viewerInformation[2];
    let ids = event.dbIdArray;
    let tagNames = [];
    if (ids.length > 0) {
      // Get the tgs of selected item ids and update 'focusedObjectsTagNames' in the store
      for (const id of ids) {
        await getTagFromId(
          projectId,
          modelId,
          revision,
          "" + id,
          viewer,
          (error, data) => {
            if (error) {
              console.error(error);
              return;
            }
            if (data) {
              tagNames.push(data.Tag);
            }
          }
        );
      }
    }
    store.dispatch({
      type: mainViewerAction.initTagNames,
      focusedObjectsTagNames: tagNames.length > 0 ? tagNames : null,
      fromViewer: true
    });
  }

  /**
   * Get Objects Ids From Tag Names
   * This function is triggered when Forge Viwer finish to load
   * Focus on ids
   */
  onViewerCompleteToload(viewer) {
    let openMarkupCard = store.getState().mainViewer.openViewMarkupCard
      ? store.getState().mainViewer.openViewMarkupCard.open
      : undefined;

    if (openMarkupCard) {
      if (!this.viewMarkupPanel) {
        let unmount = () => {
          this.viewMarkupPanel = null;
        };
        this.viewMarkupPanel = new ViewMarkupPanel(
          unmount,
          this.clientContainer.id
        );
      }
    }

    store.dispatch({
      type: mainViewerAction.openMarkUpCard,
      openViewMarkupCard: {
        open: false,
        projectId: null,
        modelId: null,
        revision: null
      },
      openViewMarkupCardFunction: this.viewMarkup
    });

    this.createToolbar();
    if (
      store.getState().mainViewer.focusedObjectsTagNames !== null &&
      store.getState().mainViewer.focusedObjectsTagNames !== undefined &&
      store.getState().mainViewer.focusedObjectsTagNames.length !== 0
    ) {
      const tagNames = store.getState().mainViewer.focusedObjectsTagNames;
      const viewerId = viewer.appContainerId;
      let actualViewer = store
        .getState()
        .mainViewer.viewers.find((v) => v.id === viewerId);
      if (actualViewer) {
        actualViewer.focusOnObjects(tagNames, viewerId);
      }
    }
  }

  onExtensionLoaded(event) {
    // Add css class to the main toolbar
    this.viewer.toolbar.addClass("right-vertical-toolbar");
    this.viewer.toolbar.getControl("modelTools") &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool") &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .setToolTip(store.getState().multiLang.toolbar.clipping);

    this.viewer.toolbar.getControl("modelTools") &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool") &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool").subMenu &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .subMenu.getControl("toolbar-sectionTool-x")
        .setToolTip(store.getState().multiLang.toolbar.xPane) &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .subMenu.getControl("toolbar-sectionTool-y")
        .setToolTip(store.getState().multiLang.toolbar.yPane) &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .subMenu.getControl("toolbar-sectionTool-z")
        .setToolTip(store.getState().multiLang.toolbar.zPane) &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .subMenu.getControl("toolbar-sectionTool-box")
        .setToolTip(store.getState().multiLang.toolbar.box) &&
      this.viewer.toolbar
        .getControl("modelTools")
        .getControl("toolbar-sectionTool")
        .setToolTip(store.getState().multiLang.toolbar.clipping);

    this.viewer.toolbar.getControl("modelTools") &&
      this.viewer.toolbar
        .getControl("modelTools")
        .removeControl("toolbar-explodeTool");

    this.viewer.toolbar.getControl("measureTools") &&
      this.viewer.toolbar
        .getControl("measureTools")
        .getControl("toolbar-delete") &&
      this.viewer.toolbar
        .getControl("measureTools")
        .getControl("toolbar-delete")
        .setToolTip("Delete");

    this.viewer.toolbar.getControl("measureTools") &&
      this.viewer.toolbar
        .getControl("measureTools")
        .getControl("toolbar-settings") &&
      this.viewer.toolbar
        .getControl("measureTools")
        .getControl("toolbar-settings")
        .setToolTip("Settings");

    this.viewer.toolbar.getControl("settingsTools") &&
      this.viewer.toolbar
        .getControl("settingsTools")
        .removeControl("toolbar-propertiesTool") &&
      this.viewer.toolbar
        .getControl("settingsTools")
        .removeControl("toolbar-fullscreenTool");
    // Set Tooltip for model structure tool
    if (
      this.viewer.toolbar.getControl("settingsTools") &&
      this.viewer.toolbar
        .getControl("settingsTools")
        .getControl("toolbar-modelStructureTool")
    ) {
      let modelStructureTool = this.viewer.toolbar
        .getControl("settingsTools")
        .getControl("toolbar-modelStructureTool");
      modelStructureTool.setToolTip(store.getState().multiLang.toolbar.browser);
      // Fix height bug generated by making the toolbar vertical
      modelStructureTool.onClick = (event) => {
        this.viewer.showModelStructurePanel(
          !this.viewer.modelstructure.isVisible()
        );
        const modelStructurePanel = document.getElementById(
          "ViewerModelStructurePanel"
        );
        modelStructurePanel.style.height = "70%";
      };
    }

    this.viewer.toolbar.getControl("navTools") &&
      this.viewer.toolbar
        .getControl("navTools")
        .removeControl("toolbar-orbitTools") &&
      this.viewer.toolbar
        .getControl("navTools")
        .removeControl("toolbar-panTool") &&
      this.viewer.toolbar
        .getControl("navTools")
        .removeControl("toolbar-zoomTool") &&
      this.viewer.toolbar
        .getControl("navTools")
        .removeControl("toolbar-cameraSubmenuTool");

    this.viewer.toolbar.getControl("navTools") &&
      this.viewer.toolbar
        .getControl("navTools")
        .getControl("toolbar-bimWalkTool") &&
      this.viewer.toolbar
        .getControl("navTools")
        .getControl("toolbar-bimWalkTool")
        .setToolTip(store.getState().multiLang.toolbar.firstPerson);

    //this.viewer.toolbar.getControl("modelTools").getControl("toolbar-measurementSubmenuTool").setToolTip("Measure");
  }

  /**
   * Called when extension is loaded by viewer.
   */
  load() {
    this.onExtensionBinded = this.onExtensionLoaded.bind(this);
    this.onSelectionBinded = this.onSelectionEvent.bind(this);
    this.onViewerCompleteToloadBinded = this.onViewerCompleteToload.bind(
      this,
      this.viewer
    );
    this.onSlider = this.onSlider.bind(this);
    this.viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.onSelectionBinded
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      this.onExtensionBinded
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      this.onViewerCompleteToloadBinded
    );

    return true;
  }

  /**
   * Called when extension is unloaded by the viewer.
   */
  unload() {
    //console.debug('Extension unloaded');
    this.viewer.removeEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      this.onExtensionBinded
    );
    this.viewer.removeEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.onSelectionBinded
    );
    this.viewer.removeEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      this.onViewerCompleteToloadBinded
    );
    this.onExtensionBinded = null;
    this.onSelectionBinded = null;
    this.onViewerCompleteToloadBinded = null;

    this.destroyToolbar();

    if (this._panel) {
      this.viewer.removePanel(this._panel);
      this._panel.uninitialize();
      this._panel = null;
    }
    window.removeEventListener("resize", () => this.positionSlider());
    return true;
  }

  createToolbar() {
    var toolbarVertical = new Autodesk.Viewing.UI.ToolBar("toolbar-Vertical");

    this.btnProperty = new Autodesk.Viewing.UI.Button(
      "ButtonGroupExtension.Content-card-2"
    );
    this.btnProperty.addClass("button-content-card-property");
    this.btnProperty.setToolTip(store.getState().multiLang.toolbar.details);
    this.btnProperty.onClick = (e) => {
      this.onClickProperty(e);
    };

    this.btnAttacgTag = new Autodesk.Viewing.UI.Button(
      "ButtonGroupExtension.Content-card-2"
    );
    this.btnAttacgTag.addClass("button-content-card-attach");
    this.btnAttacgTag.setToolTip(store.getState().multiLang.attachTag.linking);
    this.btnAttacgTag.onClick = (e) => {
      this.onClickAttachTag(e);
    };

    this.btnShare = new Autodesk.Viewing.UI.Button(
      "ButtonGroupExtension.Content-card-2"
    );
    this.btnShare.addClass("button-content-card-share");
    this.btnShare.setToolTip(store.getState().multiLang.toolbar.share);
    this.btnShare.onClick = (e) => {
      this.onClickShare(e);
    }

    this.btnNavigation = new Autodesk.Viewing.UI.Button(
      "ButtonGroupExtension.models-2d"
    );
    this.btnNavigation.addClass("button-content-card-models-2d-3d");
    this.btnNavigation.setToolTip(store.getState().multiLang.toolbar.models);
    this.btnNavigation.onClick = (e) => {
      this.onClickNavigation(e);
    };

    // this.btnReporting = new Autodesk.Viewing.UI.Button(
    //   'ButtonGroupExtension.reporting'
    // );
    // this.btnReporting.addClass('button-content-card-reporting');
    // this.btnReporting.setToolTip(store.getState().multiLang.toolbar.reports);
    // this.btnReporting.onClick = (e) => {
    //   this.onClickReporting(e);
    // };

    this.btnVExplode = new Autodesk.Viewing.UI.Button(
      "DTPButtonGroupExtension.VExplode"
    );
    this.btnVExplode.addClass("button-explode");
    this.btnVExplode.setToolTip(store.getState().multiLang.toolbar.vExplode);
    this.btnVExplode.onClick = (e) => {
      this.onClickVExplode(e);
    };

    this.btnBreakdown = new Autodesk.Viewing.UI.Button(
      "DTPButtonGroupExtension.Breakdwon"
    );
    this.btnBreakdown.addClass("button-breakdown");
    this.btnBreakdown.setToolTip(store.getState().multiLang.toolbar.breakdown);
    this.btnBreakdown.onClick = (e) => {
      this.onClickBreakdown(e);
    };

    // add button to the goup
    const ctrlGroupProperties = new Autodesk.Viewing.UI.ControlGroup(
      "ButtonGroupExtension.PropertiesControlGroup"
    );
    ctrlGroupProperties.addClass("toolbar-vertical-buttonsGroup");

    const ctrlGroupViewable = new Autodesk.Viewing.UI.ControlGroup(
      "ButtonGroupExtensionViewable.ViewableControlGroup"
    );
    ctrlGroupViewable.addClass("toolbar-vertical-buttonsGroup");

    // const ctrlGroupReporting = new Autodesk.Viewing.UI.ControlGroup(
    //   'DTPButtonGroupExtension.ReportingControlGroup'
    // );
    // ctrlGroupReporting.addClass('toolbar-vertical-buttonsGroup');

    const ctrlGroupVExplode = new Autodesk.Viewing.UI.ControlGroup(
      "DTPButtonGroupExtension.VExplodeControlGroup"
    );
    ctrlGroupVExplode.addClass("toolbar-vertical-buttonsGroup");

    const ctrlGroupBreakdown = new Autodesk.Viewing.UI.ControlGroup(
      "DTPButtonGroupExtension.BreakdownControlGroup"
    );
    ctrlGroupBreakdown.addClass("toolbar-vertical-buttonsGroup");

    const ctrlGroupAttachTag = new Autodesk.Viewing.UI.ControlGroup(
      "DTPButtonGroupExtension.AttachTagControlGroup"
    );
    ctrlGroupAttachTag.addClass("toolbar-vertical-buttonsGroup");

    const ctrlGroupShare = new Autodesk.Viewing.UI.ControlGroup(
      "DTPButtonGroupExtension.ShareControlGroup"
    );
    ctrlGroupShare.addClass("toolbar-vertical-buttonsGroup");

    ctrlGroupProperties.addControl(this.btnProperty);

    ctrlGroupViewable.addControl(this.btnNavigation);
    // ctrlGroupReporting.addControl(this.btnReporting);
    ctrlGroupVExplode.addControl(this.btnVExplode);
    ctrlGroupBreakdown.addControl(this.btnBreakdown);
    ctrlGroupAttachTag.addControl(this.btnAttacgTag);
    ctrlGroupShare.addControl(this.btnShare);
    this.btnReview = new Autodesk.Viewing.UI.Button("ViewMarkupPanel");
    this.btnReview.addClass("button-view-markup");
    this.btnReview.setToolTip(store.getState().multiLang.markup.viewMarkup);
    this.btnReview.onClick = (e) => {
      this.viewMarkup(e, this.viewer);
    };

    // add button to the goup
    const ctrlGroup = new Autodesk.Viewing.UI.ControlGroup(
      "MarkupExtension.ControlGroup"
    );
    ctrlGroup.addClass("toolbar-vertical-groupClass");
    toolbarVertical.addControl(ctrlGroup);

    const ctrlGroupReview = new Autodesk.Viewing.UI.ControlGroup(
      "ButtonGroupExtensionViewable.ReviewControlGroup"
    );
    ctrlGroupReview.addClass("toolbar-vertical-buttonsGroup");
    ctrlGroupReview.addControl(this.btnReview);

    // add group to the vertical toolbar
    toolbarVertical.addControl(ctrlGroupProperties);
    toolbarVertical.addControl(ctrlGroupBreakdown);
    toolbarVertical.addControl(ctrlGroupAttachTag);
    toolbarVertical.addControl(ctrlGroupReview);
    toolbarVertical.addControl(ctrlGroupViewable);
    toolbarVertical.addControl(ctrlGroupShare);
    // toolbarVertical.addControl(ctrlGroupReporting);
    //toolbarVertical.addControl(this.ctrlGroupVExplode);

    var toolbarHtml = document.createElement("div");
    toolbarHtml.setAttribute("id", "toolbarId");
    this.viewer.container.append(toolbarHtml);
    toolbarHtml.appendChild(toolbarVertical.container);

    this.viewer.toolbar.addControl(ctrlGroupVExplode);
  }

  destroyToolbar() {
    const toolbar = this.viewer.getToolbar(false);
    const verticalToolBar = document.getElementById("toolbarId");
    if (verticalToolBar) {
      verticalToolBar.parentNode.removeChild(verticalToolBar);
    }

    if (toolbar) {
      const ctrlGroupProperties = toolbar.getControl(
        "ButtonGroupExtension.PropertiesControlGroup"
      );

      const ctrlGroupViewable = toolbar.getControl(
        "ButtonGroupExtensionViewable.ViewableControlGroup"
      );
      // const ctrlGroupReview = toolbar.getControl(
      //   'ButtonGroupExtensionViewable.ReviewControlGroup'
      // );

      toolbar.removeControl(ctrlGroupProperties);
      toolbar.removeControl(ctrlGroupViewable);
      // toolbar.removeControl(ctrlGroupReview);
    }
  }

  /**
   * Called when user clicks on custom button
   * @param {Object} e - event object
   */
  onClickProperty(e) {
    if (!this.propertyPanel) {
      let unmount = () => {
        this.propertyPanel = null;
      };
      this.propertyPanel = new PropertyCardPanel(
        unmount,
        this.viewer.clientContainer.id
      );
    } else {
      this.propertyPanel.toggleVisibility();
    }
  }

  /**
   * Called when user clicks on custom button
   * @param {Object} e - event object
   */
  onClickNavigation(e) {
    if (!this.navigationPanel) {
      this.navigationPanel = new NavigationViewCardPanel(
        this.viewer.clientContainer.id
      );
    } else {
      this.navigationPanel.toggleVisibility();
    }
  }

  floorExplode(scale, model) {
    model = model || this.viewer.model;
    var fragList = model.getFragmentList();
    var pt = new THREE.Vector3();

    //Input scale is in the range 0-1, where 0
    //means no displacement, and 1 maximum reasonable displacement.
    scale *= 2;

    var boxes = fragList.fragments.boxes;
    var nbFrags = fragList.getCount();
    for (var fragId = 0; fragId < nbFrags; ++fragId) {
      if (scale === 0) {
        fragList.updateAnimTransform(fragId);
      } else {
        var box_offset = fragId * 6;
        var cz = Math.floor(boxes[box_offset + 2] / 10) * 10;
        pt.z = cz * scale * 4;

        fragList.updateAnimTransform(fragId, null, null, pt);
      }
    }
  }

  getAllDbIds() {
    var instanceTree = this.viewer.model.getData().instanceTree;

    var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

    return allDbIdsStr.map(function (id) {
      return parseInt(id, 10);
    });
  }

  onClickReporting(e) {
    /*var allIds = this.getAllDbIds();
        const THREE = window.THREE;
        var idToIsolate = [];
        var equipments = [
            //stairs
            {
                ids: [1164, 1280, 1392, 1960, 2854, 3017],
                color: {
                    red: 255 / 255,
                    green: 0 / 255,
                    blue: 0 / 255
                }
            },
            // railing
            {
                ids : [1082, 1991, 3115, 3145],
                color : {
                    red : 0/255,
                    green : 112/255,
                    blue : 173/255
                }
            },
            // flow fitting
            {
                ids: [3389, 3390, 3391, 3392, 3393, 3467, 3496, 3794, 3798, 3810, 3947, 3948, 3989, 3990, 7650, 7652, 7658],
                color: {
                    red: 0 / 255,
                    green: 255 / 255,
                    blue: 0 / 255
                }
            },
            // Walls
            {
                ids : [954, 969, 982, 991, 1038, 1048, 1049, 1865, 1925 , 2103],
                color : {
                    red : 112/255,
                    green : 48/255,
                    blue : 160/255
                }
            },
            // Windows
            {
                ids: [1009, 1010, 1075, 3275, 3294],
                color: {
                    red: 255 / 255,
                    green: 128 / 255,
                    blue: 0 / 255
                }
            },
            // Doors
            {
                ids: [1107, 3300, 4002, 5486],
                color: {
                    red: 255 / 255,
                    green: 255 / 255,
                    blue: 0 / 255
                }
            },
            // Colums
            {
                ids: [1004, 1005, 1006, 1922],
                color: {
                    red: 0 / 255,
                    green: 128 / 255,
                    blue: 0 / 255
                }
            }

        ];

        equipments.forEach((equipment) => {
            var ids = equipment.ids;

            if (!ids) {
                return;
            }

            //this.viewer.isolate(ids);
            this.viewer.model.getObjectTree((instanceTree) => {
                const color = new THREE.Vector4(equipment.color.red, equipment.color.green, equipment.color.blue, 0.5);

                // process nodes recursively and apply given color as theming
                ids.forEach((id) => {
                    idToIsolate.push(id);
                    instanceTree.enumNodeChildren(id, (childId) => {
                        this.viewer.setThemingColor(childId, color);
                    }, true);
                });
                // force repaint
                this.viewer.impl.invalidate(true);
            });
        })

        this.viewer.isolate(idToIsolate);
        var array1 = [];
        array1 = allIds.filter(val => !idToIsolate.includes(val));
        array1.forEach((arr) => {
            //let node = this.viewer.model.getData().instanceTree.nodeAccess.nodes[arr];
            this.viewer.model.getInstanceTree().setNodeOff(arr, true);
        });*/
  }

  onClickVExplode(e) {
    let sliderClasslist = document.getElementById("sliderHorizontal").classList;
    this.positionSlider();
    if (sliderClasslist.contains("hide")) {
      sliderClasslist.remove("hide");
      sliderClasslist.add("show");
    } else {
      sliderClasslist.remove("show");
      sliderClasslist.add("hide");
    }
  }

  positionSlider = () => {
    let slider = document.getElementById("sliderHorizontal");

    // Positionning slider under the button
    slider.style.right = "10px";
    slider.style.top =
      this.btnVExplode.container.offsetTop +
      this.btnVExplode.container.offsetHeight +
      50 +
      "px";
  };

  onClickBreakdown = (e) => {
    if (!this.breakdownPanel) {
      this.breakdownPanel = new BreakdownNavigationPanel(
        this.viewer.clientContainer.id
      );
    } else {
      this.breakdownPanel.toggleVisibility();
    }
  };

  onClickAttachTag(e) {
    if (!this.attachTagPanel) {
      this.attachTagPanel = new AttachTagPanel(this.viewer.clientContainer.id);
    } else {
      this.attachTagPanel.toggleVisibility();
    }
  }

  onClickShare(e) {
    if (!this.sharePanel) {
      this.sharePanel = new SharePanel(this.viewer.clientContainer.id);
    } else {
      this.sharePanel.toggleVisibility();
    }
  }

  viewMarkup(e) {
    /*if (!this.viewMarkupPanel) {*/
    let unmount = () => {
      this.viewMarkupPanel = null;
    };
    this.viewMarkupPanel = new ViewMarkupPanel(
      unmount,
      this.viewer.clientContainer.id
    );
    //}
  }

  onSlider = (val) => {
    this.floorExplode(val);
    this.viewer.impl.sceneUpdated(true);
  };
}

// Register extension in the viewer
Autodesk.Viewing.theExtensionManager.registerExtension(
  "DTPButtonGroupExtension",
  DTPButtonGroupExtension
);
