/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from 'utils/store';
class SharePanel {
    constructor(viewerId) {
        this.viewerId = viewerId;
        this.openCard = store.getState().card.openCard;
        this.closeCard = store.getState().card.closeCard;
        this.options = {
            height: 400,
            width: 600,
            close: () => {
                this.isVisible = false;
            }
        };
        this.toggleVisibility();
    }

    toggleVisibility() {
        this.data = {
            title: store.getState().multiLang.sharePanel.title,
            viewerId: this.viewerId
        };
        if (this.isVisible) {
            this.closeCard(this.indexCard);
        } else {
            this.indexCard = this.openCard(
                'SHARE_CARD',
                this.data,
                100,
                100,
                this.options
            );
        }
        this.isVisible = !this.isVisible;
    }

}

export default SharePanel;
