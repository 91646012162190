/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

export const extentionForModel = ["3DM", "3DS", "A", "ASM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DGN", "DLV3", "DWF", "DWFX", "DWG",
    "DWT", "DXF", "EMODE", "EXP", "F3D", "FBX", "G", "GBXML", "GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM",
    "JT", "MAX", "MODEL", "MPF", "MSR", "NEU", "NWC", "NWD", "OBJ", "PMLPRJ", "PMLPRJZ", "PRT", "PRT\\.\\d+$", "PSMODEL",
    "RCP", "RVT", "SAB", "SAT", "SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR", "ZIP", "VIEW"];

export const extentionFor1D = [
    "XLS",
    "XLSX",
    "CSV"
];

export const extentionForDocument = [
    "pdf","PDF", "doc", "DOC", "docx", "DOCX", "xls", "XLS", "xlsx", "XLSX", "ppt", "PPT", "pptx","PPTX","mp4","MP4","mp3","MP3"
];

export function isModel(file) {
    var filename = file.key ? file.key : file.name;
    return extentionForModel.includes(filename.substring(filename.lastIndexOf(".") + 1).toUpperCase());
}

export function is1D(file) {
    return compare(file, extentionFor1D);
}

export function isDocument(file) {
    return compare(file, extentionForDocument);
}

function compare(file, fileExt) {
    // for upload or come from api
    var filename = file.key ? file.key : file.name;
    return extentionFor1D.includes(filename.substring(filename.lastIndexOf(".") + 1).toUpperCase());
}