/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from 'react'

const IconGraph = props => (
  <svg width={18} height={18} viewBox="0 0 55 55" {...props}>
    <g fillRule="evenodd" >
      <path d="M49 0c-3.309 0-6 2.691-6 6 0 1.035.263 2.009.726 2.86l-9.829 9.829a7.943 7.943 0 0 0-9.795-.001l-7.669-7.669A3.947 3.947 0 0 0 17 9c0-2.206-1.794-4-4-4S9 6.794 9 9s1.794 4 4 4c.74 0 1.424-.215 2.019-.567l7.669 7.669a7.953 7.953 0 0 0 0 9.795L10.024 42.562A5.971 5.971 0 0 0 6 41c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6a5.966 5.966 0 0 0-.726-2.86l12.829-12.829a7.953 7.953 0 0 0 3.898 1.619v10.16c-2.833.478-5 2.942-5 5.91 0 3.309 2.691 6 6 6s6-2.691 6-6c0-2.967-2.167-5.431-5-5.91V32.93a7.953 7.953 0 0 0 3.898-1.619l7.669 7.669A3.945 3.945 0 0 0 41 41c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4c-.74 0-1.424.215-2.019.567l-7.669-7.669a7.953 7.953 0 0 0 0-9.795l9.665-9.665A5.967 5.967 0 0 0 49 12c3.309 0 6-2.691 6-6s-2.691-6-6-6zM11 9c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM6 51c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm27-2c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4zm-4-18c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm18 10c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm2-31c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
    </g>

  </svg>
)

export default IconGraph
