/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addUserToGroup,
  deleteUserFromGroup
} from 'services/UserService';
import { getGroupUsers } from 'services/GroupService';
import MUIDataTable from 'mui-datatables';
import { parseDate } from 'utils/DateHelper';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  TextField
} from '@material-ui/core';

class AddUserToGroup extends Component {
  state = {
    rows: [],
    users: [],
    selectedGroups: [],
    groupUsers: [],
    usersToDelete: [],
    groupName: '',
    description: '',
    enableAssign: false
  };

  componentDidMount() {
    getGroupUsers(this.props.group[0].props.children[1])
      .then((groupUsers) => {
        getGroupUsers(this.props.envGroupId)
          .then((users) => {
            let tableData = [];
            users.data.forEach((element) => {
              tableData.push([
                element.Id,
                element.email,
                parseDate(
                  element.UserCreateDate,
                  this.props.multiLang.projectFile.dateFormatISO
                )
              ]);
            });
            let groupUsersByName = groupUsers.data.map(
              (element) => element.Username
            ); // Users specific to the current group
            let allUsersByName = users.data.map((element) => element.Username); // All users
            const indexesToSelect = groupUsersByName.map((element) =>
              allUsersByName.indexOf(element)
            ); // Retrieve indexes of users already in the group
            this.setState({ users: tableData, groupUsers: indexesToSelect, groupName: this.props.group[1], description: this.props.group[2] });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.error(error));
  }

  columnsIn = [
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        display: false,
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.email,
      label: this.props.multiLang.administration.email,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.creationDate,
      label: this.props.multiLang.administration.creationDate,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      this.setState({ rows: allRowsSelected, enableAssign: true });
    },
    customToolbarSelect: () => { }
  };

  /*
        Users that are already in the group are selected by default.
        To delete them, unselect the corresponding rows and then click button "Assign" 
    */
  addUsersToGroup = (users) => {
    // If group users have been modified, update group users
    if (this.state.enableAssign) {
      let usersIndex = users.map((user) => user.dataIndex);
      let usersToDelete = this.state.groupUsers.filter(
        (element) => !usersIndex.includes(element)
      );
      console.log('users to delete ', usersToDelete);
      let usersToAdd = usersIndex.filter(
        (user) => !this.state.groupUsers.includes(user)
      );
      console.log('users to add ', usersToAdd);
      let updateUsersList = [...this.state.users];

      usersToAdd.forEach((indexUser) => {
        let userToAdd = updateUsersList[indexUser];
        addUserToGroup(userToAdd[0], this.props.group[0].props.children[1])
          .then((data) => {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.administration.success.userAddSucceeded
            );
          })
          .catch((error) => {
            console.error(error);
            this.props.snackBar.addSnackError(
              this.props.multiLang.administration.errors.userAddFailed
            );
          });
      });

      usersToDelete.forEach((indexUser) => {
        let userToDelete = updateUsersList[indexUser];
        deleteUserFromGroup(
          userToDelete[0],
          this.props.group[0].props.children[1]
        )
          .then((data) => {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.administration.success.userAddSucceeded
            );
          })
          .catch((error) => {
            console.error(error);
            this.props.snackBar.addSnackError(
              this.props.multiLang.administration.errors.userAddFailed
            );
          });
      });
    }
    // If description has been modified, update group info
    if (this.state.description !== this.props.group[2]) {
      this.props.update(
        this.props.group[0].props.children[1],
        this.state.description,
        this.state.groupName,
        this.props.projectid
      );
    }

    this.props.onClose(false);
  };

  handleGroupNameInputChange = (event) => {
    this.setState({ groupName: event.target.value });
  };

  handleDescriptionInputChange = (event) => {
    this.setState({ description: event.target.value });
  };

  render() {
    this.options.rowsSelected = this.state.groupUsers;
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.multiLang.administration.users}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper style={{ padding: 20 }}>
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={this.state.groupName}
                      id='standard-name'
                      label={this.props.multiLang.administration.name}
                      fullWidth={true}
                      type='text'
                      disabled
                      onChange={(event) =>
                        this.handleGroupNameInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={this.state.description}
                      type='text'
                      id='standard-name'
                      label={this.props.multiLang.administration.description}
                      fullWidth={true}
                      onChange={(event) =>
                        this.handleDescriptionInputChange(event)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </Paper>
        </div>
        <div style={{ margin: 10 }}>
          <MUIDataTable
            title=''
            data={this.state.users}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
          <span>
            <Button
              disabled={!this.state.enableAssign && this.state.description === this.props.group[2]}
              variant='contained'
              color='primary'
              className='float-right'
              onClick={() =>
                this.addUsersToGroup(
                  this.state.rows
                )
              }
            >
              {this.props.multiLang.administration.assign}
            </Button>
          </span>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  envGroupId: state.config.envGroupId
});

export default connect(mapStateToProps)(AddUserToGroup);
