/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import GenericCardSection from 'components/generic-card/GenericCardSection';
import GenericCardAccordion from 'components/generic-card/GenericCardAccordion';
import uuid from 'uuidv4';

class RawDataCardPanel extends Component {

    constructor() {
        super();
        this.state = {
            id: uuid()
        }
    }

    render() {
        return <Fragment>
            <ul className="nav nav-tabs">
                {this.props.data && this.props.data.sections &&
                    this.props.data.sections.map((section, index) =>
                        <li className={(index === 0 ? "active" : "")} key={index}>
                            <a className="nav-link" data-toggle="tab" href={"#link" + index + '_' + this.state.id}>{section.title}</a>
                        </li>
                    )
                }
            </ul>
            <div className="tab-content">
                {this.props.data && this.props.data.sections &&
                    this.props.data.sections.map((section, indexSection) =>
                        <GenericCardSection key={indexSection} data={section} related={indexSection !== 0} val={indexSection + '_' + this.state.id} >

                            {section.groupAttributes &&
                                section.groupAttributes.map((groupAttribute, index) =>
                                    <GenericCardAccordion key={index} data={groupAttribute} >

                                        {groupAttribute.attributes.map((attribute, index) => {
                                            var content = (
                                                <li
                                                    key={index}
                                                    className="attribute"
                                                >
                                                    { /* button with action */}
                                                    {attribute.action &&
                                                        <button className="button--link attribute__name" onClick={() => attribute.action(attribute.paramsAction)}>
                                                            <span className="">{attribute.attribute}</span>
                                                        </button>
                                                    }

                                                    { /* p without action */}
                                                    {!attribute.action &&
                                                        <p className="attribute__name">{attribute.attribute}</p>
                                                    }

                                                    {(attribute.value || attribute.value === 0) && (
                                                        <p className="attribute__value">{attribute.value}</p>
                                                    )}
                                                </li>
                                            );

                                            return !attribute.action ? (
                                                <Fragment key={index}>{content}</Fragment>
                                            ) : (
                                                    content
                                                );
                                        })}
                                    </GenericCardAccordion>
                                )}
                        </GenericCardSection>
                    )
                }
            </div>
        </Fragment>
    }
}

const mapStateToProps = state => ({
    project: state.project,
    multiLang: state.multiLang
});

export default connect(mapStateToProps)(RawDataCardPanel); 