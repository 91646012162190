/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import Notification from "rc-notification";
import "rc-notification/assets/index.css";
import { initSnackBar } from "actions/SnackBarAction";

var notification = null;
Notification.newInstance(
  {
    style: {
      top: "90px",
      right: "10px",
      width: "250px"
    }
  },
  (n) => (notification = n)
);

class SnackBar extends Component {
  componentDidMount() {
    this.props.initSnackBar(
      this.addInfo,
      this.addError,
      this.addWarning,
      this.addSuccess
    );
  }

  componentWillUnmount() {
    this.props.initSnackBar(null);
    notification.destroy();
  }

  addInfo(message) {
    notification.notice({
      content: <span>{message}</span>,
      duration: 3,
      closable: true,
      prefixCls: "bg-info text-light info",
      style: {
        padding: "20px",

        margin: "3px"
      },
      maxCount: 6
    }); // backgroundColor: "lightblue",
  }

  addError(message) {
    notification.notice({
      content: <span>{message}</span>,
      duration: 3,
      closable: true,
      prefixCls: "bg-danger text-light error",
      style: {
        padding: "20px",

        margin: "3px"
      },
      maxCount: 6
    }); // backgroundColor: "crimson",
  }

  addWarning(message) {
    notification.notice({
      content: <span>{message}</span>,
      duration: 5,
      closable: true,
      prefixCls: "bg-warning text-dark warning",
      style: {
        padding: "20px",

        margin: "3px"
      },
      maxCount: 6
    }); //backgroundColor: "orange",
  }

  addSuccess(message) {
    notification.notice({
      content: <span>{message}</span>,
      duration: 3,
      closable: true,
      prefixCls: "bg-success text-light success",
      style: {
        padding: "20px",

        margin: "3px"
      },
      maxCount: 6
    }); //backgroundColor: "mediumseagreen",
  }

  render() {
    return null;
  }
}

export default connect(null, { initSnackBar })(SnackBar);
