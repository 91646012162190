/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";

function getProjectById(projectId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + "/project", {
        params: {
            projectId: projectId
        },
        headers: headers
    })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch((error) => {
            return callback(error);
        });
}

/**
 * Get project info from Zone Table
 * @param {*} projectId
 * @param {*} callback
 */
function getProject(projectId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + `/projects/${projectId}`, { headers: headers })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch((error) => {
            return callback(error);
        });
}

function getAllProject(callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + "/projects", {
        headers: headers
    })
        .then(response => {
            if (!response.data || response.data.length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch((error) => {
            return callback(error);
        });
}

function createProject(name, description) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.post(`${config.urlProjectAPI}/project`, {
        description: description,
        projectName: name
    },
        { headers: headers }
    );
}

function deleteProject(projectId) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.delete(`${config.urlProjectAPI}/project/${projectId}`,
        { headers: headers }
    );
}

function getProjects(callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + "/projectsv2", { headers: headers })
        .then(response => {
            if (!response.data || response.data.length === 0) {
                return callback("No data");
            }
            return callback(null, response.data);
        })
        .catch((error) => {
            return callback(error);
        });
}

function getGroupsByProject(projectId) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.get(`${config.urlProjectAPI}/project/${projectId}/groups`, { headers: headers });
}

function createBreakdown(projectid, name, description, rootClass, rootRelation, sub, groups, classExpansion, defaultBreakdownChecked = false, rootTagFilter = false) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.put(`${config.urlProjectAPI}/project/${projectid}/breakdown`,
        {
            name,
            description,
            rootClass,
            rootRelationship: rootRelation,
            userId: sub,
            groups,
            classExpansion,
            rootTagFilter
        },
        {
            params: { default: defaultBreakdownChecked },
            headers
        }
    );
}

function deleteBreakdown(projectId, name, groups) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.delete(`${config.urlProjectAPI}/project/${projectId}/breakdown/${name}`, { headers: headers, data: { groups: groups } });
}

function getGroupsFromBreakdownName(projectId, name) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.get(`${config.urlProjectAPI}/project/${projectId}/breakdown/${name}/groups`, { headers: headers });
}

function updateProject(projectId, description, name) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.patch(`${config.urlProjectAPI}/project/${projectId}`,
        {
            projectName: name,
            description: description
        },
        { headers: headers }
    );
}

function updateBreakdown(projectid, name, description, sub, groups, defaultBreakdownChecked = false, rootTagFilter = false) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.patch(`${config.urlProjectAPI}/project/${projectid}/breakdown/${name}`,
        {
            description,
            userId: sub,
            groups,
            rootTagFilter,
            isDefaultBreakdown: defaultBreakdownChecked
        },
        {
            headers
        }
    );
}



export {
    getProject,
    getProjectById,
    getAllProject,
    createProject,
    deleteProject,
    getProjects,
    getGroupsByProject,
    createBreakdown,
    deleteBreakdown,
    getGroupsFromBreakdownName,
    updateProject,
    updateBreakdown
};
