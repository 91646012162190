/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class SwitchViewModal extends Component {
  state = {
    selected: null,
    list: []
  };

  handleListItemClick(index) {
    this.setState({ selected: index });
  }
  convertListItemName(item) {
    let itemName = item.i.split('#')[0];
    switch (itemName) {
      case 'FORGE_VIEWER':
        return item.fileName;
      default:
        return item.i;
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.openModal}
        onClose={this.props.onCloseModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogTitle
            disableTypography={true}
            style={{ paddingLeft: 0 }}
            id='alert-dialog-title'
          >
            <Typography variant='h6'>
              {this.props.multiLang.navBar.switchViewModal}
            </Typography>
          </DialogTitle>
          <DialogContentText id='alert-dialog-description'>
            {this.props.multiLang.navBar.chooseViewToReplace}
          </DialogContentText>
          <List component='nav' aria-label='secondary mailbox folder'>
            {this.props.layouts.map((layout, index) => (
              <ListItem
                key={index}
                button
                selected={index === this.state.selected}
                onClick={() => this.handleListItemClick(index)}
              >
                <ListItemText primary={this.convertListItemName(layout)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCloseModal} color='primary'>
            {this.props.multiLang.navBar.cancelButton}
          </Button>
          <Button
            onClick={this.props.onSwitchView.bind(this, this.state.selected)}
            color='primary'
            autoFocus
          >
            {this.props.multiLang.navBar.switchViewButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(SwitchViewModal);
