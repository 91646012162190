/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Scrollbars } from 'react-custom-scrollbars';
import SyncButton from '@material-ui/icons/Link';
import SyncOffButton from '@material-ui/icons/LinkOff';
import CloseButton from '@material-ui/icons/Close';
import { mainViewerAction } from 'actions/MainViewerAction';
import ForgeViewer from 'components/viewers/forge-viewer/ForgeViewerComponent';
import DashboardViewer from 'components/viewers/dashboard-viewer/DashboardViewerComponent';
import RemovePanelDialog from 'components/viewers/split-viewer/RemovePanelDialogComponent';
import { getModelById } from 'services/ModelService';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../main-viewer/viewer.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class SplitViewer extends Component {
  static defaultProps = {
    rowHeight: 50,
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
  };

  constructor(props) {
    super(props);

    this.state = {
      panels: [],
      newCounter: 0,
      preloadedTag: null,
      //showBar: control display of the bar at top of each viewer
      showBar: {},
      //showBar: control synchronize button on the bar at top of each viewer
      synchronized: {},
      openDialog: false,
      panelName: ''
    };
    this.onRemovePanel = this.onRemovePanel.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
    this.onSyncChange = this.onSyncChange.bind(this);
  }

  viewer = {
    dashboardViewer: 'DASHBOARD_VIEWER',
    emptyViewer: 'EMPTY_VIEWER',
    forgeViewer: 'FORGE_VIEWER',
    splitViewer: 'SPLIT_VIEWER'
  };

  componentDidMount() {
    let panels = this.getPanelsConfig();
    let syncOptions,
      showBarOptions = {};
    Promise.all(panels).then((results) => {
      results = results.filter((i) => i != null);
      results.forEach((panel) => {
        //update sync and showBar options when create/remove panels
        let syncOption = { [panel.i]: true };
        let showBarOption = { [panel.i]: false };
        syncOptions = { ...syncOptions, ...syncOption };
        showBarOptions = { ...showBarOptions, ...showBarOption };
      });
      this.setState({
        panels: results,
        synchronized: syncOptions,
        showBar: showBarOptions
      });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.mainViewer) {
      let viewToAdd = this.props.mainViewer.viewToAdd;
      let lastAdd = prevProps.mainViewer.viewToAdd;
      if (viewToAdd && viewToAdd.data && !_.isEqual(viewToAdd, lastAdd)) {
        this.addNewPanel(viewToAdd);
      }

      let viewToSwitch = this.props.mainViewer.viewToSwitch;
      let lastSwitch = prevProps.mainViewer.viewToSwitch;
      if (
        viewToSwitch &&
        viewToSwitch.data &&
        !_.isEqual(viewToSwitch, lastSwitch)
      ) {
        this.switchPanel(viewToSwitch);
      }
    }
  }

  getPanelsConfig() {
    let params = new URLSearchParams(window.location.search);
    let projectId = params.get('projectId');
    return this.props.workspaceConfig.map(async function (panel) {
      if (!panel.display) return null;
      if (panel.view.split('#')[0] === 'FORGE_VIEWER') {
        let response = await getModelById(
          projectId,
          panel.view.split('#')[1],
          panel.view.split('#')[2]
        );
        if (!response.data) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.mainViewer.modelDetailsError
          );
        }
        return {
          i: panel.view.toString(),
          x: panel.x,
          y: 0,
          w: panel.width,
          h: panel.height,
          minW: panel.minWidth,
          minH: panel.minHeight,
          static: panel.static,
          closable: panel.closable,
          fileName: panel.fileName,
          forgeData: response.data
        };
      } else {
        return {
          i: panel.view.toString(),
          x: panel.x,
          y: 0,
          w: panel.width,
          h: panel.height,
          minW: panel.minWidth,
          minH: panel.minHeight,
          static: panel.static,
          closable: panel.closable,
          fileName: panel.fileName,
          projectOcc: panel.projectOcc
        };
      }
    });
  }

  createPanel(panel) {
    const panelName = panel.i;
    return (
      <div
        key={panelName}
        style={{
          height: 'auto',
          width: 'auto'
        }}
        className='grid'
        data-grid={panel}
      >
        <div
          className='DragPanel'
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 1,
            width: '100%',
            minHeight: '20px',
            textAlign: 'center',
            opacity: 0.8,
            backgroundColor: this.state.showBar[panelName]
              ? 'white'
              : 'transparent'
          }}
          onMouseEnter={() => this.toggleHover(panelName)}
          onMouseLeave={() => this.toggleHover(panelName)}
        >
          <span
            className='buttons'
            style={{
              display: this.state.showBar[panelName] ? 'inline-block' : 'none'
            }}
          >
            {this.state.synchronized[panelName] && (
              <SyncButton
                onClick={() => this.onSyncChange(panelName)}
                fontSize='small'
                style={{ color: 'DimGrey' }}
              />
            )}
            {!this.state.synchronized[panelName] && (
              <SyncOffButton
                onClick={() => this.onSyncChange(panelName)}
                fontSize='small'
                style={{ color: 'DimGrey' }}
              />
            )}
            {panel.closable && (
              <CloseButton
                onClick={() => this.handleDialog(panelName)}
                fontSize='small'
                style={{ color: 'DimGrey' }}
              />
            )}
          </span>
        </div>
        {panelName.split('#')[0] === this.viewer.forgeViewer && (
          <ForgeViewer
            data={panel.forgeData}
            tagNames={
              this.state.synchronized[panelName]
                ? this.props.mainViewer.focusedObjectsTagNames
                : undefined
            }
            id={panelName}
            tag={this.state.preloadedTag}
            selectedTab={this.props.selectedTab}
            synchronized={this.state.synchronized[panelName]}
          />
        )}
        {panelName === this.viewer.dashboardViewer && <DashboardViewer />}
      </div>
    );
  }

  addNewPanel(viewToAdd) {
    this.setState({
      preloadedTag: viewToAdd.tag
    });
    let panel = {
      i:
        viewToAdd.viewerType === this.viewer.forgeViewer
          ? viewToAdd.viewerType +
            '#' +
            viewToAdd.modelId +
            '#' +
            viewToAdd.data.revisionNumber
          : viewToAdd.viewerType,
      x: 0,
      y: Infinity,
      w: 8,
      h: 8,
      closable: true,
      fileName: viewToAdd.fileName,
      forgeData:
        viewToAdd.viewerType === this.viewer.forgeViewer
          ? viewToAdd.data
          : null,
      projectOcc:
        viewToAdd.viewerType === this.viewer.openCascadeViewer
          ? viewToAdd.data
          : null
    };
    this.setState({
      // Add a new item. It must have a unique key!
      panels: this.state.panels.concat(panel)
    });
    this.props.dispatch({
      type: mainViewerAction.addView,
      viewToAdd: {
        viewerType: null,
        fileName: null,
        data: null,
        tag: null
      }
    });
  }

  switchPanel(viewToSwitch) {
    this.setState({
      preloadedTag: viewToSwitch.tag
    });
    let oldPanel = this.state.panels.find(
      (panel) => panel.i === viewToSwitch.oldView
    );
    this.props.dispatch({
      type: mainViewerAction.removeViewer,
      viewerToRemove: viewToSwitch.oldView
    });
    let newPanel = { ...oldPanel };
    newPanel.i = viewToSwitch.newView;
    newPanel.fileName = viewToSwitch.fileName;
    switch (viewToSwitch.newView) {
      case this.viewer.forgeViewer:
        newPanel.i =
          viewToSwitch.newView +
          '#' +
          viewToSwitch.model +
          '#' +
          viewToSwitch.data.revisionNumber;
        newPanel.forgeData = viewToSwitch.data;
        break;
      default:
        break;
    }
    let {
      [oldPanel.i]: syncOption,
      ...modifiedSyncOptions
    } = this.state.synchronized;
    let {
      [oldPanel.i]: showBarOption,
      ...modifiedshowBarOptions
    } = this.state.showBar;
    let syncOptionToAdd = { [newPanel.i]: true };
    let showBarOptionToAdd = { [newPanel.i]: false };
    let newSyncOptions = { ...modifiedSyncOptions, ...syncOptionToAdd };
    let newshowBarOptions = {
      ...modifiedshowBarOptions,
      ...showBarOptionToAdd
    };
    this.setState({
      panels: _.reject(this.state.panels, { i: viewToSwitch.oldView }).concat(
        newPanel
      ),
      synchronized: newSyncOptions,
      showBar: newshowBarOptions
    });
    this.props.dispatch({
      type: mainViewerAction.switchView,
      viewToSwitch: {
        oldView: null,
        newView: null,
        fileName: null,
        data: null,
        tag: null
      }
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange(layout) {
    layout.forEach((grid) => {
      let panel = this.state.panels.find((panel) => panel.i === grid.i);
      grid.fileName = panel.fileName;
    });
    this.saveToLS('layout', layout);
  }
  onResizeStop(layout, oldItem) {
    let viewerId = oldItem.i;
    if (
      this.props.viewers.length > 0 &&
      viewerId.split('#')[0] === this.viewer.forgeViewer
    ) {
      let viewerToResize = this.props.viewers.find((v) => v.id === viewerId);
      if (viewerToResize) {
        viewerToResize.viewer.myCurrentViewer.resize();
      }
    }
  }

  onSyncChange(panel) {
    const panelSync = this.state.synchronized;
    panelSync[panel] = !this.state.synchronized[panel];
    this.setState({ synchronized: panelSync });
  }

  toggleHover(panel) {
    const hover = this.state.showBar;
    hover[panel] = !this.state.showBar[panel];
    this.setState({ showBar: hover });
  }

  handleDialog(panel) {
    this.setState({ openDialog: !this.state.openDialog, panelName: panel });
  }

  onRemovePanel(panel) {
    this.setState({
      panels: _.reject(this.state.panels, { i: panel }),
      openDialog: !this.state.openDialog
    });
    this.props.dispatch({
      type: mainViewerAction.removeViewer,
      viewerToRemove: panel
    });
    const { [panel]: syncOption, ...newSyncOptions } = this.state.synchronized;
    const { [panel]: showBarOption, ...newshowBarOptions } = this.state.showBar;
    this.setState({ synchronized: newSyncOptions, showBar: newshowBarOptions });
  }

  saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        'rgl-8',
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

  render() {
    if (!this.state.panels) {
      return (
        <Fragment>{this.props.multiLang.mainViewer.viewerLoading}</Fragment>
      );
    }
    return (
      <Scrollbars>
        <div>
          <ResponsiveReactGridLayout
            onBreakpointChange={this.onBreakpointChange}
            onLayoutChange={this.onLayoutChange}
            onResizeStop={this.onResizeStop}
            compactType='horizontal'
            {...this.props}
            draggableHandle='.DragPanel'
          >
            {_.map(this.state.panels, (panel) => this.createPanel(panel))}
          </ResponsiveReactGridLayout>
          <RemovePanelDialog
            openDialog={this.state.openDialog}
            panel={this.state.panelName}
            onCloseDialog={this.handleDialog}
            onRemovePanel={this.onRemovePanel}
          />
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  project: state.project,
  viewers: state.mainViewer.viewers,
  mainViewer: state.mainViewer
});

export default connect(mapStateToProps)(SplitViewer);
