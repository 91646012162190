/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";

function getFileList(projectId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + "/getFileList", {
        params: {
            projectId: projectId
        },
        headers: headers
    })
        .then(function (response) {
            if (!response.data)
                return callback("No data");
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getFile(projectId, fileId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlProjectAPI + "/file", {
        params: {
            projectId: projectId,
            fileId: fileId
        },
        headers: headers
    })
        .then(function (response) {
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function updateFileOptions(projectId, fileId, options) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    let body = {
        options: {
        }
    };

    for (const category in options) {
        body.options[category] = {};
        for (const key in options[category]) {
            body.options[category][key] = options[category][key];
        }
    }

    return axios.patch(`${config.urlProjectAPI}/project/${projectId}/files/${fileId}`,
        body,
        { headers: headers }
    );
}

function updateFileProperities(projectId, fileId, fields) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    let body = {
        fields: fields
    };

    return axios.patch(`${config.urlProjectAPI}/project/${projectId}/files/${fileId}`,
        body,
        { headers: headers }
    );
}

function deleteFile(projectId, fileId, modelId, blobName) {
    let headers = store.getState().auth.headers;
    let config = store.getState().config;
    let params = {
        modelId, blobName
    };

    return axios.delete(`${config.urlProjectAPI}/project/${projectId}/files/${fileId}`,
        {
            headers: headers,
            params: params
        }
    );
}
export {
    getFileList,
    getFile,
    updateFileOptions,
    updateFileProperities,
    deleteFile
};