/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from "utils/store";
class ViewMarkupPanel {
  constructor(unmount, viewerId) {
    this.viewerId = viewerId;
    this.openCard = store.getState().card.openCard;
    this.options = {
      close: () => {
        unmount();
        document.getElementById("markup-container").className = "hide"; // markup__screenshot
        let viewer = store
          .getState()
          .mainViewer.viewers.find((v) => v.id === viewerId)
          .viewer.myCurrentViewer;
        let buttonGroup = viewer.toolbar.getControl(
          "MarkupExtension.ControlGroup3"
        );
        if (!buttonGroup) {
          viewer.getExtension("Autodesk.Viewing.MarkupsCore").hide(); //Hiding markups restore the toolbar
        }
      },
      height: 400
    };
    this.toggleVisibility();
  }

  toggleVisibility() {
    this.data = {
      title: store.getState().multiLang.viewMarkup.listMarkups,
      viewerId: this.viewerId
    };

    this.indexCard = this.openCard(
      "MARKUP_LIST_CARD",
      this.data,
      200,
      150,
      this.options
    );
  }
}

export default ViewMarkupPanel;
