/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class Annotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      type: "",
      status: "",
      label: "",
      priority: "",
      title: ""
    };

    this.handleInputValue = this.handleInputValue.bind(this);
    this.handleDescriptionValue = this.handleDescriptionValue.bind(this);
    this.handlePriorityValue = this.handlePriorityValue.bind(this);
    this.action = this.action.bind(this);
    this.isEnabled = false;
    this.isValid = this.isValid.bind(this);
  }

  handleInputValue(event) {
    this.props.onChange([event.target.name], event.target.value);
  }

  handleDescriptionValue(event) {
    this.props.onChange(event.target.name, event.target.value);
  }

  handlePriorityValue(event) {
    this.props.onChange(event.target.name, event.target.value);
  }

  handleOption(event, name) {
    this.props.onChange([name], event.target.value);
  }

  action(action, close) {
    var viewer = this.props.mainViewer.viewers.find(
      (v) => v.id === this.props.viewerId
    ).viewer;
    if (close) {
      let markupExtension = viewer.myCurrentViewer.getExtension(
        "Autodesk.Viewing.MarkupsCore"
      );
      markupExtension.clear();
      markupExtension.hide();
      let buttonGroup = viewer.myCurrentViewer.toolbar.getControl(
        "MarkupExtension.ControlGroup3"
      );
      viewer.myCurrentViewer.toolbar.removeControl(buttonGroup);
      markupExtension.leaveEditMode();
      this.props.close();
    }
    if (action) {
      this.props.onSave();
    }
  }

  isValid(action) {
    if (action.validate && this.props.isValid) return this.props.isValid();
    return true;
  }

  render() {
    return (
      <Fragment>
        {this.props.data && this.props.data.type === "select" && (
          <div className="card__form-row">
            {/*<p className="card__form-label">
                    Designation
                    </p>*/}
            <div className="card__select select ">
              <select
                name={this.props.data.name}
                id={this.props.data.name}
                onChange={(e) => this.handleOption(e, this.props.data.name)}
              >
                <option value="" defaultValue="" data-default="">
                  {this.props.multiLang.annotation.selectA}
                  {this.props.data.name}
                </option>
                {this.props.data.options.map((element, index) => (
                  <option key={index} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {this.props.data && this.props.data.type === "textarea" && (
          <Fragment>
            <div className="card__form-row">
              <p className="card__form-label">{this.props.data.label}</p>
              <textarea
                name={this.props.data.name}
                rows="4"
                placeholder={this.props.data.placeholder}
                aria-label={this.props.data.placeholder}
                onChange={this.handleDescriptionValue}
              />
            </div>
          </Fragment>
        )}

        {this.props.data && this.props.data.type === "radio" && (
          <Fragment>
            <div className="card__form-row">
              <p className="card__form-label">
                {this.props.multiLang.annotation.priority}
              </p>
              <div className="markup__priority">
                {this.props.data.options.map((element, index) => (
                  <div className="radio-button" key={index}>
                    <input
                      type="radio"
                      id={"priority-" + element}
                      name={this.props.data.name}
                      value={element}
                      onChange={this.handlePriorityValue}
                    />
                    <label htmlFor={"priority-" + element}>{element}</label>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        )}

        {this.props.data && this.props.data.type === "input" && (
          <Fragment>
            <div className="card__form-row">
              <input
                type={this.props.data.inputType}
                name={this.props.data.name}
                placeholder={this.props.data.placeholder}
                aria-label={this.props.data.placeholder}
                className="form-input"
                onChange={this.handleInputValue}
              />
            </div>
          </Fragment>
        )}

        {this.props.data && this.props.data.type === "actions" && (
          <div className="button-row button-row--center">
            {this.props.data.list.map((element, index) => (
              <button
                key={index}
                type="button"
                className={
                  "button button--rounded " +
                  (element.color === "primary" ? "button--primary" : "")
                }
                onClick={() => this.action(element.action, element.close)}
                disabled={!this.isValid(element)}
              >
                {element.label}
              </button>
            ))}
          </div>
        )}

        {/*{this.props.data && this.props.data.type === "text" &&
                <Fragment>
                    <p className="annotation__title" htmlFor={this.props.data.label}>{this.props.data.label}</p>
                    <div id={this.props.data.label}> {this.props.data.value} </div>
                </Fragment>
            }*/}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  mainViewer: state.mainViewer
});

export default connect(mapStateToProps)(Annotation);
