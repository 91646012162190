/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from 'react'

const IconProperties = props => (
  <svg width={18} height={18} className="prefix__nav-item__icon" viewBox="0 0 32 32" {...props}>
    <g fillRule="evenodd">
      <path d="M5.676 1.447A4.365 4.365 0 0 0 1.32 5.801a4.367 4.367 0 0 0 4.356 4.355c2.299 0 4.146-1.817 4.297-4.08a.667.667 0 0 0 .056-.275.667.667 0 0 0-.056-.274c-.15-2.263-1.997-4.08-4.297-4.08zm0 1.334a3.009 3.009 0 0 1 3.02 3.02 3.01 3.01 0 0 1-3.02 3.021 3.012 3.012 0 0 1-3.022-3.021 3.01 3.01 0 0 1 3.022-3.02zM14 5.135a.667.667 0 0 0 0 1.334h16.25a.667.667 0 0 0 0-1.334zm-8.174 6.642a4.367 4.367 0 0 0-4.355 4.356 4.365 4.365 0 0 0 4.355 4.353 4.363 4.363 0 0 0 4.354-4.353 4.365 4.365 0 0 0-4.354-4.356zm0 1.334a3.01 3.01 0 0 1 3.02 3.022 3.009 3.009 0 0 1-3.02 3.02 3.01 3.01 0 0 1-3.021-3.02 3.012 3.012 0 0 1 3.021-3.022zm7.924 2.354a.667.667 0 0 0 0 1.334H30a.667.667 0 0 0 0-1.334zm-8.012 6.644a4.365 4.365 0 0 0-4.355 4.354 4.367 4.367 0 0 0 4.355 4.355 4.365 4.365 0 0 0 4.354-4.355 4.363 4.363 0 0 0-4.354-4.354zm0 1.334a3.009 3.009 0 0 1 3.02 3.02 3.01 3.01 0 0 1-3.02 3.021 3.012 3.012 0 0 1-3.021-3.021 3.01 3.01 0 0 1 3.021-3.02zm7.768 2.354a.667.667 0 0 0 0 1.334h16.25a.667.667 0 0 0 0-1.334z" />
    </g>
  </svg>
)

export default IconProperties
