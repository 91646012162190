/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";
var jwtDecode = require('jwt-decode');
var signalR = require('@aspnet/signalr');

/**
 * @param {*} deviceId IoT deviceId to watch for changes
 * @return Return a websocket connection
 */
async function openConnection(projectId) {
    console.log("open connection");
    var id_token = store.getState().auth.idToken;
    var webSocketUrl = store.getState().config.webSocketUrl;
    if (store.getState().config.cloud === "AZURE") {
        var headers = store.getState().auth.headers;
        var decoded = jwtDecode(id_token);
        headers['x-ms-client-principal-name'] = decoded.sub;
        let resp = await axios.post(`${webSocketUrl}/importsSignalRInfo`, null, { headers: headers });

        let connection = new signalR.HubConnectionBuilder()
            .withUrl(resp.data.url, { accessTokenFactory: () => resp.data.accessToken })
            .build();
        connection.onclose(function () { });
        connection
            .start()
            .then()// Potential to do something on initial load)
            .catch(console.error);
        return connection;
    }
    else {
        console.log("openning wss: " + webSocketUrl + "/import?projectId=" + projectId + "&Auth=" + id_token);
        return new WebSocket(webSocketUrl + "/import?projectId=" + projectId + "&Auth=" + id_token);
    }

}

function getImportByIds(ids, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    var params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("importIds", id);
    });
    axios.get(config.urlImportAPI + "/imports", {
        params: params,
        headers: headers
    })
        .then(function (response) {
            if (!response.data)
                return callback("No data");
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getImportByTagId(projectId, tag, callback) {
    if (store.getState().config.cloud === "AZURE") {
        return;
    }
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    var params = {
        projectId: projectId,
        tag: tag
    };

    axios.get(config.urlImportAPI + "/imports", {
        params: params,
        headers: headers
    })
        .then(function (response) {
            if (!response.data)
                return callback("No data");
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getImportByImportId(importId, callback) {
    if (store.getState().config.cloud === "AZURE") {
        return;
    }
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlImportAPI + "/imports/" + importId, {
        headers: headers
    })
        .then(function (response) {
            if (!response)
                return callback("No data");
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getImportByProjectId({
    projectId,
    ExclusiveStartKey,
    ScanIndexForward,
    page
}, callback) {

    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    var params = {
        projectId: projectId,
        ExclusiveStartKey: ExclusiveStartKey ? encodeURI(ExclusiveStartKey) : null,
        ScanIndexForward,
        page
    };

    axios.get(config.urlImportAPI + "/imports", {
        params: params,
        headers: headers
    })
        .then(function (response) {
            if (!response.data) {
                callback("No data");
            } else {
                callback(null, response.data);
            }
        })
        .catch(function (error) {
            callback(error);
        });
}

function getImportStatusByProjectIdAndImportId({
    projectId,
    importId,
    entity,
    limit,
    tag,
    ExclusiveStartKey,
    ScanIndexForward,
    status,
    page
}, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    var params = {
        entity,
        Limit : limit,
        tag,
        ExclusiveStartKey: ExclusiveStartKey ? encodeURI(JSON.stringify(ExclusiveStartKey)) : null,
        ScanIndexForward,
        status,
        page
    };

    axios.get(`${config.urlImportAPI}/imports/${importId}/${projectId}/status`, {
        params: params,
        headers: headers
    })
        .then(function (response) {
            if (!response.data) {
                callback("No data");
            } else {
                callback(null, response.data);
            }
        })
        .catch(function (error) {
            callback(error);
        });
}

export {
    openConnection,
    getImportByIds,
    getImportByTagId,
    getImportByImportId,
    getImportByProjectId,
    getImportStatusByProjectIdAndImportId
};
