/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getImportStatusByProjectIdAndImportId } from 'services/ImportService';

class ImportStatusDetail extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    let importId = this.props.importId;
    if (!importId) return;

    const params = {
      projectId: this.props.projectId,
      importId: this.props.importId,
      entity: 'ATTRIBUTE',
      tag: this.props.tag,
      limit: 1000,
      status: "ERROR,WARNING,KO"
    };

    getImportStatusByProjectIdAndImportId(params, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.importDetails.errors
        );
      }

      this.setState({ data: data.data });
    });
  }

  render() {
    return (
      <Fragment>
        <TableRow style={{ height: '30px' }}>
          <TableCell style={{ padding: '0', borderBottom: 'none' }}></TableCell>
          <TableCell style={{ color: 'gray' }}>
            {this.props.multiLang.importDetails.sourceAttribute}
          </TableCell>
          <TableCell style={{ color: 'gray' }}>
            {this.props.multiLang.importDetails.status}
          </TableCell>
          <TableCell style={{ color: 'gray' }}>
            {this.props.multiLang.importDetails.message}
          </TableCell>
        </TableRow>
        {this.state.data.map((attribute) => {
          const attributeClass = attribute.AttributeClass;
          return (
            <TableRow
              style={{ height: '30px' }}
              key={this.props.tag + '#' + attributeClass}
            >
              <TableCell
                style={{ padding: '0', borderBottom: 'none' }}
              ></TableCell>
              <TableCell>{attribute.AttributeClass}</TableCell>
              <TableCell>{attribute.Status}</TableCell>
              <TableCell>{attribute.ErrorMessage}</TableCell>
            </TableRow>
          );
        })}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  snackBar: state.snackBar,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(ImportStatusDetail);
