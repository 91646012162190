/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from "utils/store";

class PropertyCardPanel {
  viewer;
  viewerId;
  openCard;
  data;
  myCurrentViewer;
  indexCard;

  constructor(unmount, viewerId) {
    this.isInit = true;
    this.onPropertyClick = this.onPropertyClick.bind(this);
    this.idModels = new Map();
    this.unmount = unmount;
    this.viewerId = viewerId;
    this.viewer = store
      .getState()
      .mainViewer.viewers.find((v) => v.id === viewerId).viewer;
    this.openCard = store.getState().card.openCard;
    this.closeCard = store.getState().card.closeCard;
    this.updateCard = store.getState().card.updateCard;
    this.addSnackError = store.getState().snackBar.addSnackError;
    this.addSnackWarning = store.getState().snackBar.addSnackWarning;
    this.multiLang = store.getState().multiLang;

    this.options = {
      close: () => {
        this.unmount();
        this.isVisible = false;
        this.forcedClosed = true;
      },
      height: 500
    };
    this.myCurrentViewer = this.viewer.myCurrentViewer;

    this.data = {
      title: "Properties"
    };

    this.toggleVisibility();
  }

  onPropertyClick(indexOfModel) {
    const viewables = this.viewerbubble.search({ type: "geometry" });
    this.viewer.selectItem(
      viewables[indexOfModel].data,
      () => {
        console.log("succes");
      },
      () => {
        console.log("error");
      }
    );
  }

  toggleVisibility() {
    if (this.isInit) {
      this.isInit = false;
      return;
    }
    if (this.isVisible) {
      this.closeCard(this.indexCard);
    } else {
      this.processCard();
    }
    // If forcely closed, do not change visibility
    if (this.forcedClosed) {
      this.forcedClosed = false;
    } else {
      this.isVisible = !this.isVisible;
    }
  }

  processCard() {
    if (this.myCurrentViewer.getSelection().length > 1) {
      this.addSnackWarning(this.multiLang.propertyCard.selectMultipleError);
    } else if (this.myCurrentViewer.getSelection().length === 0) {
      this.addSnackWarning(this.multiLang.propertyCard.notElementSelectError);
    } else {
      this.data.id = [this.myCurrentViewer.getSelection()[0] + ""];
      this.data.tagIds = this.myCurrentViewer.getSelection();
      this.data.viewerId = this.viewerId;
      this.indexCard = this.openCard(
        "PROPERTY_CARD",
        this.data,
        150,
        150,
        this.options
      );
    }
  }
  refreshData() {
    if (this.isVisible) {
      this.processCard();
    }
    // Reset forcedClosed if it is true
    if (this.forcedClosed) {
      this.forcedClosed = false;
    }
  }
}

export default PropertyCardPanel;
