/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AttachSensorModalModal from './AttachSensorModal';
import IotTabChart from './IotTabChart';
import './IotTab.css';
import { isAuthorize } from 'services/AuthorizationService';

const attachSensor = {
  rightName: 'AttachSensor'
};
class IotTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceIds: [],
      anchorEl: null,
      isAttachSensorModalIsOpen: false
    };
  }

  componentDidMount() {
    this.loadDeviceIds();
  }

  componentWillUnmount() {}

  loadDeviceIds = () => {
    var sensors = this.props.data.IOT
      ? this.props.data.IOT.map((sensor) => sensor.Name)
      : [];
    this.setState({ deviceIds: [...new Set(sensors)] });
  };

  handleCloseAttachSensorModal = () => {
    this.setState({ isAttachSensorModalIsOpen: false });
  };

  handleAddSensor = () => {
    this.setState({ isAttachSensorModalIsOpen: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.isAttachSensorModalIsOpen && (
          <AttachSensorModalModal
            isOpen={this.state.isAttachSensorModalIsOpen}
            onClose={this.handleCloseAttachSensorModal}
            tag={this.props.tag}
          />
        )}

        {this.state.deviceIds.map((deviceId) => {
          return (
            <IotTabChart
              key={deviceId}
              deviceId={deviceId}
              tag={this.props.tag}
              viewerId={this.props.viewerId}
            />
          );
        })}

        <Grid
          container
          direction='row'
          justify='flex-end'
          style={{ height: 'inherit' }}
        >
          <Grid item>
            <Fab
              disabled={
                !isAuthorize(
                  attachSensor.rightName,
                  this.props.project.projectId
                )
              }
              onClick={this.handleAddSensor}
              size='small'
            >
              <svg
                viewBox='0 0 32 32'
                fit=''
                height='50%'
                width='50%'
                preserveAspectRatio='xMidYMid meet'
                focusable='false'
              >
                <path d='m 15.677734,2.8133138 a 0.66673335,0.66673335 0 0 0 -0.65625,0.6757812 v 12.15625 H 3.5 a 0.66673335,0.66673335 0 1 0 0,1.332031 h 11.521484 v 11.511719 a 0.66673335,0.66673335 0 1 0 1.332032,0 V 16.977376 H 28.5 a 0.66673335,0.66673335 0 1 0 0,-1.332031 H 16.353516 V 3.489095 A 0.66673335,0.66673335 0 0 0 15.677734,2.8133138 Z'></path>
              </svg>
            </Fab>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project
});

export default connect(mapStateToProps)(IotTab);
