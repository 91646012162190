/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mainViewerAction } from 'actions/MainViewerAction';
import { store } from 'utils/store';

import './viewer.css';

const EMPTY_VIEWER = 'EMPTY_VIEWER';
class EmptyViewer extends Component {
  componentDidMount() {
    store.dispatch({
      type: mainViewerAction.initViewer,
      viewer: {
        id: EMPTY_VIEWER,
        viewer: null,
        viewerType: EMPTY_VIEWER
      }
    });
  }

  render() {
    return (
      <Fragment>
        <iframe
          title='Open street view'
          className='viewer-container'
          src='https://www.openstreetmap.org/export/embed.html?bbox=2.2233635187149052%2C48.86156509761587%2C2.2258740663528447%2C48.862819693490245&amp;layer=mapnik'
        ></iframe>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  openCard: state.card.openCard
});

export default connect(mapStateToProps)(EmptyViewer);
