/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Annotation from "../comment/Annotation";
import { addMarkupBasic } from "services/MarkupService";
import { getUserByFilter } from "services/UserService";
import CircularProgress from "@material-ui/core/CircularProgress";

class MarkupFormCardPanelBasic extends Component {
  constructor() {
    super();
    this.state = {
      form: {},
      data: {},
      inProgress: false
    };
    this.control = this.control.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    var data = {
      title: this.props.multiLang.createMarkupCard.title,
      form: []
    };

    var annotation = [];
    var label = [];
    var priority = [];

    annotation.push("comment");
    annotation.push("request");
    annotation.push("gap");

    label.push("architecture");
    label.push("structure");
    label.push("mechanical");
    label.push("electrical");
    label.push("specifications");
    label.push("technology");

    priority.push("1");
    priority.push("2");
    priority.push("3");
    priority.push("4");

    var type = [];

    type.push({
      type: "input",
      label: this.props.multiLang.createMarkupCard.titleLabel,
      name: "title",
      inputType: "text",
      placeholder: this.props.multiLang.createMarkupCard.titleHolder
    });

    type.push({
      type: "radio",
      label: this.props.multiLang.createMarkupCard.priorityLabel,
      name: "priority",
      options: priority,
      inline: true
    });

    //SELECT

    type.push({
      type: "select",
      name: "type",
      options: annotation,
      inline: true
    });

    type.push({
      type: "select",
      name: "label",
      options: label
    });

    // TEXTAREA

    type.push({
      type: "textarea",
      label: this.props.multiLang.createMarkupCard.descriptionLabel,
      name: "description",
      placeholder: this.props.multiLang.createMarkupCard.descriptionHolder
    });

    // ACTIONS

    type.push({
      type: "actions",
      list: [
        {
          label: this.props.multiLang.createMarkupCard.cancelButton,
          close: true
        },
        {
          label: this.props.multiLang.createMarkupCard.saveButton,
          color: "primary",
          action: () => {
            this.refreshMarkupList();
          },
          validate: true
        }
      ],
      inline: true
    });

    data.form = type;

    data.formValidator = (form) => {
      for (var element of data.form) {
        if (!form || (element.name && !form[element.name])) {
          return false;
        }
      }
      return true;
    };
    console.log("data", data);

    this.setState({ data: data });
  }

  isValid() {
    if (!this.state.data.formValidator) {
      return true;
    }
    return this.state.data.formValidator(this.state.form);
  }

  refreshMarkupList() {
    console.log("refresh");
  }

  save() {
    this.setState({ inProgress: true });
    const viewer = this.props.mainViewer.viewers.find(
      (v) => v.id === this.props.data.viewerId
    ).viewer;
    const modelId = this.props.data.viewerId
      ? this.props.data.viewerId.split("#")[1]
      : this.props.project.modelId;
    const revision = this.props.data.viewerId
      ? this.props.data.viewerId.split("#")[2]
      : this.props.project.revision;
    const markupExtension = viewer.myCurrentViewer.getExtension(
      "Autodesk.Viewing.MarkupsCore"
    );
    const markupsPersist = markupExtension.generateData();
    const viewerStatePersist = viewer.myCurrentViewer.getState();
    viewerStatePersist.viewport.name = "markup";

    let markup = {
      modelId,
      revision,
      topicType: this.state.form.type,
      topicLabel: this.state.form.label,
      title: this.state.form.title,
      description: this.state.form.description,
      priority: this.state.form.priority,
      graphics: markupsPersist,
      state: viewerStatePersist
    };

    getUserByFilter("userId", this.props.auth.userId, (error, data) => {
      let user;
      if (error) {
        user = "N/A";
      } else {
        console.log(data[0]);
        user =
          (data[0].name ? data[0].name + " " : "") +
          (data[0].family_name ?? "");
      }
      markup.creator = user;
      markup.creationDate = new Date().toISOString();
      addMarkupBasic(this.props.project.projectId, markup, (error, data) => {
        if (error) {
          console.log(error);
          return this.props.snackBar.addSnackError(
            this.props.multiLang.genericCard.saveMarkupError
          );
        } else {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.genericCard.markupSaved
          );
          markup.MarkupId = data.MarkupId;
          console.log("markup", markup);
          this.props.options.addMarkup(markup);
          this.props.options.close();
        }
      });
    });
  }

  control(type, value) {
    var form = this.state.form;
    form[type] = value;
    this.setState(Object.assign({}, {}, form));
  }

  render() {
    return (
      <Fragment>
        {this.state.inProgress ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress style={{ marginTop: 20 }} />
          </div>
        ) : (
          <form className="card__form">
            {this.state.data &&
              this.state.data.form &&
              this.state.data.form.map((form, index) => (
                <Annotation
                  key={index}
                  data={form}
                  viewerId={this.props.data.viewerId}
                  close={this.props.options.close}
                  onChange={this.control}
                  form={this.state.form}
                  onSave={this.save}
                  isValid={() => this.isValid()}
                />
              ))}
          </form>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  mainViewer: state.mainViewer,
  project: state.project,
  multiLang: state.multiLang,
  s3: state.auth.s3,
  auth: state.auth,
  snackBar: state.snackBar,
  card: state.card,
  storeBucket: state.config.storeBucket
});

export default connect(mapStateToProps)(MarkupFormCardPanelBasic);
