import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidePanel from 'components/generic-side-panel/SidePanel';
import SemanticSearchAttributeTab from 'components/nav-search/semantic-search-panel/SemanticSearchAttributeTab';
import Search from '@material-ui/icons/Search';
import { searchAction } from "actions/SearchAction";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import theme from 'theme/theme';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getClasses } from "services/CatalogueService";


class SemanticSearchPanel extends Component {

    state = {
        isSemanticSearchPanelOpen: false,
        isSemanticSearchPanelMinimized: false,
        tabIndex: 0,
        classList: [],
        selectedClass: "",
        query: [
            {
                ruleType: "projectRule",
                field: "projectId",
                operator: "eq",
                value: this.props.project.projectId
            },
            {
                ruleType: "classRule",
                field: "className",
                operator: "eq",
                value: ""
            },
            {
                ruleType: "attributesRule",
                rules: []
            },
            {
                ruleType: "relationshipsRule",
                rules: []
            }

        ],
        searchResult: []
    }

    openSemanticSearchPanel = () => {
        this.setState({ isSemanticSearchPanelOpen: true });
    };

    closeSemanticSearchPanel = () => {
        this.setState({ isSemanticSearchPanelOpen: false });
    };

    minimizeSemanticSearchPanel = () => {
        this.setState({ isSemanticSearchPanelMinimized: true });
    };

    expandSemanticSearchPanel = () => {
        this.setState({ isSemanticSearchPanelMinimized: false });
    };

    componentDidMount() {
        this.props.dispatch({
            type: searchAction.sidePanelAction,
            openSemanticSearchPanel: this.openSemanticSearchPanel,
            closeSemanticSearchPanel: this.closeSemanticSearchPanel,
        });
        this.loadClasses();
    }

    loadClasses = (value) => {
        let beginWith = value ? value : "";
        getClasses(this.props.project.projectId, beginWith, (error, data) => {
            if (error) {
                console.error(error);
            } else {
                this.setState({ classList: data });
            }
        });
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
    };

    handleAutocomplete = (event, newValue) => {
        let updateQuery = [...this.state.query];
        let classIndex = updateQuery.findIndex(element => {
            return element.ruleType === "classRule";
        });
        let classRule = updateQuery[classIndex];
        classRule.value = newValue;
        updateQuery[classIndex] = classRule;
        this.setState({ selectedClass: newValue, query: updateQuery });
    };

    handleRulesChange = (attributesRules) => {
        let updateQuery = [...this.state.query];
        updateQuery[2].rules = attributesRules.rules;
        this.setState({ query: updateQuery });
    }

    handleRelationChange = (realtionsRules) => {
        let updateQuery = [...this.state.query];
        updateQuery[3].rules = realtionsRules.rules;
        this.setState({ query: updateQuery });
    }

    runQuery = () => {
        // Init search params to be excuted by the extended SearchPanel
        this.props.dispatch({
            type: searchAction.initSearchParameters,
            searchValue: this.state.query,
            searchType: true
        });
        // Close current panel and open result panel
        this.setState({ isSemanticSearchPanelOpen: false }, () => this.props.searchParams.openSidePanel());
    }


    // Disable run button if the query is not valid
    isValidQuery = () => {
        // If we do not have any class selectedd
        if (!this.state.query[1].value) return false;
        // If we have  an atrribute Selectd without an operator
        if (this.state.query[2].rules.length > 0 && this.state.query[2].rules.some(rule => !rule.operator)) return false;
        // If we have many attributes selected without a logical operator between them
        if (this.state.query[2].rules.length > 1 && this.state.query[2].rules.slice(1).some(rule => !rule.condition)) return false;
        return true;
    }

    render() {
        return (

            <SidePanel open={this.state.isSemanticSearchPanelOpen}
                minimize={this.state.isSemanticSearchPanelMinimized} minimizetop={"220px"}
                id="semanticSearchPanel"
            >
                {this.state.isSemanticSearchPanelMinimized ? (
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <IconButton onClick={this.expandSemanticSearchPanel}>
                                    <Search />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} style={{
                                writingMode: 'vertical-rl',
                                paddingRight: 12,
                                color: theme.palette.primary.main
                            }}>
                                {this.props.multiLang.search.labelSimpleSearch}
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <div>
                        <div>
                            <Grid container>
                                <Grid item xs={3} sm={3}>
                                    <Tooltip title={this.props.multiLang.search.minimize}>
                                        <IconButton onClick={this.minimizeSemanticSearchPanel}>
                                            <ChevronRight />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3} sm={5} style={{ padding: 7 }}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                                        {this.props.multiLang.search.labelSimpleSearch}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    {/* <Tooltip title={this.props.multiLang.search.fullScreen}>
                                            <IconButton>
                                                <Fullscreen />
                                            </IconButton>
                                        </Tooltip> */}
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Tooltip title={this.props.multiLang.search.close}>
                                        <IconButton onClick={this.closeSemanticSearchPanel}>
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {/* <Grid item xs>
                                                    <Button disabled color="primary">
                                                        {this.props.multiLang.semanticSearch.cancel}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs>
                                                    <Button disabled color="primary">
                                                        {this.props.multiLang.semanticSearch.save}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs>
                                                    <Button disabled color="primary">
                                                        {this.props.multiLang.semanticSearch.load}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs>
                                                    <Button disabled color="primary">
                                                        {this.props.multiLang.semanticSearch.visualize}
                                                    </Button>
                                                </Grid> */}
                                <Grid item xs>
                                    <Button disabled={!this.isValidQuery()} color="primary" onClick={this.runQuery}>
                                        {this.props.multiLang.semanticSearch.run}
                                    </Button>
                                </Grid>
                            </Grid>
                            { }
                            <Grid container style={{ marginTop: 30 }}>
                                <Grid item xs style={{ marginLeft: 5 }}>
                                    <Autocomplete
                                        id="combo-box"
                                        options={Array.isArray(this.state.classList) ? this.state.classList : []}
                                        getOptionLabel={(option) => option}
                                        style={{ width: 300 }}
                                        onChange={(event, newValue) => {
                                            this.handleAutocomplete(event, newValue);
                                        }}
                                        value={this.state.selectedClass}
                                        renderInput={(params) => <TextField {...params}
                                            label={this.props.multiLang.semanticSearch.selectClass}
                                            variant="outlined"
                                        />}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                {this.state.tabIndex === 0 &&
                                    <SemanticSearchAttributeTab className={this.state.selectedClass}
                                        onRulesChange={this.handleRulesChange} attributesRules={this.state.query[2].rules} />}
                            </div>
                        </div>

                    </div>
                )}
            </SidePanel>


        );
    }
}

const mapStateToProps = state => ({
    searchParams: state.searchParams,
    project: state.project,
    multiLang: state.multiLang,
    snackBar: state.snackBar,
    viewer: state.mainViewer.viewer,
    openCard: state.card.openCard,
});

export default connect(
    mapStateToProps,
)(SemanticSearchPanel);
