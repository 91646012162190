/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";


function getGroups(){
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.get(`${config.urlUserSettingAPI}/groups`, { headers: headers });
}

function addGroup(description, name, projectid){
    var headers = store.getState().auth.headers;
    var config = store.getState().config;
    
    return axios.put(`${config.urlUserSettingAPI}/group`, 
        { 
            description: description,
            groupName: name,
            projectId: projectid
        },
        { headers: headers }
    );
}

function deleteGroup(groupId, projectId) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.delete(`${config.urlUserSettingAPI}/group/${groupId}`,
        {  headers: headers , data : { projectId: projectId } }
    );
}

function updateGroup(groupId, description, name, projectId) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.patch(`${config.urlUserSettingAPI}/group/${groupId}`,
        {
            description: description,
            groupName: name,
            projectId: projectId
        },
        {headers: headers}
    );
}

function getGroupUsers (groupId){
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.get(`${config.urlUserSettingAPI}/group/${groupId}/users`, { headers: headers });
}

export {
    getGroups,
    deleteGroup,
    addGroup,
    updateGroup,
    getGroupUsers
};