/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { mainViewerAction } from "actions/MainViewerAction";
import NavSearch from "components/nav-search/NavSearch";
import SwitchViewComponent from "./switch-view-component/SwitchViewComponent";
import UserMenuComponent from "./user-menu-component/UserMenuComponent";
import NotificationComponent from "./notification-component/NotificationComponent";
import UserTagFavoriteComponent from "./user-tag-favourite-component/UserTagFavouriteComponent";
import { breakdownAction } from "actions/BreakdownAction";
import Tooltip from "@material-ui/core/Tooltip";
import "./NavBar.css";

class NavBar extends Component {
  state = {
    resetSwitch: false
  };
  componentDidMount() {
    this.props.dispatch({
      type: mainViewerAction.initSize,
      size: {
        navBar: {
          height: this.navRef.offsetHeight,
          width: this.navRef.offsetWidth
        },
        mainViewer: {
          height: window.innerHeight - this.navRef.offsetHeight,
          width: window.innerWidth // width is the screen size
        }
      }
    });
  }
  // Init properties related to a project switch view
  resetProjectProps = () => {
    this.setState({ resetSwitch: true });
    this.props.dispatch({
      type: mainViewerAction.initTagNames,
      focusedObjectsTagNames: null,
      fromViewer: true
    });
    // Init breakdown data in the store
    this.props.dispatch({
      type: breakdownAction.initBreakdownData,
      breakDownData: {},
      breakDownOptions: {
        rootTagFilter: false
      }
    });
  };

  render() {
    const { projectId } = this.props;
    const navBar = (
      <div ref={(navRef) => (this.navRef = navRef)}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="nav__logo__project">
            <Link
              className="navbar-brand mr-auto primary"
              to="/projects"
              onClick={this.resetProjectProps}
            >
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={this.props.config.logo}
                    alt="Italian Trulli"
                    className="nav-logo"
                    width="64"
                    height="38"
                  />
                </div>
                <div className="d-flex flex-column">
                  <div>
                    <div>
                      <span className="nav-logo">
                        {this.props.multiLang.navBar.logo}
                      </span>
                    </div>
                    <div>
                      <span className="navbar-brand mr-auto primary nav-item__project">
                        <i>{this.props.name}</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* Site name */}
          {this.props.isSearchable && this.props.siteName && (
            <div
              style={{
                marginLeft: "20px",
                marginRight: "auto",
                paddingRight: "10px",
                display: "inline-grid",
                width: "25%"
              }}
            >
              <Tooltip title={this.props.siteName}>
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                  className="primary"
                >
                  {this.props.siteName}
                </span>
              </Tooltip>
            </div>
          )}
          {/* Search  */}
          <NavSearch isSearchable={this.props.isSearchable} />

          {/* Items de navigation */}
          <div className="header-nav-infos ml-auto">
            <Link
              to={`/projects/${this.props.projectId}/files`}
              className="nav-item"
              style={!this.props.projectId ? { pointerEvents: "none" } : {}}
              aria-label="Projects list"
              onClick={this.resetProjectProps}
            >
              <svg
                className="nav-item__icon"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="m 5.6757812,1.4472656 c -2.3968433,0 -4.3554687,1.9566723 -4.3554687,4.3535156 0,2.3968435 1.9586253,4.3554688 4.3554687,4.3554688 2.2992351,0 4.1463628,-1.8175979 4.296875,-4.0800781 A 0.66673335,0.66673335 0 0 0 10.029297,5.8007812 0.66673335,0.66673335 0 0 0 9.9726562,5.5273438 C 9.8231091,3.263943 7.9756807,1.4472656 5.6757812,1.4472656 Z m 0,1.3339844 c 1.6762567,0 3.0195313,1.3432748 3.0195313,3.0195312 0,1.6762567 -1.3432747,3.0214844 -3.0195313,3.0214844 -1.6762565,0 -3.0214843,-1.3452278 -3.0214843,-3.0214844 0,-1.6762565 1.3452278,-3.0195312 3.0214843,-3.0195312 z M 14,5.1347656 A 0.6669922,0.6669922 0 0 0 14,6.46875 h 16.25 a 0.6669922,0.6669922 0 0 0 0,-1.3339844 z M 5.8261719,11.777344 c -2.3968435,0 -4.3554688,1.958625 -4.3554688,4.355468 0,2.396845 1.9586253,4.353516 4.3554688,4.353516 2.3968434,0 4.3535161,-1.956672 4.3535161,-4.353516 0,-2.396843 -1.9566727,-4.355468 -4.3535161,-4.355468 z m 0,1.333984 c 1.6762566,0 3.0195312,1.345228 3.0195312,3.021484 0,1.676257 -1.3432746,3.019532 -3.0195312,3.019532 -1.6762566,0 -3.0214844,-1.343275 -3.0214844,-3.019532 0,-1.676256 1.3452278,-3.021484 3.0214844,-3.021484 z M 13.75,15.464844 a 0.666992,0.666992 0 0 0 0,1.333984 H 30 a 0.666992,0.666992 0 0 0 0,-1.333984 z m -8.0117188,6.644531 c -2.3968433,0 -4.3554687,1.956672 -4.3554687,4.353516 0,2.396843 1.9586253,4.355468 4.3554687,4.355468 2.3968435,0 4.3535158,-1.958625 4.3535158,-4.355468 0,-2.396844 -1.9566723,-4.353516 -4.3535158,-4.353516 z m 0,1.333984 c 1.6762567,0 3.0195313,1.343275 3.0195313,3.019532 0,1.676256 -1.3432747,3.021484 -3.0195313,3.021484 -1.6762565,0 -3.0214843,-1.345228 -3.0214843,-3.021484 0,-1.676257 1.3452278,-3.019532 3.0214843,-3.019532 z m 7.7675778,2.353516 a 0.666992,0.666992 0 0 0 0,1.333984 h 16.25 a 0.666992,0.666992 0 0 0 0,-1.333984 z"></path>
              </svg>
              <span className="nav-item__text">
                {this.props.multiLang.navBar.files}
              </span>
            </Link>

            <UserTagFavoriteComponent projectId={projectId} />

            <NotificationComponent />

            <SwitchViewComponent reset={this.state.resetSwitch} />

            <UserMenuComponent />
          </div>
          {/* FIN - Items de navigation */}
        </nav>
      </div>
    );

    return navBar;
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
  projectId: state.project.projectId,
  modelId: state.project.modelId,
  revision: state.project.revision,
  notifications: state.notification,
  userRoles: state.userRole,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  name: state.project.name,
  project: state.project,
  viewerType: state.project.viewer,
  mainViewer: state.mainViewer,
  isSearchable: state.mainViewer.isSearchable,
  openCard: state.card.openCard,
  closeAllCard: state.card.closeAllCard,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  siteName: state.project.siteName
});

export default connect(mapStateToProps)(NavBar);
