/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from "axios";
import { store } from "utils/store";

function addCommentToMarkup(comments, id, markupId, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .post(
      config.urlDesignReviewAPI + "/addCommentAnnotation",
      {
        comments: comments,
        id: id,
        markupId: markupId
      },
      { headers: headers }
    )
    .then((response) => {
      if (!response.data || Object.keys(response.data).length === 0)
        return callback("No data");
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

function updateMarkupStatus(id, markupId, status, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .post(
      `${config.urlDesignReviewAPI}/setAnnotationStatus`,
      {
        id: id,
        markupId: markupId,
        status: status
      },
      { headers: headers }
    )
    .then((response) => {
      if (!response.data) return callback("No data");
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

function getAllMarkups(projectId, modelId, revision, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .get(`${config.urlDesignReviewAPI}/getAllAnnotation`, {
      params: {
        projectId: projectId,
        modelId: modelId,
        revision: revision
      },
      headers: headers
    })
    .then((response) => {
      if (!response.data) return callback("No data");
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

function addMarkup(projectId, modelId, revision, reviewId, markup, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .post(
      `${config.urlDesignReviewAPI}/setAnnotation`,
      {
        projectid: projectId,
        modelid: modelId,
        revision: revision,
        reviewId: reviewId,
        ...markup
      },
      { headers: headers }
    )
    .then((response) => {
      if (!response.data) return callback("No data");
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

function addMarkupBasic(projectId, markup, callback) {
  let headers = store.getState().auth.headers;
  const config = store.getState().config;

  let promise = axios.post(
    `${config.urlProjectAPI}/project/${projectId}/markup`,
    markup,
    { headers: headers }
  );
  if (callback) {
    promise.then(function (response) {
      return callback(null, response.data);
    });
    promise.catch(function (error) {
      callback(error);
    });
  } else return promise;
}

function getMarkupsByRevision(projectId, modelId, revision, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .get(`${config.urlDesignReviewAPI}/getAnnotationsByRevision`, {
      params: {
        projectId: projectId,
        modelId: modelId,
        revision: revision
      },
      headers: headers
    })
    .then((response) => {
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

function getMarkupsBasic(projectId, modelId, revision, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  let promise = axios.get(
    `${config.urlProjectAPI}/project/${projectId}/markup`,
    {
      params: {
        modelId: modelId,
        revision: revision
      },
      headers: headers
    }
  );
  if (callback) {
    promise.then(function (response) {
      return callback(null, response.data);
    });
    promise.catch(function (error) {
      callback(error);
    });
  } else return promise;
}

function deleteMarkup(projectId, markupId, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  let promise = axios.delete(
    `${config.urlProjectAPI}/project/${projectId}/markup/${markupId}`,
    {
      headers: headers
    }
  );
  if (callback) {
    promise.then(function (response) {
      return callback(null, response.data);
    });
    promise.catch(function (error) {
      callback(error);
    });
  } else return promise;
}

function generateBCFFile(projectId, modelId, revision, callback) {
  let headers = store.getState().auth.headers;
  let config = store.getState().config;

  axios
    .post(
      `${config.urlDesignReviewAPI}/generateBCFFile`,
      {
        projectId: projectId,
        modelId: modelId,
        revision: revision
      },
      { headers: headers }
    )
    .then((response) => {
      if (!response.data) return callback("No data");
      return callback(null, response.data);
    })
    .catch((error) => {
      return callback(error);
    });
}

export {
  addCommentToMarkup,
  getAllMarkups,
  addMarkup,
  addMarkupBasic,
  getMarkupsByRevision,
  getMarkupsBasic,
  updateMarkupStatus,
  generateBCFFile,
  deleteMarkup
};
