/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

class SimpleSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Call search API (from props)
   */
  search() {
    if (!this.props.search) {
      return console.error("No search function");
    }

    this.props.search();
  }

  /**
   * Handle change string (from props) 
   * @param {*} event 
   */
  onChange(event) {
    if (!this.props.onChange) {
      return console.error("No onChange function");
    }

    this.props.onChange(event.target.value);
  }

  render() {

    return (
      <Fragment>
        
        <TextField
          label={this.props.multiLang.search.labelSimpleSearch}
          onKeyPress={(event) => { if (event.key === 'Enter') this.search(); }}
          onChange={this.onChange}
          style={{ minWidth: this.props.inputSize }}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  projectId: state.project.projectId,
  multiLang: state.multiLang,
});


export default connect(mapStateToProps)(SimpleSearch);