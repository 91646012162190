/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  Typography
} from '@material-ui/core';
import {
  getGroupsFromBreakdownName,
  deleteBreakdown,
  createBreakdown,
  updateBreakdown
} from 'services/ProjectService';
import { getBreakdowns } from 'services/AssetService';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AddBreakdown from './AddBreakdown';
import { getUserAdminProject } from 'services/UserService';
import _ from 'lodash';

class Breakdowns extends Component {
  state = {
    projectList: [],
    project: {},
    isAddBreakdownModalOpen: false,
    isEditBreakdownModalOpen: false,
    selectedRow: [],
    breakdowns: [],
    breakdownsTable: [],
    edit: false
  };

  componentDidMount() {
    getUserAdminProject()
      .then((result) => {
        this.setState({ projectList: result.data });
      })
      .catch((err) => {
        console.log(err);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectRetrievalFailed
        );
      });
  }

  columnsIn = [
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.description,
      label: this.props.multiLang.administration.description,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.groups,
      label: this.props.multiLang.administration.groups,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.administration.default,
      label: this.props.multiLang.administration.default,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.administration.filtered,
      label: this.props.multiLang.administration.filtered,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.administration.actions,
      label: this.props.multiLang.administration.actions,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    onRowsDelete: (rowsDeleted) => {
      this.deleteBreakdowns(rowsDeleted);
    },
    customToolbar: () => {
      return (
        <Tooltip title={this.props.multiLang.administration.createBreakdown}>
          <span>
            <IconButton disabled={Object.keys(this.state.project).length === 0} onClick={() => this.handleAddBreakdown()}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    onRowClick: (rowData) => {
      let selectedRow = this.state.breakdowns.find(function (element) {
        return element.breakdown.Name === rowData[0];
      });
      this.setState({ selectedRow: selectedRow });
    }
  };

  handleBreakdownCreation = (
    projectId,
    breakdownName,
    breakdownDescription,
    defaultBreakdownChecked,
    classesSelected,
    relationshipsSelected,
    groupsSelected,
    classExpansionSelected,
    rootTagFilterChecked
  ) => {
    createBreakdown(
      projectId,
      breakdownName,
      breakdownDescription,
      classesSelected,
      relationshipsSelected,
      this.props.userId,
      groupsSelected,
      classExpansionSelected,
      defaultBreakdownChecked,
      rootTagFilterChecked
    )
      .then((data) => {
        let updateBreakdownsTableList = [...this.state.breakdownsTable];
        const groups = groupsSelected.map((element) => element.Name);
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton onClick={() => this.handleEditBreakdown()}>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        );
        updateBreakdownsTableList.push([
          breakdownName,
          breakdownDescription,
          groups.join(','),
          this.isDefaultBreakdown(defaultBreakdownChecked),
          this.isFilteredBreakdown(rootTagFilterChecked),
          edit
        ]);
        this.setState({
          isAddBreakdownModalOpen: false,
          breakdownsTable: updateBreakdownsTableList
        });
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.breakdownCreationSucceeded
        );
      })
      .catch((error) => {
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.breakdownCreationFailed
        );
      });
  };

  deleteBreakdowns = (groups) => {
    let updateBreakdownsList = [...this.state.breakdowns];
    let updateBreakdownsTableList = [...this.state.breakdownsTable];
    groups.data.reverse().forEach((element) => {
      let groupTodelete = updateBreakdownsList[element.dataIndex];
      deleteBreakdown(
        this.state.project.Id,
        groupTodelete.breakdown.Name,
        groupTodelete.groups
      )
        .then((data) => {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success
              .breakdownRemovalSucceeded
          );
          updateBreakdownsList.splice(element.dataIndex, 1);
          updateBreakdownsTableList.splice(element.dataIndex, 1);
          this.setState({
            breakdowns: updateBreakdownsList,
            breakdownsTable: updateBreakdownsTableList
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.breakdownRemovalFailed
          );
        });
    });
  };

  updateBreakdown = (
    projectId,
    breakdownDescription,
    defaultBreakdownChecked,
    classesSelected,
    relationshipsSelected,
    groupsSelected,
    classExpansionSelected,
    rootTagFilterChecked
  ) => {
    // Get breakdown list
    let updateBreakdownsList = [...this.state.breakdowns];
    let selectedBreakdown = this.state.selectedRow.breakdown;
    let updateBreakdownsTableList = [...this.state.breakdownsTable];
    // Get breakdown to be updated
    let breakdownToUpdate = updateBreakdownsList.find(
      (element) => element.breakdown.Name === selectedBreakdown.Name
    );
    let index = updateBreakdownsList.findIndex(
      (element) => element.breakdown.Name === selectedBreakdown.Name
    );
    // update a breakdown consist in deleting it and recreates it with same name
    if (breakdownToUpdate) {
      // If breakdown structure has been modified then recreate it 
      if (!_.isEqual(selectedBreakdown.RootClass, classesSelected) ||
        !_.isEqual(selectedBreakdown.RootRelationship, relationshipsSelected) ||
        !_.isEqual(selectedBreakdown.SpecificClassExpansion, classExpansionSelected)) {
        deleteBreakdown(
          this.state.project.Id,
          breakdownToUpdate.breakdown.Name,
          breakdownToUpdate.groups
        )
          .then((data) => {
            createBreakdown(
              projectId,
              breakdownToUpdate.breakdown.Name,
              breakdownDescription,
              classesSelected,
              relationshipsSelected,
              this.props.userId,
              groupsSelected,
              classExpansionSelected,
              defaultBreakdownChecked,
              rootTagFilterChecked
            )
              .then((data) => {
                const groups = groupsSelected.map((element) => element.Name);
                let edit = (
                  <Tooltip title={this.props.multiLang.administration.edit}>
                    <IconButton onClick={() => this.handleEditBreakdown()}>
                      {' '}
                      <EditIcon color='primary' />{' '}
                    </IconButton>
                  </Tooltip>
                );
                updateBreakdownsList.splice(index, 1);
                updateBreakdownsTableList.splice(index, 1);
                updateBreakdownsTableList.push([
                  breakdownToUpdate.breakdown.Name,
                  breakdownDescription,
                  groups.join(','),
                  this.isDefaultBreakdown(defaultBreakdownChecked),
                  this.isFilteredBreakdown(rootTagFilterChecked),
                  edit
                ]);
                this.setState({
                  isEditBreakdownModalOpen: false,
                  breakdownsTable: updateBreakdownsTableList
                });
                this.props.snackBar.addSnackSuccess(
                  this.props.multiLang.administration.success
                    .breakdownUpdateSucceeded
                );
                this.handleChangeBreackDowns({
                  target: { value: this.state.project }
                });
              })
              .catch((errorCreate) => {
                console.error(errorCreate);
                this.props.snackBar.addSnackError(
                  this.props.multiLang.administration.errors.breakdownUpdateFailed
                );
              });
          })
          .catch((error) => {
            console.log(error);
            this.props.snackBar.addSnackError(
              this.props.multiLang.administration.errors.breakdownUpdateFailed
            );
          });
      } else {
        // If breakdown structure not modified, just update breakdwon information
        updateBreakdown(
          projectId,
          breakdownToUpdate.breakdown.Name,
          breakdownDescription,
          this.props.userId,
          groupsSelected,
          defaultBreakdownChecked,
          rootTagFilterChecked
        ).then((res) => {
          // Reload breakdown to update the list 
          this.handleChangeBreackDowns({
            target: { value: this.state.project }
          });
          // close Edit breakdown modal
          this.setState({
            isEditBreakdownModalOpen: false,
          });
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success
              .breakdownUpdateSucceeded
          );
        }).catch((error) => {
          console.log(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.breakdownUpdateFailed
          );
        });

      }
    }
  };

  handleChangeBreackDowns = (event) => {
    this.setState({ project: event.target.value });
    let breakdownsTable = [];
    let breakdowns = [];
    getBreakdowns(event.target.value.Id)
      .then(async (data) => {
        const breakdownsListResult = data.data.result;
        for (const breakdown of breakdownsListResult) {
          const response = await getGroupsFromBreakdownName(
            event.target.value.Id,
            breakdown.Name
          );
          let groups = response.data
            ? response.data.result.map((element) => element.Name)
            : [];

          let edit = (
            <Tooltip title={this.props.multiLang.administration.edit}>
              <IconButton onClick={() => this.handleEditBreakdown()}>
                {' '}
                <EditIcon color='primary' />{' '}
              </IconButton>
            </Tooltip>
          );
          breakdownsTable.push([
            breakdown.Name,
            breakdown.Description,
            groups.join(','),
            this.isDefaultBreakdown(breakdown.Default),
            this.isFilteredBreakdown(breakdown.RootTagFilter),
            edit
          ]);
          breakdowns.push({
            breakdown: breakdown,
            groups: response.data.result || []
          });
        }
        this.setState({
          breakdownsTable: breakdownsTable,
          breakdowns: breakdowns
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddBreakdown = () => {
    this.setState({ isAddBreakdownModalOpen: true });
  };

  handleCloseBreakdownModal = (value) => {
    this.setState({ isAddBreakdownModalOpen: value });
  };

  handleEditBreakdown = () => {
    this.setState({ isEditBreakdownModalOpen: true, edit: true });
  };

  handleCloseEditBreakdownModal = (value) => {
    this.setState({ isEditBreakdownModalOpen: value, edit: false });
  };

  isDefaultBreakdown = (boolIsDefault) => {
    return boolIsDefault === true
      ? this.props.multiLang.administration.yes
      : this.props.multiLang.administration.no;
  };

  isFilteredBreakdown = (boolIsFiltered) => {
    return boolIsFiltered === true
      ? this.props.multiLang.administration.yes
      : this.props.multiLang.administration.no;
  };

  render() {
    // The first breakdown config for a project is automatically the default one
    let isDefaultBreakdown = this.state.breakdowns.length === 0;
    return (
      <Fragment>
        {this.state.isAddBreakdownModalOpen && (
          <AddBreakdown
            isOpen={this.state.isAddBreakdownModalOpen}
            onClose={this.handleCloseBreakdownModal}
            add={this.handleBreakdownCreation}
            projectid={this.state.project.Id}
            isDefault={isDefaultBreakdown}
          />
        )}
        {this.state.isEditBreakdownModalOpen &&
          this.state.selectedRow &&
          this.state.edit && (
            <AddBreakdown
              isOpen={this.state.isEditBreakdownModalOpen}
              onClose={this.handleCloseEditBreakdownModal}
              breakdown={this.state.selectedRow}
              edit={this.state.edit}
              update={this.updateBreakdown}
              projectid={this.state.project.Id}
              isDefault={this.state.selectedRow.breakdown.Default}
            />
          )}
        <Typography style={{ color: '#757575', fontWeight: 'bold' }}>
          <span onClick={() => this.props.close()}
            style={{ cursor: 'pointer' }}>
            {this.props.multiLang.administration.projectManagement}{' '} &gt; {' '}
          </span>
          {this.props.multiLang.administration.breakdowns}
        </Typography>
        <div style={{ marginBottom: '40px', padding: 20 }}>
          <FormControl className='formControl' fullWidth={true}>
            <InputLabel htmlFor='select-multiple-checkbox'>
              {this.props.multiLang.administration.projects}
            </InputLabel>
            <Select
              value={this.state.project}
              onChange={this.handleChangeBreackDowns}
              input={<Input id='select-multiple-checkbox' />}
            >
              {this.state.projectList.map((project) => (
                <MenuItem key={project.Id} value={project}>
                  {project.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: '10px' }}>
          <MUIDataTable
            title={this.props.multiLang.administration.breakdowns}
            data={this.state.breakdownsTable}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  userId: state.auth.userId
});

export default connect(mapStateToProps)(Breakdowns);
