/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CssBaseline,
  Typography,
  ListItem,
  ListItemText,
  List,
  Divider,
  Drawer
} from '@material-ui/core';
import ScrollArea from 'react-scrollbar';
import './UserSettings.css';
import ReflectAdministration from 'components/user-settings/reflect-administration/ReflectAdministration';
import ProjectAdministration from 'components/user-settings/project-administration/ProjectAdministration';
import ProfileSettings from 'components/user-settings/profile/ProfileSettings';
import theme from 'theme/theme';
import { isAuthorize } from 'services/AuthorizationService';

const projectAdminRight = {
  rightName: 'AdministrateProject'
};

const reflectAdminRight = {
  rightName: 'AdministrateReflect'
};

class UserSettings extends Component {
  state = {
    pageNumber: 'USER_PROFILE'
  };

  constructor(props) {
    super(props);
    // Reference to child component to reset them on click
    this.childRef = null;
  }

  handleMenuClick(componentName) {
    this.setState({ pageNumber: componentName });
    // Reset menu component on click
    if(this.childRef)  this.childRef.reset();
  }

  menuControl = () => {
    switch (this.state.pageNumber) {
      case 'ADMIN_REFLECT':
        return (
          <div>
            {' '}
            <ReflectAdministration ref={ref => this.childRef = ref }/>{' '}
          </div>
        );
      case 'ADMIN_PROJECT':
        return (
          <div>
            {' '}
            <ProjectAdministration ref={ref => this.childRef = ref } />{' '}
          </div>
        );
      case 'USER_PROFILE':
        return (
          <div>
            {' '}
            <ProfileSettings />{' '}
          </div>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <ScrollArea>
        <div className='root'>
          <CssBaseline />
          <Drawer
            className='drawer'
            variant='permanent'
            PaperProps={{ style: { position: 'relative' } }}
            anchor='left'
          >
            <div className='toolbar' />
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography style={{ color: 'grey', fontWeight: 'bold' }}>
                      {this.props.multiLang.administration.userSettings}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => this.handleMenuClick('USER_PROFILE')}
                selected={this.state.pageNumber === 'USER_PROFILE'}
              >
                <ListItemText
                  disableTypography
                  style={{ paddingLeft: '16px' }}
                  primary={
                    <Typography
                      style={{
                        color:
                          this.state.pageNumber === 'USER_PROFILE'
                            ? theme.palette.primary.main
                            : 'black'
                      }}
                    >
                      {this.props.multiLang.administration.userProfile}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography style={{ color: 'grey', fontWeight: 'bold' }}>
                      {this.props.multiLang.administration.adminSettings}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => this.handleMenuClick('ADMIN_REFLECT')}
                selected={this.state.pageNumber === 'ADMIN_REFLECT'}
                disabled={!isAuthorize(reflectAdminRight.rightName)}
              >
                <ListItemText
                  disableTypography
                  style={{ paddingLeft: '16px' }}
                  primary={
                    <Typography
                      style={{
                        color:
                          this.state.pageNumber === 'ADMIN_REFLECT'
                            ? theme.palette.primary.main
                            : 'black'
                      }}
                    >
                      Reflect
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => this.handleMenuClick('ADMIN_PROJECT')}
                selected={this.state.pageNumber === 'ADMIN_PROJECT'}
                disabled={!isAuthorize(projectAdminRight.rightName)}
              >
                <ListItemText
                  disableTypography
                  style={{ paddingLeft: '16px' }}
                  primary={
                    <Typography
                      style={{
                        color:
                          this.state.pageNumber === 'ADMIN_PROJECT'
                            ? theme.palette.primary.main
                            : 'black'
                      }}
                    >
                      {this.props.multiLang.administration.projectManagement}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Drawer>
          <main className='content'>{this.menuControl()}</main>
        </div>
      </ScrollArea>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(UserSettings);
