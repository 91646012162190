/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from 'utils/store';

function getTags(projectId, tagClass) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(`${config.urlAssetAPI}/tags`, {
    params: {
      projectId: projectId,
      tagClass: tagClass
    },
    headers: headers
  });
}

function getRelations(projectId, tag, relationLabel) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(`${config.urlAssetAPI}/relations`, {
    params: {
      projectId: projectId,
      tag: tag,
      relationLabel: relationLabel
    },
    headers: headers
  });
}

function getBreakdown(projectId, breakdown) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(
    `${config.urlAssetAPI}/breakdowns/${breakdown}/${projectId}`,
    {
      params: {},
      headers: headers
    }
  );
}

function getBreakdowns(projectId) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(
    `${config.urlAssetAPI}/breakdowns`,
    {
      params: { projectId },
      headers
    }
  );
}

function getViewsWithTag(projectId, tag) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(`${config.urlAssetAPI}/tags/${tag}/views`, {
    params: { projectId: projectId },
    headers: headers
  });
}

function getAttachedTags(Model, Revision, ProjectId) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.get(`${config.urlAssetAPI}/tags/attached`, {
    params: { Model: Model, Revision: Revision, ProjectId: ProjectId },
    headers: headers
  });
}

function attachTag(Model, Revision, ProjectId, ids, Tag) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.post(`${config.urlAssetAPI}/tags/attach`, {
    "Ids": ids,
    "Model": Model,
    "Revision": Revision,
    "Tag": Tag,
    "ProjectId": ProjectId
  }, {
    headers: headers
  });
}

function detachTag(Model, Revision, ProjectId, Tag) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios.delete(`${config.urlAssetAPI}/tags/detach`, {headers: headers, data: {
      "Model": Model,
      "Revision": Revision,
      "Tag": Tag,
      "ProjectId": ProjectId
    }})
}

export { getTags, getRelations, getBreakdown, getViewsWithTag, getBreakdowns, getAttachedTags, attachTag, detachTag };
