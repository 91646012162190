/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import theme from 'theme/theme';
import { breakdownAction } from 'actions/BreakdownAction';
import { mainViewerAction } from 'actions/MainViewerAction';
import { constructTagLabel } from 'utils/TagHelper';

const visibilty = {
  right: 0,
  top: 0,
  width: '35px',
  height: '20px',
  border: 'none',
  outline: 'none',
  margin: 0,
  backgroundPosition: '6px 12px',
  backgroundRepeat: 'no-repeat',
  opacity: 1,
  paddingLeft: 20
};

class ExpansionRecursion extends Component {
  state = {
    relations: null,
    show: this.props.showed,
    previousSelectedId: ''
  };

  theme = createMuiTheme({
    overrides: {
      MuiListItem: {
        button: {
          backgroundColor: this.props.changebgColor
            ? theme.color.aliceBlue
            : 'inherit'
        }
      }
    },
    props: {
      MuiButtonBase: {
        disableRipple: true
      }
    }
  });

  componentDidUpdate(prevProps) {
    const { showed } = this.props;
    if (prevProps.showed !== showed) {
      this.setState({ show: showed });
    }
  }

  open = () => {
    // Change node open state in the store
    this.props.dispatch({
      type: breakdownAction.setNodeOpen,
      listId: this.props.listId,
      nodeId: this.props.nodeId,
      open: true
    });
  };

  close = () => {
    this.props.dispatch({
      type: breakdownAction.setNodeOpen,
      listId: this.props.listId,
      nodeId: this.props.nodeId,
      open: false
    });
  };

  show = () => {
    const { viewer, nodeFromStore } = this.props;
    this.setState({ show: true });

    let tags = this.getAllTag(nodeFromStore);
    if (viewer) {
      viewer.showObjects(tags, viewer.id);
    }
  };

  hide = () => {
    const { viewer, nodeFromStore } = this.props;
    this.setState({ show: false });

    let tags = this.getAllTag(nodeFromStore);
    if (viewer) {
      viewer.hideObjects(tags, viewer.id);
    }
  };



  getAllTag = (node) => {
    var tags = [];

    tags.push(node.Tag);
    tags.push(...this.props.getAllTagChild(this.props.listId, node.id));

    console.log(tags);
    return tags;
  };

  selectTag = (event) => {
    event.stopPropagation();
    const { viewer, nodeFromStore } = this.props;
    const isSelected = nodeFromStore.Tag === this.props.selectedNode;
    let tags = this.getAllTag(nodeFromStore);
    if (isSelected) {
      // Init selected Tag to null in the store
      this.props.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: null,
        selectionFromAbs: true
      });
      this.props.dispatch({
        type: breakdownAction.unselectNode
      });
    } else {
      if (viewer) {
        // Init Selected Tag in the store to select them in the model viewer
        this.props.dispatch({
          type: mainViewerAction.initTagNames,
          focusedObjectsTagNames: tags,
          selectionFromAbs: true
        });
      }
      this.props.openCard('PROPERTY_CARD', { tag: nodeFromStore.Tag }, 150, 150, {
        height: 500
      });
      this.props.dispatch({
        type: breakdownAction.selectNode,
        selectedNode: nodeFromStore.Tag
      });
    }
  };

  render() {
    const { nodeFromStore, depth, projectId, viewer } = this.props;
    const { show } = this.state;
    const isSelected = nodeFromStore.Tag === this.props.selectedNode;
    const child = nodeFromStore.Child || [];
    const hasChild = child.length !== 0;
    let changebgColor = this.props.changebgColor;
    // Get node open state form the Store
    let open = (nodeFromStore.open) ? true : false;
    let prefix = open ? (
      <ArrowDropDown onClick={() => this.close()} />
    ) : (
        <ArrowRightIcon onClick={() => this.open()} />
      );

    return (
      <Fragment>
        {!(this.props.showOnlyActive && nodeFromStore.EquipmentStatus && nodeFromStore.EquipmentStatus !== 'En service') &&
          <div>
            <ThemeProvider theme={this.theme}>
              <ListItem
                button
                onClick={this.handleClick}
                style={{
                  padding: '9px 6px 6px 13px',
                  marginBottom: 1,
                  position: 'relative'
                }}
                id={this.props.nodeFromStore.Tag}
              >
                <div style={{ transform: `translate(${depth * 10}px)` }}>
                  {hasChild && prefix}
                  {!hasChild && (
                    <div style={{ height: '24px', width: '24px' }}></div>
                  )}
                </div>
                <ListItemText
                  primary={
                    <Typography
                      type='body2'
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        transform: `translate(${depth * 10}px)`,
                        color: isSelected ? '#1CAEDC' : 'black'
                      }}
                    >
                      {constructTagLabel(nodeFromStore.Tag, nodeFromStore.TagLabel)}
                    </Typography>
                  }
                  className={isSelected ? 'selected' : ''}
                  onClick={this.selectTag}
                />
                <ListItemIcon style={visibilty}>
                  {show ? (
                    <VisibilityIcon onClick={() => this.hide()} />
                  ) : (
                      <VisibilityOffIcon onClick={() => this.show()} />
                    )}
                </ListItemIcon>
              </ListItem>
            </ThemeProvider>
            <Collapse in={open} timeout='auto'>
              {open &&
                <List disablePadding>
                  {child.map((childnodeId, index) => {
                    changebgColor = !changebgColor;
                    return (
                      <ExpansionRecursionConnected
                        key={index}
                        nodeId={childnodeId}
                        listId={this.props.listId}
                        projectId={projectId}
                        depth={depth + 1}
                        changebgColor={changebgColor}
                        showed={show}
                        viewer={viewer}
                        getAllTagChild={this.props.getAllTagChild}
                        showOnlyActive={this.props.showOnlyActive}
                      ></ExpansionRecursionConnected>
                    );
                  })}
                </List>
              }
            </Collapse>
          </div>}
      </Fragment>

    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  selectedNode: state.breakdown.selectedNode,
  nodeFromStore: state.breakdown.breakDownData[ownProps.listId][ownProps.nodeId],
  openCard: state.card.openCard
});

const ExpansionRecursionConnected = connect(mapStateToProps)(
  ExpansionRecursion
);
export default ExpansionRecursionConnected;
