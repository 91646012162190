/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import MainViewer from 'components/viewers/main-viewer/MainViewerComponent';
import GenericCardWrapper from 'components/generic-card/GenericCardWrapper';
import NavBar from 'components/nav-bar/NavBar';
import ProjectRootComponent from 'components/project-list/ProjectRoot';
import { Provider } from 'react-redux';
import { store } from 'utils/store';
import ConfirmModal from 'components/modal/ConfirmModal';
import SnackBar from 'components/snackbar/SnackBar';
import HomeComponent from 'components/home/HomeComponent';
import LogoutComponent from 'components/logout/LogoutComponent';
import DownloadBCF from 'components/link-BCF/DownloadBCF';
import OpenAPIComponent from 'components/open-api/OpenAPIComponent';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { loadConfig } from 'services/ConfigService';
import { initConfig } from 'actions/ConfigAction';
import * as langActions from 'actions/MultiLangAction';
import { loadProgressBar } from 'axios-progress-bar';
import theme from 'theme/theme';
import 'axios-progress-bar/dist/nprogress.css';
import './index.css';
import './App.css';
import UserSettings from 'components/user-settings/UserSettings';
import Auth from 'services/auth/Auth';
import { getUserFeatureZone } from 'services/AuthorizationService';
import { authAction } from 'actions/AuthAction';
import { getGroupUsers } from 'services/GroupService';
import { getUserByFilter, addUserToGroup } from 'services/UserService';

const progressBar = true;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      component: [
        { name: 'config', isReady: false },
        { name: 'auth', isReady: false }
      ],
      redirectUrl: ""
    };
    this.genericCardWrapper = React.createRef();
    this.updateIsReady = this.updateIsReady.bind(this);
    this.componentReady = this.componentReady.bind(this);
  }

  componentDidMount = () => {
    if (progressBar) loadProgressBar({ showSpinner: false });

    store.dispatch({
      type: langActions.SWITCH_LANGUAGE,
      language: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'en'
    });

    loadConfig((error, data) => {
      if (error) {
        console.error(error);
      }
      initConfig(data);
      this.componentReady('config');

      this.initAuth();
    });
  };

  componentReady = (componentName) => {
    var component = this.state.component;

    component
      .filter((component) => component.name === componentName)
      .find((component) => (component.isReady = true));

    this.setState({ component: component });

    this.updateIsReady();
  };

  updateIsReady = () => {
    var isReady = true;
    this.state.component
      .filter((component) => component.isReady === false)
      .find((component) => (isReady = false));

    if (isReady) {
      getUserFeatureZone()
        .then((result) => {
          store.dispatch({
            type: authAction.initAuthorization,
            authorization: result.data
          });
          let redirectUrl = "";
          // Redirect to requested page if existed
          if (localStorage.getItem('redirect')) {
            redirectUrl = localStorage.getItem('redirect');
            localStorage.removeItem('redirect');
          }
          this.setState({ isReady: true, redirectUrl });
        })
        .catch((err) => {
          console.log(err);
        });

      this.checkUserExsistInEnvGroup();

    } else {
      this.setState({ isReady: false });
    }
  };

  allowDragOver = (event) => {
    event.preventDefault();
  };

  initAuth = () => {
    Auth.initAuth(this.onLogin);

    if (Auth.isAuthenticated()) {
      this.setState({ isLog: true });
      return;
    }
  };

  onLogin = () => {
    this.componentReady('auth');
  };

  // Check if current user exists in environment group and if not add him/her
  checkUserExsistInEnvGroup = () => {
    // Get all users in this environment
    const envGroupId = store.getState().config.envGroupId;
    const userId = store.getState().auth.userId;
    getGroupUsers(envGroupId).then((users) => {
      // check if the user exist in the group
      const userExists = users.data.length > 0 && users.data.find(user => user.Id === userId);
      // If not add him
      if (!userExists) {
        addUserToGroup(userId, envGroupId).catch(error => console.error('error verifing environment user', error));
      }
    }).catch(error => console.error('error verifing environment user', error));

  };


  render() {
    return (
      <div className='App'>
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <Router>
              {this.state.isReady && (
                <div className='app-container' onDragOver={this.allowDragOver}>
                  <NavBar />
                  <GenericCardWrapper />
                  <SnackBar />
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return (
                        this.state.redirectUrl ?
                          <Redirect to={this.state.redirectUrl} /> :
                          <Redirect to="/home" />
                      );
                    }}
                  />
                  <Route exact path='/home' component={HomeComponent} />
                  <Route path='/projects' component={ProjectRootComponent} />
                  <Route path='/viewer' component={MainViewer} />
                  <Route path='/logout' component={LogoutComponent} />
                  <Route path='/download' component={DownloadBCF} />
                  <Route path='/userSettings' component={UserSettings} />
                  <Route path='/openAPI' component={OpenAPIComponent} />

                  <ConfirmModal />
                </div>
              )}
            </Router>
          </MuiThemeProvider>
        </Provider>
      </div>
    );
  }
}

export default App;
