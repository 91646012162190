/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { projectAction } from 'actions/ProjectAction';

export default function projectReducer(state, action) {
  state = state || {};

  switch (action.type) {
    case projectAction.removeProject:
      return Object.assign({}, state, {
        projectId: null,
        description: null,
        name: null,
        isProjectOwner: null,
        viewer: null,
        modelId: null
      });
    case projectAction.initProject:
      return Object.assign({}, state, {
        projectId: action.projectId,
        description: action.description,
        name: action.name,
        isProjectOwner: action.isProjectOwner,
        viewer: action.viewer
      });
    case projectAction.initFile:
      return Object.assign({}, state, {
        modelId: action.modelId,
        modelName: action.modelName,
        revision: action.revision,
        fileId: action.fileId,
        actualViewer: action.actualViewer,
        siteName: action.siteName
      });
    case projectAction.initMetadata:
      return Object.assign({}, state, {
        metadata: action.metadata
      });
    case projectAction.initReview:
      return Object.assign({}, state, {
        review: action.review
      });
    case projectAction.updateSelectedBreakdown:
      return Object.assign({}, state, {
        selectedBreakdown: action.selectedBreakdown
      });
    default:
      return state;
  }
}
