/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import md5 from 'js-md5';
import { isModel, extentionForDocument } from 'utils/ModelHelper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './FileUploadPropertyCardPanel.css';
import { store } from 'utils/store';
import Axios from 'axios';

const documentService = require('../../../services/DocumentService');

const azure = require('azure-storage');

class FileUploadPropertyCardPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      openMenu: false,
      close: '',
      fileSize: '',
      dataset1D: 'NONE',
      dataset2D3D: '',
      datasetDocument: 'DOCUMENT',
      datasetMappingDocument: '',
      documentMapping: '',
      files: [],
      sectionUploadToShow: null,
      fileInputName: '',
      displayInputfile: null,
      percent: 0,
      uploading: false,
      toModel: false,
      selectedModel: 'NONE',
      redirectFileResult: false,
      dataSourceType: null,
      viewerName: 'NONE'
    };
    this.fileInput = React.createRef();

    this.handleSelectedModelChange = this.handleSelectedModelChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.documentMappingHandler = this.documentMappingHandler.bind(this);
    this.uploadHandler = this.uploadHandler.bind(this);
    this.datasetChangedHandler = this.datasetChangedHandler.bind(this);
    this.viewerChangedHandler = this.viewerChangedHandler.bind(this);
    this.bucketName = this.props.config.bucket;
  }

  componentDidMount() {
    console.log(this.props.tag);
  }

  handleSelectedModelChange(event) {
    this.setState({ selectedModel: event.target.value });
  }

  handleClickMenu = () => {
    this.setState((state) => ({ openMenu: !state.openMenu }));
  };

  setPercent(progress) {
    this.setState({ percent: progress });
  }

  changeSectionUploadToShow(files) {
    this.setState({
      sectionUploadToShow: 'Document(s)',
      dataSourceType: 'Doc'
    });
  }

  fileChangedHandler(event) {
    if (event.target.files.length) {
      if (event.target.name === 'selectedFile') {
        if (event.target.files.length > 0) {
          this.setState({
            fileName: event.target.files[0].name.substr(
              0,
              event.target.files[0].name.lastIndexOf('.')
            )
          });
          this.setState({ fileSize: event.target.files[0].name.length });
        }
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }

      var dataset = event.target.files[0].name.split('.')[1].toUpperCase();
      if (!this.props.uploadToModel) {
        this.changeSectionUploadToShow(event.target.files);
      } else if (!isModel(event.target.files[0])) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.fileUpload.onModelError
        );
      }

      this.setState({
        fileInput: event.target.files[0],
        displayInputfile: event.target.files[0].name,
        dataset2D3D: dataset,
        dataSourceType: 'DOC'
      });
    } else {
      this.setState({
        fileInput: React.createRef(),
        displayInputfile: null,
        dataset2D3D: '',
        dataSourceType: ''
      });
    }
  }

  renderFileName() {
    const { fileName, fileSize } = this.state;

    let file = null;
    let sizeOfFile = null;

    file = fileName ? (
      <label>{fileName}</label>
    ) : (
      <label>{this.props.multiLang.fileUpload.chooseFile}</label>
    );
    sizeOfFile = fileSize ? <label>{fileSize}/80</label> : <label>0/80</label>;
    return (
      <div className='upload-file-raw'>
        <p className='document-title'>{file}</p>
        <p className='document-size'>{sizeOfFile}</p>
      </div>
    );
  }

  documentMappingHandler(event) {
    if (event.target.files.length) {
      this.setState({
        documentMapping: event.target.files[0],
        selectedfile: event.target.files[0].name,
        datasetMappingDocument: event.target.files[0].name
          .split('.')[1]
          .toUpperCase()
      });
    }
  }

  datasetChangedHandler(event) {
    this.setState({
      dataset1D: event.target.value
    });
  }

  viewerChangedHandler(event) {
    this.setState({
      viewerName: event.target.value
    });
  }

  selectChangedHandler(event) {
    this.setState({ sectionUploadToShow: event.target.value });
  }

  getHashNameFile(fileInputName) {
    return md5(fileInputName);
  }

  verifParams() {
    return this.state.fileInput;
  }

  buildParams() {
    return {
      Bucket: this.bucketName,
      Key:
        this.getHashNameFile(this.state.displayInputfile) +
        '/' +
        this.props.projectId +
        '/' +
        this.state.datasetDocument +
        '/' +
        this.state.displayInputfile,
      Body: this.state.fileInput,
      ACL: 'private',
      Tagging:
        'dataset=' +
        this.state.datasetDocument +
        '&project=' +
        this.props.projectId,
      Metadata: {
        sub: this.props.userId,
        fileid: this.props.fileId ? this.props.fileId : 'new_file',
        datasourcetype: this.state.dataSourceType,
        viewername: this.props.viewer,
        tagobject: this.props.tag,
        attributesdocumentname: this.state.displayInputfile,
        attributeslocation:
          this.getHashNameFile(this.state.displayInputfile) +
          '/' +
          this.props.projectId +
          '/' +
          this.state.datasetDocument +
          '/' +
          this.state.displayInputfile
      }
    };
  }
  /**
   * Config the bucket S3 with necessary information
   *  into S3 the file is registred as
   *   [hash of name file]/[projet name]/[dataset]/[name file]
   *
   *  For feature of upload document(s) in Bucket S3 is
   *
   * [hash of name file mapping]/[projet name]/[dataset]/[name file]
   */
  uploadHandler(event) {
    this.setState({ uploading: true });
    if (this.verifParams()) {
      switch (this.props.config.authProvider) {
        case 'AzureADDB2C':
          this.uploadAzureFile();
          break;
        case 'AWSCognito':
          this.uploadAwsFile();
          break;
        default:
          console.error('Cloud is not defined');
      }
    } else {
      this.props.snackBar.addSnackError(
        this.props.multiLang.projectFile.uploadfileError
      );
      console.log("The upload file's name is missing");
    }
    event.preventDefault();
  }

  uploadAzureFile = async () => {
    var config = store.getState().config;
    const param = this.buildParams();
    // get sas token
    let result = await documentService.getSasToken(
      config.uploadStorageAccountName,
      config.uploadContainerName
    );
    let blobService = azure.createBlobServiceWithSas(
      config.uploadStorageUri,
      result.token
    );
    let resultData = await documentService.uploadFile(
      config.uploadStorageUri,
      this.state.fileInput,
      config.uploadContainerName,
      param.Key,
      param.Metadata,
      result.token
    );
    if (resultData.status === 201) {
      blobService.setBlobMetadata(
        config.uploadContainerName,
        param.Key,
        param.Metadata,
        (err, result, Response) => {
          if (result) {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.projectFile.uploadOf +
                this.state.displayInputfile +
                this.props.multiLang.projectFile.uploadComplete
            );
            this.setState({ uploading: false });
            this.props.close();
          } else {
            this.props.snackBar.addSnackError(
              this.props.multiLang.projectFile.uploadfileError
            );
          }
        }
      );
    }
    //TODO: this be use with API management
    var headers = store.getState().auth.headers;
    Axios.post(
      config.masterLogicAppUrl,
      {
        blobName: param.Key
      },
      { headers: headers }
    );
  };

  uploadAwsFile = () => {
    this.props.s3
      .upload(
        this.buildParams(),
        function (err, data) {
          if (err) {
            this.props.snackBar.addSnackError(
              this.props.multiLang.projectFile.uploadfileError
            );
            console.error(err);
          } else {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.projectFile.uploadOf +
                this.state.displayInputfile +
                this.props.multiLang.projectFile.uploadComplete
            );
            this.setState({ uploading: false });
            this.props.close();
          }
        }.bind(this)
      )
      .on(
        'httpUploadProgress',
        function (evt) {
          var progress = Math.round((evt.loaded / evt.total) * 100);
          this.setPercent(progress);
        }.bind(this)
      );
  };

  acceptFilesList() {
    return '.' + extentionForDocument.join(',.');
  }

  renderUpload() {
    return (
      <div className='row'>
        <div className='upload-area'>
          <div className='upload-file-raw'>
            <div className='custom-file'>
              <label className='files-to-upload'>
                {this.props.multiLang.navBar.methodsOfUpload}
              </label>
              <div className='btn-upload-document'>
                <input
                  accept={this.acceptFilesList()}
                  className='input-upload-file-hide'
                  id='contained-button-file'
                  disabled={this.props.wasDrag}
                  type='file'
                  name='selectedFile'
                  onChange={this.fileChangedHandler}
                />
                <label htmlFor='contained-button-file'>
                  <Button
                    variant='contained'
                    component='span'
                    className='icon-upload-document'
                  >
                    <svg
                      viewBox='0 0 24 24'
                      fit=''
                      height='100%'
                      width='100%'
                      preserveAspectRatio='xMidYMid meet'
                      focusable='false'
                    >
                      <g>
                        <path d='M0 0h24v24H0z' fill='none'></path>
                        <path d='M 20,6 12,6 10,4 4,4 C 2.9,4 2.01,4.9 2.01,6 L 2,18 c 0,1.1 0.9,2 2,2 l 16,0 c 1.1,0 2,-0.9 2,-2 L 22,8 C 22,6.9 21.1,6 20,6 Z m 1.144068,12.966102 -18.0338985,0 -0.025424,-11.90678 18.0847462,-0.050847 z'></path>
                      </g>
                    </svg>
                  </Button>
                </label>
              </div>
            </div>
          </div>

          <div className='dropdown-list-menu'>
            <ListItem button onClick={this.handleClickMenu}>
              <ListItemText
                inset
                primary={this.props.multiLang.propertyCard.supportedFormat}
              />
              {this.state.openMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openMenu} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button className='dropdown-menu'>
                  <ListItemText
                    inset
                    primary='PDF, DOC, DOCX, PPT, PPTX, MP4, MP3'
                  />
                </ListItem>
              </List>
            </Collapse>
          </div>
        </div>
        <div className='upload-file-raw'>
          <label className='document-name'>
            {this.props.multiLang.projectFile.documentName}
          </label>
        </div>
        {this.renderFileName()}

        <div className='upload-file-raw'>
          <Button
            variant='outlined'
            size='medium'
            color='primary'
            className='btn-upload'
            onClick={this.uploadHandler}
          >
            {this.props.multiLang.projectFile.upload}
          </Button>
        </div>
      </div>
    );
  }

  renderModelList(files) {
    return files
      .filter((file) => isModel(file))
      .map((file) => {
        return (
          <option key={file.modelId} value={file.modelId}>
            {file.key}
          </option>
        );
      });
  }

  render() {
    return (
      <div role='main'>
        <div className='container'>{this.renderUpload()}</div>
        {this.state.uploading && (
          <progress value={this.state.percent} min='0' max='100'></progress>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  fileId: state.project.fileId,
  viewer: state.project.viewer,
  userId: state.auth.userId,
  snackBar: state.snackBar,
  s3: state.auth.s3,
  config: state.config,
  multiLang: state.multiLang,
  modelId: state.project.modelId,
  revision: state.project.revision
});

export default connect(mapStateToProps)(FileUploadPropertyCardPanel);
