/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import { connect } from "react-redux";
import { constructTagLabel } from "utils/TagHelper";

class AdvancedSearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  /**
   * Call change page function from props
   * @param {any} event
   * @param {int} newPage
   */
  handleChangePage(event, newPage) {
    if (this.props.onChangePage) {
      this.props.onChangePage(newPage);
    }
  }

  /**
   * Call change row per page function from props
   * @param {any} event
   * @param {int} newPage
   */
  handleChangeRowsPerPage(event) {
    if (this.props.onChangeRowsPerPage) {
      this.props.onChangeRowsPerPage(event.target.value);
    }
  }

  /**
   * Handle row click
   */
  handleRowClick(row) {
    if (this.props.onRowSelected) this.props.onRowSelected(row);
  }

  openNav = () => {
    document.getElementById("SidenavId").style.width = "400px";
  };

  openExtendedSearch = () => {
    this.props.handleCloseSearch();
    this.props.searchParams.openSidePanel();
  };

  render() {
    const rows = this.props.rows;

    if (!rows) {
      return <Fragment />;
    }

    return (
      <div>
        <Table>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} onClick={() => this.handleRowClick(row)}>
                <TableCell component="th" scope="row">
                  {constructTagLabel(row.tag, row.tagLabel)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ paddingRight: 0 }}
                rowsPerPageOptions={[this.props.nbElementByPage]}
                colSpan={2}
                count={this.props.totalNbElements}
                rowsPerPage={this.props.nbElementByPage}
                page={this.props.pageNumber - 1}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                //ActionsComponent={TablePaginationActions}
              />
              <TableCell>
                <IconButton
                  onClick={this.openExtendedSearch}
                  style={{ marginTop: 8 }}
                >
                  <VerticalSplit />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchParams: state.searchParams
});
export default connect(mapStateToProps)(AdvancedSearchResult);
