/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { getImportStatusByProjectIdAndImportId } from 'services/ImportService';
import ImportStatusDetail from './ImportStatusDetail';

class ImportDetails extends Component {
  state = {
    data: []
  };

  columns = [
    {
      name: this.props.multiLang.importDetails.category,
      label: this.props.multiLang.importDetails.category,
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ['REJET_MESSAGE', 'ALERTE_IMPORT', 'ALERTE_QUALITE']
        }
      }
    },
    {
      name: this.props.multiLang.importDetails.tag,
      label: this.props.multiLang.importDetails.tag,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.importDetails.line,
      label: this.props.multiLang.importDetails.line,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.importDetails.status,
      label: this.props.multiLang.importDetails.status,
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ['OK', 'KO', 'WARNING', 'ERROR']
        }
      }
    },
    {
      name: this.props.multiLang.importDetails.message,
      label: this.props.multiLang.importDetails.message,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  options = {
    filter: false, // unable filter feature in table
    filterType: 'dropdown', // use drop down for filtring data
    responsive: 'scrollMaxHeight', // 'scrollMaxHeight':limits height of table, 'scrollFullHeight':table takes on as much height as needed to display all rows set in rowsPerPage
    selectableRows: 'none', // disable row selection
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset,
        filters: this.props.multiLang.projectFile.filters
      }
    },
    search: false,
    print: false, // disable print feature
    download: true, // enable download feature
    onDownload: (buildHead, buildBody, columns, data) => {
      // Get columns heads
      const columnsHead = columns.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.name);
        return accumulator;
      },
        []
      );
      // Get all results and write them to a csv file
      this.writeResultToFile(columnsHead);
      return false;
    },
    expandableRows: true,
    rowsPerPageOptions: [10],
    renderExpandableRow: (rowData) => {
      return (
        <ImportStatusDetail
          importId={this.state.importId}
          projectId={this.props.projectId}
          tag={rowData[1]}
        />
      );
    },
    serverSide: true,

    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        this.loadData(this.state.importId, tableState.page, "ERROR,WARNING,KO");
      }
    },
    onFilterChange: (changedColumn, filterList) => {
      this.loadData(this.state.importId, this.state.page, "ERROR,WARNING,KO", filterList[2][0]); // TODO Vérifier le fonctionnement, A quoi correspond la matrice filterList ?
    }
  };

  componentDidMount() {
    const importId = this.props.match.params.importId;
    this.setState({ importId });
    if (!this.props.projectId) return;

    this.loadData(importId, 0, "ERROR,WARNING,KO");
  }

  loadData(importId, page, status) {
    let ScanIndexForward = page < this.state.page;

    const params = {
      projectId: this.props.projectId,
      importId: importId,
      entity: 'TAG,IMPORT,DATASOURCE',
      limit: 10,
      ExclusiveStartKey: this.state.LastEvaluatedKey,
      ScanIndexForward,
      status,
      page
    };
    getImportStatusByProjectIdAndImportId(params, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.datasources.errors.importLoad
        );
      }
      let formatedImport = data.data.map((status) => {
        return [
          status.Category,
          status.Tag,
          status.LineNumber,
          status.Status,
          status.ErrorMessage ? status.ErrorMessage : ''
        ];
      });

      this.options.count = data.Count;
      this.setState({
        data: formatedImport,
        LastEvaluatedKey: data.LastEvaluatedKey,
        page
      });
    });
  }
  // write all table data into a file
  writeResultToFile(columns) {
    const params = {
      projectId: this.props.projectId,
      importId: this.state.importId,
      entity: 'TAG,IMPORT,DATASOURCE',
      limit: this.options.count,
      page: 0,
      status: "ERROR,WARNING,KO"
    };
    getImportStatusByProjectIdAndImportId(params, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.datasources.errors.importLoad
        );
      }
      let formatedImport = [columns];
      data.data.forEach((status) => {
        formatedImport.push([
          status.Category,
          status.Tag,
          status.LineNumber,
          status.Status,
          status.ErrorMessage ? status.ErrorMessage : ''
        ]);
      });
      let csvContent = formatedImport.map(function (d) {
        return d.join(';');
      }).join('\n');
      this.download(`imports_${this.props.projectId}_${this.props.match.params.importId}_${new Date().toISOString()}.csv`, csvContent);
    });
  }

  // Create a link and download the file
  download(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }



  render() {
    if (!this.state.data) return <Fragment />;
    return (
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Imports'}
            data={this.state.data}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  projectName: state.project.name,
  snackBar: state.snackBar,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(ImportDetails);
