/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Fab from '@material-ui/core/Fab';
import { getEventsForModel } from 'services/NotificationService';
import { store } from 'utils/store';

const Autodesk = window.Autodesk;
const THREE = window.THREE;

class IconMarkupExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this._group = null;
    this._button = null;
    this._icons = options.icons || [];
  }

  load() {
    console.log('load 3dmarkup extension');
    this.viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.updateIcons.bind(this)
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.ISOLATE_EVENT,
      this.updateIcons.bind(this)
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.HIDE_EVENT,
      this.updateIcons.bind(this)
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.SHOW_EVENT,
      this.updateIcons.bind(this)
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      this.display3DMarkup.bind(this)
    );
    return true;
  }

  unload() {
    // Clean our UI elements if we added any
    if (this._group) {
      this._group.removeControl(this._button);
      if (this._group.getNumberOfControls() === 0) {
        this.viewer.toolbar.removeControl(this._group);
      }
    }
    return true;
  }

  onToolbarCreated() {
    // Create a new toolbar group if it doesn't exist
    this._group = this.viewer.toolbar.getControl('customExtensions');
    if (!this._group) {
      this._group = new Autodesk.Viewing.UI.ControlGroup('customExtensions');
      this.viewer.toolbar.addControl(this._group);
    }

    // Add a new button to the toolbar group
    this._button = new Autodesk.Viewing.UI.Button('IconExtension');
    this._button.onClick = (ev) => {
      this._enabled = !this._enabled;
      this.showIcons(this._enabled);
      this._button.setState(this._enabled ? 0 : 1);
    };
    this._button.setToolTip(this.options.button.tooltip);
    this._button.container.children[0].classList.add(
      'fas',
      this.options.button.icon
    );
    this._group.addControl(this._button);
  }

  showIcons(show) {
    const $viewer = document.getElementById(this.viewer.clientContainer.id);
    // do we have anything to show?
    if (this._icons === undefined || this.icons === null) return;

    // do we have access to the instance tree?
    let tree = this.viewer.model.getInstanceTree();
    if (tree === undefined) {
      console.log('Loading tree...');
    }

    const onClick = (id) => {
      this.viewer.select(id);
      this.viewer.utilities.fitToView();
    };

    this._frags = {};
    for (var i = 0; i < this._icons.length; i++) {
      // we need to collect all the fragIds for a given dbId
      const icon = this._icons[i];
      this._frags['dbId' + icon.dbId] = [];
      const $label = document.createElement('label');
      $label.classList.add('markupLabel');
      $label.setAttribute('id', icon.dbId);
      const span = document.createElement('span');
      ReactDOM.render(
        <Fab
          size='small'
          color='secondary'
          aria-label='add'
          onClick={() => onClick(icon.dbId)}
        >
          {icon.nbrEvent}
        </Fab>,
        $label
      );
      $label.appendChild(span);
      $viewer.appendChild($label);
      // now collect the fragIds
      const _this = this;
      tree.enumNodeFragments(icon.dbId, function (fragId) {
        _this._frags['dbId' + icon.dbId].push(fragId);
        _this.updateIcons(); // re-position of each fragId found
      });
    }
  }

  getModifiedWorldBoundingBox(dbId) {
    const nodebBox = new THREE.Box3();
    if (this.viewer.model) {
      var fragList = this.viewer.model.getFragmentList();

      // for each fragId on the list, get the bounding box
      if (this._frags && this._frags['dbId' + dbId]) {
        for (const fragId of this._frags['dbId' + dbId]) {
          const fragbBox = new THREE.Box3();
          fragList.getWorldBounds(fragId, fragbBox);
          nodebBox.union(fragbBox); // create a unifed bounding box
        }
      }
    }
    return nodebBox;
  }

  updateIcons() {
    const $viewer = document.getElementById(this.viewer.clientContainer.id);
    var $viewerPosition = $viewer.getBoundingClientRect();
    let labels = document.getElementsByClassName('markupLabel');
    for (const label of labels) {
      const id = label.getAttribute('id');
      // get the center of the dbId (based on its fragIds bounding boxes)
      const pos = this.viewer.worldToClient(
        this.getModifiedWorldBoundingBox(id).center()
      );
      //position the label center to it
      label.style.left = Math.floor(pos.x - label.offsetWidth / 2) + 'px';
      label.style.top = Math.floor(pos.y - label.offsetHeight / 2) + 'px';
      if (this.viewer.model) {
        label.style.display = this.viewer.isNodeVisible(id) ? 'block' : 'none';
      }
      let labelPosition = label.getBoundingClientRect();
      label.style.display =
        labelPosition.top > $viewerPosition.top &&
        labelPosition.left > $viewerPosition.left &&
        labelPosition.bottom < $viewerPosition.bottom &&
        labelPosition.right < $viewerPosition.right
          ? 'block'
          : 'none';
    }
  }

  display3DMarkup() {
    let viewerId = this.viewer.clientContainer.id;
    let projectId = store.getState().project.projectId;
    let modelId = viewerId.split('#')[1];
    let revision = viewerId.split('#')[2];
    let viewer = 'FORGE_VIEWER';
    console.log(modelId);
    getEventsForModel(projectId, modelId, revision, viewer)
      .then((result) => {
        this._icons = result.data.map((element) => {
          return { dbId: parseInt(element.id), nbrEvent: element.nbrEvent };
        });
        this.showIcons(true);
      })
      .catch((error) => {
        console.log('error : ', error);
      });
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  'IconMarkupExtension',
  IconMarkupExtension
);
