/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAllUserSetting } from 'services/UserSettingService';
import { getFile } from 'services/FileService';
import { projectAction } from 'actions/ProjectAction';
import _ from 'lodash';

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      hasNoFavorite: false
    };
  }

  componentDidMount() {
    getAllUserSetting((error, dataUserSetting) => {
      if (error) {
        this.setState({
          hasNoFavorite: true
        });
        return;
      }

      if (!_.has(dataUserSetting.defaultModel, 'projectId')
        || !_.has(dataUserSetting.defaultModel, 'fileId')) {
        this.setState({ hasNoFavorite: true });
        return;
      }

      getFile(
        dataUserSetting.defaultModel.projectId,
        dataUserSetting.defaultModel.fileId,
        (error, dataFile) => {
          if (error) {
            console.error(error);
            this.setState({ hasNoFavorite: true });
            return;
          }
          this.setState({
            viewer: dataFile.viewer,
            projectId: dataUserSetting.defaultModel.projectId,
            modelId: dataFile.modelId,
            revision: dataFile.current,
            isReady: true,
            modelName: dataUserSetting.defaultModel.modelFileName
          });
          this.props.dispatch({
            type: projectAction.initFile,
            projectId: dataUserSetting.defaultModel.projectId,
            modelId: dataFile.modelId,
            revision: dataFile.current,
            modelName: dataUserSetting.defaultModel.modelFileName,
            actualViewer: dataFile.viewer,
            siteName: dataFile.siteName
          });
        }
      );
    });
  }

  render() {
    if (this.state.hasNoFavorite) {
      return <Fragment>{<Redirect to={`/projects`} />}</Fragment>;
    }
    if (this.state.isReady) {
      return (
        <Fragment>
          {
            <Redirect
              to={`/viewer?viewer=${this.state.viewer}&projectId=${this.state.projectId}&modelId=${this.state.modelId}&revision=${this.state.revision}`}
            />
          }
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(HomeComponent);
