/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { parseModelDataForDataSet } from "./Model-utils";
import { Accordion, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { constructTagLabel } from "utils/TagHelper";

import "./RelatedTab.css";
import { withStyles } from "@material-ui/core/styles";

const AccordionSummary = withStyles({
  content: {
    color: "#0c4178",
    marginLeft: "30px !important"
  },

  expandIcon: {
    color: "#0c4178",
    left: "8px",
    right: "unset"
  }
})((props) => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = "AccordionSummary";

class RelatedTab extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const data = parseModelDataForDataSet(this.props.data, "Related Items");

    if (!data) {
      return (
        <Fragment>
          <div className="dp-notif-right">
            <span className="dp-info-what">
              {this.props.multiLang.propertyCard.noAttributes}
            </span>
          </div>
        </Fragment>
      );
    }

    // TODO
    // Remove IS_OBJECT_OF from the relations
    // The relation is displayed with the modelId
    // The following line will be removed after modification (DTP-728)
    delete data.IS_OBJECT_OF;

    return (
      <Fragment>
        <div className="tab-content">
          <div className="expandedModel">
            {data && (
              <Fragment>
                {Object.keys(data).map((dataset, indexDataset) => (
                  <Accordion key={indexDataset} className="expansionModel">
                    <AccordionSummary
                      className="expansionSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      {dataset.replace(/_/g, " ")}
                    </AccordionSummary>
                    {data[dataset].map((attribute, index) => (
                      <AccordionDetails
                        key={attribute + index}
                        className="expansionDetailsModel"
                      >
                        <button
                          className="button--link attribute__name expansionButton"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.card.openCard(
                              "PROPERTY_CARD",
                              { title: "Properties", tag: attribute.Name },
                              150,
                              150,
                              { id: attribute.Name }
                            );
                          }}
                        >
                          <span className="">
                            {constructTagLabel(
                              attribute.Name,
                              attribute.TagLabel
                            )}
                          </span>
                        </button>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  card: state.card,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(RelatedTab);
