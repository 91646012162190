/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import './SidePanel.css'


class SidePanel extends Component {

    sidePanelId = this.props.id;

    minimizeSearch = () => {
        document.getElementById(this.sidePanelId).style.width = "50px";
        document.getElementById(this.sidePanelId).style.height = "150px";
        document.getElementById(this.sidePanelId).style.top = this.props.minimizetop;
        document.getElementById(this.sidePanelId).style.borderTop = "1px solid #e0e0e0";
        document.getElementById(this.sidePanelId).style.borderBottom = "1px solid #e0e0e0";
        document.getElementById(this.sidePanelId).style.zIndex = 10;

    };

    expandSearch = () => {
        if (document.getElementById(this.sidePanelId)) {
            document.getElementById(this.sidePanelId).style.width = "400px";
            document.getElementById(this.sidePanelId).style.top = null;
            document.getElementById(this.sidePanelId).style.height = "100%";
            document.getElementById(this.sidePanelId).style.zIndex = 20;
        }
    };

    render() {
        if (this.props.open) {
            if (this.props.minimize) {
                this.minimizeSearch();
                return (
                    <div id={this.sidePanelId} className="sidenav">
                        {this.props.children}
                    </div>
                );
            }
            this.expandSearch();
            return (
                <div id={this.sidePanelId} className="sidenav">
                    {this.props.children}
                </div>
            );
        }
        return null;
    }

}


export default SidePanel;