/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { getImportByProjectId } from 'services/ImportService';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/OpenInNew';
import Tooltip from "@material-ui/core/Tooltip";
import { parseDate } from 'utils/DateHelper';
import moment from 'moment';

class FileUpload extends Component {
  state = {
    data: [],
    page: 0
  };

  columns = [
    {
      name: this.props.multiLang.datasources.import.startDate,
      label: this.props.multiLang.datasources.import.startDate,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.endDate,
      label: this.props.multiLang.datasources.import.endDate,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.importFileName,
      label: this.props.multiLang.datasources.import.importFileName,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.state,
      label: this.props.multiLang.datasources.import.state,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip title={
              this.getStatusHelpText(
                Object.keys(this.props.multiLang.datasources.import.steps)
                  .find(e => value === this.props.multiLang.datasources.import.steps[e])
              )
            }>
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: this.props.multiLang.datasources.import.total,
      label: this.props.multiLang.datasources.import.total,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.totalSuccess,
      label: this.props.multiLang.datasources.import.totalSuccess,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.totalError,
      label: this.props.multiLang.datasources.import.totalError,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: this.props.multiLang.datasources.import.detailListButton,
      label: this.props.multiLang.datasources.import.detailListButton,
      options: {
        filter: true,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fragment>
              <IconButton
                aria-label='More'
                aria-haspopup='true'
                onClick={(event) => {
                  this.setState({ goToImportDetails: value });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Fragment>
          );
        }
      }
    }
  ];

  options = {
    filter: false, // disable filter feature in table
    filterType: 'dropdown', // use drop down for filtring data
    responsive: 'scrollMaxHeight',
    selectableRows: 'none', // disable row selection
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset,
        filters: this.props.multiLang.projectFile.filters
      }
    },
    search : false,
    print: false, // disable print feature
    download: false, // disable download feature,
    serverSide: true,
    rowsPerPageOptions: [10],
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        this.loadData(this.props.projectId, tableState.page);
      }
    }
  };

  getStatusHelpText = (value) => {
    let tooltipContent;
    if (value) {
      tooltipContent = <React.Fragment>{this.props.multiLang.datasources.import.steps.description[value]}</React.Fragment>;
    } else {
      tooltipContent = <React.Fragment></React.Fragment>;
    }
    return tooltipContent;
  }

  componentDidMount() {
    const projectId = this.props.projectId;
    if (!projectId) return;
    this.loadData(projectId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.loadData(this.props.projectId);
    }
  }

  loadData(projectId, page = 0) {
    let ScanIndexForward = page < this.state.page;

    const params = {
      projectId: projectId,
      ExclusiveStartKey: this.state.LastEvaluatedKey,
      ScanIndexForward,
      page
    };
    getImportByProjectId(params, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.datasources.errors.importLoad
        );
      }
      let imports = Object.keys(data.Imports).map((importId) => {
        let status = this.props.multiLang.datasources.import.steps[
          data.Imports[importId].Step
        ];
        // If no translation, keep the key
        status = status ? status : data.Imports[importId].Step;

        // For retro-compatibility
        let total = data.Imports[importId].Total == null ? null : (data.Imports[importId].Total.hasOwnProperty('Count') ? data.Imports[importId].Total.Count : data.Imports[importId].Total);
        let totalSuccess = data.Imports[importId].TotalSuccess == null ? null : (data.Imports[importId].TotalSuccess.hasOwnProperty('Count') ? data.Imports[importId].TotalSuccess.Count : data.Imports[importId].TotalSuccess);
        let totalError = data.Imports[importId].TotalError == null ? null : (data.Imports[importId].TotalError.hasOwnProperty('Count') ? data.Imports[importId].TotalError.Count : data.Imports[importId].TotalError);

        return [
          parseDate(
            data.Imports[importId].ImportDate,
            this.props.multiLang.projectFile.dateFormatISO
          ),
          data.Imports[importId].EndDatetime
            ? parseDate(
              data.Imports[importId].EndDatetime,
              this.props.multiLang.projectFile.dateFormatISO
            )
            : '',
          data.Imports[importId].Filename,
          status,
          total,
          totalSuccess,
          totalError,
          importId,
          data.Imports[importId].DatasourceId
        ];
      });

      imports.sort((import1, import2) => {
        return moment(
          import2[0],
          this.props.multiLang.projectFile.dateFormatISO
        ) > moment(import1[0], this.props.multiLang.projectFile.dateFormatISO)
          ? 1
          : moment(import2[0], this.props.multiLang.projectFile.dateFormatISO) <
            moment(import1[0], this.props.multiLang.projectFile.dateFormatISO)
            ? -1
            : 0;
      });

      this.options.count = data.Count;
      this.setState({
        data: imports,
        LastEvaluatedKey: data.LastEvaluatedKey,
        page
      });
    });
  }

  render() {
    if (this.state.goToImportDetails) {
      return (
        <Redirect
          to={`/projects/${this.props.projectId}/imports/${this.state.goToImportDetails}`}
        />
      );
    }
    let data;
    if (this.props.location.state) {
      let modelId = this.props.location.state.modelId;
      data = modelId
        ? this.state.data.filter((file) => file[8] === modelId)
        : this.state.data;
    } else {
      data = this.state.data;
    }
    return (
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Imports'}
            data={data}
            columns={this.columns}
            options={this.options}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  projectName: state.project.name,
  snackBar: state.snackBar,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(FileUpload);
