/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { projectAction } from 'actions/ProjectAction';
import { getUserProjects } from 'services/UserService';
import ProjectListCard from './ProjectListCard';

class ProjectListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      toModel: false,
      getPrj: 'Pending...'
    };
  }

  componentDidMount() {
    // Get the user's projects
    getUserProjects((error, data) => {
      if (error) {
        console.error(error);
        this.setState({ getPrj: 'noData' });
        return this.props.snackBar.addSnackError(
          this.props.multiLang.project.accessPrjError
        );
      }
      this.setState({ projects: data });

      this.props.dispatch({
        type: projectAction.removeProject,
        projectId: null,
        description: null,
        name: null,
        isProjectOwner: null,
        viewer: null,
        selectedBreakdown: null
      });
    });
  }

  handleClick(project) {
    this.props.dispatch({
      type: projectAction.initProject,
      projectId: project.ProjectId,
      description: project.Description,
      name: project.Name,
      isProjectOwner: project.ProjectOwner,
      viewer: project.Viewer
    });
    this.setState({
      toModel: true,
      projectId: project.ProjectId
    });
  }

  renderProject() {
    return this.state.projects.map((project) => {
      return (
        <ProjectListCard
          key={project.ProjectId}
          project={project}
          onClick={() => this.handleClick.bind(this, project)}
        ></ProjectListCard>
      );
    });
  }

  render() {
    if (this.state.toModel === true) {
      return <Redirect to={`/projects/${this.state.projectId}/files`} />;
    }
    if (this.state.getPrj === 'noData') {
      return <div>This user does not have access to any project.</div>;
    }
    return (
      <ScrollArea>
        <div className='container'>
          <br />
          <div className='card-columns'>{this.renderProject()}</div>
        </div>
      </ScrollArea>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  projectName: state.project.name,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  config: state.config
});

export default connect(mapStateToProps)(ProjectListComponent);
