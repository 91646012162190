/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from "react";
import PropertyCardPanel from "components/card/PropertyCardPanel";
import RawDataCardPanel from "components/card/RawDataCardPanel";
import MarkupFormCardPanelBasic from "components/card/MarkupFormCardPanelBasic";
import ListMarkupCardPanelBasic from "components/card/ListMarkupCardPanelBasic";
import BreakdownPanel from "components/card/BreakdownPanel";
import AttachTagPanel from "components/card/AttachTagPanel";
import SharePanel from "components/card/SharePanel";

import IotExpandChart from "components/card/property-card/IotExpandChart.js";

export default function FactoryComponent(type, data, options) {
  if (type === "PROPERTY_CARD")
    return (
      <PropertyCardPanel
        data={data}
        forceRender={Math.random()}
        options={options}
      />
    );
  if (type === "MARKUP_FORM_CARD")
    return (
      <MarkupFormCardPanelBasic
        data={data}
        forceRender={Math.random()}
        options={options}
      />
    );
  if (type === "MARKUP_LIST_CARD")
    return (
      <ListMarkupCardPanelBasic
        data={data}
        forceRender={Math.random()}
        options={options}
      />
    );
  if (type === "EXPAND_CHART_CARD")
    return <IotExpandChart data={data} forceRender={Math.random()} />;
  if (type === "BREAKDOWN_CARD")
    return (
      <BreakdownPanel
        data={data}
        forceRender={Math.random()}
        options={options}
      />
    );
  if (type === "ATTACH_TAG_CARD") return <AttachTagPanel data={data} />;
  if (type === "SHARE_CARD") return <SharePanel data={data} />;
  if (type === "RAW_DATA_CARD")
    return <RawDataCardPanel data={data} forceRender={Math.random()} />; // TODO supprimer

  return;
}
