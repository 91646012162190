/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from "axios/index";
import { store } from "utils/store";

function getAllRights(){
    let headers = store.getState().auth.headers;
    let config = store.getState().config;

    return axios.get(`${config.urlProjectAPI}/featureZones/rights`, { headers: headers});
}

function createFeatureZone(name, projectId, description, groups, rights){
    let headers = store.getState().auth.headers;
    let config = store.getState().config;

    let data = {
        name: name,
        projectId: projectId,
        description: description,
        groups: groups,
        rights: rights
    };
    return axios.put(`${config.urlProjectAPI}/featureZones`,data, { headers: headers});
}

function updateFeatureZone(name, description, groups, rights, featureZoneId){
    let headers = store.getState().auth.headers;
    let config = store.getState().config;

    let data = {
        name: name,
        description: description,
        groups: groups,
        rights: rights,
        featureZoneId: featureZoneId
    };
    return axios.put(`${config.urlProjectAPI}/featureZones/update`,data, { headers: headers});
}

function getFeatureZoneByProject(projectId){
    let headers = store.getState().auth.headers;
    let config = store.getState().config;
    return axios.get(`${config.urlProjectAPI}/featureZones/projects/${projectId}`, {headers: headers});
}

function deleteFeatureZone(featureZoneId, projectId){
    let headers = store.getState().auth.headers;
    let config = store.getState().config;
    return axios.delete(`${config.urlProjectAPI}/featureZones/${featureZoneId}/projects/${projectId}`, {headers: headers});
}


export {
    getFeatureZoneByProject,
    getAllRights,
    createFeatureZone,
    deleteFeatureZone,
    updateFeatureZone
};