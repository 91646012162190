/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AutoComplete from './AutoComplete';

import nearley from 'nearley';
import grammar from './parser/grammar';

import { getClasses, getAttributes } from 'services/CatalogueService';

const operatorList = ['=', '<', '>', 'like'];
class AdvancedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      tagClass: '',
      attributeConditions: '',
      excludedWords: '',
      searchInfo: {
        tagClass: '',
        attributeConditions: '',
        excludedWords: ''
      }
    };

    this.getAttributesList = this.getAttributesList.bind(this);
    this.handleAttributeConditionsChange = this.handleAttributeConditionsChange.bind(
      this
    );
    this.selectedAttribute = this.selectedAttribute.bind(this);
  }

  /**
   * Handle for attributeConditions value and store in state
   * @param {string} attributeConditions
   */
  handleAttributeConditionsChange(attributeConditions) {
    this.setState({ attributeConditions: attributeConditions });

    // *** F* UGLY***
    var buildAttributeConditions =
      this.state.datasetName + '.' + attributeConditions.split('.')[1];
    var className = attributeConditions.split('.')[0];

    if (this.props.onChange)
      this.props.onChange({
        className: className,
        attributeConditions: buildAttributeConditions
      });
  }

  /**
   * Parse attribute conditions
   * @param {string} attributeConditions
   * @return {Object} { conditionType, parserResults, err }
   */
  parseAttributeConditions(attributeConditions) {
    // Nothing to Parse
    if (!attributeConditions) {
      return { conditionType: 'CLASS', parserResults: {} };
    }

    var conditionType;
    // Create a Parser object from our grammar.
    var parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));

    try {
      parser.feed(attributeConditions);
    } catch (err) {
      // Catch error not matching to grammar
      return { conditionType: null, parserResults: null, err };
    }

    const parserResults = parser.results[0];
    const lastCharIsSpace = attributeConditions.slice(-1) === ' ';

    if (parserResults.className && !parserResults.hasDot) {
      // case Class
      conditionType = 'CLASS';
    } else if (
      parserResults.className && // SAV dataset
      parserResults.hasDot &&
      !parserResults.operator &&
      !lastCharIsSpace
    ) {
      // case attribute
      conditionType = 'ATTRIBUTE';
    } else if (!parserResults.value) {
      // case operator
      conditionType = 'OPERATOR';
    } else {
      // case value
      conditionType = 'VALUE';
    }

    return { conditionType, parserResults };
  }

  /**
   * Call api and return table result for suggestion
   * @param {string} attributeConditions
   * @param {function} callback
   */
  getAttributesList(attributeConditions, callback) {
    const { conditionType, parserResults, err } = this.parseAttributeConditions(
      attributeConditions
    );

    if (err) {
      // Create message error using offset of parser
      const messageErr = (
        <Fragment>
          <p>
            {this.props.multiLang.search.error}

            {attributeConditions.slice(0, err.offset)}
            <span style={{ color: 'red' }}>
              {attributeConditions.slice(err.offset)}
            </span>
            <br />

            {this.props.multiLang.search.help}
            <br />
            {this.props.multiLang.search.example}
          </p>
        </Fragment>
      );
      return callback({ suggestionType: messageErr, suggestions: [] });
    }
    if (!conditionType || !parserResults) {
      return callback({ suggestionType: null, suggestions: [] });
    }

    const className = parserResults.className
      ? parserResults.className[0]
      : null;
    const attribute = parserResults.attribute
      ? parserResults.attribute[0]
      : null;

    switch (conditionType) {
      case 'CLASS':
        getClasses(this.props.projectId, className, (error, data) => {
          if (error) {
            callback({ suggestionType: 'Classes', suggestions: [] });
          } else {
            const suggestions = [];
            data.forEach((obj) => {
              suggestions.push({ displayValue: `${obj}`, value: obj });
            });
            callback({ suggestionType: 'Classes', suggestions: suggestions });
          }
        });
        break;

      case 'ATTRIBUTE':
        // case attribute

        getAttributes(
          this.props.projectId,
          className,
          null,
          attribute,
          (error, data) => {
            if (error) {
              callback({ suggestionType: 'Attributes', suggestions: [] });
            } else {
              const suggestions = [];
              data.forEach((obj) => {
                suggestions.push({
                  displayValue: `${obj.attributeName} (${obj.datasetName})`,
                  value: obj
                });
              });
              callback({
                suggestionType: 'Attributes',
                suggestions: suggestions
              });
            }
          }
        );

        break;

      case 'OPERATOR':
        // case operator

        const suggestions = [];
        operatorList.forEach((obj) => {
          suggestions.push({ displayValue: `${obj}`, value: obj });
        });

        callback({ suggestionType: 'Operator', suggestions: suggestions });
        break;

      default:
        // case value
        callback({ suggestionType: null, suggestions: [] });
        break;
    }
  }

  quoteIfNeed(inputString) {
    return inputString.includes(' ') ? '"' + inputString + '"' : inputString;
  }
  /**
   * return select attributes with previous string value
   * @param {*} value
   * @returns {string} newAttributeConditions
   */
  selectedAttribute(selected) {
    var newAttributeConditions = '';
    const { conditionType, parserResults } = this.parseAttributeConditions(
      this.state.attributeConditions
    );

    const value = selected.value;

    // Caution : parser return table of string
    switch (conditionType) {
      case 'CLASS':
        newAttributeConditions += this.quoteIfNeed(value);
        newAttributeConditions += '.';
        break;

      case 'ATTRIBUTE':
        // newAttributeConditions += this.quoteIfNeed(parserResults.dataset[0]);
        newAttributeConditions += this.quoteIfNeed(parserResults.className[0]);
        newAttributeConditions += '.';
        newAttributeConditions += this.quoteIfNeed(value.attributeName);

        console.log(value);
        // Put in memory datasetName ***UGLY***
        this.setState({ datasetName: value.datasetName });
        break;

      case 'OPERATOR':
        //newAttributeConditions += this.quoteIfNeed(parserResults.dataset[0]);
        newAttributeConditions += this.quoteIfNeed(parserResults.className[0]);
        newAttributeConditions += '.';
        newAttributeConditions += this.quoteIfNeed(parserResults.attribute[0]);
        newAttributeConditions += ' ';
        newAttributeConditions += this.quoteIfNeed(value);
        break;

      default:
        break;
    }

    return newAttributeConditions;
  }

  render() {
    return (
      <Fragment>
        <AutoComplete
          label={this.props.multiLang.search.labelAdvanceSearch}
          loadSuggestions={this.getAttributesList}
          onSelect={this.selectedAttribute}
          onChange={this.handleAttributeConditionsChange}
          subheader='Object'
          style={{ minWidth: this.props.inputSize }}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(AdvancedSearch);
