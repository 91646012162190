/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from 'utils/store';

function getContentCardFromTag(tag, projectId, callback) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  axios
    .get(`${config.urlAssetAPI}/tags/${encodeURI(tag)}`, {
      params: {
        projectId: projectId
      },
      headers: headers
    })
    .then(function (response) {
      if (!response.data || Object.keys(response.data).length === 0)
        return callback(null, {});
      return callback(null, response.data);
    })
    .catch(function (error) {
      return callback(error);
    });
}

function getObjectsFromKeyword(
  projectId,
  keywords,
  nbElementByPage,
  pageNumber,
  callback
) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  let promise = axios.post(
    config.urlSearchAPI + '/search',
    {
      query: keywords,
      projectId: projectId,
      pageSize: nbElementByPage,
      pageNumber: pageNumber + 1
    },
    { headers: headers }
  );

  if (callback) {
    promise.then(function (response) {
      if (!response.data) return callback('No data');
      return callback(null, response.data);
    });
    promise.catch(function (error) {
      callback(error);
    });
  } else return promise;
}

function getIdfromTag(projectId, modelId, revision, tag, viewer, callback) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  let promise = axios.post(
    config.urlSearchAPI + '/getIdFromTag',
    {
      projectId: projectId ? projectId : '',
      model: modelId ? modelId : '',
      revision: revision ? revision : '',
      tag: tag ? tag : '',
      viewer: viewer ? viewer : ''
    },
    { headers: headers }
  );
  if (callback) {
    promise.then(function (response) {
      if (!response.data) return callback('No data');
      return callback(null, response.data);
    });
    promise.catch(function (error) {
      callback(error);
    });
  } else return promise;
}

function getTagFromId(projectId, modelId, revision, id, viewer, callback) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  return axios
    .post(
      config.urlSearchAPI + '/getTagFromId',
      {
        projectId: projectId ? projectId : '',
        model: modelId ? modelId : '',
        revision: revision ? revision : '',
        id: id ? id : '',
        viewer: viewer ? viewer : ''
      },
      { headers: headers }
    )
    .then(function (response) {
      if (!response.data) return callback('No data');
      return callback(null, response.data);
    })
    .catch(function (error) {
      callback(error);
    });
}

function getTagFromIdPromise(projectId, modelId, revision, id, viewer) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios
        .post(
            config.urlSearchAPI + '/getTagFromId',
            {
                projectId: projectId ? projectId : '',
                model: modelId ? modelId : '',
                revision: revision ? revision : '',
                id: id ? id : '',
                viewer: viewer ? viewer : ''
            },
            { headers: headers }
        );
}

export {
  getContentCardFromTag,
  getObjectsFromKeyword,
  getIdfromTag,
  getTagFromId,
  getTagFromIdPromise
};
