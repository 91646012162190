/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import { getFileList, deleteFile } from 'services/FileService';
import MUIDataTable from 'mui-datatables';
import { getProjectById } from 'services/ProjectService';
import { projectAction } from 'actions/ProjectAction';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAllRevisions } from 'services/ModelService';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SettingsIcon from '@material-ui/icons/Settings';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopy from '@material-ui/icons/FileCopy';
import CloudUpload from '@material-ui/icons/CloudUpload';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Popover from '@material-ui/core/Popover';
import { isModel } from 'utils/ModelHelper';
import Tooltip from '@material-ui/core/Tooltip';
import theme from 'theme/theme';
import { parseDate } from 'utils/DateHelper';
import {
  getAllUserSetting,
  setUserSettingByKey
} from 'services/UserSettingService';
import { openConnection, getImportByIds } from 'services/ImportService';
import { isAuthorize } from 'services/AuthorizationService';

import UploadFileProjectModal from './upload-file-project/UploadFileProjectModal';
import LinearProgress from '@material-ui/core/LinearProgress';
import { computeProgress } from './ProgressBarHelper';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import ModelProperties from './ModelProperties/ModelProperties';
import FileProperty from './update-file-property/FileProperty';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
const documentService = require('../../services/DocumentService');
const upload = {
  rightName: 'Upload1D/2D/3D'
};
const SPLIT_VIEWER = 'SPLIT_VIEWER';
const FILE_ID_INDEX = 8;
class ProjectFileListComponent extends Component {
  state = {
    fileList: null, // liste of file
    tableData: [], // data displayed in table
    anchorEl: null,
    redirectToViewer: false,
    selectedFile: null,
    openRevisionModal: false,
    verionsOfSelectedFile: [],
    modelPropertiesModalOpen: false,
    selectedRevision: null,
    modalUploadPopup: false,
    filePropertyModalOpen: false,
    filesToUpload: null,
    uploadToModel: null,
    dropzoneActive: false,
    wasDrag: false,
    deleteConfirmationModal: false
  };

  getMuiTheme = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MUIDataTableHeadCell: {
          root: {
            '&:nth-child(1)': {
              paddingLeft: 58
            }
          }
        }
      }
    });

  // bucket Name for S3
  storeBucketName = this.props.config.storeBucket;

  // options used to configure table
  options = {
    filter: true, // unable filter feature in table
    filterType: 'dropdown', // use drop down for filtring data
    responsive: 'scrollMaxHeight',
    selectableRows: 'none', // disable row selection
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset,
        filters: this.props.multiLang.projectFile.filters
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    customToolbar: () => {
      // add upload button to table toolbar
      return (
        <Tooltip title={this.props.multiLang.projectFile.uploadNewFile}>
          <span>
            <IconButton
              disabled={!isAuthorize(upload.rightName, this.props.projectId)}
              onClick={this.openUploadModal}
            >
              <CloudUpload />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    print: false, // desable print feature
    download: false, // desable download feature
    onRowClick: (rowData, rowMeta) => {
      this.openFileWhenClickRow(rowData, rowMeta);
    }
  };

  // column configuration for table
  columns = [
    {
      name: this.props.multiLang.projectFile.fileNameCol,
      label: this.props.multiLang.projectFile.fileNameCol,
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // add custom data to table body (action button)
          var isDefaultModel = false;
          if (this.state.fileList && this.state.defaultModel)
            isDefaultModel =
              this.state.fileList.find(file => file.fileId === tableMeta.rowData[FILE_ID_INDEX]).fileId ===
              this.state.defaultModel.fileId &&
              this.props.projectId === this.state.defaultModel.projectId;
          return (
            <div>
              {isDefaultModel && (
                <StarIcon
                  style={{ marginRight: 10 }}
                  onClick={(event) => {
                    this.removeFavouriteModel(event);
                  }}
                />
              )}
              {!isDefaultModel && (
                <StarBorderIcon
                  style={{ marginRight: 10 }}
                  onClick={(event) => {
                    this.setFavorite(event, value, tableMeta, updateValue);
                  }}
                />
              )}
              {value}
            </div>
          );
        }
      }
    },
    {
      name: this.props.multiLang.projectFile.siteName,
      label: this.props.multiLang.projectFile.siteName,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.projectFile.datasourceId,
      label: this.props.multiLang.projectFile.datasourceId,
      options: {
        display: false,
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.projectFile.dateCol,
      label: this.props.multiLang.projectFile.dateCol,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.projectFile.revision,
      label: this.props.multiLang.projectFile.revision,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.projectFile.state,
      label: this.props.multiLang.projectFile.state,

      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // add custom data to table body (action button)
          let { completedProgess, stepName } = computeProgress(value);
          return (
            <Fragment>
              {completedProgess && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div> {stepName} </div>
                  <LinearProgress
                    variant='determinate'
                    value={completedProgess}
                  />
                </div>
              )}
              {!completedProgess && <Fragment>{value}</Fragment>}
            </Fragment>
          );
        }
      }
    },
    {
      name: this.props.multiLang.projectFile.dataType,
      label: this.props.multiLang.projectFile.dataType,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.projectFile.action,
      label: this.props.multiLang.projectFile.action,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // add custom data to table body (action button)
          return (
            <div>
              <IconButton
                aria-label='More'
                aria-haspopup='true'
                aria-owns={this.state.anchorEl ? 'action-menu' : undefined}
                onClick={(event) => {
                  this.openFileAction(event, value, tableMeta, updateValue);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        }
      }
    },
    {
      name: this.props.multiLang.projectFile.fileId,
      label: this.props.multiLang.projectFile.fileId,
      options: {
        display: false,
        filter: true,
        sort: true
      }
    }
  ];

  /**
   * get project from id
   * @param projectId
   */
  getProject = (projectId) => {
    if (projectId && !this.props.projectId) {
      // if project is not set in store
      getProjectById(projectId, (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.mainViewer.projectDetailsError
          );
        }

        // add project informations to store
        this.props.dispatch({
          type: projectAction.initProject,
          projectId: projectId,
          isProjectOwner: data.ProjectOwner,
          name: data.Name,
          viewer: data.Viewer
        });

        // load project's files
        this.loadFiles();
      });
    } else {
      this.loadFiles();
    }
  };

  /**
   * get project's files from projectId
   */
  loadFiles = () => {
    if (this.props.projectId) {
      getFileList(this.props.projectId, (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.projectFile.loadFileError
          );
        }

        data = data.filter((file) => isModel(file)); // get only 3d files
        this.setState({ fileList: data });

        this.parseFileData(data); // parse files data to be rendered in table
        this.loadImports(data);
      });
    }
  };

  /**
   * get projct's files from projectId
   */
  loadImports = (fileList) => {
    if (!fileList) return;

    let importIdList = [];
    fileList.forEach((file) => {
      if (file.importId) {
        importIdList.push(file.importId);
      }
    });
    if (importIdList.length > 0) {
      getImportByIds(importIdList, this.parseImports);
    }
  };

  parseImports = (error, data) => {
    if (error) {
      return this.props.snackBar.addSnackError(
        this.props.multiLang.progress.loadError
      );
    }
    let map = {};
    data.forEach((element) => {
      map[element.importId] = element.step;
    });
    this.state.fileList.forEach((file) => {
      if (map[file.importId]) {
        file.step = map[file.importId];
      }
    });
    this.parseFileData(this.state.fileList);
  };
  /**
   * create table structre
   * @param fileData
   */
  parseFileData = (fileData) => {
    let dataTable = [];

    if (!fileData) return;
    fileData.forEach((file) => {
      dataTable.push([
        file.key,
        file.siteName ? file.siteName : '',
        file.modelId,
        file.creationDate
          ? parseDate(
            file.creationDate,
            this.props.multiLang.projectFile.dateFormatISO
          )
          : '',
        file.viewer === SPLIT_VIEWER ? '' : 'v' + file.revision,
        file.step ? file.step : '',
        file.key.split('.')[1].toUpperCase(),
        'action',
        file.fileId
      ]);
    });

    this.setState({ tableData: dataTable });
  };

  removeImportIdFromCacheImport = (importFile) => { };

  cleanCacheImport = (fileList) => {
    if (!this.props.import.waitingImport) return [];
  };

  getFileFromCacheImport = () => {
    if (!this.props.import.waitingImport) return [];

    let fileFromCache = [];
    this.props.import.waitingImport.forEach((element) => {
      fileFromCache.push({
        key: element.key,
        step: 'WAITING START CONVERSION'
      });
    });

    return fileFromCache;
  };

  getDefaultModel = () => {
    getAllUserSetting((error, data) => {
      if (error) {
        this.setState({
          hasNoFavorite: true
        });
        return;
      }

      this.setState({
        defaultModel: {
          fileId: data.defaultModel.fileId,
          projectId: data.defaultModel.projectId
        }
      });

      this.parseFileData(this.state.fileList);
    });
  };

  async componentDidMount() {
    const { projectId } = this.props.match.params;
    this.getProject(projectId);
    this.getDefaultModel();
    if (!this.connection) {
      this.connection = await openConnection(projectId);
      let cloudConfig = this.props.cloudConfig;
      if (cloudConfig === 'AzureADDB2C') {
        this.connection.on('ImportsUpdated', (data) => {
          console.log('ImportsUpdated: ', data);
          this.updateFileTable(data);
        });
      } else {
        this.connection = openConnection(projectId);
        this.connection.onmessage = (evt) => {
          var data = JSON.parse(evt.data);
          this.updateFileTable(data);
        };
      }
    }
  }

  componentWillUnmount() {
    if (this.connection) {
      let cloudConfig = this.props.cloudConfig;
      if (cloudConfig !== 'AzureADDB2C') {
        this.connection.close();
      }
    }
  }

  /**
   * update chart data by adding passed data
   * @param data
   */
  updateFileTable = (data) => {
    if (!this.state.fileList) return;

    const fileIndex = this.state.fileList.findIndex(
      (file) => file.importId === data.importId
    );

    // is new import
    if (fileIndex !== -1) {
      // update step of file in table
      let fileList = [...this.state.fileList];
      fileList[fileIndex].step = data.step;
      this.setState({ fileList });
      this.parseFileData(this.state.fileList);
    } else {
      // Is not in table so reload to get all metadata of file
      this.loadFiles();
    }
  };
  /**
   *  open action menu when clicking in action button
   *  set selected file
   *
   * @param event
   * @param value
   * @param tableMeta
   * @param updateValue
   */
  openFileAction = (event, value, tableMeta, updateValue) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ selectedFile: this.state.fileList.find(file => file.fileId === tableMeta.rowData[FILE_ID_INDEX]) });
  };

  /**
   *  set favorite file
   *
   * @param event
   * @param value
   * @param tableMeta
   * @param updateValue
   */
  setFavorite = (event, value, tableMeta, updateValue) => {
    event.stopPropagation();

    const selectedFile = this.state.fileList.find(file => file.fileId === tableMeta.rowData[FILE_ID_INDEX]);
    const newFavorite = {
      fileId: selectedFile.fileId,
      projectId: this.props.projectId,
      modelFileName: selectedFile.key
    };

    setUserSettingByKey('defaultModel', newFavorite, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.defaultModelError
        );
      }

      this.setState({ defaultModel: newFavorite });
      this.parseFileData(this.state.fileList);
    });
  };

  removeFavouriteModel = (event) => {
    event.stopPropagation();

    setUserSettingByKey('defaultModel', {}, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.defaultModelError
        );
      }

      this.setState({ defaultModel: {} });
      this.parseFileData(this.state.fileList);
    });
  };

  /**
   * close action menu
   */
  closeFileAction = () => {
    this.setState({ anchorEl: null, selectedFile: null });
  };

  /**
   * open 3file file when open button in action menu is clicked
   */
  openViewerFile = () => {
    // set model informations in store
    this.props.dispatch({
      type: projectAction.initFile,
      modelName: this.state.selectedFile.key,
      modelId: this.state.selectedFile.modelId,
      revision: this.state.selectedFile.revision,
      fileId: this.state.selectedFile.fileId,
      actualViewer: this.state.selectedFile.viewer,
      siteName: this.state.selectedFile.siteName
    });
    this.setState({ redirectToViewer: true }); // redirect to viewer page
  };

  /**
   * open file when click on table row
   * @param rowData
   * @param rowMeta
   */
  openFileWhenClickRow = (rowData, rowMeta) => {
    this.setState(
      {
        selectedFile: this.state.fileList.find(
          (file) => file.fileId === rowData[FILE_ID_INDEX]
        ),
        redirectToViewer: true
      },
      () => {
        // set model informations in store
        this.props.dispatch({
          type: projectAction.initFile,
          modelName: this.state.selectedFile.key,
          modelId: this.state.selectedFile.modelId,
          revision: this.state.selectedFile.revision,
          fileId: this.state.selectedFile.fileId,
          actualViewer: this.state.selectedFile.viewer,
          siteName: this.state.selectedFile.siteName
        });
      }
    );
  };

  /**
   * download selected file
   */
  downloadFile = () => {
    let cloudConfig = this.props.config.authProvider;
    switch (cloudConfig) {
      case 'AzureADDB2C':
        this.downloadAzureFile(this.state.selectedFile);
        break;
      case 'AWSCognito':
        this.downloadAwsFile(this.state.selectedFile);
        break;
      default:
        console.error('Cloud is not defined');
    }
  };
  downloadAwsFile = (file) => {
    // s3 params
    let params = {
      Bucket: this.storeBucketName,
      Key: file.path + '/' + file.key
    };
    let that = this;
    this.props.s3.getSignedUrl('getObject', params, (err, url) => {
      if (err)
        return that.props.snackBar.addSnackWarning(
          that.props.multiLang.projectFile.downloadfileError
        );
      else {
        var link = document.createElement('a');
        link.href = url;
        link.download = params.Key.split('/')[1];
        link.click();
      }
    });
  };
  downloadAzureFile = async (file) => {
    let config = this.props.config;
    let that = this;
    try {
      let result = await documentService.getSasToken(
        config.storeStorageAccountName,
        config.storeContainerName
      );
      let url = `https://${config.storeStorageAccountName}.blob.core.windows.net/${config.storeContainerName}/${file.path}?${result.token}`;
      var link = document.createElement('a');
      link.href = url;
      link.download = file.path.split('/')[1];
      link.click();
    } catch (err) {
      return that.props.snackBar.addSnackWarning(
        that.props.multiLang.projectFile.downloadfileError
      );
    }
  };

  /**
   * get all avalable revion for a selected file
   * @param project
   * @param model
   */
  getRevisions = (project, model) => {
    if (project && model) {
      getAllRevisions(project, model, (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.forgeViewer.getRevisionError
          );
        }
        this.setState({ verionsOfSelectedFile: data });
      });
    }
  };

  /**
   * get revision for selected file
   * open revision modal
   */
  openRevisionModal = () => {
    this.getRevisions(this.props.projectId, this.state.selectedFile.modelId);
    this.setState({ openRevisionModal: true });
  };

  /**
   * close revision modal
   */
  closeRevisionModal = () => {
    this.setState({
      openRevisionModal: false,
      verionsOfSelectedFile: [],
      selectedRevision: ''
    });
  };

  openModelPropertiesModal = () => {
    this.setState({
      modelPropertiesModalOpen: true
    });
  }

  closeModelPropertiesModal = () => {
    this.setState({
      modelPropertiesModalOpen: false
    });
  }

  closeFilePropertiesModal = () => {
    this.setState({
      filePropertyModalOpen: false
    });
    this.closeFileAction();
  }

  updateFileOptions = (options, selectedFileId) => {
    this.setState({
      fileList: this.state.fileList.map(e => {
        if (e.fileId === selectedFileId) {
          e.options = { ...e.options, breakdownRootTag: { ...(e.options || {}).breakdownRootTag, ...options.breakdownRootTag } }
        }
        return e;
      })
    });
  }

  /**
   * set selected revision
   * @param event
   */
  handleSelectRevision = (event) => {
    this.setState({ selectedRevision: event.target.value });
  };

  /**
   * close upload modal
   */
  closeModalUpload = () => {
    this.setState({ modalUploadPopup: false, selectedFile: null });
  };

  /**
   * open upload modal
   */
  openUploadModal = () => {
    this.setState({
      filesToUpload: null,
      uploadToModel: null,
      dropzoneActive: false,
      modalUploadPopup: true,
      wasDrag: false
    });
  };

  /**
   * upload a new revision of a file
   */
  uploadNewRevision = () => {
    // set model informations in store
    this.props.dispatch({
      type: projectAction.initFile,
      modelName: this.state.selectedFile.key,
      modelId: this.state.selectedFile.modelId,
      revision: this.state.selectedFile.revision,
      fileId: this.state.selectedFile.fileId
    });

    // open file upload with selected file
    this.setState({
      filesToUpload: null,
      uploadToModel: this.state.selectedFile,
      dropzoneActive: false,
      modalUploadPopup: true
    });
  };

  handleDeleteConfirmation = () => {
    deleteFile(this.props.projectId, this.state.selectedFile.fileId, this.state.selectedFile.modelId, this.state.selectedFile.key).then(() => {
      this.loadFiles();
      this.props.snackBar.addSnackSuccess(
        this.props.multiLang.projectFile.fileDeleteSuccess
      );
      this.setState({ deleteConfirmationModal: false });
      this.closeFileAction();
    }).catch(error => {
      console.log(error);
      this.props.snackBar.addSnackError(
        this.props.multiLang.projectFile.fileDeleteError
      );
    })
  }

  render() {
    // redirect to viewer if open button is clicked
    if (this.state.redirectToViewer === true) {
      var viewer = this.state.selectedFile.viewer
        ? this.state.selectedFile.viewer
        : this.props.viewer;
      var revision = this.state.selectedRevision
        ? this.state.selectedRevision
        : this.state.selectedFile.revision;
      return (
        <Redirect
          to={
            viewer !== 'SPLIT_VIEWER'
              ? {
                pathname: `/viewer`,
                search: `?viewer=${viewer}&projectId=${this.props.projectId}&modelId=${this.state.selectedFile.modelId}&revision=${revision}`
              }
              : {
                pathname: `/viewer`,
                search: `?viewer=${viewer}&projectId=${this.props.projectId}&fileId=${this.state.selectedFile.fileId}`,
                state: {
                  workspaceConfig: this.state.selectedFile.workspaceConfig
                }
              }
          }
        />
      );
    }

    return (
      <div>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <div>
              <Popover
                id='action-menu'
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.closeFileAction}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      this.openViewerFile();
                    }}
                  >
                    <ListItemIcon>
                      <OpenInBrowser style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.open}
                    />
                  </MenuItem>
                  {/* Hide download button for PPV3D*/}
                  {/* <MenuItem
                    onClick={() => {
                      this.downloadFile();
                      this.closeFileAction();
                    }}
                  >
                    <ListItemIcon>
                      <CloudDownload style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      primary={this.props.multiLang.projectFile.download}
                    />
                  </MenuItem> */}
                  <MenuItem
                    disabled={
                      !isAuthorize(upload.rightName, this.props.projectId)
                    }
                    onClick={() => {
                      this.uploadNewRevision();
                    }}
                  >
                    <ListItemIcon>
                      <CloudUpload style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.upload}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.openRevisionModal();
                    }}
                  >
                    <ListItemIcon>
                      <FileCopy style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.revision}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.openModelPropertiesModal();
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.settings}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.setState({ filePropertyModalOpen: true })}
                  >
                    <ListItemIcon>
                      <Edit style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.updateSiteName}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.setState({ deleteConfirmationModal: true })}
                  >
                    <ListItemIcon>
                      <Delete style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.props.multiLang.projectFile.deleteFile}
                    />
                  </MenuItem>
                </MenuList>
              </Popover>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: 10 }}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={this.props.projectName}
                  data={this.state.tableData}
                  columns={this.columns}
                  options={this.options}
                />
              </MuiThemeProvider>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.openRevisionModal}
          onClose={this.closeRevisionModal}
          aria-labelledby='simple-dialog-title'
          fullWidth={true}
          maxWidth={'xs'}
        >
          <DialogTitle id='form-dialog-title'>
            {this.props.multiLang.viewMarkup.previousRevision}{' '}
          </DialogTitle>
          <Paper style={{ padding: 20, margin: 10 }}>
            <form
              style={{ display: 'flex', flexWrap: 'wrap' }}
              onSubmit={this.submitCreateThreshold}
            >
              <Grid container spacing={3} justify={'center'}>
                <Grid item xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor='age-simple'>
                      {this.props.multiLang.projectFile.selectRevision}
                    </InputLabel>
                    <Select
                      value={this.state.selectedRevision}
                      onChange={this.handleSelectRevision}
                    >
                      {this.state.verionsOfSelectedFile &&
                        this.state.verionsOfSelectedFile.map(
                          (element, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={element.revisionNumber}
                              >
                                {this.props.multiLang.projectFile.revision +
                                  ' ' +
                                  element.revisionNumber + ' - ' + moment(element.creationDate).format('DD/MM/YYYY')}
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <DialogActions>
            <Button
              onClick={this.openViewerFile}
              color='primary'
              variant='contained'
            >
              {this.props.multiLang.createMarkupCard.openButton}
            </Button>
            <Button onClick={() => {
              this.closeRevisionModal();
              this.closeFileAction();
            }} color='primary'>
              {this.props.multiLang.createMarkupCard.cancelButton}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.deleteConfirmationModal}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">{this.props.multiLang.projectFile.confirmation}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.multiLang.projectFile.deleteConfirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleDeleteConfirmation} color="primary">
              {this.props.multiLang.search.confirmButton}
            </Button>
            <Button onClick={() => this.setState({ deleteConfirmationModal: false })} color="primary" autoFocus>
              {this.props.multiLang.search.cancelButton}
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.modalUploadPopup && (
          <UploadFileProjectModal
            isFileUploadModalOpen={this.state.modalUploadPopup}
            onClose={this.closeModalUpload}
            fileId={
              this.state.selectedFile ? this.state.selectedFile.fileId : null
            }
          />
        )}
        {this.state.selectedFile &&
          <ModelProperties
            modelPropertiesModalOpen={this.state.modelPropertiesModalOpen}
            selectedFileKey={this.state.selectedFile.key}
            selectedFileId={this.state.selectedFile.fileId}
            updateFileOptions={this.updateFileOptions}
            selectedFileOptions={(this.state.selectedFile.options || {}).breakdownRootTag || {}}
            closeModelPropertiesModal={this.closeModelPropertiesModal}
            projectId={this.props.projectId}
          />
        }
        {this.state.selectedFile &&
          <FileProperty
            filePropertyModalOpen={this.state.filePropertyModalOpen}
            updateFileProperities={this.loadFiles}
            selectedFile={this.state.selectedFile}
            siteName={this.state.selectedFile.siteName ? this.state.selectedFile.siteName : ''}
            closeFilePropertyModal={this.closeFilePropertiesModal}
            projectId={this.props.projectId}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  projectName: state.project.name,
  viewer: state.project.viewer,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  config: state.config,
  s3: state.auth.s3,
  import: state.import,
  cloudConfig: state.config.authProvider
});

export default connect(mapStateToProps)(ProjectFileListComponent);
