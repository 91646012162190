/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

export const en = {
  project: {
    seeModels: "See Models",
    accessPrjError: "This user does not have access to any project"
  },
  navBar: {
    logo: "PPV3D",
    profil: "Profil",
    search: "Search...",
    projects: "Projects",
    files: "Files",
    account: "Account",
    logout: "Log out",
    logoutMessage: "Loging out...",
    queryObjError: "There was an error querying objects",
    userRoleError: "There was an error retrieving user and role",
    notificationError: "There was an error retrieving your notifications",
    noNotification: "You don't have any notification",
    noUserTagFavourite: "You don't have any favorite tag",
    archiveNotificationError:
      "Error when archiving the notification, please try again later",
    removeFavouriteTagError:
      "Error when deleting the favourite tag, please try again later",
    addFavouriteTagError:
      "Error when adding the favourite tag, please try again later",
    addFavouriteTagSuccess:
      "The tag has been sucessfully added to your favorite tags list",
    removeFavouriteTag: "Remove favourite tag",
    unread: "Unread notification",
    archive: "Clear notification",
    switchViewHeader: "Switch view",
    noSwitchView: "The selected tag is not present in any other view",
    switchViewModal: "Switch view",
    chooseViewToReplace: "Select the view you want to replace:",
    cancelButton: "Cancel",
    switchViewButton: "Switch view",
    geoserverViewer: "Cartographic View",
    eventViewer: "Event View",
    notifications: "Notifications",
    roleOfProject: "Project Role",
    user: "User",
    favourites: "Favorites",
    administration: "Administration"
  },
  search: {
    labelAdvanceSearch: "Advanced Search",
    labelSimpleSearch: "Search",
    error: "Error :",
    help: "Help : Class.Attribute Operator Value",
    example: "Example : Pump.Pression > 20",
    minimize: "Minimize",
    close: "Close",
    fullScreen: "Full screen",
    selectAll: "Select All",
    deselectAll: "Deselect All",
    exportAll: "Export All",
    fitView: "Fit View",
    showOnly: "Show only",
    hide: "Hide",
    confirmSelectAll:
      "Are you sure you want to apply this operation on %number% objects?",
    confirmSelectAllTitle: "Large selection confirmation",
    confirmButton: "Yes",
    cancelButton: "Cancel",
    confirmExportAllTitle: "Large export confirmation",
    focusOnObject: "can not focus on objects",
    isolateObject: "can not isolate objects",
    hideObjects: "can not hide objects",
    loadAllObject: "Unable to load all objects",
    itemsSelected: "Item(s) selected",
    select: "Select"
  },
  semanticSearch: {
    operators: [
      {
        label: "Equal",
        code: "eq"
      },
      {
        label: "Greater",
        code: "gt"
      },
      {
        label: "Lower",
        code: "lt"
      },
      {
        label: "Greater or equal",
        code: "ge"
      },
      {
        label: "Lower or equal",
        code: "le"
      }
    ],
    cancel: "Cancel",
    save: "Save",
    visualize: "Visualize",
    run: "Run",
    load: "Load",
    selectClass: "Select Class",
    selectAttribute: "Select Attribute",
    selectRelation: "Select Relation",
    attribute: "Attribute",
    relation: "Relation"
  },
  projectFile: {
    checkProjectMsg: "Please choose a project in the projects tab.",
    fileNameCol: "File Name",
    fileId: "File ID",
    action: "Action",
    revision: "Revision",
    state: "State",
    dataType: "Data Type",
    dataSetCol: "Dataset",
    stateCol: "State",
    refresh: "Refresh",
    upload: "Upload",
    uploadFile: "Upload File",
    btnUploadFile: "Upload",
    uploadNewFile: "Upload new file",
    documentsUpload: "Documents Upload",
    open: "Open",
    see: "See",
    update: "Update",
    delete: "Delete",
    download: "Download",
    more: "More",
    uploadType: "Choose the upload type",
    model1D: "1D model",
    model2D3D: "2D or 3D model",
    chooseFile: "Choose a file",
    chooseViewer: "Choose a viewer",
    selectModel: "Select a model",
    selectDataset: "Select a dataset",
    selectMapping: "Select a mapping",
    uploadfileError: "There was an error uploading your file",
    downloadfileError: "There was an error downloading your file",
    uploadOf: "Upload of ",
    uploadComplete: " complete",
    projectDetailsError: "There was an error retrieving the project details",
    loadFileError: "There was an error loading the file from your project",
    defaultModelError: "There was an error setting default project",
    search: "Search",
    viewColumns: "View Columns",
    filterTable: "Filter table",
    showColumns: "Show Columns",
    print: "Print",
    rowsPerPage: "Rows per page",
    all: "All",
    filters: "FILTERS",
    reset: "RESET",
    notMatchingRecord: "Sorry, no matching records found",
    sort: "Sort",
    deleteFunction: "Delete function will be available in the next release",
    projectFile: "Drop files...",
    chooseRevision: "Choose revision",
    modify: "Modify",
    seeMore: "See more",
    dateFormatISO: "YYYY/MM/DD hh:mm A",
    dateCol: "Date",
    selectRevision: "Select Revision",
    DragNDropFile: "Drag and Drop file here or click",
    DropHere: "Drop here",
    favoriteCol: "Favorite",
    datasourceId: "Datasource id",
    settings: "Settings",
    article: "of",
    breakdowns: "Breakdowns Structures",
    name: "Name",
    description: "Description",
    default: "Is default?",
    yes: "Yes",
    rootNode: "Default filter tag",
    errorWhileSavingOptions: "Error during save of options",
    errorWhileSavingSiteName: "Error while saving site name",
    siteName: "Site name",
    fileProperties: "File Properities",
    updateSiteName: "Update site name",
    deleteFile: "Delete file",
    confirmation: "Confirmation",
    deleteConfirmationText:
      "Are you sure you want to delete the selected file?",
    fileDeleteSuccess: "The File has been successfully deleted",
    fileDeleteError: "An error occured deleting the selected file"
  },
  mainViewer: {
    projectDetailsError: "There was an error retrieving project details",
    modelDetailsError: "There was an error retrieving model details",
    reviewDetailsError: "There was an error retrieving review details",
    viewerLoading: "Viewer is loading...",
    switchButton: "Switch View"
  },
  genericCard: {
    saveMarkupError: "There was an error saving your markup",
    markupSaved: "Markup saved",
    saveStatusError: "There was an error saving your status",
    statusChanged: "Status of review changed"
  },
  forgeViewer: {
    urnInvalide: "Invalid URN !",
    urnInvalideMessage:
      "Can not display the 3D viewer because the URN is not valid.",
    getRevisionError: "There was an error while getting revisions of the model",
    closeMarkup: "Close Markup"
  },
  forgeViewerHelper: {
    authenticateError: "There was an error authenticating with Forge"
  },
  splitViewer: {
    closePanel: "Are you sure you want to remove this panel ?",
    remove: "Remove",
    cancel: "Cancel"
  },
  viewer: {
    viewerError: "Can't open viewer"
  },
  eventViewer: {
    eventStatus: {
      Inprogress: "In Progress",
      Pending: "Pending",
      All: "All",
      Closed: "Closed"
    },
    severityValue: {
      informational: "Informational",
      low: "Low",
      medium: "Medium",
      high: "High",
      critical: "Critical"
    },
    takeCharge: "TAKE CHARGE",
    origin: "Origin",
    location: "Location",
    description: "Description",
    severity: "Severity",
    archive: "Archive",
    close: "Close",
    procedure: "Procedure",
    cancel: "Cancel",
    send: "Send",
    interventionRequest: "Intervention request",
    requestId: "ID",
    requestName: "Name",
    priority: "Priority",
    requestPriority: {
      High: "High",
      Medium: "Medium",
      Low: "Low"
    },
    requestStatusList: {
      InProgress: "In Progress",
      Pending: "Pending",
      Closed: "Closed"
    },
    requestStatus: "Status",
    userInCharge: "User in charge",
    search: "Search",
    rowsPerPage: "Rows per page",
    noRecords: "No records",
    addRequest: "Add new request",
    edit: "Edit",
    interventionType: "Intervention type",
    address: "Intervention address",
    save: "Save"
  },
  propertyCard: {
    add: "Add",
    sensorData: "Sensor Data",
    addThreshold: "Add Threshold",
    unableOpenContentCard: "Unable to open content card",
    selectMultipleError:
      "Multiple selection not allow with Property Card. Please select one object.",
    notElementSelectError: "Please select an object.",
    objectAlreadyAttached: "This object is already attached to Tag ",
    tagAttachedSuccess: "Tag attached with success",
    tagAttachedError: "Tag not attached, Error",
    tagDetachSuccess: "Tag detached with success",
    tagDetachError: "Tag not detached, Error",
    addSensor: "Add a threshold",
    addSensorThreshold: "Add Sensor Threshold",
    sensorLabel: "Sensor",
    tag: "Tag",
    close: "Close",
    attachSensor: "Attach a sensor",
    unAttach: "Unattach Sensor",
    errorAddSensor: " Error while adding sensor",
    successAddSensor: "Sensor added with success",
    unableToOpenDocument: "Unable to open the document",
    errorToggleStateConnectedPlug:
      "Unable to change state of the connected plug",
    successToggleConnectedPlug: "Connected plug's state changed with success",
    methodsOfUpload: "Methods for specifying files to upload...",
    comparator: "Comparator",
    value: "Value",
    timeLimit: "Time limit",
    creationDate: "Creation date",
    errorAddingThreshold: "error when adding threshold",
    newThresholdCreated: "New threshold created",
    errorWhenRetrievingThreshold: "Error when retrieving thresholds",
    errorWhenDeletingThreshold: "Error when deleting thresholds",
    deleteThreshold: "Threshold deleted",
    lastHour: "Last hour",
    lastDay: "Last day",
    lastMonth: "Last month",
    duringLastMinute: "During last (minute)",
    equalTo: "Equal to",
    greaterThan: "Greater than",
    lowerThan: "Lower than",
    triggerWhenValueIs: "Trigger when value is",
    thresholds: "Thresholds",
    properties: "Properties",
    relatedItems: "Related Items",
    graph: "Graph",
    documents: "Documents",
    monitoring: "Monitoring",
    supportedFormat: "Supported formats",
    noDataSensor: "No live data from sensor",
    noAttributes: "There is no attributes associated to this tag yet.",
    showThresholds: "Show thresholds",
    create: "CREATE",
    expand: "Expand",
    downloadCsv: "Download CSV",
    periode: "From",
    compare: "Compare",
    share: "Share",
    addToFavourites: "Add to favorites",
    relation: "Relation",
    showEmptyAttributes: "Show empty attributes",
    unrelatedObject: "Selected object is not related to a tag",
    nonRenewedEquipment:
      "Attention, equipment is out of service and not replaced",
    attachedOutOfServiceEquipment:
      "Attention, equipment is out of service and it is attached to 3D object Model, although it's been replaced"
  },
  createMarkupCard: {
    title: "Create Markup",
    titleLabel: "Title",
    titleHolder: "Title",
    descriptionLabel: "Description",
    descriptionHolder: "Type a description",
    priorityLabel: "Priority",
    cancelButton: "CANCEL",
    openButton: "OPEN",
    saveButton: "SAVE"
  },
  breakdownCard: {
    selectBreakdown: "Select breakdown structure",
    selectRootTag: "Select root tag filter",
    showOnlyActive: "Show only Active equipments"
  },
  markup: {
    review: "Review",
    annotation: "Annotation",
    createMarkup: "Create markup",
    viewMarkup: "Markups",
    startReview: "Start Review",
    arrowMarkup: "Arrow",
    circleMarkup: "Circle",
    cloudMarkup: "Cloud",
    freehandMarkup: "Freehand",
    hightlightMarkup: "Hightlight",
    penMarkup: "Pen",
    polylineMarkup: "Polyline",
    rectangleMarkup: "Rectangle",
    textMarkup: "Text",
    askDesignReview: "Are you sure you want to ask a design review ? ",
    startReviewError: "There was an error starting the review",
    reviewStarted: "Review started",
    exportAsBCF: "Export as BCF",
    reviewStatus: {
      INITIAL: "NOT STARTED",
      UNDER_REVIEW: "UNDER REVIEW",
      REJECTED: "REJECTED",
      VALIDATION: "VALIDATED"
    }
  },
  navigationViewCard: {
    modelsArchitectural: "Models Architectural",
    model3D: "Model 3D",
    model2D: "Model 2D"
  },
  viewMarkup: {
    title: "Markup Detail",
    titleLabel: "Title",
    typeLabel: "Type",
    statusLabel: "Status",
    labelLabel: "Label",
    descriptionLabel: "Description",
    priorityLabel: "Priority",
    commentLabel: "Comment",
    commentHolder: "Answer here.",
    listMarkups: "List of markups",
    loadingMarkupError: "There was an error loading markups",
    publish: "PUBLISH",
    discussion: "Discussion",
    BCFButton: "BCF",
    rejectButton: "REJECT",
    validateButton: "VALIDATE",
    open: "Open markup",
    close: "Close markup",
    view: "VIEW",
    viewpoint: "ADOPT VIEWPOINT",
    updateStatusError: "Error while updating markup status",
    updateStatusSuccess: "markup status successfully updated",
    generateBCFError: "Error in generate BCF File: ",
    generateBCFSucces: "BCF file generate with success",
    idCard: "ID card",
    from: "From",
    nextRevision: "Next revision",
    previousRevision: "Previous revision",
    validated: "VALIDATED",
    rejected: "REJECTED",
    deleteMarkupError: "Error while deleteing markup",
    deleteMarkupSuccess: "Markup successfully deleted ",
    creator: "Created By",
    creationDate: "Creation Date"
  },
  annotation: {
    selectA: "Select a ",
    priority: "Priority"
  },
  comment: {
    saveCommentError: "There was an error saving your comment",
    commentSaved: "Comment saved",
    addComment: "add a comment ...",
    reply: "REPLY"
  },
  fileUpload: {
    title1D: "Upload 1D",
    title2D3D: "Upload 2D/3D",
    titleDocument: "Upload Document(s)",
    titleDefault: "Upload",
    onModelError: "You can drag only a model file (rvt or ifc) on model.",
    updatingModelMessage: "You are updating model : ",
    chooseFile: "File Name",
    errorWhileRetrievingMapping: "error while while retrieving mapping rules",
    errorNonValidExtension: "Unsupported extension",
    errorSelectValidModel: "Select a valid 3D/2D file"
  },
  toolbar: {
    firstPerson: "1st Person",
    measure: "Measure",
    clipping: "Clipping",
    explosion: "Explosion",
    breakdown: "Breakdown",
    xPane: "X pane",
    yPane: "Y pane",
    zPane: "Z pane",
    box: "Box",
    reports: "Reports",
    vExplode: "Level Explode",
    models: "Models",
    details: "Details",
    browser: "Model Browser",
    share: "Share"
  },
  attachSensor: {
    connectedPlug: "Connected plug",
    sensor: "Sensor"
  },
  app: {
    lang: "EN"
  },
  thresholdComparator: {
    // Similars to propertyCard.equalTo, propertyCard.greaterThan and propertyCard.lowerThan
    equalTo: "equal to",
    greaterThan: "greater than",
    lowerThan: "lower than"
  },
  notification: {
    messageNotifNewReview: "You have one model to review",
    messageNotifRejectedReview: "Your model has been rejected",
    messageNotifValidatedReview: "Your model has been validated",
    messageNotifIot:
      "The value of the %SensorType% sensor: %DeviceId% associated to %Tag% is %ThresholdComparator% %ThresholdValue%",
    messageNotifImport: "The import of %fileName% has ended"
  },
  sensor: {
    type: {
      pressure: "pressure",
      temperature: "temperature",
      presence: "presence",
      healthcheck: "healthcheck"
    }
  },
  administration: {
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
    actualPassword: "Actual Password",
    company: "Company",
    password: "Password",
    login: "Login",
    changePassword: "Change password",
    userProfile: "Profile",
    userSettings: "User settings",
    createProjectDescription:
      "Create a project and assign it to an existing project administrator",
    projects: "Projects",
    users: "Users",
    breakdowns: "Breakdowns Structures",
    featureZone: "Rights Management",
    rightsCategory: {
      administration: "Administration",
      importAndDownload: "Import and Download",
      designReview: "Design Review",
      sensors: "Sensors",
      linkingTags: "Linking Tags"
    },
    rights: {
      ValidateRejectDesignReview: "Validate or reject design Review",
      "Upload1D/2D/3D": "Import",
      CreateFolder: "Create folder",
      CreateCommentOnMarkup: "Create comments on markup",
      AdministrateProject: "Project Administration",
      MonitoringImport: "Import Monitoring",
      CreateSensorThreshold: "Create sensor thresholds",
      CreateMarkup: "Create Markup",
      Upload2D3D: "2D/3D import",
      DownloadBCF: "Download BCF",
      AskForDesignReview: "Ask for design revue",
      UploadMedia: "Import Media",
      AttachSensor: "Attach sensors",
      DownloadFiles: "Download files",
      DownloadMedia: "Download Media",
      LinkUnlink: "Link / Unlink"
    },
    createFeatureZone: "Create Feature Zone",
    createFeatureZoneDescription: "Manage your feature zone",
    defaultBreakdownStructure: "Default breakdown structure",
    breakdownRootTagFilter: "Filter by root tag",
    createBreakdownDescription:
      "Create a feature zone and assigned it to a group​",
    createUserDescription:
      "Create user and assign him to existing project and groups",
    createGroupDescription:
      "Create a user group on a project and add existing user on it",
    description: "Description",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    projectId: "Project Id",
    groupId: "Group Id",
    groups: "Groups",
    filtered: "Filtered",
    default: "Default",
    yes: "Yes",
    no: "No",
    actions: "Actions",
    createProject: "Create project",
    createGroup: "Create group",
    createUser: "Create user",
    editProject: "Edit project",
    editUser: "Edit user",
    editBreakdown: "Edit breakdown structure",
    createBreakdown: "Create breakdown structure",
    edit: "Edit",
    add: "Add",
    save: "Save",
    cancel: "Cancel",
    assign: "Assign",
    userList: "User list",
    creationDate: "Creation Date",
    phone: "Phone",
    email: "Email",
    language: {
      lang: "Language",
      chooseLanguage: "Choose language",
      en: "English",
      fr: "French",
      ok: "OK"
    },
    projectDetail: "Project details",
    groupDetail: "Group details",
    adminSettings: "Administration settings",
    projectManagement: "Project management",
    selectClass: "Select a class",
    selectRelationship: "Select a relationship",
    classes: "Classes",
    relationships: "Relationships",
    relationshipsToFollow: "Relationships to follow from root",
    specificClassExpansion: "Specific class expansion",
    passwordValidation: "The password must contain at least 6 characters",
    passwordNotIdentical: "Non-identical password",
    notificationTitle: "Notification center",
    loginTitle: "Login information",
    userInformation: "User information",
    notificationMail: "Email",
    notificationSMS: "SMS",
    notificationImport: "Import",
    notificationReview: "Review",
    notificationIOT: "IOT",
    administratorOf: "Project Administrator of",
    status: "Status",
    user: "User",
    projectAdministrators: "Project Administrators",
    removeProjectsDialog: {
      removeProjects: "Are you sure you want to remove selected project(s) ?",
      remove: "Remove",
      cancel: "Cancel"
    },
    errors: {
      projectCreationFailed: "Error during the creation of the project",
      projectRetrievalFailed: "Error while retrieving projects list",
      projectUpdateFailed: "Error while updating project",
      projectRemovalFailed: "Error while deleting project",
      groupCreationFailed: "Error while creating group",
      groupRetrievalFailed: "Error while retrieving group lists",
      groupRemovalFailed: "Error while deleting group",
      userAddFailed: "Error while adding user to group",
      userRemovalFailed: "Error while deleting user",
      userRetrievalFailed: "Error while retrieving users list",
      breakdownCreationFailed: "Error while creating breakdown structure",
      breakdownRemovalFailed: "Error while deleting breakdown structure",
      breakdownUpdateFailed: "Error while updating breakdown structure",
      passwordChangeFailed: "Error while changing password",
      updateAttributesFailed: "Error editing personal information",
      errorPhone: "Invalid number",
      errorEmail: "Invalid email",
      getUserInformationFailed: "Error while getting user information",
      userCreationFailed: "An error occured while creating the user",
      userDeletionFailed: "An error occured while deleting the user",
      emptyLogin: "Login is empty",
      invalidEmail: "Invalid email",
      emptyPassword: "Passsword is empty",
      invalidPhoneNumber: "Invalid phone number",
      featureZoneCreationFailed: "Error while creating feature zone",
      featureZoneGetFailed: "Error while getting features zone",
      featureZoneUpdateFailed: "Error while updating features zone",
      featureZoneDeleteFailed: "Error while deleting features zone"
    },
    success: {
      projectCreationSucceeded: "Project successfully created",
      projectRemovalSucceeded: "Project successfully deleted",
      projectUpdateSucceeded: "Project successfully updated",
      groupCreationSucceeded: "Group successfully created",
      groupRemovalSucceeded: "Group successfully deleted",
      userAddSucceeded: "User Successfully added",
      userRemovalSucceeded: "User successfully deleted",
      breakdownCreationSucceeded: "breakdown structure successfully created",
      breakdownRemovalSucceeded: "breakdown structure successfully deleted",
      breakdownUpdateSucceeded: "breakdown structure successfully updated",
      userAddFailed: "User Successfully added",
      changePasswordSucceeded: "Password successfully changed",
      updateAttributesSucceeded: "Personal information updated",
      userCreationSucceeded: "User successfully created",
      userDeletionFailed: "User successfully deleted",
      featureZoneCreationSucceeded: "Feature zone successfully created",
      featureZoneUpdateSucceeded: "Feature zone successfully updated",
      featureZoneRemovalSucceeded: "Feature zone successfully deleted"
    }
  },
  datasources: {
    importListPageTitle: "Imports",
    datasourceListPageTitle: "Files",
    import: {
      importFileName: "File name",
      importDate: "Import date",
      startDate: "Load start date",
      endDate: "Load end date",
      version: "Version",
      state: "State",
      dataType: "Data type",
      importId: "Import id",
      datasourceId: "Datasource id",
      total: "Number of lines",
      totalSuccess: "... in success",
      totalError: "... in error",
      detailListButton: "Details",
      steps: {
        FINISH: "Finished",
        FINISHED_ERROR: "Error",
        FINISHED_WARNING: "Ended with warnings",
        FINISHED_SUCCESS: "Success",
        "UPDATING INFOCARD": "Updating Infocard",
        CONVERTING: "Converting",
        DYNAMO_LOADER: "Infocard load",
        ELASTIC_LOADER: "Search Load",
        SVF_CONNECTOR: "Conversion",
        SYNC_CONNECTOR: "Data synchronisation",
        description: {
          FINISH: "All import operations are done",
          FINISHED_ERROR:
            "Technical error during import, or all tags are in error",
          FINISHED_WARNING: "At least one tags was not imported",
          FINISHED_SUCCESS: "All tags were imported successfully",
          "UPDATING INFOCARD": "Updating Infocard",
          CONVERTING: "Converting 3D model",
          DYNAMO_LOADER: "Loading infocards",
          ELASTIC_LOADER: "Loading search index",
          SVF_CONNECTOR: "3D model data extration",
          SYNC_CONNECTOR: "Data synchronisation"
        }
      }
    },
    errors: {
      importLoad: "Error during loading import list",
      importDetailLoad: "Error during loading import detail"
    }
  },
  progress: {
    loadError: "Error during loading import state"
  },
  importDetails: {
    importDate: "Import date",
    filename: "File Name",
    line: "Line",
    status: "Status",
    tag: "Tag",
    message: "Message",
    sourceAttribute: "Source attribute",
    error: "Error during loading import detail",
    detailListButton: "Details",
    category: "Category",
    component: "Component"
  },
  breakdownPanel: {
    noBreakdown:
      "Your project doesn’t have any breakdown structure for the moment.",
    notVisibleInViewer: "The selected object is not visible in current viewer."
  },
  attachTag: {
    class: "Class",
    all: "All",
    detached: "Detached",
    attached: "Attached",
    linking: "Linking Tags",
    attach: "Attach tag",
    detach: "Detach tag"
  },
  sharePanel: {
    title: "External share of the model",
    viewerLink: "Link to the Viewer",
    copyInfos: "Copy all information"
  }
};
