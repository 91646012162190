import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import _ from 'lodash';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getAttributes } from "services/CatalogueService";


class SemanticSearchAttributeTab extends Component {

    state = {
        className: this.props.className ? this.props.className : "",
        attributeList: [],
        selectedAttributes: {
            ruleType: "attributesRule",
            rules: this.props.attributesRules ? this.props.attributesRules : []
        },
        selectedAttribute: null
    }

    handleOperatorValue = (event, index) => {
        let updateSelectedAttributes = { ...this.state.selectedAttributes };
        let rule = updateSelectedAttributes.rules[index];
        rule.operator = event.target.value;
        this.setState({ selectedAttributes: updateSelectedAttributes });
        this.props.onRulesChange(updateSelectedAttributes);
    };

    deleteAttribute = (index) => {
        console.log('delete : ', index);
        let updateSelectedAttributes = { ...this.state.selectedAttributes };
        updateSelectedAttributes.rules.splice(index, 1);
        this.setState({ selectedAttributes: updateSelectedAttributes });
        this.props.onRulesChange(updateSelectedAttributes);
    }

    handleValue = (event, index) => {
        let updateSelectedAttributes = { ...this.state.selectedAttributes };
        updateSelectedAttributes.rules[index].attributeValue = event.target.value;
        this.setState({ selectedAttributes: updateSelectedAttributes });
        this.props.onRulesChange(updateSelectedAttributes);
    };

    handleCondition = (condition, index) => {
        let updateSelectedAttributes = { ...this.state.selectedAttributes };
        updateSelectedAttributes.rules[index].condition = condition;
        this.setState({ selectedAttributes: updateSelectedAttributes });
        this.props.onRulesChange(updateSelectedAttributes);
    };


    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.className, prevProps.className)) {
            if (this.props.className) { 
                this.setState({ className: this.props.className, selectedAttribute: null }, () => this.loadAttributes()); 
            } else {
                // If select class is empty then reset the values
                this.setState({ className: null, selectedAttribute: null, attributeList: [] })
            }

        }
    }

    loadAttributes = (value) => {
        let beginWith = value ? value : "";
        getAttributes(this.props.project.projectId, this.state.className, null, beginWith).then(result => {
            this.setState({ attributeList: result.data });
        }).catch(error => {
            console.error(error);
        });
    }

    handleAutocomplete = (event, newValue) => {
        this.setState({ selectedAttribute: newValue });
    };

    selectAttribute = () => {
        let updateSelectedAttributes = { ...this.state.selectedAttributes };
        updateSelectedAttributes.rules.push({
            datasetName: this.state.selectedAttribute.DatasetName,
            attributeName: this.state.selectedAttribute.Data,
            operator: "",
            attributeValue: '',
            attributeType: this.state.selectedAttribute.AttributeType.toLowerCase()
        });
        this.setState({ selectedAttributes: updateSelectedAttributes });
        this.props.onRulesChange(updateSelectedAttributes);
    }
    // Filter operator choices based on attribute Type
    getOperatorForAttributeType = (attribute) => {
        if (attribute.attributeType.toLowerCase() === 'string') {
            return this.props.multiLang.semanticSearch.operators.filter((operator) => operator.code === 'eq');
        } else {
            return this.props.multiLang.semanticSearch.operators;
        }
    }

    render() {
        return (
            <div>
                <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={6} style={{ marginLeft: 5 }}>
                        < Autocomplete
                            id="combo-box-demo"
                            options={Array.isArray(this.state.attributeList) ? this.state.attributeList : []}
                            getOptionLabel={(option) => {
                                return option.Data + ' (' + option.DatasetName + ')';
                            }}
                            onChange={(event, newValue) => {
                                this.handleAutocomplete(event, newValue);
                            }}
                            value={this.state.selectedAttribute}
                            renderInput={(params) => <TextField {...params} label={this.props.multiLang.semanticSearch.selectAttribute} variant="outlined"
                            />}
                        />

                    </Grid>
                    <Grid item xs={5} style={{ textAlign: "center" }}>
                        <IconButton
                            aria-label="add"
                            disabled={!this.state.selectedAttribute}
                            onClick={this.selectAttribute}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Divider variant="middle" />
                    </Grid>
                </Grid>

                {this.state.selectedAttributes.rules.map((attribute, index) => {
                    return (
                        <Grid key={index} container style={{ marginTop: 30 }}>
                            <Grid item xs={4} style={{ textAlign: "center", paddingTop: 20 }}>
                                <HighlightOffIcon onClick={() => { this.deleteAttribute(index) }} />  {attribute.attributeName}
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{ minWidth: 120 }}>
                                    <InputLabel>Operator</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        value={attribute.operator}
                                        onChange={(e) => { this.handleOperatorValue(e, index) }}
                                    >
                                        {this.getOperatorForAttributeType(attribute).map(((element, index) => {
                                            return <MenuItem key={index} value={element.code}>{element.label}</MenuItem>;
                                        }))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "center", paddingTop: 4, paddingRight: 10 }}>
                                <TextField id="standard-basic" label="value" value={attribute.attributeValue}
                                    onChange={(e) => {
                                        this.handleValue(e, index);
                                    }} />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                <Divider variant="middle" />
                            </Grid>
                            {(this.state.selectedAttributes.rules.length !== (index + 1)) &&
                                <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
                                    <ButtonGroup variant="contained" color="primary"
                                        aria-label="contained primary button group">
                                        <Button style={{ backgroundColor: this.state.selectedAttributes.rules[index + 1].condition === "AND" ? '#004c75' : '#0070AD' }}
                                            onClick={() => { this.handleCondition("AND", index + 1) }}>And</Button>
                                        <Button style={{ backgroundColor: this.state.selectedAttributes.rules[index + 1].condition === "OR" ? '#004c75' : '#0070AD' }}
                                            onClick={() => { this.handleCondition("OR", index + 1) }}>Or</Button>
                                    </ButtonGroup>
                                </Grid>
                            }

                        </Grid>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    project: state.project,
    multiLang: state.multiLang,
    snackBar: state.snackBar,
});

export default connect(
    mapStateToProps,
)(SemanticSearchAttributeTab);
