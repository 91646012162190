/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { createMuiTheme } from '@material-ui/core/styles';

/*
const color = {
  // Old value used for green: #95E616
  blue: '#0c4178',
  blueTextarea: '#4a4a4a',
  green: '#5A9628',
  red: '#FF304C',
  lightBlue: '#1CAEDC',
  desabledLink: '#808080',
  grey: '#757575'
};
*/

const palette = {
  primary: {
    // light: will be calculated from palette.primary.main,
    main: '#0070AD'
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    light: '#0066ff',
    main: '#ECECEC',
    // dark: will be calculated from palette.secondary.main,
    contrastText: '#ffcc00'
  },
  error: {
    main: '#ffffff'
  },
  // error: will use the default color
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)'
    //gridGap: `15px`
  }
};

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Helvetica', 'Arial sans-serif'].join(',')
  },
  palette,
  legend: {
    //marginTop: theme.spacing.unit * 2,
    maxWidth: 300
  },
  paper: {
    maxWidth: 600,
    overflow: 'auto'
  },
  select: {
    width: 200
  },
  popper: {
    zIndex: 1,
    width: 600,
    marginLeft: '-240px',
    marginTop: '78px',
    left: '45% !important',
    transform: 'none !important'
    /*webkit-transform: none;*/
  },
  button: {
    borderRadius: 25
  },
  anchor: {
    width: 600,
    height: 10,
    borderRadius: '50%',
    position: 'absolute',
    top: 10
  },
  smLabel: {
    paddingTop: 'calc(.25rem + 1px)',
    paddingBottom: 'calc(.25rem + 1px)',
    fontSize: '0.75rem',
    lineHeight: '1.5'
  },
  closeMarkUp: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    bottom: 30
  },
  markup: {
    position: 'relative',
    gridColumn: 1,
    gridRow: 2,
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    backgroundColor: '#fff',
    zIndex: 6,
    textAlign: 'center',
    /* Center and scale the image nicely */
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  imgMarkup: {
    margin: 'auto',
    overflow: 'auto',
    position: 'absolute',
    left: 0,
    bottom: 0
  },
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14,
    textAlign: 'center'
  },
  pos: {
    marginBottom: 8
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  container_auto: {
    flexGrow: 1,
    position: 'relative'
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  autoComp: {
    zIndex: 23
  },
  searchBack: {
    position: 'absolute',
    top: '5px',
    left: '5px'
  },
  chip: {
    backgroundColor: 'transparent'
  },
  buttonExport: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  color: {
    /* Old value used for green: #95E616 */
    blue: '#0c4178',
    lightBlue: '#1CAEDC',
    darkBlue: '#1CAEDC',
    skyBlue: '#0070AD',
    blueTextarea: '#4a4a4a',
    green: '#5A9628',
    yellow: '#fff431',
    red: '#FF304C',
    darkRed: '#cc0016',
    orange: '#ff9f32',
    disabledLink: '#808080',
    lightGrey: '#9B9B9B',
    grey: '#757575',
    white: '#FFF',
    black: '#000',
    cyan: '#12ABDB',
    aliceBlue: 'rgba(240,248,255,0.5)'
  },
  overrides: {
    MuiFab: {
      root: {
        backgroundColor: 'white',
        margin: 20,
        border: '1px solid #757575',
        boxShadow: 'none'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#9e9e9e'
      }
    }
  }
});

export default theme;
