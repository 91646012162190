/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import Downshift from 'downshift';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import InputAdornment from '@material-ui/core/InputAdornment';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      selectedItem: [],
      suggestions: [],
      focused: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectionSuggestion = this.handleSelectionSuggestion.bind(this);
    this.setSuggestions = this.setSuggestions.bind(this);
  }

  componentDidMount() {
    this.loadSuggestions('');
  }
  componentDidUpdate() {
    if (this.refInput && this.refInput.focus)
      this.refInput.focus();
  }

  /** 
   * Render Balise Input
   * @param {Object} inputProps
   */
  renderInput(inputProps) {
    const { InputProps, classes, ref, label, startAdornment, style, ...other } = inputProps;
    const inputAdornment = startAdornment ? <InputAdornment> {startAdornment}</InputAdornment> : null;
    return (
      <TextField
        label={label}
        InputProps={{
          inputRef: ref,
          startAdornment: inputAdornment,
          ...InputProps,
        }}
        {...other}
        style={style}
        ref={(refInput) => this.refInput = refInput}
      />
    );
  }

  /**
   * Render Suggestion 
   * @param {Object} { suggestion, index, itemProps, highlightedIndex, selectedItem }
   */
  renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    // TODO les lignes sont elles utiles
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

    return (
      <ListItem
        {...itemProps}
        key={index}
        selected={isHighlighted}
        button
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.displayValue}
      </ListItem>
    );
  }

  /**
   * Handle changing input and load suggestion
   * @param {Object} event
   */
  handleInputChange(event) {
    var inputValue = event.target.value;

    this.setState({ inputValue: inputValue });
    // Reload Catalog
    this.loadSuggestions(inputValue);

    if (this.props.onChange)
      this.props.onChange(inputValue);
  };

  /**
   *  Handle click on suggestion
   * @param {string} item
   */
  handleSelectionSuggestion(item) {
    const selectedSuggestion = this.props.onSelect(item);
    this.setState({ inputValue: selectedSuggestion });

    // Reload Catalog
    this.loadSuggestions(selectedSuggestion);
    this.props.onChange(selectedSuggestion);
  }

  /**
   * Load suggestions (from props)
   * @param {string} inputValue
   */
  loadSuggestions(inputValue) {
    this.props.loadSuggestions(inputValue, this.setSuggestions);
  }

  /**
   * Set suggestions
   * @param {array} suggestions 
   */
  setSuggestions(suggestions) {
    this.setState({ suggestions: suggestions })
  }

  onBlur() {
    this.setState({ focused: false })
  }

  onFocus() {
    this.setState({ focused: true })
  }
  render() {

    const subheader = this.state.suggestions ? <ListSubheader component="div">{this.state.suggestions.suggestionType}</ListSubheader> : null;

    return (
      <Downshift
        inputValue={this.state.inputValue}
        onChange={this.handleSelectionSuggestion}
        selectedItem={this.state.selectedItem}
      >
        {({
          // All function came from Downshit
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex
        }) => (
            <span> 
              {/* Render input */}
              {this.renderInput({
                fullWidth: true,
                InputProps: getInputProps({
                  onChange: this.handleInputChange,
                  placeholder: this.props.placeholder
                }),
                label: this.props.label,
                startAdornment: this.props.adornment,
                style: this.props.style,
                ref: node => {
                  // get reference of input
                  this.inputRef = node;
                },
              })}

              {/* Suggestion */}
              <Popper open={isOpen}
                disablePortal
                anchorEl={this.inputRef}
                style={{ zIndex: 10000 }} /* Z-index be in Search window (***UGLY***) */
              >
                <Paper style={{ width: this.inputRef ? this.inputRef.clientWidth : null }}>
                  <List
                    //component="nav"
                    subheader={subheader}
                  >
                    {this.state.suggestions && this.state.suggestions.suggestions &&
                      this.state.suggestions.suggestions.map((suggestion, index) =>
                        this.renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem: selectedItem,
                        }),
                      )}
                  </List>
                </Paper>
              </Popper>
            </span>
          )}
      </Downshift>
    );
  }
}
export default AutoComplete;

/*
AutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  loadSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
*/