/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GenericCard from './GenericCard';
import { initWrapperCard } from 'actions/CardAction';

class GenericCardWrapper extends Component {
  panelSize = 370;
  constructor(props) {
    super(props);
    this.state = {
      genericCardList: {},
      actualZIndex: 10
    };
    this.add = this.add.bind(this);
    this.close = this.close.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.update = this.update.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.getIdCardByName = this.getIdCardByName.bind(this);
  }

  componentDidMount() {
    this.props.initWrapperCard(
      this.add,
      this.drop,
      this.close,
      this.closeAll,
      this.update,
      this.getIdCardByName
    );
  }

  add(typeComponent, data, positionX, positionY, options = {}) {
    const { isLeftPanel } = options;
    if (positionX == null || positionY == null) {
      console.error('Open card - data or positionX or positionY is null');
      return;
    }
    if (isLeftPanel) {
      positionX = window.innerWidth - this.panelSize - positionX;
    }
    let id = null;

    if (options.id) {
      id = options.id;
      if (this.state.genericCardList.hasOwnProperty(id)) {
        this.update(id, data);
        return id;
      }
    } else {
      id = '' + Math.random();
      while (this.state.genericCardList.hasOwnProperty(id)) {
        id = '' + Math.random();
      }
    }
    let newCard = {};
    newCard[id] = {
      data: data,
      positionX: positionX,
      positionY: positionY,
      options: options,
      typeComponent: typeComponent,
      zIndex: this.state.actualZIndex + 1
    };
    this.setState({ actualZIndex: this.state.actualZIndex + 1 });

    let updateGenericCardList = { ...this.state.genericCardList, ...newCard };
    this.setState({
      genericCardList: updateGenericCardList
    });

    return id;
  }

  close(id) {
    var genericCardList = this.state.genericCardList;
    delete genericCardList[id];

    this.setState({ genericCardList: genericCardList });
  }

  closeAll(callback) {
    // call back executed after set state
    this.setState({ genericCardList: {} }, callback);
  }

  getIdCardByName(name) {
    return this.props.genericCardList.find(
      (card) => card.typeComponent === name
    );
  }

  update(id, newData, newid = null) {
    let newGenericCardList = Object.assign({}, this.state.genericCardList);
    if (newGenericCardList[id]) {
      var newCard = {
        data: newData,
        options: this.state.genericCardList[id].options,
        positionX: this.state.genericCardList[id].positionX,
        positionY: this.state.genericCardList[id].positionY,
        typeComponent: this.state.genericCardList[id].typeComponent,
        zIndex: this.state.actualZIndex + 1
      };
      this.setState({ actualZIndex: this.state.actualZIndex + 1 });
      if (newid && newid !== id) {
        delete newGenericCardList[id];
        id = newid;
      }

      newGenericCardList[id] = newCard;
      this.setState({ genericCardList: newGenericCardList });
    }
    return id;
  }

  updatePosition(id, positionX, positionY) {
    let newGenericCardList = Object.assign({}, this.state.genericCardList);
    if (newGenericCardList[id]) {
      var newCard = {
        data: this.state.genericCardList[id].data,
        options: this.state.genericCardList[id].options,
        positionX: positionX,
        positionY: positionY,
        typeComponent: this.state.genericCardList[id].typeComponent,
        zIndex: this.state.actualZIndex + 1
      };
      this.setState({ actualZIndex: this.state.actualZIndex + 1 });

      newGenericCardList[id] = newCard;
      this.setState({ genericCardList: newGenericCardList });
    }
    return id;
  }

  updateCardZindex = (id) => {
    if (this.state.genericCardList[id]) {
      let updateGenericCardList = this.state.genericCardList;
      let updateCard = updateGenericCardList[id];
      updateCard.zIndex = this.state.actualZIndex + 1;
      updateGenericCardList[id] = updateCard;
      this.setState({
        actualZIndex: this.state.actualZIndex + 1,
        genericCardList: updateGenericCardList
      });
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.genericCardList &&
          Object.keys(this.state.genericCardList).map((id) => {
            let card = this.state.genericCardList[id];
            return (
              <GenericCard
                key={id}
                id={id}
                data={card.data}
                selectedCard={this.props.selectedCard === id ? true : false}
                positionX={card.positionX}
                positionY={card.positionY}
                options={card.options}
                typeComponent={card.typeComponent}
                dropTouch={this.dropTouch}
                updateZIndex={this.updateCardZindex}
                zIndex={card.zIndex}
                updatePosition={this.updatePosition}
              >
                {this.child}
              </GenericCard>
            );
          })}
      </Fragment>
    );
  }
}

export default connect(null, { initWrapperCard })(GenericCardWrapper);
