/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import theme from '../../../theme/theme';
import Popover from '@material-ui/core/Popover';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import UnreadIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import {
  getAllNotification,
  getNbrNotifications,
  readNotification,
  archiveNotification,
  getIotNotifMessage,
  getReviewNotifMessage,
  getImportNotifMessage,
  openConnection
} from 'services/NotificationService';
import { getProjectById } from 'services/ProjectService';
import { projectAction } from 'actions/ProjectAction';
import { mainViewerAction } from 'actions/MainViewerAction';
import { store } from 'utils/store';
import { parseDate } from 'utils/DateHelper';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import './NotificationComponent.css';

class NotificationComponent extends Component {
  state = {
    notifAnchorEl: null,
    lastEvaluatedKey: null, //page de notifications list suivante
    notifications: null,
    hasMoreNotif: false,
    nbrNotifications: ''
  };

  root = {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  };
  inline = {
    display: 'inline'
  };

  closeNotificationMenu = () => {
    this.setState({ notifAnchorEl: null });
  };

  openNotificationMenu = (event) => {
    this.setState({
      notifAnchorEl: event.currentTarget,

      // Do not mutate the state with splice or slice to only keep the first page of notification, better to use filter
      notifications: this.state.notifications
        ? this.state.notifications.filter((item, index) => {
            return index < 5;
          })
        : null,
      isNotificationsButtonActive: true
    });
    this.getNotification();
  };

  async componentDidMount() {
    //TODO: change the notification number after implementing web socket
    this.getNbrNotifications();
    this.getNotification();
    if (!this.connection) {
      this.connection = await openConnection();
      let cloudConfig = this.props.cloudConfig;
      if (cloudConfig === 'AzureADDB2C') {
        this.connection.on('NotificationsUpdated', (data) => {
          console.log('NotificationsUpdated: ', data);
          this.setNotificationCount(data);
        });
      } else {
        this.connection.onmessage = (evt) => {
          console.log('New WS: ' + JSON.stringify(evt.data));
          var data = JSON.parse(evt.data);
          this.setNotificationCount(data.Count);
        };
      }
    }
  }

  componentWillUnmount() {
    let cloudConfig = this.props.cloudConfig;
    if (cloudConfig !== 'AzureADDB2C') {
      this.connection.close();
    }
  }

  getNbrNotifications = () => {
    getNbrNotifications((error, data) => {
      if (error) {
        console.error(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.navBar.notificationError
        );
      }
      this.setNotificationCount(data.Count);
    });
  };

  setNotificationCount = (count) => {
    this.setState({ nbrNotifications: count });
  };

  getNotification = (lastEvaluatedKey) => {
    const thresholdComparatorLabels = this.props.multiLang.thresholdComparator;
    const sensorTypeLabels = this.props.multiLang.sensor.type;
    const messageNotifIot = this.props.multiLang.notification.messageNotifIot;
    const messageNotifImport = this.props.multiLang.notification
      .messageNotifImport;
    console.log('lastEvaluatedKey: ', lastEvaluatedKey);
    getAllNotification(lastEvaluatedKey, (error, data) => {
      if (error) {
        console.error(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.navBar.notificationError
        );
      }
      //LastEvaluatedKey non présent veut dire qu'il n'y a plus d'élements
      this.setState({
        lastEvaluatedKey: data.LastEvaluatedKey,
        hasMoreNotif: data.hasOwnProperty('LastEvaluatedKey')
      });
      if (data && data.Notifications && data.Notifications.length > 0) {
        data.Notifications.forEach((notification) => {
          let notifMessageData = notification.MessageData;
          switch (notification.Type) {
            case 'Iot':
              notification.Message = getIotNotifMessage(
                notifMessageData,
                thresholdComparatorLabels,
                sensorTypeLabels,
                messageNotifIot
              );
              break;
            case 'Review':
              notification.Message = getReviewNotifMessage(
                notification.ReviewStatus,
                this.props.multiLang.notification
              );
              break;
            case 'Import':
              notification.Message = getImportNotifMessage(
                notifMessageData,
                messageNotifImport
              );
              break;
            default:
              console.error(
                'The type of notification ' +
                  notification.Type +
                  ' is not recognized'
              );
          }
        });

        if (lastEvaluatedKey) {
          // We are loading more element to the array
          this.setState({
            notifications: this.state.notifications.concat(data.Notifications)
          });
        } else {
          // We are reseting the notifications list
          this.setState({ notifications: data.Notifications });
        }
      }
    });
  };

  //Changer le statut d'une notification en READ après click event
  readNotification = (NotificationId) => {
    console.log('read notification: ', NotificationId);
    readNotification(NotificationId, (error, data) => {
      if (error) {
        console.error(error);
      }
    });
  };

  // Remove notification from menu
  archiveNotification = (event, NotificationId) => {
    // Split click on Delete Button on event click on item
    event.stopPropagation();
    archiveNotification(NotificationId, (error, data) => {
      if (error) {
        console.error(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.navBar.archiveNotificationError
        );
      }
      //Remove Element
      this.setState({
        notifications: this.state.notifications.filter(
          (n) => n.NotificationId !== NotificationId
        )
      });
    });
  };

  openNotificationinViewer = (item) => {
    switch (item.Type) {
      case 'Review':
        this.openReviewNotification(item);
        break;
      case 'Iot':
        this.openIotNotification(item);
        break;
      case 'Import':
        this.openImportNotification(item);
        break;
      default:
        break;
    }
    //lire une notification
    this.closeNotificationMenu();
    this.readNotification(item.NotificationId);
  };

  /**
   * Open IOT notification
   * Redirect to viewer
   * open prop card
   * @param item
   */
  openIotNotification = (item) => {
    if (
      this.props.project.modelId === item.ModelId &&
      this.props.mainViewer.viewers.length > 0
    ) {
      if (item.TagName) {
        const type = item.Type === 'Iot' ? 'Monitoring' : null;
        this.props.closeAllCard(() => {
          this.props.openCard(
            'PROPERTY_CARD',
            {
              tag: item.TagName,
              selectedTab: type
            },
            150,
            150,
            { height: 500 }
          );
        });
        let viewers = this.props.viewers.filter(
          (v) => v.focusOnObjects !== null
        );
        if (viewers && viewers.length > 0) {
          viewers.forEach((v) => v.focusOnObjects([item.TagName]));
        }
      }
    } else {
      store.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: [item.TagName]
      });
      const type = item.Type === 'Iot' ? 'Monitoring' : null;
      // close all card and after open notification card
      this.props.closeAllCard(() => {
        this.props.openCard(
          'PROPERTY_CARD',
          {
            tag: item.TagName,
            projectId: item.ProjectId,
            selectedTab: type
          },
          150,
          150,
          { height: 500 }
        );
      });
      this.props.history.push(
        `/viewer?viewer=${item.Viewer}&projectId=${item.ProjectId}&modelId=${item.ModelId}&revision=${item.Revision}`
      );
    }
  };

  /**
   * Open Review notification
   * redirect to viewer
   *
   * @param item
   */
  openReviewNotification = (item) => {
    this.props.closeAllCard();

    if (
      this.props.project.modelId === item.ModelId &&
      this.props.project.projectId === item.ProjectId &&
      this.props.project.revision === item.Revision &&
      this.props.mainViewer.viewers.length > 0
    ) {
      this.props.mainViewer.openViewMarkupCardFunction();
    } else {
      store.dispatch({
        type: mainViewerAction.openMarkUpCard,
        openViewMarkupCard: {
          open: true,
          projectId: item.ProjectId,
          modelId: item.ModelId,
          revision: item.Revision
        },
        openViewMarkupCardFunction: null,
        unmountViewMarkupCardFunction: null
      });
      this.props.history.push(
        `/viewer?viewer=FORGE_VIEWER&projectId=${item.ProjectId}&modelId=${item.ModelId}&revision=${item.Revision}`
      );
    }
  };

  /**
   * Open Import notification
   * redirect to import page
   *
   * @param item
   */
  openImportNotification = (item) => {
    this.props.closeAllCard();

    getProjectById(item.ProjectId, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.mainViewer.projectDetailsError
        );
      }
      // add project informations to store
      store.dispatch({
        type: projectAction.initProject,
        projectId: item.ProjectId,
        isProjectOwner: data.ProjectOwner,
        name: data.Name,
        viewer: data.Viewer
      });
      this.props.history.push({
        pathname: `/projects/${item.ProjectId}/imports`,
        state: { modelId: item.ModelId }
      });
    });
  };

  loadMoreNotifs = () => {
    this.getNotification(this.state.lastEvaluatedKey);
  };

  render() {
    return (
      <div>
        <button
          type='button'
          className='nav-item'
          onClick={this.openNotificationMenu}
          aria-owns={this.state.notifAnchorEl ? 'user-menu' : undefined}
        >
          <svg
            className='nav-item__icon'
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            fit=''
            height='100%'
            width='100%'
            preserveAspectRatio='xMidYMid meet'
            focusable='false'
          >
            <path
              fill='#9B9B9B'
              d='M30.17 26.788c-.235-.215-2.145-2.15-2.145-8.252a8.917 8.917 0 0 0-2.201-5.67 8.29 8.29 0 0 0-2.69-2.094.841.841 0 0 0 0-.153c0-1.722-1.411-3.119-3.153-3.119-1.741 0-3.153 1.397-3.153 3.12a.841.841 0 0 0 0 .152 8.29 8.29 0 0 0-2.69 2.093 8.917 8.917 0 0 0-2.201 5.671c0 6.091-1.904 8.02-2.139 8.235a1.614 1.614 0 0 0-.728 1.86 1.753 1.753 0 0 0 1.72 1.231h5.608a.838.838 0 0 0 0 1.168 4.94 4.94 0 0 0 3.52 1.47 4.94 4.94 0 0 0 3.52-1.47.838.838 0 0 0 0-1.134h5.683a1.753 1.753 0 0 0 1.72-1.23 1.619 1.619 0 0 0-.671-1.878zM19.998 9.178c.6.051 1.12.434 1.342.988a6.653 6.653 0 0 0-2.683 0 1.586 1.586 0 0 1 1.341-.987zm0 2.553c3.635 0 6.307 3.63 6.307 6.805a21.284 21.284 0 0 0 .671 5.672H13.02c.478-1.853.704-3.76.671-5.672 0-3.176 2.672-6.805 6.307-6.805zm-.04 19.078a3.188 3.188 0 0 1-2.293-.936h4.53c-.59.594-1.395.93-2.237.936zm-9.134-2.631a5.518 5.518 0 0 0 1.594-2.269h15.166a5.518 5.518 0 0 0 1.594 2.269H10.824z'
            />
          </svg>

          <span className='badge badge-light nav-item__badge'>
            {this.state.notifications &&
              this.state.nbrNotifications !== 0 &&
              this.state.nbrNotifications}
          </span>
          <span className='nav-item__text'>
            {this.props.multiLang.navBar.notifications}
          </span>
        </button>

        <Popover
          id='notification-menu'
          open={Boolean(this.state.notifAnchorEl)}
          anchorEl={this.state.notifAnchorEl}
          onClose={this.closeNotificationMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {!this.state.notifications ||
          this.state.notifications.length === 0 ? (
            <div
              style={{
                minWidth: 400,
                minHeight: 50,
                textAlign: 'center',
                paddingTop: 10
              }}
            >
              {this.props.multiLang.navBar.noNotification}
            </div>
          ) : (
            <List>
              <div id='notifs_list'>
                <InfiniteScroll
                  loadMore={() => {
                    // Do not call multiple time load notif before completion of the first request
                    this.setState({ hasMoreNotif: false });
                    this.loadMoreNotifs();
                  }}
                  hasMore={this.state.hasMoreNotif}
                  loader={
                    <div
                      style={{ textAlign: 'center', paddingTop: '3px' }}
                      key={0}
                    >
                      <CircularProgress
                        variant='indeterminate'
                        disableShrink
                        size={20}
                        thickness={4}
                      />
                    </div>
                  }
                  useWindow={false}
                  threshold={5}
                >
                  {this.state.notifications &&
                    this.state.notifications.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        alignItems='flex-start'
                        disableGutters={true}
                        style={{ paddingRight: '10px' }}
                      >
                        <ListItemText
                          onClick={() => this.openNotificationinViewer(item)}
                          primaryTypographyProps={{ variant: 'caption' }}
                          style={{ marginLeft: 20 }}
                          primary={
                            <React.Fragment>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    style={{ color: theme.color.lightGrey }}
                                    variant={'caption'}
                                    component='span'
                                  >
                                    {item.Type === 'Review'
                                      ? this.props.multiLang.markup.review
                                      : item.Type}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{ textAlign: 'right' }}
                                >
                                  {item.StateNotification === 'UNREAD' && (
                                    <Tooltip
                                      title={this.props.multiLang.navBar.unread}
                                    >
                                      <UnreadIcon
                                        style={{
                                          fontSize: '15px',
                                          color: '#007bff'
                                        }}
                                      />
                                    </Tooltip>
                                  )}

                                  <Tooltip
                                    title={this.props.multiLang.navBar.archive}
                                  >
                                    <CloseIcon
                                      onClick={(e) =>
                                        this.archiveNotification(
                                          e,
                                          item.NotificationId
                                        )
                                      }
                                      style={{ fontSize: '15px' }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant={'caption'}
                                style={{ color: theme.color.lightGrey }}
                              >
                                {item.Message}
                              </Typography>
                              <Typography variant={'body2'} color='primary'>
                                {parseDate(
                                  item.CreationDate,
                                  this.props.multiLang.projectFile.dateFormatISO
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                </InfiniteScroll>
              </div>
            </List>
          )}
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewers: state.mainViewer.viewers,
  projectId: state.project.projectId,
  modelId: state.project.modelId,
  revision: state.project.revision,
  notifications: state.notification,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  project: state.project,
  mainViewer: state.mainViewer,
  openCard: state.card.openCard,
  closeAllCard: state.card.closeAllCard,
  webSocketUrl: state.config.webSocketUrl,
  id_token: state.auth.idToken,
  cloudConfig: state.config.authProvider
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(NotificationComponent);
