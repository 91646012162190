/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import {
  getUserTagFavourites,
  removeFavouriteTag
} from '../../../services/UserService';
import StarBorder from '@material-ui/icons/StarBorder';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { store } from 'utils/store';
import { mainViewerAction } from 'actions/MainViewerAction';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import theme from 'theme/theme';
import ScrollArea from 'react-scrollbar';
import { constructTagLabel } from 'utils/TagHelper';

class UserTagFavouriteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagFavourites: null,
      userTagFavouriteEnchorEl: null
    };
  }

  componentDidMount() { }

  getUserTagFavourites = (userId) => {
    getUserTagFavourites(userId, (err, data) => {
      this.setState({ tagFavourites: data });
    });
  };

  openUserTagFavouriteMenu = (event) => {
    const { userId } = this.props;
    this.getUserTagFavourites(userId);
    this.setState({ userTagFavouriteEnchorEl: event.currentTarget });
  };

  closeUserTagFavouriteMenu = () => {
    this.setState({ userTagFavouriteEnchorEl: null });
  };

  removeFavouriteTag = (event, userId, tagId) => {
    event.stopPropagation();
    removeFavouriteTag(userId, tagId, (error) => {
      if (error) {
        console.error(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.navBar.removeFavouriteTagError
        );
      }
      this.setState({
        tagFavourites: this.state.tagFavourites.filter(
          (tagFavourite) => tagFavourite.CompositeSortKey !== tagId
        )
      });
    });
  };

  openFavouriteTaginViewer = (item) => {
    if (item.Tag) {
      if (
        this.props.project.modelId === item.Model &&
        this.props.mainViewer.viewers.length > 0
      ) {
        this.props.closeAllCard(() => {
          this.props.openCard('PROPERTY_CARD', { tag: item.Tag }, 150, 150, {
            height: 500
          });
        });
        let viewers = this.props.mainViewer.viewers.filter(
          (v) => v.focusOnObjects !== null
        );
        if (viewers && viewers.length > 0) {
          viewers.forEach((v) => v.focusOnObjects([item.Tag.toString()], v.id.toString()));
        }
      } else {
        store.dispatch({
          type: mainViewerAction.initTagNames,
          focusedObjectsTagNames: [item.Tag.toString()]
        });
        // close all card and after open info card
        this.props.closeAllCard(() => {
          this.props.openCard(
            'PROPERTY_CARD',
            {
              tag: item.Tag,
              projectId: item.ProjectId
            },
            150,
            150,
            { height: 500 }
          );
        });
        this.props.history.push(
          `/viewer?viewer=${item.Viewer}&projectId=${item.ProjectId}&modelId=${item.Model}&revision=${item.Revision}`
        );
      }
    }
    this.closeUserTagFavouriteMenu();
  };

  render() {
    return (
      <div>
        <button
          type='button'
          className='nav-item'
          onClick={this.openUserTagFavouriteMenu}
          aria-owns={
            this.state.userTagFavouriteEnchorEl
              ? 'user-tag-favourite-menu'
              : undefined
          }
          aria-haspopup='true'
        >
          <StarBorder />
          <span className='nav-item__text'>
            {this.props.multiLang.navBar.favourites}
          </span>
        </button>

        <Popover
          id='user-tag-favourite-menu'
          open={Boolean(this.state.userTagFavouriteEnchorEl)}
          anchorEl={this.state.userTagFavouriteEnchorEl}
          onClose={this.closeUserTagFavouriteMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {!this.state.tagFavourites ||
            this.state.tagFavourites.length === 0 ? (
              <div
                style={{
                  minWidth: 400,
                  minHeight: 50,
                  textAlign: 'center',
                  paddingTop: 10
                }}
              >
                {this.props.multiLang.navBar.noUserTagFavourite}
              </div>
            ) : (
              <ScrollArea smoothScrolling style={{ height: '300px' }}>
                <List>
                  <div id='tag_favourites_list' style={{ width: 400 }}>
                    {this.state.tagFavourites.map((item, index) => {
                      return (
                        <ListItem button key={index}>
                          <ListItemText
                            onClick={() => this.openFavouriteTaginViewer(item)}
                            id={item.ProjectName}
                            primary={
                              <React.Fragment>
                                <Grid container spacing={0}>
                                  <Grid item xs={12} sm={10}>
                                    <Typography component='span' color='primary'>
                                    {constructTagLabel(item.Tag, item.TagLabel)} - ({item.TagClass})
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                    style={{ textAlign: 'right' }}
                                  >
                                    <Tooltip
                                      title={
                                        this.props.multiLang.navBar
                                          .removeFavouriteTag
                                      }
                                    >
                                      <CloseIcon
                                        onClick={(e) =>
                                          this.removeFavouriteTag(
                                            e,
                                            item.UserId,
                                            item.ProjectId + '#' + item.Tag
                                          )
                                        }
                                        style={{ fontSize: '15px' }}
                                      />
                                    </Tooltip>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Typography component='span'>
                                      {item.FileName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Typography
                                      component='span'
                                      style={{ color: theme.color.grey }}
                                    >
                                      {item.ProjectName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </div>
                </List>
              </ScrollArea>
            )}
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  openCard: state.card.openCard,
  closeAllCard: state.card.closeAllCard,
  userId: state.auth.userId,
  project: state.project,
  mainViewer: state.mainViewer
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(UserTagFavouriteComponent);
