/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { mainViewerAction } from "actions/MainViewerAction";
const initialState = {
  viewers: []
};
export default function mainViewerReducer(state = initialState, action) {
  switch (action.type) {
    case mainViewerAction.initViewer:
      return Object.assign({}, state, {
        viewers: [...state.viewers, action.viewer],
        isSearchable: true // action.isSearchable
      });
    case mainViewerAction.initTagNames:
      return Object.assign({}, state, {
        focusedObjectsTagNames: action.focusedObjectsTagNames,
        selectedObjectIds: null,
        modelTagName: null,
        selectionFromAbs: action.selectionFromAbs ? true : false,
        fromViewer: action.fromViewer ? true : false,
        selectionFromAttachedTags: action.selectionFromAttachedTags
          ? true
          : false
      });

    case mainViewerAction.removeGeoserverPoi:
      return Object.assign({}, state, {
        targetPoiId: action.targetPoiId
      });

    case mainViewerAction.removeViewer:
      return Object.assign({}, state, {
        viewers:
          action.viewerToRemove !== "All"
            ? state.viewers.filter((item) => item.id !== action.viewerToRemove)
            : [],
        isSearchable:
          action.viewerToRemove === "All"
            ? null
            : state.viewers.length <= 1
            ? null
            : true
      });
    case mainViewerAction.initObjectIds:
      return Object.assign({}, state, {
        selectedObjectIds: action.selectedIds
      });
    case mainViewerAction.removeTagNames:
      return Object.assign({}, state, {
        focusedObjectsTagNames: null,
        selectedObjectIds: null
      });
    case mainViewerAction.initSize:
      return Object.assign({}, state, {
        size: action.size
      });
    case mainViewerAction.openMarkUpCard:
      return Object.assign({}, state, {
        openViewMarkupCard: {
          open: action.openViewMarkupCard.open,
          projectId: action.openViewMarkupCard.projectId,
          modelId: action.openViewMarkupCard.modelId,
          revision: action.openViewMarkupCard.revision
        },
        openViewMarkupCardFunction: action.openViewMarkupCardFunction
      });
    case mainViewerAction.addView:
      return Object.assign({}, state, {
        viewToAdd: {
          viewerType: action.viewToAdd.viewerType,
          fileName: action.viewToAdd.fileName,
          data: action.viewToAdd.data,
          tag: action.viewToAdd.tag,
          modelId: action.viewToAdd.modelId
        }
      });
    case mainViewerAction.switchView:
      return Object.assign({}, state, {
        viewToSwitch: {
          oldView: action.viewToSwitch.oldView,
          newView: action.viewToSwitch.newView,
          fileName: action.viewToSwitch.fileName,
          model: action.viewToSwitch.model ? action.viewToSwitch.model : null,
          data: action.viewToSwitch.data,
          tag: action.viewToSwitch.tag
        }
      });
    default:
      return state;
  }
}
