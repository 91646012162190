/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tooltip, IconButton, Typography } from '@material-ui/core';
import {
  createProject,
  deleteProject,
  getProjects,
  updateProject
} from 'services/ProjectService';
import { addGroup } from 'services/GroupService';
import { addUserToGroup, deleteUserFromGroup } from 'services/UserService';
import { createFeatureZone } from 'services/FeatureZoneService';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddProject from './AddProject';
import RemoveProjectsDialog from './RemoveProjectsDialog';
import './Projects.css';
import ScrollArea from 'react-scrollbar';
import theme from 'theme/theme';

const PROJECT_ADMIN_RIGHT = {
  rightId: '570d3e2014f540498e56678fb4f132c4',
  rightName: 'AdministrateProject'
};
const ADMIN = 'admin';
const ADMIN_PROJECT = 'admin project';
class Projects extends Component {
  state = {
    backgroundColor: '#FFF',
    titleColor: '#0070AD',
    descriptionColor: '#000',
    shadow: 0,
    projectName: '',
    description: '',
    projectList: [],
    groups: [],
    groupValue: [],
    isEditProjectModalOpen: false,
    isAddProjectModalOpen: false,
    selectedRow: [],
    projectsToRemove: [],
    openDialog: false
  };

  componentDidMount() {
    getProjects((error, projects) => {
      if (error) {
        console.error(error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectRetrievalFailed
        );
      }
      let tableData = [];
      projects.forEach((element) => {
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton onClick={() => this.handleEditProject()}>
              <EditIcon color='primary' />
            </IconButton>
          </Tooltip>
        );
        tableData.push([element.Id, element.Name, element.Description, edit]);
      });
      this.setState({ projectList: tableData });
    });
  }

  handleEditProject = () => {
    this.setState({ isEditProjectModalOpen: true });
  };

  handleCloseEditProjectModal = (value) => {
    this.setState({ isEditProjectModalOpen: value });
  };

  handleAddProject = () => {
    this.setState({ isAddProjectModalOpen: true });
  };

  handleCloseAddProjectModal = (value) => {
    this.setState({ isAddProjectModalOpen: value });
  };

  columnsIn = [
    {
      name: this.props.multiLang.administration.projectId,
      label: this.props.multiLang.administration.projectId,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.description,
      label: this.props.multiLang.administration.description,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.actions,
      label: this.props.multiLang.administration.actions,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title={this.props.multiLang.administration.createProject}>
          <IconButton onClick={() => this.handleAddProject()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    customToolbarSelect: (selectedRows, displayedData, setSelectedRows) => {
      return (
        <Tooltip title={this.props.multiLang.projectFile.delete}>
          <IconButton
            style={{ marginRight: 24 }}
            onClick={() => {
              this.setState({
                openDialog: true,
                projectsToRemove: selectedRows
              });
              setSelectedRows([]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onRowClick: (rowData) => {
      this.setState({ selectedRow: rowData });
    }
  };

  handleDialog() {
    this.setState({ openDialog: !this.state.openDialog });
  }

  handleProjectCreation = (projectName, description, users) => {
    createProject(projectName, description)
      .then((data) => {
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton onClick={() => this.handleEditProject()}>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        );
        let updateProjectsList = [...this.state.projectList];
        updateProjectsList.push([
          data.data.ZoneId,
          projectName,
          description,
          edit
        ]);
        this.setState({
          projectList: updateProjectsList,
          isAddProjectModalOpen: false
        });
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.projectCreationSucceeded
        );
        let projectId = data.data.ZoneId;
        addGroup(ADMIN_PROJECT, ADMIN, projectId)
          .then((data) => {
            const groupId = data.data.id;
            let groups = [];
            groups.push({ Name: ADMIN, ZoneId: groupId });
            let rights = [PROJECT_ADMIN_RIGHT];
            if (users.length > 0) {
              users.forEach((user) =>
                addUserToGroup(user.Id, groupId)
              );
            }
            createFeatureZone(ADMIN, projectId, ADMIN_PROJECT, groups, rights);
          })
          .catch((error) => {
            console.error('failed ' + error);
          });
      })
      .catch((error) => {
        console.log(error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectCreationFailed
        );
      });
  };

  handleProjectEdit = async (projectId, projectName, description, orginalAdminsList, selectedUsers, groupId) => {
    let updateProjectsList = [...this.state.projectList];
    let index = updateProjectsList.findIndex((project) => {
      return project[0] === projectId;
    });
    // Update the admins of this of this project
    await this.updateGroupUsers(orginalAdminsList, selectedUsers, groupId);
    updateProject(projectId, description, projectName)
      .then(() => {
        updateProjectsList.splice(index, 1);
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton onClick={() => this.handleEditProject()}>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        );
        updateProjectsList.push([projectId, projectName, description, edit]);
        this.setState({
          projectList: updateProjectsList,
          isEditProjectModalOpen: false
        });
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.projectUpdateSucceeded
        );
      })
      .catch((error) => {
        console.error(error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectUpdateFailed
        );
      });
  };

  updateGroupUsers = (orginalAdminsList, selectedUsers, groupId) => {
    // get list of users to be deleted adnd to be added
    const usersToDelete = orginalAdminsList.filter(userId => !selectedUsers.includes(userId));
    const usersToAdd = selectedUsers.filter(userId => !orginalAdminsList.includes(userId));
    // Add the new selected users to admin Group
    usersToAdd.forEach((userId) => {
      addUserToGroup(userId, groupId)
        .then(() => {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success.userAddSucceeded
          );
        })
        .catch((error) => {
          console.error(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.userAddFailed
          );
        });
    });
    // Delete users from admin Group
    usersToDelete.forEach((userId) => {
      deleteUserFromGroup(
        userId,
        groupId
      )
        .then(() => {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success.userAddSucceeded
          );
        })
        .catch((error) => {
          console.error(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.userAddFailed
          );
        });
    });

  };

  deleteProjects = (projects) => {
    let updateProjectsList = [...this.state.projectList];
    projects.data.reverse().forEach((element) => {
      let projectTodelete = updateProjectsList[element.dataIndex];

      deleteProject(projectTodelete[0])
        .then(() => {
          updateProjectsList.splice(element.dataIndex, 1);
          this.setState({ projectList: updateProjectsList });
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success.projectRemovalSucceeded
          );
        })
        .catch((error) => {
          console.log(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.projectRemovalFailed
          );
        });
    });
    this.handleDialog();
  };

  render() {
    return (
      <Fragment>
        <Typography style={{ color: '#757575', fontWeight: 'bold' }}>
          <span onClick={() => this.props.close()}
            style={{ cursor: 'pointer' }}>
            {this.props.multiLang.administration.projectManagement}{' '} &gt; {' '}
          </span>
          {this.props.multiLang.administration.groups}
        </Typography>
        {this.state.isAddProjectModalOpen && (
          <AddProject
            isOpen={this.state.isAddProjectModalOpen}
            onClose={this.handleCloseAddProjectModal}
            addProject={this.handleProjectCreation}
          />
        )}
        {this.state.isEditProjectModalOpen && (
          <AddProject
            isOpen={this.state.isEditProjectModalOpen}
            onClose={this.handleCloseEditProjectModal}
            edit={true}
            updateProject={this.handleProjectEdit}
            project={this.state.selectedRow}
          />
        )}
        <ScrollArea>
          <div style={{ margin: '10px' }}>
            <MUIDataTable
              title={this.props.multiLang.administration.projects}
              data={this.state.projectList}
              columns={this.columnsIn}
              options={this.options}
            />
          </div>
          <RemoveProjectsDialog
            openDialog={this.state.openDialog}
            projects={this.state.projectsToRemove}
            onCloseDialog={this.handleDialog.bind(this)}
            onRemoveProjects={this.deleteProjects}
          />
        </ScrollArea>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(Projects);
