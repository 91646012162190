/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import uuid from "uuidv4";
import buildComponent from "./FactoryComponent";
import Close from "@material-ui/icons/Close";
import IconExpand from "resources/svg/IconExpand";
import IconCollapse from "resources/svg/IconCollapse";
import IconButton from "@material-ui/core/IconButton";
import ScrollArea from "react-scrollbar";
import { Rnd } from "react-rnd";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarBorder from "@material-ui/icons/StarBorder";
// import Share from '@material-ui/icons/Share';
// import Compare from '@material-ui/icons/Compare';
import theme from "../../theme/theme";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import { isAuthorize } from "services/AuthorizationService";
import { addUserTagFavourite } from "../../services/UserService";
import { attachTag, detachTag } from "services/AssetService";
import { Typography, FormControlLabel, Switch } from "@material-ui/core";
import { mainViewerAction } from "../../actions/MainViewerAction";

import "./GenericCard.css";
import { getTagFromIdPromise } from "../../services/ObjectService";

const zIndex = 1;
const defaultWidth = "370";
const defaultHeight = "500";
const marginX = 200;
const marginY = 100;
// attaching tag right
const link = {
  rightName: "LinkUnlink"
};
class GenericCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedItem: null,
      height: this.props.options.height
        ? this.props.options.height
        : defaultHeight,
      width: this.props.options.width ? this.props.options.width : defaultWidth,
      positionX: this.props.positionX,
      positionY: this.props.positionY,
      id: uuid(),
      zIndex: zIndex + 1,
      isExpand: false,
      tag: "",
      hasAttachRight: false,
      showEmptyAttribute: false
    };

    this.close = this.close.bind(this);
    this.changeSelectedItem = this.changeSelectedItem.bind(this);
    this.cardSelected = this.cardSelected.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.bucketName = this.props.config.bucket;
    this.storeBucketName = this.props.config.storeBucket;
    this.expand = this.expand.bind(this);
  }

  componentDidMount() {
    this.setTitle(this.props.data.title);
    if (this.props.data.viewerId) {
      this.setState({ viewerId: this.props.data.viewerId });
    }

    const { projectId } = this.props;
    this.setState({ hasAttachRight: isAuthorize(link.rightName, projectId) });
  }

  changeSelectedItem(selected) {
    this.setState({
      selectedItem: selected
    });
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  setTag = (tag) => {
    this.setState({ tag: tag });
  };

  cardSelected() {
    this.props.updateZIndex(this.props.id, this.updateZindex);
  }

  buildChild() {
    // Compute height
    this.heightContent = null;
    if (this.genericCardHeaderRef) {
      this.heightContent =
        this.state.height - this.genericCardHeaderRef.clientHeight;
    }

    let options = {
      close: this.close,
      setTitle: this.setTitle,
      height: this.heightContent,
      setTag: this.setTag,
      showEmptyAttribute: this.state.showEmptyAttribute,
      addMarkup: this.props.options.addMarkup
    };

    return buildComponent(this.props.typeComponent, this.props.data, options);
  }

  /**
   * Expand
   */
  expand() {
    const size = this.props.mainViewer.size;

    var positionX;
    var positionY;
    const width = size.mainViewer.width - marginX;
    const height = size.mainViewer.height - marginY * 1.5;

    if (this.state.isExpand) {
      positionX = this.state.positionXBeforeExpand;
      positionY = this.state.positionYBeforeExpand;
      this.setState({
        isExpand: false,
        height: this.props.options.height,
        width: this.props.options.width,
        // clean position
        positionXBeforeExpand: null,
        positionYBeforeExpand: null
      });
    } else {
      this.setState({
        isExpand: true,
        height: height,
        width: width,
        // save position before expand
        positionXBeforeExpand: this.props.positionX,
        positionYBeforeExpand: this.props.positionY
      });

      // center position in viewer
      positionX = 0 + marginX / 2;
      positionY = size.navBar.height + marginY / 2;

      this.setState({ positionY: positionY, positionX: positionX });
    }

    if (this.props.updatePosition)
      this.props.updatePosition(this.props.id, positionX, positionY);
  }

  isPropertyCard() {
    return this.props.typeComponent === "PROPERTY_CARD";
  }

  isExpandable() {
    return this.props.typeComponent !== "EXPAND_CHART_CARD";
  }

  openActions = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeActions = () => {
    this.setState({ anchorEl: null });
  };

  addUserTagFavourite = () => {
    const { userId, projectId, projectName, modelName } = this.props;
    const modelId = this.state.viewerId
      ? this.state.viewerId.split("#")[1]
      : this.props.modelId;
    const revision = this.state.viewerId
      ? this.state.viewerId.split("#")[2]
      : this.props.revision;
    const viewerName = this.state.viewerId
      ? this.state.viewerId.split("#")[0]
      : this.props.viewerName;
    const project = {
      projectName: projectName,
      projectId: projectId
    };
    const { tag } = this.state;

    const model = {
      modelId: modelId,
      modelFilename: modelName,
      revision: revision
    };

    addUserTagFavourite(userId, tag, project, model, viewerName, (error) => {
      if (error) {
        console.error(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.navBar.addFavouriteTagError
        );
      }
      return this.props.snackBar.addSnackSuccess(
        this.props.multiLang.navBar.addFavouriteTagSuccess
      );
    });
    this.closeActions();
  };

  handleDoubleClickTitle = () => {
    try {
      var textField = document.createElement("textarea");
      textField.innerText = "" + this.state.tag.tagName;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
    } catch (e) {
      console.error("No tag in title");
    }
  };

  isTagsAttach = async (ids) => {
    let Id;
    for (const id of ids) {
      await getTagFromIdPromise(
        this.props.project.projectId,
        this.props.modelId,
        this.props.revision,
        "" + id,
        "FORGE_VIEWER"
      )
        .then((result) => {
          Id = result.data.Tag;
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    }
    return Id;
  };

  attachTag = async () => {
    let ids = this.props.viewers[0].viewer.myCurrentViewer.getSelection();
    if (isNaN(ids[0])) {
      this.props.snackBar.addSnackWarning(
        this.props.multiLang.propertyCard.notElementSelectError
      );
    } else {
      console.log("IDS : ", ids);
      const focusedObjectsTagNames = this.props.focusedObjectsTagNames;
      // If selected objected is already linked to a tag, display warning message
      if (focusedObjectsTagNames && focusedObjectsTagNames.length > 0) {
        this.props.snackBar.addSnackWarning(
          this.props.multiLang.propertyCard.objectAlreadyAttached +
            focusedObjectsTagNames[0]
        );
      } else {
        const tag = this.state.tag.tagName.toString();
        attachTag(
          this.props.modelId,
          parseInt(this.props.revision),
          this.props.project.projectId,
          ids,
          tag
        )
          .then((response) => {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.propertyCard.tagAttachedSuccess
            );
            // Reload linking tag list if it is open
            if (this.props.data.reloadTaglist)
              this.props.data.reloadTaglist(tag, ids);
            // update focusedObjectsTagNames in the store
            this.props.dispatch({
              type: mainViewerAction.initTagNames,
              focusedObjectsTagNames: [tag],
              fromViewer: true
            });
          })
          .catch((error) => {
            this.props.snackBar.addSnackError(
              this.props.multiLang.propertyCard.tagAttachedError
            );
            console.error(error);
          });
      }
    }
  };

  detachTag = () => {
    const tag = this.state.tag.tagName.toString();
    detachTag(
      this.props.modelId,
      parseInt(this.props.revision),
      this.props.project.projectId,
      tag
    )
      .then((response) => {
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.propertyCard.tagDetachSuccess
        );
        // Reload linking tag list if it is open
        if (this.props.data.reloadTaglist) this.props.data.reloadTaglist(tag);
        // update focusedObjectsTagNames in the store
        this.props.dispatch({
          type: mainViewerAction.initTagNames,
          focusedObjectsTagNames: null
        });
      })
      .catch((error) => {
        this.props.snackBar.addSnackError(
          this.props.multiLang.propertyCard.tagDetachError
        );
        console.error(error);
      });
  };

  render() {
    let viewer = this.props.mainViewer.viewers.find((v) => v.frame);
    if (!this.props.data) {
      console.error("Content card have no data");
      return <Fragment />;
    }

    return (
      <Rnd
        style={{ zIndex: this.props.zIndex }}
        minWidth={200}
        minHeight={300}
        size={{
          height: this.state.height,
          width: this.state.width
        }}
        position={{
          x: this.state.positionX,
          y: this.state.positionY
        }}
        enableResizing={{
          top: false,
          right: true,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: true,
          bottomLeft: false,
          topLeft: false
        }}
        onResize={(e, direction, ref, delta, position) => {
          this.setState({
            width: ref.offsetWidth,
            height: ref.offsetHeight
          });
        }}
        onResizeStart={(e, direction, ref) => {
          if (viewer) {
            viewer.frame.style.pointerEvents = "none";
          }
        }}
        onResizeStop={(e, direction, ref) => {
          if (viewer) {
            viewer.frame.style.pointerEvents = "auto";
          }
        }}
        onDragStop={(e, d) => {
          this.setState({ positionX: d.x, positionY: d.y });
        }}
        bounds="parent"
        dragHandleClassName="card__header"
      >
        <section
          id="generic-card-1"
          className={
            "card card--viewer " + (this.props.selectedCard ? "selected" : "")
          }
          onClick={this.cardSelected}
        >
          <header
            className="card__header"
            style={{ paddingLeft: this.isPropertyCard() ? "5px" : "20px" }}
            ref={(genericCardHeaderRef) =>
              (this.genericCardHeaderRef = genericCardHeaderRef)
            }
          >
            {this.isPropertyCard() && (
              <Fragment>
                <IconButton
                  onClick={this.openActions}
                  style={{ marginLeft: "0px" }}
                  aria-owns={this.state.anchorEl ? "info-card-menu" : undefined}
                  aria-haspopup="true"
                >
                  <MoreVert />
                </IconButton>

                <Menu
                  id="info-card-menu"
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.closeActions}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  getContentAnchorEl={null}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  style={{ color: "red" }}
                  disableAutoFocusItem={true}
                >
                  <MenuItem
                    className="menuItem"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.addUserTagFavourite();
                    }}
                  >
                    <ListItemIcon>
                      <StarBorder style={{ color: theme.color.lightBlue }} />
                    </ListItemIcon>
                    {this.props.multiLang.propertyCard.addToFavourites}
                  </MenuItem>
                  {this.state.hasAttachRight && (
                    <div>
                      <MenuItem className="menuItem" onClick={this.attachTag}>
                        <ListItemIcon>
                          <LinkIcon style={{ color: theme.color.lightBlue }} />
                        </ListItemIcon>
                        {this.props.multiLang.attachTag.attach}
                      </MenuItem>

                      <MenuItem className="menuItem" onClick={this.detachTag}>
                        <ListItemIcon>
                          <LinkOffIcon
                            style={{ color: theme.color.lightBlue }}
                          />
                        </ListItemIcon>
                        {this.props.multiLang.attachTag.detach}
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.showEmptyAttribute}
                              onChange={() =>
                                this.setState({
                                  showEmptyAttribute:
                                    !this.state.showEmptyAttribute
                                })
                              }
                              value="checkedB"
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label={
                            <Typography>
                              {
                                this.props.multiLang.propertyCard
                                  .showEmptyAttributes
                              }
                            </Typography>
                          }
                        />
                      </MenuItem>
                    </div>
                  )}
                  {/* <MenuItem
                    disabled
                    className='menuItem'
                    onClick={() => {
                      return null;
                    }}
                  >
                    <ListItemIcon>
                      <Share />
                    </ListItemIcon>
                    {this.props.multiLang.propertyCard.share}
                  </MenuItem>

                  <MenuItem
                    disabled
                    className='menuItem'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ListItemIcon>
                      <Compare />
                    </ListItemIcon>
                    {this.props.multiLang.propertyCard.compare}
                  </MenuItem> */}
                </Menu>
              </Fragment>
            )}

            <div className="card__title-wrapper">
              <h2
                className="card__title"
                onDoubleClick={this.handleDoubleClickTitle}
              >
                {this.state.title}
              </h2>
            </div>

            {this.isExpandable() && ( // dont show expand icon if we are in expand chart
              <IconButton
                onClick={this.expand}
                style={{ padding: "0px", marginLeft: "5px" }}
              >
                {!this.state.isExpand && <IconExpand />}
                {this.state.isExpand && <IconCollapse />}
              </IconButton>
            )}

            <IconButton onClick={this.close} style={{ padding: "0px" }}>
              <Close />
            </IconButton>
          </header>
          <ScrollArea smoothScrolling>
            <div>
              {this.buildChild()}
              {this.props.children}
            </div>
          </ScrollArea>
        </section>
      </Rnd>
    );
  }

  close(event) {
    if (this.props.options.close) {
      this.props.options.close();
    }
    if (this.props.closeCard) this.props.closeCard(this.props.id);
    else console.error("Need function closeCard");
  }
}

const mapStateToProps = (state) => ({
  closeCard: state.card.closeCard,
  project: state.project,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  s3: state.auth.s3,
  config: state.config,
  mainViewer: state.mainViewer,
  userId: state.auth.userId,
  projectId: state.project.projectId,
  modelId: state.project.modelId,
  revision: state.project.revision,
  viewerName: state.project.viewer,
  projectName: state.project.name,
  modelName: state.project.modelName,
  viewers: state.mainViewer.viewers,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames
});

export default connect(mapStateToProps)(GenericCard);
