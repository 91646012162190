/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

export const fr = {
  project: {
    seeModels: "Voir Modèles",
    accessPrjError: "Aucun projet affecté à cet utilisateur"
  },
  navBar: {
    logo: "PPV3D",
    profil: "Profil",
    search: "Rechercher...",
    projects: "Projets",
    files: "Fichiers",
    account: "Compte",
    logout: "Se déconnecter",
    logoutMessage: "Déconnexion...",
    queryObjError: "Une erreur survenue lors de chargement des données",
    userRoleError: "Une erreur survenue lors de l'affihage des users/rôles",
    notificationError:
      "Une erreur survenue lors de l'affichage des notifications",
    archiveNotificationError:
      "Une erreur est survenue lors de l'archivage de la notification, merci de réessayer plus tard",
    removeFavouriteTagError:
      "Une erreur est survenue lors de la suppression du tag favori, merci de réessayer plus tard",
    addFavouriteTagError:
      "Une erreur est survenue lors de l'ajout du tag favori, merci de réessayer plus tard",
    addFavouriteTagSuccess: "Le tag a été ajouté en favori avec succès",
    removeFavouriteTag: "Supprimer le tag favori",
    unread: "Notification non lue",
    archive: "Effacer la notification",
    noNotification: "Vous n'avez aucune notification",
    noUserTagFavourite: "Vous n'avez aucun tag favori",
    switchViewHeader: "Changer de vue",
    noSwitchView: "Le tag selectionné n’est present dans aucune autre vue",
    switchViewModal: "Changement de vue",
    chooseViewToReplace: "Choisissez la vue à remplacer :",
    cancelButton: "Annuler",
    switchViewButton: "Remplacer",
    geoserverViewer: "Vue Cartographique",
    eventViewer: "Vue des événements",
    notifications: "Notifications",
    roleOfProject: "Rôle Projet",
    user: "Utilisateur",
    favourites: "Favoris",
    administration: "Administration"
  },
  search: {
    labelAdvanceSearch: "Recherche Avancée",
    labelSimpleSearch: "Recherche",
    Error: "Erreur :",
    help: "Aide : Classe.Attribue Operator Valeur",
    example: "Example : Pompe.Pression > 20",
    minimize: "Minimiser",
    close: "Fermer",
    fullScreen: "Plein ecran",
    selectAll: "Sélectionner tout",
    deselectAll: "désélectionner tout",
    exportAll: "exporter tout",
    fitView: "Zoomer",
    showOnly: "Isoler",
    hide: "Cacher",
    confirmSelectAll:
      "Êtes-vous sûr de vouloir appliquer cette opération aux %number% objets ?",
    confirmSelectAllTitle: "Confirmation de la sélection large",
    confirmButton: "Oui",
    cancelButton: "Annuler",
    confirmExportAllTitle: "Confirmation de l'export large",
    focusOnObject: "Impossible de zoomer",
    isolateObject: "Impossible d'isoler les objets",
    hideObjects: "Impossible de cacher les objets",
    loadAllObject: "Impossible de charger tout les objets",
    itemsSelected: "Elément(s) sélectionné(s)",
    select: "Sélectionner"
  },
  semanticSearch: {
    operators: [
      {
        label: "Egal",
        code: "eq"
      },
      {
        label: "Supérieur",
        code: "gt"
      },
      {
        label: "Inférieur",
        code: "lt"
      },
      {
        label: "Supérieur ou égal",
        code: "ge"
      },
      {
        label: "Inférieu ou égal",
        code: "le"
      }
    ],
    cancel: "Annuler",
    save: "Sauvegarder",
    visualize: "Visualiser",
    run: "Executer",
    load: "Charger",
    selectClass: "Selectionner Classe",
    selectAttribute: "Selectionner Attribut",
    selectRelation: "Selectionner Relation",
    attribute: "Attribut"
  },
  projectFile: {
    checkProjectMsg: "Veuillez choisir un projet dans l'onglet projets.",
    fileNameCol: "Nom du fichier",
    fileId: "ID du fichier",
    action: "Action",
    revision: "Révision",
    state: "Etat",
    dataType: "Type de données",
    dataSetCol: "Groupe de données",
    stateCol: "Etat",
    refresh: "Rafraîchir",
    upload: "Importer",
    uploadFile: "Importer un fichier",
    btnUploadFile: "Importer",
    uploadNewFile: "Importer un fichier",
    documentsUpload: "Importer un document",
    open: "Ouvrir",
    see: "Voir",
    update: "Modifier",
    delete: "Supprimer",
    download: "Télécharger",
    more: "Plus",
    uploadType: "Choisir le type de téléchargement",
    model1D: "modèle 1D",
    model2D3D: "modèle 2D or 3D",
    chooseFile: "Choisir un fichier",
    chooseViewer: "Choisir un viewer",
    selectModel: "Sélectionner un modèle",
    selectDataset: "Sélectionner une dataSet",
    selectMapping: "Sélectionner un mapping",
    uploadfileError: "Une erreur survenue lors du téléchargement du fichier",
    downloadfileError: "Une erreur survenue lors du téléchargement du fichier",
    uploadOf: "Téléchargement de ",
    uploadComplete: " est terminé",
    projectDetailsError:
      "Une erreur survenue lors de l'affichage des détails du projet",
    defaultModelError:
      "Une erreur survenue lors de l'enregistrement du project",
    loadFileError: "Une erreur survenue lors du chargement du fichier",
    search: "Rechercher",
    viewColumns: "Voir colonnes",
    filterTable: "Filtrer",
    showColumns: "Afficher les colonnes",
    print: "Imprimer",
    rowsPerPage: "Ligne par page",
    all: "Tout",
    filters: "FILTRES",
    reset: "EFFACER",
    notMatchingRecord: "Désolé, aucun enregistrement correspondant trouvé",
    sort: "Trier",
    deleteFunction:
      "La fonction de suppression sera disponible dans la prochaine version",
    projectFile: "Déposer des fichiers ...",
    chooseRevision: "Choisissez la révision",
    modify: "Modifier",
    seeMore: "Voir plus",
    dateFormatISO: "DD/MM/YYYY HH:mm",
    dateCol: "Date",
    selectRevision: "Revision",
    DragNDropFile: "Déposer le fichier ici ou cliquer",
    DropHere: "Déposer ici",
    datasourceId: "Numéro de source de donnée",
    settings: "Paramètres",
    article: "de",
    breakdowns: "Arborescences",
    name: "Nom",
    description: "Description",
    default: "Par défaut ?",
    yes: "Oui",
    rootNode: "Tag filtre par défaut",
    errorWhileSavingOptions:
      "Une erreur est survenue lors de la sauvegarde des options",
    errorWhileSavingSiteName:
      "Une erreur est survenue lors de la sauvegarde du nom du site",
    siteName: "Nom du site",
    fileProperties: "Les propriétés du fichier",
    updateSiteName: "Modifier le nom du site",
    deleteFile: "Supprimer le fichier",
    confirmation: "Confirmation",
    deleteConfirmationText:
      "Êtes-vous sûr de vouloir supprimer le fichier sélectionné?",
    fileDeleteSuccess: "Le fichier a été supprimé avec succès",
    fileDeleteError: "Une erreur survenue lors de la supression du fichier"
  },
  mainViewer: {
    projectDetailsError:
      "Une erreur survenue lors de l'affichage des détails du projet",
    modelDetailsError:
      "Une erreur survenue lors de l'affichage des détails du modèle",
    reviewDetailsError:
      "Une erreur survenue lors de l'affichage des détails du viewer",
    viewerLoading: "Chargement du viewer...",
    switchButton: "Changer de vue"
  },
  genericCard: {
    saveMarkupError: "Une erreur survenue lors de l'enregistrement du markup",
    markupSaved: "Markup enregistré",
    saveStatusError: "Une erreur survenue lors de l'enregistrement de l'état",
    statusChanged: "Etat du review a changé"
  },
  forgeViewer: {
    urnInvalide: "URN invalide !",
    urnInvalideMessage:
      "Impossible d'afficher le viewer 3D car l'URN n'est pas valide.",
    getRevisionError:
      "Une erreue est survenue lors de la recupération des révisions du modèle",
    closeMarkup: "Fermer l'annotation"
  },
  forgeViewerHelper: {
    authenticateError: "Erreur d'authentification à Forge"
  },
  splitViewer: {
    closePanel: "Etes-vous sûr de vouloir retirer cette vue ?",
    remove: "Retirer",
    cancel: "Annuler"
  },
  viewer: {
    viewerError: "Can't open viewer"
  },
  eventViewer: {
    eventStatus: {
      Inprogress: "En cours",
      Pending: "En attente",
      All: "Tous",
      Closed: "Clôturé"
    },
    severityValue: {
      informational: "Information",
      low: "Bas",
      medium: "Moyen",
      high: "Elevé",
      critical: "Critique"
    },
    takeCharge: "PRENDRE EN CHARGE",
    origin: "Origine",
    location: "Localisation",
    description: "Description",
    severity: "Severité",
    archive: "Archiver",
    close: "Clôturer",
    procedure: "Procédure",
    cancel: "Annuler",
    send: "Envoyer",
    interventionRequest: "Demande d'intervention",
    requestId: "ID",
    requestName: "Libellé",
    priority: "Priorité",
    requestPriority: {
      High: "Haute",
      Medium: "Moyenne",
      Low: "Basse"
    },
    requestStatusList: {
      InProgress: "En cours",
      Pending: "En attente",
      Closed: "Clôturé"
    },
    requestStatus: "Statut",
    userInCharge: "Responsable",
    search: "Recherche",
    rowsPerPage: "Ligne par page",
    noRecords: "Pas de résultat",
    addRequest: "Créer une nouvelle demande",
    edit: "Modifier",
    interventionType: "Type d'intervention",
    address: "Adresse de l'intervention",
    save: "Sauvegarder"
  },
  propertyCard: {
    add: "Ajouter",
    sensorData: "Données du Capteur",
    addThreshold: "Ajouter un seuil",
    unableOpenContentCard: "Impossible d'ouvrir la content card",
    selectMultipleError:
      "Selection multiple non authorisé avec la Property Card. Choisir un élément unique svp.",
    notElementSelectError: "Choisir un élément svp.",
    objectAlreadyAttached: "Cet objet est déjà rattaché au tag ",
    tagAttachedSuccess: "Tag attaché avec succès",
    tagAttachedError: "Tag non attaché, erreur",
    tagDetachSuccess: "Tag detaché avec succès",
    tagDetachError: "Tag non detaché, Erreur",
    addSensor: "ATTACHER",
    addSensorThreshold: "Ajouter un seuil de capteur",
    sensorLabel: "Capteur",
    tag: "Tag",
    attachSensor: "Lier un capteur",
    unAttach: "Détacher un capteur",
    close: "Fermer",
    errorAddSensor: " Erreur lors de l'ajout du capteur",
    successAddSensor: "Capteur ajouté avec succès",
    unableToOpenDocument: "Impossible d'ouvrir le document",
    errorToggleStateConnectedPlug:
      "Impossible de changer l'état de la prise connectée",
    successToggleConnectedPlug: "Etat de la prise connectée changé avec succès",
    methodsOfUpload: "Methodes pour spécifier les fichiers à charger...",
    comparator: "Comparateur",
    value: "Valeur",
    timeLimit: "Pendant les dernières (minutes)",
    creationDate: "Date de création",
    errorAddingThreshold: "erreur lors de l'ajout du seuil",
    newThresholdCreated: "Nouveau seuil créé",
    errorWhenRetrievingThreshold: "Erreur lors de la récupération des seuils",
    errorWhenDeletingThreshold: "Erreur lors de la suppression des seuils",
    deleteThreshold: "Seuil supprimé",
    lastHour: "Dernière heure",
    lastDay: "Dernier jour",
    lastMonth: "Dernier mois",
    duringLastMinute: "Pendant les dernières (minutes)",
    equalTo: "Egal à",
    greaterThan: "Supérieur à",
    lowerThan: "Inférieur à",
    triggerWhenValueIs: "Déclencher quand la valeur est",
    thresholds: "Seuils",
    properties: "Propriétés",
    relatedItems: "Articles connexes",
    graph: "Graphe",
    documents: "Documents",
    monitoring: "Monitoring",
    supportedFormat: "Formats pris en charge",
    noDataSensor: "Pas de données du capteur",
    noAttributes: "Il n'y a pas d'attribut associé à cette balise.",
    showThresholds: "Afficher les seuils",
    create: "CRÉER",
    expand: "Etendre",
    downloadCsv: "Télécharger en CSV",
    periode: "Période",
    compare: "Comparer",
    share: "Partager",
    addToFavourites: "Ajouter aux favoris",
    relation: "Relation",
    showEmptyAttributes: "Afficher les attributs vides",
    unrelatedObject: "L'objet sélectionné n'est pas lié à un tag",
    nonRenewedEquipment: "Attention, équipement sorti et non renouvelé",
    attachedOutOfServiceEquipment:
      "Attention, équipement sorti et attaché à un objet 3D pourtant il a été renouvelé"
  },
  createMarkupCard: {
    title: "Créer une annotation",
    titleLabel: "Titre",
    titleHolder: "Titre",
    descriptionLabel: "Description",
    descriptionHolder: "Saisir une description",
    priorityLabel: "Priorité",
    cancelButton: "ANNULER",
    openButton: "OUVRIR",
    saveButton: "SAUVEGARDER"
  },
  breakdownCard: {
    selectBreakdown: "Sélectionner une arborescence",
    selectRootTag: "Sélectionner un tag de filtre",
    showOnlyActive: "Masquer les sortis"
  },
  markup: {
    review: "Revue",
    annotation: "Annotation",
    createMarkup: "Créer une annotation",
    viewMarkup: "Liste des annotations",
    startReview: "Démarrer Revue",
    arrowMarkup: "Flèche",
    circleMarkup: "Cercle",
    cloudMarkup: "Nuage",
    freehandMarkup: "A main levée",
    hightlightMarkup: "Surligner",
    penMarkup: "Stylo",
    polylineMarkup: "Polyligne",
    rectangleMarkup: "Rectangle",
    textMarkup: "Text",
    askDesignReview:
      "Êtes-vous sûr de vouloir demander une revue de conception ? ",
    startReviewError: "Une erreur survenue lors du démarrage de la revue",
    reviewStarted: "Revue démarrée",
    exportAsBCF: "Télécharger en BCF",
    reviewStatus: {
      INITIAL: "NON DEMARRÉE",
      UNDER_REVIEW: "EN COURS",
      REJECTED: "REJETÉE",
      VALIDATION: "VALIDÉE"
    }
  },
  navigationViewCard: {
    modelsArchitectural: "Modèles Architectural",
    model3D: "Modèle 3D",
    model2D: "modèle 2D"
  },
  viewMarkup: {
    title: "Détail Markup ",
    titleLabel: "Titre",
    typeLabel: "Type",
    statusLabel: "Etat",
    labelLabel: "Label",
    descriptionLabel: "Description",
    priorityLabel: "Priorité",
    commentLabel: "Commentaire",
    commentHolder: "Répondez ici.",
    listMarkups: "Liste des annotations",
    loadingMarkupError: "Erreur de chargement des markups",
    publish: "PUBLIER",
    discussion: "Discussion",
    BCFButton: "BCF",
    rejectButton: "REJETER",
    validateButton: "VALIDER",
    open: "Ouvrir annotation",
    close: "Fermer annotation",
    view: "VOIR",
    viewpoint: "VUE PERSPECTIVE",
    updateStatusError:
      "erreur lors de la mise à jour du statut de l'annotation",
    updateStatusSuccess: "mise à jour du statut de l'annotation réussie",
    generateBCFError: "Erreur de génération du fichier BCF: ",
    generateBCFSucces: "Fichier BCF est généré avec succès",
    idCard: "Carte d'identité",
    from: "De",
    nextRevision: "Prochaine révision",
    previousRevision: "Choisir révision",
    validated: "VALIDATED",
    rejected: "REJETEE",
    deleteMarkupError: "Erreur lors de la suppression de l'annotation",
    deleteMarkupSuccess: "L'annotation a été supprimée avec succès ",
    creator: "Créé par",
    creationDate: "Date de creation"
  },
  annotation: {
    selectA: "Sélectionner ",
    priority: "Priorité"
  },
  comment: {
    saveCommentError:
      "Une erreur survenue lors de la sauvegarde du commentaire",
    commentSaved: "Commentaire sauvegardé",
    addComment: "ajouter un commentaire ...",
    reply: "REPONDRE"
  },
  fileUpload: {
    title1D: "Chargement 1D",
    title2D3D: "Chargement 2D/3D",
    titleDocument: "Chargement Document(s)",
    chooseFile: "Nom du document",
    errorWhileRetrievingMapping:
      "erreur lors de la récupération des règles de mappage",
    errorNonValidExtension: "Extension non prise en charge",
    errorSelectValidModel: "Selectionner un fichier 3D/2D valide"
  },
  toolbar: {
    firstPerson: "1ere Personne",
    measure: "Mesure",
    clipping: "Section",
    explosion: "Explosion",
    breakdown: "Arborescence",
    xPane: "axe X",
    yPane: "axe Y",
    zPane: "axe Z",
    box: "Volume",
    reports: "Rapports",
    vExplode: "Eclatement",
    models: "Modèles",
    details: "Détails",
    browser: "Navigateur",
    share: "Partager"
  },
  attachSensor: {
    connectedPlug: "Prise connectée",
    sensor: "Capteur"
  },
  app: {
    lang: "FR"
  },
  thresholdComparator: {
    equalTo: "égale à",
    greaterThan: "supérieure à",
    lowerThan: "inférieure à"
  },
  notification: {
    messageNotifNewReview: "Vous avez un modèle à approuver",
    messageNotifRejectedReview: "Votre modèle a été rejeté",
    messageNotifValidatedReview: "Votre modèle a été approuvé",
    messageNotifIot:
      "La valeur du capteur de %SensorType%: %DeviceId% associé à %Tag% est %ThresholdComparator% %ThresholdValue%",
    messageNotifImport: "L'import du fichier %fileName% est terminé"
  },
  sensor: {
    type: {
      pressure: "pression",
      temperature: "température",
      presence: "présence",
      healthcheck: "panne"
    }
  },
  administration: {
    confirmPassword: "Confirmer Mot de passe",
    newPassword: "Nouveau Mot de passe",
    actualPassword: "Mot de passe Actuel",
    company: "Entreprise",
    password: "Mot de passe",
    login: "Login",
    changePassword: "Changer le Mot de passe",
    userProfile: "Profil",
    userSettings: "Paramètres utilisateur",
    createProjectDescription:
      "Créer un projet et l'assigner à un administrateur de projet",
    projects: "Projets",
    users: "Utilisateurs",
    breakdowns: "Arborescences",
    featureZone: "Gestion des droits",
    rightsCategory: {
      administration: "Administration",
      importAndDownload: "Import et Téléchargement",
      designReview: "Design Review",
      sensors: "Capteurs",
      linkingTags: "Raccordement de tags"
    },
    rights: {
      ValidateRejectDesignReview: "Valider ou Rejeter une Revue",
      "Upload1D/2D/3D": "Import",
      CreateFolder: "Création des dossiers",
      CreateCommentOnMarkup: "Création de commentaires sur les annoations",
      AdministrateProject: "Administration de projet",
      MonitoringImport: "Suivi des imports",
      CreateSensorThreshold: "Création de seuils",
      CreateMarkup: "Création d'annotation",
      Upload2D3D: "import 2D/3D",
      DownloadBCF: "Téléchargement BCF",
      AskForDesignReview: "Deamnde de Revue",
      UploadMedia: "Import de Media",
      AttachSensor: "Ajouter un capteur",
      DownloadFiles: "Téléchargement de fichiers",
      DownloadMedia: "Télécharger un Media",
      LinkUnlink: "Associer / Dissocier"
    },
    createFeatureZoneDescription:
      "Créer une zone de droits fonctionnels et l'affecter à un groupe",
    createFeatureZone: "Créer une zone de droits",
    defaultBreakdownStructure: "Arborescence par défaut",
    breakdownRootTagFilter: "Filter par tag de départ",
    createBreakdownDescription: "Gérer la structure d'arborescence du projet",
    createUserDescription:
      "Créer un utilisateur et l'assigner à projet et à un groupe",
    createGroupDescription:
      "Créer un groupe dans un projet et y ajouter des utilisateurs",
    description: "Description",
    name: "Nom",
    phone: "Téléphone",
    lastName: "Nom",
    firstName: "Prenom",
    projectId: "Id Projet",
    groupId: "Id groupe",
    groups: "Groupes",
    filtered: "Filtré",
    default: "Défaut",
    yes: "Oui",
    no: "Non",
    actions: "Actions",
    createProject: "Créer un projet",
    createGroup: "Créer un groupe",
    createUser: "Créer un utilisateur",
    editProject: "Modifier un projet",
    editUser: "Modifier un utilisateur",
    editBreakdown: "Modifier la structure d'arborescence",
    createBreakdown: "Créer une structure d'arborescence",
    edit: "Modifier",
    add: "Ajouter",
    save: "Sauvegarder",
    cancel: "Annuler",
    assign: "Assigner",
    userList: "Utilisateurs",
    creationDate: "Date de creation",
    email: "Email",
    language: {
      lang: "Langues",
      chooseLanguage: "Choisir une langue",
      en: "Anglais",
      fr: "Français",
      ok: "OK"
    },
    projectDetail: "Detail du projet",
    groupDetail: "Détail du groupe",
    adminSettings: "Outils d'administration",
    projectManagement: "Management de projet",
    selectClass: "Sélectionner une classe",
    selectRelationship: "Sélectionner une relation",
    classes: "Classes",
    relationships: "Relations",
    relationshipsToFollow: "Relations à suivre à partir de l'élement parent",
    specificClassExpansion: "Expansion spécifique à la classe",
    passwordValidation: "Le mot de passe doit contenir 6 caractères minimum",
    passwordNotIdentical: "Mot de passe non identique",
    notificationTitle: "Centre des notifications",
    loginTitle: "Informations de connexion",
    userInformation: "Informations utilisateur",
    errorPhone: "Numéro invalide",
    errorEmail: "Email invalide",
    notificationMail: "Email",
    notificationSMS: "SMS",
    notificationImport: "Import",
    notificationReview: "Revue",
    notificationIOT: "IOT",
    administratorOf: "Administrateur du projet",
    status: "Statut",
    user: "Utilisateur",
    projectAdministrators: "Administrateurs projet",
    removeProjectsDialog: {
      removeProjects:
        "Etes-vous sûr de vouloir supprimer le(s) projet(s) selectionné(s)?",
      remove: "Supprimer",
      cancel: "Annuler"
    },
    errors: {
      projectCreationFailed: "Erreur lors de la création du projet",
      projectRetrievalFailed:
        "Erreur lors de la récupération de la liste des projets",
      projectUpdateFailed: "Erreur lors de la mise à jour du projet",
      projectRemovalFailed: "Erreur lors de la suppression du projet",
      groupCreationFailed: "Erreur lors de la création du groupe",
      groupRetrievalFailed:
        "Erreur lors de la récupération de la liste des groupes",
      groupRemovalFailed: "Erreur lors de la suppression du groupe",
      userAddFailed: "Erreur lors de l'ajout de l'utilisateur",
      userRemovalFailed: "Erreur lors de la suppression de l'utilisateur",
      userRetrievalFailed:
        "Erreur lors de la récupération de la liste des utilisateurs",
      breakdownCreationFailed:
        "Erreur lors de la création de la structure d'arborescence",
      breakdownRemovalFailed:
        "Erreur lors de la suppression de la structure d'arborescence",
      breakdownUpdateFailed:
        "Erreur lors de la mise à jour de la structure d'arborescence",
      passwordChangeFailed: "Erreur lors du changement du mot de passe",
      updateAttributesFailed:
        "Erreur lors de la modification des informations personnelles",
      getUserInformationFailed:
        "Erreur lors de la recuperation des informations utilisateur",
      userCreationFailed: "Erreur lors de la création de l'utilisateur",
      userDeletionFailed: "Erreur lors de la création de l'utilisateur",
      emptyLogin: "Login non renseigné",
      invalidEmail: "Email invalide",
      emptyPassword: "Mot de passe non renseigné",
      invalidPhoneNumber: "Numéro de téléphone invalide",
      featureZoneCreationFailed:
        "Erreur lors de la création de la zone de droit",
      featureZoneGetFailed: "Erreur lors de la récupération des zone de droits",
      featureZoneUpdateFailed:
        "Erreur lors de la mise à jour de la zone de droit",
      featureZoneDeleteFailed:
        "Erreur lors de la suppression de la zone de droit"
    },
    success: {
      projectCreationSucceeded: "Projet créé avec succès",
      projectRemovalSucceeded: "Projet supprimé avec succès",
      projectUpdateSucceeded: "Projet mis à jour avec succès",
      groupCreationSucceeded: "Groupe créé avec succès",
      groupRemovalSucceeded: "Groupe supprimé avec succès",
      userAddSucceeded: "utilisateur ajouté avec succès",
      userRemovalSucceeded: "Utilisateur supprimé avec succès",
      breakdownCreationSucceeded: "structure d'arborescence créée avec succès",
      breakdownRemovalSucceeded:
        "structure d'arborescence supprimée avec succès",
      breakdownUpdateSucceeded:
        "structure d'arborescence mise à jour avec succès",
      changePasswordSucceeded: "Mot de passe changé avec succès",
      updateAttributesSucceeded: "Informations personnelles mises à jour",
      userCreationSucceeded: "L'utiliateur a été créé avec succès",
      userDeletionFailed: "L'utiliateur a été supprimé avec succès",
      featureZoneCreationSucceeded: "La zone de droit a été créée avec succés",
      featureZoneUpdateSucceeded:
        "La zone de droit a été mis à jour avec succés"
    }
  },
  datasources: {
    importListPageTitle: "Imports",
    datasourceListPageTitle: "Fichiers",
    import: {
      importFileName: "Nom du fichier",
      importDate: "Date d'import",
      startDate: "Date de début d'import",
      endDate: "Date de fin d'import",
      version: "Version",
      state: "Statut",
      dataType: "Type",
      importId: "Numéro d'import",
      datasourceId: "Numéro de source de donnée",
      total: "Nombre de lignes",
      totalSuccess: "... en succès",
      totalError: "... en erreur",
      detailListButton: "Détails",
      steps: {
        FINISH: "Terminé",
        FINISHED_ERROR: "Erreur",
        FINISHED_WARNING: "Terminé avec alertes",
        FINISHED_SUCCESS: "Succès",
        "UPDATING INFOCARD": "Mise à jour des fiches d'information",
        CONVERTING: "Conversion",
        DYNAMO_LOADER: "Chargement des fiches d'informations",
        ELASTIC_LOADER: "Chargement de la recherche",
        SVF_CONNECTOR: "Conversion",
        SYNC_CONNECTOR: "Synchronisation des données",
        description: {
          FINISH: "Terminé",
          FINISHED_ERROR:
            "Une erreur technique a interompu l'import, ou tous les tags sont en erreur",
          FINISHED_WARNING: "Au moins un tag n'a pas été importé",
          FINISHED_SUCCESS: "Tous les tags ont été importés",
          "UPDATING INFOCARD": "Mise à jour des fiches d'information",
          CONVERTING: "Conversion du modèle 3D",
          DYNAMO_LOADER: "Chargement des fiches d'informations",
          ELASTIC_LOADER: "Chargement de la recherche",
          SVF_CONNECTOR: "Extraction des données du modèle 3D",
          SYNC_CONNECTOR: "Synchronisation des données"
        }
      }
    },
    errors: {
      importLoad: "Erreur pendant le chargement de la liste des imports",
      importDetailLoad: "Erreur pendant le chargement du detail de l'imports"
    }
  },
  progress: {
    loadError: "Erreur pendant le chargement de l'état des imports"
  },
  importDetails: {
    importDate: "Date d'import",
    filename: "Nom du fichier",
    line: "Ligne",
    status: "Statut",
    tag: "Tag",
    message: "Message",
    sourceAttribute: "Attribute source",
    error: "Erreur pendant le chargement du detail de l'imports",
    detailListButton: "Détails",
    category: "Catégorie",
    component: "Composant"
  },
  breakdownPanel: {
    noBreakdown: "Votre projet n’a pas d’arborescence pour le moment.",
    notVisibleInViewer:
      "L'objet selectionné n'est pas visible dans la vue actuelle."
  },
  attachTag: {
    class: "Classe",
    all: "Tous",
    detached: "Détaché",
    attached: "Attaché",
    linking: "Raccordement Tags",
    attach: "Raccorder tag",
    detach: "Déraccorder tag"
  },
  sharePanel: {
    title: "Partage externe du modèle",
    viewerLink: "Lien vers le Viewer",
    copyInfos: "Copier toutes les informations"
  }
};
