/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { connect } from 'react-redux';
import {
  Select,
  MenuItem,
  List,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  TextField,
  Switch,
  FormControlLabel,
  Typography

} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { getBreakdown, getBreakdowns } from 'services/AssetService';
import ExpansionRecursion from './ExpansionRecursionConnected';
import { projectAction } from 'actions/ProjectAction';
import { breakdownAction } from 'actions/BreakdownAction';
import PropTypes from 'prop-types';
import { constructTagLabel } from 'utils/TagHelper';

class BreakdownPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breakdowns: [],
      selectedBreakdown: {},
      displayedBreakdownData: {},
      displaySearch: false,
      searchValue: '',
      defaultRootTag: '',
      showOnlyActive: true
    };
  }

  componentDidMount() {
    getBreakdowns(this.props.project.projectId)
      .then((data) => {
        let breakdowns = data.data.result;
        // Retrieve the last selected Breakdown structure from the store
        let selectedBreakdown = this.props.project.selectedBreakdown;
        // If it does not exist or we do not have breakdown data in store, we load the default breakdown structure
        if (!selectedBreakdown || !this.props.breakDownData) {
          selectedBreakdown = {};
          let defaultBreakdown = breakdowns
            .filter((breakdown) => breakdown.Default === true)
            .pop();
          if (
            defaultBreakdown &&
            defaultBreakdown.RootClass &&
            defaultBreakdown.RootClass.length > 0
          ) {
            selectedBreakdown = defaultBreakdown;
          }
        }
        this.setState({ breakdowns: data.data.result });
        this.setState({ selectedBreakdown });
        this.loadSelectedBreakdown(selectedBreakdown);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: projectAction.updateSelectedBreakdown,
      selectedBreakdown: this.state.selectedBreakdown
    });
  }

  handleChange = (event) => {
    this.setState({
      displaySearch: false,
      defaultRootTag: ''
    });
    this.loadSelectedBreakdown(event.target.value);
  };

  loadSelectedBreakdown(breakdownSelected) {
    getBreakdown(this.props.project.projectId, breakdownSelected.Name)
      .then(async (data) => {
        // Init breakdown data in the store
        this.props.dispatch({
          type: breakdownAction.initBreakdownData,
          breakDownData: data.data,
          breakDownOptions: {
            rootTagFilter: breakdownSelected.RootTagFilter || false
          }
        });
        // Reset Filtered Data
        this.setState({
          displayedBreakdownData: {},
          displaySearch: false
        });

        // Set default root tag if exist for the current breakdown. If not select the first tag of the list
        // Get File options
        let options = this.props.mainViewer.viewers.find(
          (v) => v.id === this.props.data.viewerId
        ).options;
        // Check if this breakdown has a default tag
        let defaultRootTag = (Object.keys(options).length > 0 && options.breakdownRootTag[breakdownSelected.Name]) ?
          options.breakdownRootTag[breakdownSelected.Name] : data.data[0][0].Tag;
        // Check tag exist in the list. If not fallback to the first tag of the list
        let tagIndex = Object.keys(data.data).findIndex(key => data.data[key][0].Tag === defaultRootTag);
        defaultRootTag = tagIndex >= 0 ? defaultRootTag : data.data[0][0].Tag;
        this.setState({ defaultRootTag });

        // If an object is already selected, open it in the structure
        if (this.props.focusedObjectsTagNames) {
          let breakdownData = data.data;
          // Search for the node and open the path to it
          for (var key in breakdownData) {
            let found = await this.SearchForTagAndOpenNode(key, breakdownData[key], this.props.focusedObjectsTagNames[0], 0);
            if (found && this.props.breakdownOptions.rootTagFilter) {
              // Change the default Tag to that who has the selected tag in his descendant
              defaultRootTag = breakdownData[key][0].Tag;
              this.setState({ defaultRootTag });
            }
          }
          // Set selected node in the store
          this.props.dispatch({
            type: breakdownAction.selectNode,
            selectedNode: this.props.focusedObjectsTagNames[0]
          });
          let element = document.getElementById(this.props.selectedNode);
          // Get root element
          let rootElement = document.getElementById(defaultRootTag);
          if (element && rootElement) {
            setTimeout(() => {
              // Caluculate distance to scroll
              const scrollTop = element.getBoundingClientRect().y - rootElement.getBoundingClientRect().y;
              this.context.scrollArea.refresh();
              this.context.scrollArea.scrollYTo(scrollTop);
            }, 1000);
          }

        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.setState({ selectedBreakdown: breakdownSelected });
  }

  async componentDidUpdate(prevProps) {
    // If an object is selected in the viewer, Select it in the structure
    if (!_.isEqual(prevProps.focusedObjectsTagNames, this.props.focusedObjectsTagNames) && !this.props.selectionFromAbs) {
      if (this.props.focusedObjectsTagNames) {
        let selectedTag = this.props.focusedObjectsTagNames[0];
        let breakdownData = this.props.breakdownData;
        let defaultRootTag = this.state.defaultRootTag;
        // Search for the node and open the path to it
        for (var key in breakdownData) {
          let found = await this.SearchForTagAndOpenNode(key, breakdownData[key], this.props.focusedObjectsTagNames[0], 0);
          if (found && this.props.breakdownOptions.rootTagFilter) {
            // Change the default Tag to that who has the selected tag in his descendant 
            defaultRootTag = breakdownData[key][0].Tag;
            this.setState({ defaultRootTag });
          }
        }
        this.props.dispatch({
          type: breakdownAction.selectNode,
          selectedNode: selectedTag
        });
        let element = document.getElementById(this.props.selectedNode);
        // Get root element
        let rootElement = document.getElementById(defaultRootTag);

        if (element && rootElement) {
          // Scroll the bar to the selected element
          setTimeout(() => {
            // Caluculate distance to scroll
            const scrollTop = element.getBoundingClientRect().y - rootElement.getBoundingClientRect().y;
            this.context.scrollArea.refresh();
            this.context.scrollArea.scrollYTo(scrollTop);

          }, 1000);
        }
      } else {
        this.props.dispatch({
          type: breakdownAction.unselectNode
        });
      }
    }
  }

  // Get all descendant Tags for a node (for selection purpose)
  getAllDescendantTags = (listId, nodeId) => {
    const data = this.props.breakdownData;
    const descendantIds = this.getAllDescendantIds(data, listId, nodeId);
    return descendantIds.map(id => data[listId][id].Tag);
  }

  getAllDescendantIds = (data, listId, nodeId) => (
    (data[listId][nodeId].Child) ? data[listId][nodeId].Child.reduce((acc, childId) => (
      [...acc, childId, ...this.getAllDescendantIds(data, listId, childId)]
    ), []) : []
  )
  // Search for tag and open the path to node
  SearchForTagAndOpenNode(listId, element, filterValue, nodeId) {
    if (element[nodeId].Tag.toLowerCase().includes(filterValue)) {
      return true;
    } else if (element[nodeId].Child && element[nodeId].Child.length > 0) {
      let result = false;
      for (let i = 0; result === false && i < element[nodeId].Child.length; i++) {
        result = this.SearchForTagAndOpenNode(listId, element, filterValue, element[nodeId].Child[i]);
      }
      if (result && !element[nodeId].open) {
        this.props.dispatch({
          type: breakdownAction.setNodeOpen,
          listId: listId,
          nodeId: nodeId,
          open: true
        });
      }
      return result;
    }
    return false;
  }

  searchTree(element, filterValue, nodeId) {
    if (element[nodeId].Tag.toLowerCase().includes(filterValue)) {
      return true;
    } else if (element[nodeId].Child && element[nodeId].Child.length > 0) {
      let result = false;
      for (let i = 0; result === false && i < element[nodeId].Child.length; i++) {
        result = this.searchTree(element, filterValue, element[nodeId].Child[i]);
      }
      return result;
    }
    return false;
  }

  //handle search result
  handleDynamicFilter = (event) => {
    let filterValue = event.target.value.toLowerCase();
    let updateDisplayedBreakdownData = {};
    let breakdownData = this.props.breakdownData;
    for (var key in breakdownData) {
      if (this.searchTree(breakdownData[key], filterValue, 0)) {
        updateDisplayedBreakdownData[key] = breakdownData[key];
      }
    }
    this.setState({
      displayedBreakdownData: updateDisplayedBreakdownData,
      searchValue: event.target.value
    });
  };

  handleOnlyActiveEquipment = (equipmentDisplayOption) => {
    this.setState({
      showOnlyActive: equipmentDisplayOption.target.checked
    });

  };

  // Order an array of tags
  compare = (a, b) => {
    if (a.TagLabel && b.TagLabel) {
      return (a.TagLabel.toLowerCase() > b.TagLabel.toLowerCase()) ? 1 : ((b.TagLabel.toLowerCase() > a.TagLabel.toLowerCase()) ? -1 : 0);
    } else {
      return (a.Tag > b.Tag) ? 1 : ((b.Tag > a.Tag) ? -1 : 0);
    }
  };

  render() {
    const { project } = this.props;
    const viewer = this.props.mainViewer.viewers.find(
      (v) => v.id === this.props.data.viewerId
    );
    const { selectedBreakdown, breakdowns, defaultRootTag } = this.state;

    if (breakdowns && breakdowns.length === 0) {
      return (
        <Fragment>
          <div style={{ margin: 20, width: '100%' }}>
            {this.props.multiLang.breakdownPanel.noBreakdown}
          </div>
        </Fragment>
      );
    }
    let changebgColor = false; //decide if we change the backgroundColor of a list item
    // Display all data or just the filtered data
    let data = (_.isEmpty(this.state.displayedBreakdownData)) ? this.props.breakdownData : this.state.displayedBreakdownData;
    // order tags filter
    let filterData = Object.keys(data).map(key => data[key][0]).sort(this.compare);
    // Get all listId from data. Filter the data if the root tag filter is true.
    let dataKeys = Object.keys(data).filter(key => !this.props.breakdownOptions.rootTagFilter || data[key][0].Tag === defaultRootTag);
    let nodes = dataKeys.map((dataKey, index) => {
      changebgColor = !changebgColor;
      return (
        <ExpansionRecursion
          key={index}
          listId={dataKey}
          nodeId={data[dataKey][0].id}
          projectId={project.projectId}
          depth={0}
          changebgColor={changebgColor}
          showed={true}
          viewer={viewer}
          selectedObjectId={this.props.selectedObjectId}
          getAllTagChild={this.getAllDescendantTags}
          showOnlyActive={this.state.showOnlyActive}
        ></ExpansionRecursion>
      );
    });
    return (
      <Fragment>
        <div
          style={{
            padding: 20,
            paddingBottom: 5,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <FormControl>
            <InputLabel htmlFor='select-multiple-checkbox'>
              {this.props.multiLang.breakdownCard.selectBreakdown}
            </InputLabel>
            <Select
              value={selectedBreakdown}
              onChange={this.handleChange}
              input={<Input id='select-multiple-checkbox' />}
              style={{ width: 250 }}
              displayEmpty
              renderValue={(selectedValue) => {
                return selectedValue && selectedValue.Name
                  ? selectedValue.Name
                  : this.props.multiLang.breakdownCard.selectBreakdown;
              }}
            >
              {breakdowns.map((breakdown, index) => (
                <MenuItem key={index} value={breakdown}>
                  {breakdown.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            this.props.breakdownOptions.rootTagFilter &&
            <FormControl>
              <InputLabel htmlFor='select-root-tag-filter'>
                {this.props.multiLang.breakdownCard.selectRootTag}
              </InputLabel>
              <Select
                style={{ width: 250 }}
                value={defaultRootTag}
                input={<Input id='select-root-tag-filter' />}
                onChange={e => this.setState({ defaultRootTag: e.target.value })}
              >
                {filterData.map((tag, index) => (
                  <MenuItem key={index} value={tag.Tag}>
                    {constructTagLabel(tag.Tag, tag.TagLabel)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          {this.state.displaySearch && (
            <FormControl>
              <TextField
                onChange={this.handleDynamicFilter}
                id='input-with-icon-textfield'
                value={this.state.searchValue}
                style={{ paddingTop: 15 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          )}
          <FormControlLabel
            style={{ paddingTop: 10 }}
            control={
              <Switch
                checked={
                  this.state.showOnlyActive
                }
                onChange={(equipmentDisplayOption) =>
                  this.handleOnlyActiveEquipment(
                    equipmentDisplayOption
                  )
                }
                value='checkedB'
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={
              <Typography>
                {this.props.multiLang.breakdownCard.showOnlyActive}
              </Typography>
            }
          />
        </div>
        <List>{nodes}</List>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  mainViewer: state.mainViewer,
  selectedObjectId: state.mainViewer.selectedObjectIds,
  breakdownData: state.breakdown.breakDownData,
  breakdownOptions: state.breakdown.breakDownOptions,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  selectionFromAbs: state.mainViewer.selectionFromAbs,
  selectedNode: state.breakdown.selectedNode,
});
BreakdownPanel.contextTypes = {
  scrollArea: PropTypes.object
};

export default connect(mapStateToProps)(BreakdownPanel);
