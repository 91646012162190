/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";
var jwtDecode = require('jwt-decode');
var signalR = require('@aspnet/signalr');

function getSensorHistory(deviceId, startDate, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlIotAPI + "/sensorHistory", {
        headers: headers,
        params: { deviceId: deviceId, startDate: startDate }
    }).then(function (response) {
        if (!response.data || Object.keys(response.data).length === 0)
            return callback(null, []);
        return callback(null, response.data);
    })
        .catch(function (error) {
            return callback(error);
        });
}


function addSensorThreshold(tag, deviceId, comparator, timeLimit, value, projectId, modelId, revision, viewer, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.post(config.urlIotAPI + "/addThreshold", {
            tag: tag,
            deviceId: deviceId,
            comparator: comparator,
            timeLimit: timeLimit,
            value: value,
            projectId: projectId,
            modelId: modelId,
            revision: revision,
            viewer: viewer
        },
        {headers: headers})
        .then(function (response) {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}


function getThresholds(deviceId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlIotAPI + "/getThresholds", {
        headers: headers,
        params: {deviceId: deviceId}
    }).then(function (response) {
        if (!response.data || Object.keys(response.data).length === 0)
            return callback("No data");
        return callback(null, response.data);
    })
        .catch(function (error) {
            return callback(error);
        });
}

function deleteThreshold(deviceId, comparator, value, callback) {

    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.delete(config.urlIotAPI + "/deleteThreshold", {
        headers: headers,
        data: {deviceId: deviceId, value: value, comparator: comparator}
    }).then(function (response) {
        if (!response.data || Object.keys(response.data).length === 0)
            return callback("No data");
        return callback(null, response.data);
    })
        .catch(function (error) {
            return callback(error);
        });
}

function actuator(action, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.post(config.urlIotAPI + "/sensorActuator", {
            action: action
        },
        {headers: headers})
        .then(function (response) {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

/**
 * @param {*} deviceId IoT deviceId to watch for changes
 * @return Return a websocket connection
 */
async function openConnection(deviceId) {
    var id_token = store.getState().auth.idToken;
    var webSocketUrl = store.getState().config.webSocketUrl;
        console.log("CLOUD: ",store.getState().config.cloud);
        if (store.getState().config.cloud === "AZURE") {
        var headers = store.getState().auth.headers;
        var decoded = jwtDecode(id_token);
        headers['x-ms-client-principal-name'] = decoded.sub;
        let resp = await axios.post(`${webSocketUrl}/iotSignalRInfo`, null,{ headers: headers });
        
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(resp.data.url, { accessTokenFactory: () => resp.data.accessToken })
            .build();
                connection.onclose(function() {})
                connection
                .start()
                .then(res => console.log(res))// Potential to do something on initial load)
                .catch(console.error)
        return connection;             

    }
    else{
        console.log("openning wss: " + webSocketUrl + "/iot?deviceIds=" + deviceId + "&Auth=" + id_token);
        return new WebSocket(webSocketUrl + "/iot?deviceIds=" + deviceId + "&Auth=" + id_token);
    }
}

export {
    getSensorHistory,
    addSensorThreshold,
    getThresholds,
    deleteThreshold,
    actuator,
    openConnection
};