import React, { Component } from "react";
import { store } from "utils/store";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import { getToken } from "services/ForgeService";

const Autodesk = window.Autodesk;
const VIEWER_LINK = "https://autodesk-platform-services.github.io/aps-tutorial-postman/display_svf.html";

class SharePanel extends Component {

    copyToClipboard(tocopy) {
        let forgeViewers = this.props.viewers.filter(
            (v) => v.viewerType === "FORGE_VIEWER"
        );
        if (tocopy === 'urn') {
            navigator.clipboard.writeText(forgeViewers[0].urn);
        }
        else if (tocopy === 'token') {
            navigator.clipboard.writeText(forgeViewers[0].lastToken);
        }
        else if (tocopy === 'viewer') {
            navigator.clipboard.writeText(VIEWER_LINK);
        }
        else {
            navigator.clipboard.writeText("Erreur de copie");
        }
    }

    copyAllInfo() {
        let forgeViewers = this.props.viewers.filter(
            (v) => v.viewerType === "FORGE_VIEWER"
        );
        let message = "";
        message += store.getState().multiLang.sharePanel.viewerLink + " : " + VIEWER_LINK + "\n \n";
        message += "URN : " + forgeViewers[0].urn + "\n \n";
        message += "Token : " + forgeViewers[0].lastToken;
        navigator.clipboard.writeText(message);

        var resturl = VIEWER_LINK;
    }

    getNewToken() {
        getToken((error, token) => {
            if (error) {
                console.error(error);
                return store
                  .getState()
                  .snackBar.addSnackError(
                    store.getState().multiLang.forgeViewerHelper.authenticateError
                  );
            }
            const options = Autodesk.Viewing.createInitializerOptions();
            options.env = "AutodeskProduction";
            options.language =
            localStorage.getItem("language") != null
                ? localStorage.getItem("language")
                : "en";
            options.getAccessToken = function (onSuccess) {
                onSuccess(token.access_token, token.expires_in);
            };
            let forgeViewers = this.props.viewers.filter(
                (v) => v.viewerType === "FORGE_VIEWER"
            );
            forgeViewers[0].lastToken = token.access_token;
        });
    }

    render() {
        let forgeViewers = this.props.viewers.filter(
            (v) => v.viewerType === "FORGE_VIEWER"
        );
        if (forgeViewers.length < 0) {
        console.log("Le modèle ne provient pas de forge, URN non disponible ");
        }
        this.getNewToken();
        return(
            <>
                <Grid
                container
                spacing={3}
                style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 30 }}
                ></Grid>
                <Grid item xs={12} style = {{paddingLeft: 10}}>
                    <Link href={VIEWER_LINK}>{store.getState().multiLang.sharePanel.viewerLink}</Link>
                    <label>
                        <Fab size="small" aria-label="copyURN" onClick={() => this.copyToClipboard('viewer')}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            height="24px" 
                            viewBox="0 0 24 24" 
                            width="24px" 
                            fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                        </svg>
                        </Fab>
                    </label>
                </Grid>
                <Grid item xs={12} style = {{paddingLeft: 10}}>
                    <TextField
                        id="outlined-multiline-static"
                        label="URN"
                        multiline
                        maxRows={2}
                        defaultValue={forgeViewers[0].urn}
                        style = {{width: '75%'}}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    <label>
                        <Fab size="small" aria-label="copyURN" onClick={() => this.copyToClipboard('urn')}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            height="24px" 
                            viewBox="0 0 24 24" 
                            width="24px" 
                            fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                        </svg>
                        </Fab>
                    </label>
                </Grid>
                <Grid item xs={12} style = {{paddingLeft: 10}}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Token"
                        multiline
                        maxRows={2}
                        defaultValue={forgeViewers[0].lastToken}
                        style = {{width: '75%'}}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    <label>
                        <Fab size="small" aria-label="copyTOKEN" onClick={() => this.copyToClipboard('token')}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            height="24px" 
                            viewBox="0 0 24 24" 
                            width="24px" 
                            fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                        </Fab>
                    </label>
                </Grid>
                <Grid item xs={12} style = {{paddingLeft: 10}}>
                <Fab variant="extended" size="small" aria-label="copyTOKEN" onClick={() => this.copyAllInfo()}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            height="24px" 
                            viewBox="0 0 24 24" 
                            width="24px" 
                            fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                        </svg>
                        {store.getState().multiLang.sharePanel.copyInfos}
                        </Fab>
                </Grid>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    project: state.project,
    multiLang: state.multiLang,
    snackBar: state.snackBar,
    mainViewer: state.mainViewer,
    viewers: state.mainViewer.viewers,
    selectedObjectId: state.mainViewer.selectedObjectIds,
    openCard: state.card.openCard,
    focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
    selectionFromAttachedTags: state.mainViewer.selectionFromAttachedTags
  });
  
  export default connect(mapStateToProps)(SharePanel);