/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormLabel,
  FormGroup,
  ListItemText,
  Checkbox,
  Grid,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Chip,
  FormControlLabel
} from '@material-ui/core';
import { getGroupsByProject } from 'services/ProjectService';
import { getAllRights } from 'services/FeatureZoneService';

class AddFeatureZone extends Component {
  state = {
    featureZoneName: '',
    featureDescription: '',
    groups: [],
    groupsSelected: [],
    rights: [],
    selectedRights: []
  };

  componentDidMount() {
    // get project groups
    getGroupsByProject(this.props.project.Id)
      .then((data) => {
        this.setState({ groups: data.data.result });
      })
      .catch((error) => {
        console.error(error);
      });

    // get all rights
    getAllRights()
      .then((result) => {
        this.setState({ rights: result.data });
      })
      .catch((error) => {
        console.error(error);
      });

    //if is edit mode init the feature zone
    if (this.props.edit) {
      this.setState({
        featureZoneName: this.props.featureZone.Name,
        featureDescription: this.props.featureZone.Description,
        groupsSelected: this.props.featureZone.Groups,
        selectedRights: this.props.featureZone.Rights
      });
    }
  }

  handelChange = (event, name) => {
    this.setState({ [name]: event.target.value });
  };

  createFeatureZone = () => {
    if (this.props.edit) {
      this.props.updateFeatureZone(
        this.state.featureZoneName,
        this.state.featureDescription,
        this.state.groupsSelected,
        this.state.selectedRights,
        this.props.featureZone.FeatureZoneId
      );
    } else {
      this.props.createFeatureZone(
        this.state.featureZoneName,
        this.state.featureDescription,
        this.state.groupsSelected,
        this.state.selectedRights
      );
    }
    this.props.onClose(false);
  };

  deleteGroupChips = (groupName) => {
    let groupSelected = [...this.state.groupsSelected];
    groupSelected = groupSelected.filter((element) => {
      return element.Name !== groupName;
    });
    this.setState({ groupsSelected: groupSelected });
  };

  handelSelectRights = (right) => {
    let updateSelectedRights = [...this.state.selectedRights];
    let index = updateSelectedRights.findIndex((element) => {
      return element.rightId === right.rightId;
    });
    if (index === -1) {
      updateSelectedRights.push(right);
    } else {
      updateSelectedRights.splice(index, 1);
    }

    this.setState({ selectedRights: updateSelectedRights });
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.props.isOpen}
          onClose={() => this.props.onClose(false)}
          aria-labelledby='simple-dialog-title'
          maxWidth={'md'}
          fullWidth={true}
          style={{ flexGrow: 0 }}
        >
          <DialogTitle id='form-dialog-title'>
            {this.props.multiLang.administration.createFeatureZone}
          </DialogTitle>
          <Paper style={{ padding: 20, margin: 10 }}>
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={this.state.featureZoneName}
                    name='featureZone'
                    id='standard-name'
                    label={this.props.multiLang.administration.name}
                    fullWidth={true}
                    type='text'
                    onChange={(e) => this.handelChange(e, 'featureZoneName')}
                    disabled={this.props.edit}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    value={this.state.featureDescription}
                    type='text'
                    name='breakdownDescription'
                    id='standard-name'
                    label={this.props.multiLang.administration.description}
                    fullWidth={true}
                    onChange={(e) => this.handelChange(e, 'featureDescription')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className='formControl' fullWidth={true}>
                    <InputLabel htmlFor='select-multiple-chip'>
                      {this.props.multiLang.administration.groups}
                    </InputLabel>
                    <Select
                      multiple
                      value={this.state.groupsSelected}
                      onChange={(e) => this.handelChange(e, 'groupsSelected')}
                      input={<Input id='select-multiple-checkbox' />}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value, index) => (
                            <Chip
                              key={value.ZoneId + index}
                              label={value.Name}
                              onDelete={() => this.deleteGroupChips(value.Name)}
                              style={{ marginRight: 5 }}
                              color='primary'
                            />
                          ))}
                        </div>
                      )}
                    >
                      {this.state.groups.map((group) => (
                        <MenuItem key={group.ZoneId} value={group}>
                          <Checkbox
                            color='primary'
                            checked={
                              this.state.groupsSelected.find((element) => {
                                return element.Name === group.Name;
                              }) !== undefined
                            }
                          />
                          <ListItemText primary={group.Name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Paper>

          <Paper
            style={{ padding: 20, margin: 10, flexGrow: 0, flexWrap: 'wrap' }}
          >
            <Grid container spacing={4}>
              {this.state.rights.map(
                (category) =>
                  this.props.multiLang.administration.rightsCategory[
                    category.categoryId
                  ] && (
                    <Grid item xs={12} sm={4}>
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>
                          {
                            this.props.multiLang.administration.rightsCategory[
                              category.categoryId
                            ]
                          }
                        </FormLabel>
                        <FormGroup>
                          {category.categoryRights.map((right) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  value={right.rightName}
                                  onChange={() =>
                                    this.handelSelectRights(right)
                                  }
                                  checked={
                                    this.state.selectedRights.find(
                                      (element) => {
                                        return (
                                          element.rightId === right.rightId
                                        );
                                      }
                                    ) !== undefined
                                  }
                                />
                              }
                              label={
                                this.props.multiLang.administration.rights[
                                  right.rightName
                                ]
                              }
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  )
              )}
            </Grid>
          </Paper>

          <DialogActions>
            <Button color='primary' onClick={() => this.props.onClose(false)}>
              {this.props.multiLang.propertyCard.close}
            </Button>
            <Button
              onClick={this.createFeatureZone}
              variant='contained'
              color='primary'
              className='float-right'
            >
              {this.props.edit
                ? this.props.multiLang.administration.edit
                : this.props.multiLang.propertyCard.create}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  userId: state.auth.userId
});

export default connect(mapStateToProps)(AddFeatureZone);
