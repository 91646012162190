/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from "utils/store";
import axios from 'axios';
const jwtDecode = require('jwt-decode');
const signalR = require('@aspnet/signalr');
/**
 * Function used to build the IoT notification message
 * @param {*} notifMessageData : Data used to build the notification message
 * @param {*} thresholdComparatorLabels : The threshold comparator labels in the selected language
 * @param {*} sensorTypeLabels : The differents sensor type labels,
 * used in the notif message, in the selected language
 * @param {*} iotNotifMessageTemplate : The IoT notification message template
 */
function getIotNotifMessage(notifMessageData, thresholdComparatorLabels, sensorTypeLabels, iotNotifMessageTemplate) {

    let thresholdComparatorLabel = getthresholdComparatorLabel(notifMessageData.ThresholdComparator, thresholdComparatorLabels);
    let sensorTypeLabel = getSensorTypeLabel(notifMessageData.SensorType, sensorTypeLabels);
    let dataToReplace = {
        "%ThresholdComparator%": thresholdComparatorLabel,
        "%ThresholdValue%": notifMessageData.ThresholdValue,
        "%SensorType%": sensorTypeLabel,
        "%DeviceId%": notifMessageData.DeviceId,
        "%Tag%": notifMessageData.Tag
    };

    let iotNotifMessage = iotNotifMessageTemplate.replace(/%\w+%/g, function (all) {
        return dataToReplace[all] || all;
    });

    return iotNotifMessage;
}

function getImportNotifMessage(notifMessageData, messageNotifImport, ) {
    return messageNotifImport.replace(/%fileName%/g, notifMessageData ? notifMessageData.FileName : '%fileName%');
}

/**
 *
 * @param {*} reviewStatus
 * @param {*} reviewMessageNotif
 */
function getReviewNotifMessage(reviewStatus, reviewMessageNotif) {
    switch (reviewStatus) {
        case "REJECTED":
            return reviewMessageNotif.messageNotifRejectedReview;
        case "VALIDATED":
            return reviewMessageNotif.messageNotifValidatedReview;
        default:
            return reviewMessageNotif.messageNotifNewReview;
    }
}

/**
 *
 * @param {*} thresholdComparator : the threshold comparator
 * @param {*} thresholdComparatorLabels : The threshold comparator labels in the selected language
 */
function getthresholdComparatorLabel(thresholdComparator, thresholdComparatorLabels) {
    switch (thresholdComparator) {
        case ">":
            return thresholdComparatorLabels.greaterThan;
        case "<":
            return thresholdComparatorLabels.lowerThan;
        case "=":
            return thresholdComparatorLabels.equalTo;
        default:
            return "";
    }
}

/**
 *
 * @param {*} sensorType The sensor type
 * @param {*} sensorTypeLabels : The differents sensor type labels,
 * used in the notif message, in the selected language
 */
function getSensorTypeLabel(sensorType, sensorTypeLabels) {
    switch (sensorType) {
        case "Temperature":
            return sensorTypeLabels.temperature;
        case "Pressure":
            return sensorTypeLabels.pressure;
        case "Presence":
            return sensorTypeLabels.presence;
        case "Healthcheck":
            return sensorTypeLabels.healthcheck;
        default:
            console.log('There is no corresponding sensor type label for ' + sensorType);
            return "";
    }
}

/**
 * @return Return a websocket connection
 */
async function openConnection() {
    const id_token = store.getState().auth.idToken;
    const webSocketUrl = store.getState().config.webSocketUrl;
    if (store.getState().config.cloud === "AZURE") {
        const headers = store.getState().auth.headers;
        const decoded = jwtDecode(id_token);
        headers['x-ms-client-principal-name'] = decoded.sub;
        let resp = await axios.post(`${webSocketUrl}/notificationsSignalRInfo`, null,{ headers: headers });
        
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(resp.data.url, { accessTokenFactory: () => resp.data.accessToken })
            .build();
                connection.onclose(function() {})
                connection
                .start()
                .then(res => console.log(res))// Potential to do something on initial load)
                .catch(console.error)
        return connection;             

    }
    else{
        console.log("openning wss: " + webSocketUrl + "/notification?Auth=" + id_token);
        return new WebSocket(webSocketUrl + "/notification?Auth=" + id_token);
    }
}

function getAllNotification(lastEvaluatedKey, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    axios.get(config.urlNotificationAPI + '/notifications', {
        params: { lastEvaluatedKey },
        headers: headers
    })
        .then(response => {
            console.log(response.data);
            if (!response.data)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getNbrNotifications(callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    axios.get(config.urlNotificationAPI + '/users/count', { headers: headers })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function readNotification(notificationId, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    console.log(config.urlNotificationAPI + '/notifications/' + notificationId);
    axios.patch(config.urlNotificationAPI + '/notifications/' + notificationId,
        {
            status: "READ"
        },
        { headers: headers }
    )
        .then(response => {
            if (!response.data)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function archiveNotification(notificationId, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    axios.patch(config.urlNotificationAPI + '/notifications/' + notificationId,
        {
            status: "ARCHIVED"
        },
        { headers: headers }
    )
        .then(response => {
            if (!response.data)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getEventsForModel (projectId, modelId, revision, viewer) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    return axios.get(config.urlNotificationAPI + '/events/3d', {headers: headers, params: {projectId: projectId, modelId: modelId, revision: revision, viewer: viewer}});
}

function getEventsByProject (projectId) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    return axios.get(config.urlNotificationAPI + '/events', {headers: headers, params: {projectId: projectId}});
}

function updateEvent (event, projectId, eventId) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    delete event["_id"];
    return axios.put(config.urlNotificationAPI + '/events/'+ eventId, event, {headers: headers, params: {projectId: projectId}});
}



export {
    updateEvent,
    getEventsByProject,
    getEventsForModel,
    getAllNotification,
    getNbrNotifications,
    readNotification,
    archiveNotification,
    getIotNotifMessage,
    getReviewNotifMessage,
    getImportNotifMessage,
    openConnection
};
