import React, { Component } from "react";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Switch, FormControlLabel, Typography } from "@material-ui/core";
import theme from "theme/theme";
import { getAttachedTags } from "services/AssetService";
import { mainViewerAction } from "../../actions/MainViewerAction";
import { store } from "utils/store";
import { Virtuoso } from "react-virtuoso";
import CircularProgress from "@material-ui/core/CircularProgress";
import { constructTagLabel } from "utils/TagHelper";
import _ from "lodash";
const ACTIVE_EQUIPMENT_STATUS = "En service";

class AttachTagPanel extends Component {
  state = {
    tagFilter: "",
    selectedClass: "",
    selectedState: "all",
    filterList: [],
    selectedTag: {},
    tagsList: [],
    showOnlyActive: true
  };

  componentDidMount() {
    getAttachedTags(
      this.props.project.modelId,
      this.props.project.revision,
      this.props.project.projectId
    )
      .then((result) => {
        // Order tag list
        const tagsList = result.data.sort(this.compare);
        // Filter tag list based on equiment status
        const filterList = tagsList.filter(
          (tag) =>
            tag.EquipmentStatus &&
            tag.EquipmentStatus === ACTIVE_EQUIPMENT_STATUS
        );
        this.setState({ tagsList, filterList });
      })
      .catch((error) => {
        console.error(error);
      });
    // Get reference to the list
    this.virtuoso = React.createRef();
  }

  async componentDidUpdate(prevProps) {
    // Updated selected tag in the panel
    if (
      !_.isEqual(
        prevProps.focusedObjectsTagNames,
        this.props.focusedObjectsTagNames
      ) &&
      !this.props.selectionFromAttachedTags
    ) {
      if (this.props.focusedObjectsTagNames) {
        let focusedTag = this.props.focusedObjectsTagNames[0];
        let index = this.state.filterList.findIndex(
          (element) => element.Tag === focusedTag
        );
        // Scroll to focused tag and select it
        if (index > -1) {
          this.virtuoso.current.scrollToIndex({ index });
          this.setState({ selectedTag: { Tag: focusedTag } });
        }
      }
      // Unselect tag in the panel
      else {
        this.setState({ selectedTag: {} });
      }
    }
  }

  classFilter = (event) => {
    let value = event.target.value.toLowerCase();
    let updateFilterList = [...this.state.tagsList];
    let filteredArray = updateFilterList.filter((element) => {
      if (this.state.selectedState === "all") {
        return (
          !(
            this.state.showOnlyActive &&
            element.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
          ) &&
          element.Class.toLowerCase().includes(value) &&
          element.Tag.toLowerCase().includes(this.state.tagFilter)
        );
      } else {
        return (
          !(
            this.state.showOnlyActive &&
            element.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
          ) &&
          element.Attached === this.state.selectedState &&
          element.Class.toLowerCase().includes(value) &&
          element.Tag.toLowerCase().includes(this.state.tagFilter)
        );
      }
    });
    this.setState({ selectedClass: value, filterList: filteredArray });
  };

  tagFilter = (event) => {
    let value = event.target.value;
    let updateFilterList = [...this.state.tagsList];
    let filteredArray = updateFilterList.filter((element) => {
      if (this.state.selectedState === "all") {
        return (
          !(
            this.state.showOnlyActive &&
            element.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
          ) &&
          element.Class.toLowerCase().includes(this.state.selectedClass) &&
          (isNaN(value)
            ? element.TagLabel && element.TagLabel.toLowerCase().includes(value)
            : element.Tag.toLowerCase().includes(value))
        );
      } else {
        return (
          !(
            this.state.showOnlyActive &&
            element.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
          ) &&
          element.Attached === this.state.selectedState &&
          element.Class.toLowerCase().includes(this.state.selectedClass) &&
          (isNaN(value)
            ? element.TagLabel && element.TagLabel.toLowerCase().includes(value)
            : element.Tag.toLowerCase().includes(value))
        );
      }
    });
    this.setState({ tagFilter: value, filterList: filteredArray });
  };

  stateFilter = (event) => {
    let value = event.target.value;
    let updateFilterList = [...this.state.tagsList];
    let filteredArray = updateFilterList.filter((element) => {
      if (value === "all") {
        return (
          !(
            this.state.showOnlyActive &&
            element.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
          ) &&
          element.Class.toLowerCase().includes(this.state.selectedClass) &&
          element.Tag.toLowerCase().includes(this.state.tagFilter)
        );
      } else {
        return (
          element.Attached === value &&
          element.Class.toLowerCase().includes(this.state.selectedClass) &&
          element.Tag.toLowerCase().includes(this.state.tagFilter)
        );
      }
    });
    this.setState({ selectedState: value, filterList: filteredArray });
  };

  selectTag = (event, tag) => {
    event.stopPropagation();
    if (tag.Tag === this.state.selectedTag.Tag) {
      this.setState({ selectedTag: {} });
      this.props.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: null,
        selectionFromAttachedTags: true
      });
    } else {
      const ids = tag.Id ? tag.Id.map((e) => parseInt(e, 10)) : [];
      this.props.openCard(
        "PROPERTY_CARD",
        { tag: tag.Tag, tagIds: ids, reloadTaglist: this.reloadList },
        150,
        150,
        {
          height: 500
        }
      );
      this.props.viewers[0].focusOnObjectsByIds(ids, this.props.data.viewerId);
      this.setState({ selectedTag: tag });
      store.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: [tag.Tag],
        fromViewer: true,
        selectionFromAttachedTags: true
      });
    }
  };

  reloadList = (tag, ids) => {
    // Update tag in tagList
    let tagsList = [...this.state.tagsList].map((element) =>
      element.Tag === tag
        ? { ...element, Attached: !element.Attached, Id: ids }
        : element
    );
    // Update tag in filtered tagList
    let filterList = [...this.state.filterList].map((element) =>
      element.Tag === tag
        ? { ...element, Attached: !element.Attached, Id: ids }
        : element
    );
    this.setState({ tagsList, filterList });
  };

  handleOnlyActiveEquipment = (equipmentDisplayOption) => {
    let tagList = equipmentDisplayOption.target.checked
      ? this.state.filterList.filter(
          (tag) =>
            tag.EquipmentStatus &&
            tag.EquipmentStatus === ACTIVE_EQUIPMENT_STATUS
        )
      : this.state.filterList
          .concat(
            this.state.tagsList.filter(
              (tag) => tag.EquipmentStatus !== ACTIVE_EQUIPMENT_STATUS
            )
          )
          .sort(this.compare);
    this.setState({
      showOnlyActive: equipmentDisplayOption.target.checked,
      filterList: tagList
    });
  };

  GenerateItem = (index, element) => {
    return (
      <div>
        <ListItem
          key={index}
          role={undefined}
          dense
          button
          style={{
            backgroundColor:
              this.state.selectedTag.Tag === element.Tag
                ? theme.color.cyan
                : "",
            color: this.state.selectedTag.Tag === element.Tag ? "white" : ""
          }}
          onClick={(e) => {
            this.selectTag(e, element);
          }}
        >
          <ListItemText
            primary={
              constructTagLabel(element.Tag, element.TagLabel) +
              " (" +
              element.Class +
              ")"
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="comments">
              {element.Attached ? <LinkIcon /> : <LinkOffIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  };

  // Order an array of tags
  compare = (a, b) => {
    if (a.TagLabel && b.TagLabel) {
      return a.TagLabel.toLowerCase() > b.TagLabel.toLowerCase()
        ? 1
        : b.TagLabel.toLowerCase() > a.TagLabel.toLowerCase()
        ? -1
        : 0;
    } else {
      return a.Tag > b.Tag ? 1 : b.Tag > a.Tag ? -1 : 0;
    }
  };

  render() {
    return (
      <div>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Grid item xs={6}>
            <TextField
              fullWidth={true}
              id="standard-basic"
              label={this.props.multiLang.attachTag.class}
              value={this.state.selectedClass}
              onChange={this.classFilter}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth={true}
              id="standard-basic"
              label="Tag"
              value={this.state.tagFilter}
              onChange={this.tagFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedState}
              onChange={this.stateFilter}
              fullWidth={true}
            >
              <MenuItem value={true}>
                {this.props.multiLang.attachTag.attached}
              </MenuItem>
              <MenuItem value={false}>
                {this.props.multiLang.attachTag.detached}
              </MenuItem>
              <MenuItem value={"all"}>
                {this.props.multiLang.attachTag.all}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 5 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showOnlyActive}
                  onChange={(equipmentDisplayOption) =>
                    this.handleOnlyActiveEquipment(equipmentDisplayOption)
                  }
                  value="checkedB"
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={
                <Typography>
                  {this.props.multiLang.breakdownCard.showOnlyActive}
                </Typography>
              }
            />
          </Grid>
        </Grid>

        {this.state.tagsList.length === 0 ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress style={{ marginTop: 20 }} />
          </div>
        ) : (
          <List>
            <Virtuoso
              data={this.state.filterList}
              overscan={5}
              itemContent={(index, element) =>
                this.GenerateItem(index, element)
              }
              style={{ height: "310px", width: "100%" }}
              ref={this.virtuoso}
            />
          </List>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  mainViewer: state.mainViewer,
  viewers: state.mainViewer.viewers,
  selectedObjectId: state.mainViewer.selectedObjectIds,
  openCard: state.card.openCard,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  selectionFromAttachedTags: state.mainViewer.selectionFromAttachedTags
});

export default connect(mapStateToProps)(AttachTagPanel);
