/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getMarkupsBasic, deleteMarkup } from "services/MarkupService";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import CreateMarkupCardPanel from "components/viewers/forge-viewer/extensions/CreateMarkupCardPanel";
import { ListMarkups } from "../generic-card/design-review/ListMarkups";
import MarkupDetailsBasic from "../generic-card/design-review/MarkupDetailsBasic";
import List from "@material-ui/core/List";
import helpers from "components/viewers/forge-viewer/ForgeViewerHelper";
import IconButton from "@material-ui/core/IconButton";

import "../generic-card/design-review/ListMarkupCard.css";

class ListMarkupCardPanelBasic extends Component {
  constructor() {
    super();
    this.state = {
      openDetails: false,
      markups: null,
      selectedMarkup: null
    };
    this.adoptViewpoint = this.adoptViewpoint.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showAnnotation = this.showAnnotation.bind(this);
    this.returnToMarkups = this.returnToMarkups.bind(this);
  }

  componentDidMount() {
    this.viewerId = this.props.data.viewerId;
    this.loadData();
  }

  loadData() {
    const viewerId = this.viewerId;
    let modelId = viewerId.split("#")[1];
    let revision = viewerId.split("#")[2];

    getMarkupsBasic(this.props.projectId, modelId, revision, (error, data) => {
      if (error) {
        console.log(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.viewMarkup.loadingMarkupError
        );
      }
      this.setState({
        markups: data.map((markup) => {
          return { ...markup, viewed: false };
        })
      });
    });
  }

  returnToMarkups() {
    this.setState({ openDetails: false });
  }

  adoptViewpoint(state) {
    helpers.restoreState(state, this.viewerId);
  }

  handleClick(markup) {
    this.setState({ openDetails: true, selectedMarkup: markup });
  }

  showAnnotation(graphics, state, markupId, index) {
    let markups = [...this.state.markups];
    const annotationVisible = !markups[index].viewed;
    markups[index].viewed = annotationVisible;
    this.setState({ markups });
    if (annotationVisible) {
      this.adoptViewpoint(state);
      // Wait until the model is settled
      setTimeout(
        () => helpers.loadMarkups(graphics, markupId, this.viewerId),
        500
      );
    } else {
      helpers.hideMarkups(this.viewerId);
    }
  }

  createMarkup = () => {
    helpers.enterEditMode(this.viewerId);
    let unmountMarkupCardPanel = (markup) => {
      this.CreateMarkupCardPanel = null;
    };
    if (!this.CreateMarkupCardPanel) {
      this.props.card.closeAllCard(() => {
        this.CreateMarkupCardPanel = new CreateMarkupCardPanel(
          unmountMarkupCardPanel,
          this.viewerId,
          this.addMarkup
        );
      });
    }
  };

  addMarkup = (markup) => {
    let markups = [...this.state.markups];
    markups.push(markup);
    this.setState({ markups });
  };

  deleteMarkup = (markupId) => {
    deleteMarkup(this.props.projectId, markupId, (error, reponse) => {
      if (error) {
        console.log(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.viewMarkup.deleteMarkupError
        );
      }
      let markups = [...this.state.markups].filter(
        (element) => element.MarkupId !== markupId
      );
      this.setState({ markups });
      this.props.snackBar.addSnackSuccess(
        this.props.multiLang.viewMarkup.deleteMarkupSuccess
      );
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card__revision-wrapper">
          {this.state.markups && (
            <Fragment>
              {!this.state.openDetails && (
                <Fragment>
                  <List
                    component="div"
                    style={{ marginTop: "30px", border: "1px solid #eaeaea" }}
                  >
                    {this.state.markups &&
                      this.state.markups
                        .sort((a, b) => {
                          return (
                            new Date(b.creationDate).getTime() -
                            new Date(a.creationDate).getTime()
                          );
                        })
                        .map((markup, index) => (
                          <ListMarkups
                            key={index}
                            order={index + 1}
                            markupId={markup.MarkupId}
                            title={markup.title}
                            viewpoint={() =>
                              this.showAnnotation(
                                markup.graphics,
                                markup.state,
                                markup.MarkupId,
                                index
                              )
                            }
                            openDetails={() => {
                              this.handleClick(markup);
                              this.adoptViewpoint(markup.state);
                            }}
                            viewed={markup.viewed}
                            deleteMarkup={this.deleteMarkup}
                          />
                        ))}
                  </List>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    style={{ height: "inherit" }}
                  >
                    <Grid item>
                      <Fab size="small" onClick={this.createMarkup}>
                        <svg
                          viewBox="0 0 32 32"
                          fit=""
                          height="50%"
                          width="50%"
                          preserveAspectRatio="xMidYMid meet"
                          focusable="false"
                        >
                          <path d="m 15.677734,2.8133138 a 0.66673335,0.66673335 0 0 0 -0.65625,0.6757812 v 12.15625 H 3.5 a 0.66673335,0.66673335 0 1 0 0,1.332031 h 11.521484 v 11.511719 a 0.66673335,0.66673335 0 1 0 1.332032,0 V 16.977376 H 28.5 a 0.66673335,0.66673335 0 1 0 0,-1.332031 H 16.353516 V 3.489095 A 0.66673335,0.66673335 0 0 0 15.677734,2.8133138 Z"></path>
                        </svg>
                      </Fab>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              {this.state.openDetails && (
                <Fragment>
                  <div className="card__annotation card__annotation--title">
                    <span className="card__annotation-text">
                      {this.state.selectedMarkup.title}
                    </span>
                    <IconButton
                      onClick={() => {
                        this.setState({ openDetails: false });
                      }}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </div>
                  <MarkupDetailsBasic data={this.state.selectedMarkup} />
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  card: state.card
});

export default connect(mapStateToProps)(ListMarkupCardPanelBasic);
