/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { connect } from 'react-redux';
import { addSensor } from 'services/SensorService';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class AttachSensorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceid: '',
      typeSensor: ''
    };
  }

  addSensor() {
    addSensor(
      this.props.project.projectId,
      this.props.tag,
      this.state.deviceid,
      this.state.typeSensor,
      (error, response) => {
        if (error) {
          this.props.snackBar.addSnackError(
            this.props.multiLang.propertyCard.errorAddSensor
          );
        } else {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.propertyCard.successAddSensor
          );
          this.setState({
            deviceid: '',
            typeSensor: ''
          });
        }
      }
    );
  }

  handleChange(event) {
    this.setState({ deviceid: event.target.value });
  }

  handleTypeChange = (event) => {
    this.setState({ typeSensor: event.target.value });
  };

  componentDidMount() {}

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <AttachmentIcon /> {this.props.multiLang.propertyCard.attachSensor}
        </DialogTitle>
        <DialogContent>
          <Paper style={{ padding: 20, margin: 10, width: '500px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label={this.props.multiLang.propertyCard.sensorLabel}
                  fullWidth={true}
                  required={true}
                  type='text'
                  value={this.state.deviceid}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth={true}>
                  <Select
                    style={{ verticalAlign: 'bottom' }}
                    value={this.state.typeSensor}
                    onChange={(event) => this.handleTypeChange(event)}
                  >
                    <MenuItem value={'ConnectedPlug'}>
                      {this.props.multiLang.attachSensor.connectedPlug}
                    </MenuItem>
                    <MenuItem value={'Sensor'}>
                      {this.props.multiLang.attachSensor.sensor}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => this.addSensor()}
          >
            {this.props.multiLang.propertyCard.addSensor}
          </Button>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  project: state.project
});

export default connect(mapStateToProps)(AttachSensorModal);
