/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'actions/ModalAction';

import './Modal.css';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.action = this.action.bind(this);
  }

  action() {
    this.close();
    this.props.validAction();
  }

  close() {
    closeModal();
  }

  render() {
    const show = this.props.displayType === 'CONFIRM';

    return (
      <Fragment>
        {show && (
          <div className='popin-overlay'>
            <div className='popin'>
              <header className='popin__header card__header'>
                <div className='card__title-wrapper'>
                  <svg
                    className='icon card__icon'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 687.13 684.58'
                  >
                    <path d='M357.85,121.35H15.07a14.25,14.25,0,1,1,0-28.5H357.85a14.25,14.25,0,0,1,0,28.5Z' />
                    <path d='M561.61,278.14H15.07a14.25,14.25,0,1,1,0-28.5H561.61a14.25,14.25,0,0,1,0,28.5Z' />
                    <path d='M466.4,434.94H15.07a14.25,14.25,0,1,1,0-28.5H466.4a14.25,14.25,0,0,1,0,28.5Z' />
                    <path d='M672.07,591.73h-657a14.25,14.25,0,0,1,0-28.5h657a14.25,14.25,0,1,1,0,28.5Z' />
                  </svg>
                  <h2 className='card__title'>{this.props.title}</h2>
                </div>
                <button
                  onClick={this.close}
                  className='card__close-btn'
                  aria-label='Close the card'
                >
                  <svg
                    className='icon'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 687.13 684.58'
                  >
                    <path d='M662,1.23A21.26,21.26,0,0,0,647.4,7.64L343,312.12,39.37,8.51a21.23,21.23,0,1,0-30,30L313,342.14,9.67,645.38a21.27,21.27,0,1,0,30.08,30.08h0L343,372.16,647.09,676.27a21.26,21.26,0,0,0,31.71-28.33,20.73,20.73,0,0,0-1.69-1.69L373,342.14,677.48,37.72A21.25,21.25,0,0,0,662,1.23Z' />
                  </svg>
                </button>
              </header>

              <div className='card__content'>
                <div>{this.props.message}</div>

                <div className='popin__actions'>
                  <button
                    className='button button--rounded button--border button--cancel'
                    onClick={this.close}
                  >
                    NO
                  </button>
                  <button
                    className='button button--rounded button--border button--valid'
                    onClick={this.action}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.modal.message,
  validAction: state.modal.validAction,
  title: state.modal.title,
  displayType: state.modal.displayType
});

export default connect(mapStateToProps)(ConfirmModal);
