/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { Component } from 'react';
import { generateBCFFile } from 'services/MarkupService';
import { connect } from 'react-redux';

class DownloadBCF extends Component {
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const projectId = params.get('projectId');
    const modelId = params.get('modelId');
    const revision = params.get('revision');

    this.getBCFData(projectId, modelId, revision);
  }

  getBCFData(project, model, revision) {
    generateBCFFile(project, model, revision, (error, response) => {
      if (error) {
        console.log(error);
        return this.props.snackBar.addSnackError(
          this.props.multiLang.viewMarkup.generateBCFError + error
        );
      }

      this.props.snackBar.addSnackSuccess(
        this.props.multiLang.viewMarkup.generateBCFSucces
      );

      if (response.bcfKey) {
        var params = {
          Bucket: this.props.storeBucket,
          Key: response.bcfKey
        };

        var url = this.props.s3.getSignedUrl('getObject', params);
        if (url) {
          var link = document.createElement('a');
          link.href = url;
          link.download = params.Key.split('/')[1];
          link.click();
        }
      }
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  s3: state.auth.s3,
  snackBar: state.snackBar,
  storeBucket: state.config.storeBucket
});

export default connect(mapStateToProps)(DownloadBCF);
