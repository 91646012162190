/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AddFeatureZone from './AddFeatureZone';
import {
  createFeatureZone,
  getFeatureZoneByProject,
  deleteFeatureZone,
  updateFeatureZone
} from 'services/FeatureZoneService';
import { getUserAdminProject } from 'services/UserService';

class FeatureZone extends Component {
  state = {
    projects: [],
    featureZoneDataTable: [],
    featureZones: [],
    isAddFeatureModalZoneOpen: false,
    selectedProject: {},
    editFeatureZone: false,
    selectedFeatureZone: {}
  };

  componentDidMount() {
    //get Projects
    getUserAdminProject()
      .then((result) => {
        this.setState({ projects: result.data });
      })
      .catch((err) => {
        console.log(err);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectRetrievalFailed
        );
      });
  }

  columnsIn = [
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.description,
      label: this.props.multiLang.administration.description,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.groups,
      label: this.props.multiLang.administration.groups,
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: this.props.multiLang.administration.actions,
      label: this.props.multiLang.administration.actions,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton onClick={() => this.handelEditFeatureZone(value)}>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        )
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title={this.props.multiLang.administration.createFeatureZone}>
          <span>
            <IconButton
              onClick={() => this.handelOpenAndCloseAddFeatureZoneModal(true)}
              disabled={Object.keys(this.state.selectedProject).length === 0}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    onRowsDelete: (rowsDeleted, data) => {
      this.deleteFeatureZones(rowsDeleted, data);
    }
  };

  handelOpenAndCloseAddFeatureZoneModal = (state) => {
    this.setState({ isAddFeatureModalZoneOpen: state, editFeatureZone: false });
  };

  createFeatureZone = (name, description, groups, rights) => {
    createFeatureZone(
      name,
      this.state.selectedProject.Id,
      description,
      groups,
      rights
    )
      .then((result) => {
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success
            .featureZoneCreationSucceeded
        );
        this.getFeatureZoneByProject(this.state.selectedProject.Id);
      })
      .catch((err) => {
        console.log(err);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.featureZoneCreationFailed
        );
      });
  };

  handelChangeSelectProject = (event) => {
    let selectedProject = event.target.value;
    this.setState({ selectedProject: selectedProject });
    this.getFeatureZoneByProject(selectedProject.Id);
  };

  getFeatureZoneByProject = (projectId) => {
    let updadteFeatureZoneDataTablethis = [];
    getFeatureZoneByProject(projectId)
      .then((result) => {
        result.data.forEach((element) => {
          updadteFeatureZoneDataTablethis.push([
            element.Name,
            element.Description,
            element.Groups.map((element) => {
              return element.Name;
            }).join(','),
            element
          ]);
        });
        this.setState({
          featureZoneDataTable: updadteFeatureZoneDataTablethis,
          featureZones: result.data
        });
      })
      .catch((error) => {
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.featureZoneGetFailed
        );
      });
  };

  handelEditFeatureZone = (value) => {
    this.setState({
      selectedFeatureZone: value,
      editFeatureZone: true,
      isAddFeatureModalZoneOpen: true
    });
  };

  updateFeatureZone = (name, description, groups, rights, featureZoneId) => {
    updateFeatureZone(name, description, groups, rights, featureZoneId)
      .then((result) => {
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.featureZoneUpdateSucceeded
        );
        this.getFeatureZoneByProject(this.state.selectedProject.Id);
      })
      .catch((err) => {
        console.log(err);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.featureZoneUpdateFailed
        );
      });
  };

  deleteFeatureZones = (rowsDeleted) => {
    let updateFeatureZones = [...this.state.featureZones];
    rowsDeleted.data.forEach((element) => {
      let featureZoneToDelete = updateFeatureZones[element.dataIndex];
      deleteFeatureZone(
        featureZoneToDelete.FeatureZoneId,
        this.state.selectedProject.Id
      )
        .then((result) => {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success
              .featureZoneRemovalSucceeded
          );
          this.getFeatureZoneByProject(this.state.selectedProject.Id);
        })
        .catch((error) => {
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.featureZoneDeleteFailed
          );
        });
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.isAddFeatureModalZoneOpen && (
          <AddFeatureZone
            isOpen={this.state.isAddFeatureModalZoneOpen}
            onClose={this.handelOpenAndCloseAddFeatureZoneModal}
            createFeatureZone={this.createFeatureZone}
            updateFeatureZone={this.updateFeatureZone}
            project={this.state.selectedProject}
            edit={this.state.editFeatureZone}
            featureZone={this.state.selectedFeatureZone}
          />
        )}

        <Typography style={{ color: '#757575', fontWeight: 'bold' }}>
          <span onClick={() => this.props.close()}
            style={{ cursor: 'pointer' }}>
            {this.props.multiLang.administration.projectManagement}{' '} &gt; {' '}
          </span>
          {this.props.multiLang.administration.featureZone}
        </Typography>
        <div style={{ marginBottom: '40px', padding: 20 }}>
          <FormControl className='formControl' fullWidth={true}>
            <InputLabel htmlFor='select-multiple-checkbox'>
              {this.props.multiLang.administration.projects}
            </InputLabel>
            <Select
              value={this.state.selectedProject}
              onChange={this.handelChangeSelectProject}
            >
              {this.state.projects.map((project, index) => (
                <MenuItem key={project.Id + index} value={project}>
                  {project.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: '10px' }}>
          <MUIDataTable
            title={this.props.multiLang.administration.featureZone}
            data={this.state.featureZoneDataTable}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  userId: state.auth.userId
});

export default connect(mapStateToProps)(FeatureZone);
