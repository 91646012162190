/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getGroupsByProject } from 'services/ProjectService';
import { getGroupUsers } from 'services/GroupService';

class AddProject extends Component {
  state = {
    projectName: '',
    description: '',
    usersList: [],
    selectedUsers: [],
    orginalAdminList: [],
    adminGroupId: null
  };

  componentDidMount() {
    // If it is edit Mode
    if (this.props.edit) {
      this.setState({ projectName: this.props.project[1], description: this.props.project[2] });
      // Get the admins of the projects
      this.getProjectAdmins(this.props.project[0]);
    } else {
      // Get All Environment users
      this.getUsers();
    }
  }

  handleProjectNameInputChange = (event) => {
    this.setState({ projectName: event.target.value });
  };

  handleDescriptionInputChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleUsersInputChange = (event, values) => {
    this.setState({ selectedUsers: values });
  };

  getUsers() {
    getGroupUsers(this.props.envGroupId)
      .then((response) => {
        this.setState({ usersList: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getProjectAdmins(projectId) {
    let adminGroup;
    // get admin group for this project
    await getGroupsByProject(projectId)
      .then((data) => {
        adminGroup = data.data.result.filter((element) => element.Name === 'admin');
        this.setState({ adminGroupId: adminGroup[0].ZoneId });
      })
      .catch((error) => {
        console.log(error);
      });
    // Get the users of admin group
    getGroupUsers(adminGroup[0].ZoneId).then(res => {
      // Get All Environment users
      this.getUsers();
      this.setState({ selectedUsers: res.data, orginalAdminList: res.data });

    }
    );
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.edit
            ? this.props.multiLang.administration.editProject
            : this.props.multiLang.administration.createProject}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper style={{ padding: 20, margin: 10, width: '500px' }}>
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={this.state.projectName}
                      id='name'
                      label={this.props.multiLang.administration.name}
                      fullWidth={true}
                      type='text'
                      onChange={(event) =>
                        this.handleProjectNameInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={this.state.description}
                      type='text'
                      id='description'
                      label={this.props.multiLang.administration.description}
                      fullWidth={true}
                      onChange={(event) =>
                        this.handleDescriptionInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id='users'
                      options={Array.isArray(this.state.usersList) ? this.state.usersList : []}
                      getOptionLabel={(option) => (option.email) ? option.email : ''}
                      onChange={this.handleUsersInputChange}
                      value={this.state.selectedUsers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label={this.props.multiLang.administration.projectAdministrators}
                          placeholder={
                            this.props.multiLang.administration.projectAdministrators
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </Paper>
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='float-right'
            onClick={
              this.props.edit
                ? () =>
                  this.props.updateProject(
                    this.props.project[0],
                    this.state.projectName,
                    this.state.description,
                    this.state.orginalAdminList.map(e => e.Id),
                    this.state.selectedUsers.map(e => e.Id),
                    this.state.adminGroupId
                  )
                : () =>
                  this.props.addProject(
                    this.state.projectName,
                    this.state.description,
                    this.state.selectedUsers
                  )
            }
          >
            {this.props.edit
              ? this.props.multiLang.administration.edit
              : this.props.multiLang.propertyCard.create}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  envGroupId: state.config.envGroupId
});

export default connect(mapStateToProps)(AddProject);
