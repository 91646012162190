/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from "utils/store";

class CreateMarkupCardPanel {
  constructor(unmount, viewerId, addMarkup) {
    this.viewerId = viewerId;
    this.openCard = store.getState().card.openCard;
    this.closeCard = store.getState().card.closeCard;
    this.toggleVisibility(unmount, addMarkup);
  }

  toggleVisibility(unmount, addMarkup) {
    this.data = {
      title: store.getState().multiLang.createMarkupCard.title,
      viewerId: this.viewerId
    };
    let viewer = store
      .getState()
      .mainViewer.viewers.find((v) => v.id === this.viewerId).viewer;
    let options = {
      close: () => {
        unmount();
        let markupExtension = viewer.myCurrentViewer.getExtension(
          "Autodesk.Viewing.MarkupsCore"
        );
        markupExtension.clear();
        markupExtension.hide();

        let buttonGroup = viewer.myCurrentViewer.toolbar.getControl(
          "MarkupExtension.ControlGroup3"
        );
        viewer.myCurrentViewer.toolbar.removeControl(buttonGroup);
        markupExtension.leaveEditMode();
      },
      addMarkup
      //height: 490
    };
    console.log(this.data);
    this.indexCard = this.openCard(
      "MARKUP_FORM_CARD",
      this.data,
      30,
      100,
      options
    );
  }
}

export default CreateMarkupCardPanel;
