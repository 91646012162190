/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { combineReducers } from 'redux';
import mainViewerReducer from './MainViewerReducer';
import authReducer from './AuthReducer';
import projectReducer from './ProjectReducer';
import modalReducer from './ModalReducer';
import snackBarReducer from './SnackBarReducer';
import cardReducer from './CardReducer';
import configReducer from './ConfigReducer';
import multiLangReducer from './MultiLangReducer';
import selectMarkupReducer from './SelectMarkupReducer';
import searchReducer from './SearchReducer';
import breakdownReducer from './BreakdownReducer';

const rootReducer = combineReducers({
  mainViewer: mainViewerReducer,
  auth: authReducer,
  project: projectReducer,
  modal: modalReducer,
  snackBar: snackBarReducer,
  card: cardReducer,
  config: configReducer,
  multiLang: multiLangReducer,
  selectMarkup: selectMarkupReducer,
  searchParams: searchReducer,
  breakdown: breakdownReducer
});

export default rootReducer;
