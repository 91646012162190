/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from "react-redux";

class ExtendedSearchSelectAllModal extends Component {

  state = {
    selectAll: false
  }

  handleConfirmation = async () => {
    this.setState({ selectAll: true });
    this.props.retrieveAllTags();
  }

  handleCancel = () => {
    this.props.handleClose();
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            {!this.state.selectAll ?
              <DialogContentText id="alert-dialog-description">
                {this.props.multiLang.search.confirmSelectAll.replace(/%\w+%/g, this.props.elementCount)}
              </DialogContentText>
              :
              <Fragment>
                <DialogContentText id="alert-dialog-description">
                  Processing...
              </DialogContentText>
                <LinearProgress variant="buffer" value={this.props.progress} valueBuffer={0} />
              </Fragment>
            }
          </DialogContent>
          <DialogActions>
            {!this.state.selectAll &&
              <Button variant="contained" onClick={this.handleConfirmation} color="primary">
                {this.props.multiLang.search.confirmButton}
              </Button>
            }
            <Button onClick={this.props.handleClose} color="primary" autoFocus>
              {this.props.multiLang.search.cancelButton}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(ExtendedSearchSelectAllModal);