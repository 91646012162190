/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  Typography
} from '@material-ui/core';
import { deleteGroup, addGroup, updateGroup } from 'services/GroupService';
import { getGroupsByProject } from 'services/ProjectService';
import { getUserAdminProject } from 'services/UserService';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import AddGroup from './AddGroup';
import ShowGroupDetails from './ShowGroupDetails';
import AddUserToGroup from './AddUserToGroup';

class Groups extends Component {
  state = {
    groups: [],
    projectList: [],
    project: {},
    isAddGroupModalOpen: false,
    isShowGroupModalOpen: false,
    isAddUserModalOpen: false,
    selectedRow: []
  };

  componentDidMount() {
    getUserAdminProject()
      .then((result) => {
        this.setState({ projectList: result.data });
      })
      .catch((err) => {
        console.log(err);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.projectRetrievalFailed
        );
      });
  }

  columnsIn = [
    {
      name: this.props.multiLang.administration.groupId,
      label: this.props.multiLang.administration.groupId,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.name,
      label: this.props.multiLang.administration.name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.description,
      label: this.props.multiLang.administration.description,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.actions,
      label: this.props.multiLang.administration.actions,
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    onRowsDelete: (rowsDeleted) => {
      this.deleteGroups(rowsDeleted);
    },
    customToolbar: () => {
      return (
        <Tooltip title={this.props.multiLang.administration.createGroup}>
          <span>
          <IconButton disabled={Object.keys(this.state.project).length === 0} onClick={() => this.handleAddGroup()}>
            <AddIcon />
          </IconButton>
          </span>
        </Tooltip>
      );
    },
    onCellClick: (colData, cellMeta) => {
      const groups = this.state.groups;
      this.setState({ selectedRow: groups[cellMeta.dataIndex] });

      if (cellMeta.colIndex === 0) {
        this.handleShowGroup();
      } else if (cellMeta.colIndex === 3) {
        this.handleEditGroup();
      }
    }
  };

  handleGroupCreation = (groupName, description, projectId) => {
    addGroup(description, groupName, projectId)
      .then((result) => {
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        );
        let groupId = (
          <Typography color='primary'> {result.data.id}</Typography>
        );
        let updateGroupsList = [...this.state.groups];
        updateGroupsList.push([groupId, groupName, description, edit]);
        this.setState({ groups: updateGroupsList });
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.groupCreationSucceeded
        );
        this.setState({ isAddGroupModalOpen: false });
      })
      .catch((error) => {
        console.log(error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.groupCreationFailed
        );
      });
  };

  deleteGroups = (groups) => {
    let updateGroupsList = [...this.state.groups];
    groups.data.reverse().forEach((element) => {
      let groupTodelete = updateGroupsList[element.dataIndex];
      deleteGroup(groupTodelete[0].props.children[1], this.state.project.Id)
        .then((data) => {
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.administration.success.groupRemovalSucceeded
          );
          updateGroupsList.splice(element.dataIndex, 1);
          this.setState({ groups: updateGroupsList });
        })
        .catch((error) => {
          console.log(error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.administration.errors.groupRemovalFailed
          );
        });
    });
  };

  handleChange = (event) => {
    this.setState({ project: event.target.value });

    getGroupsByProject(event.target.value.Id)
      .then((data) => {
        let tableData = [];
        data.data.result.forEach((element) => {
          let groupId = (
            <Typography color='primary'> {element.ZoneId}</Typography>
          );
          let edit = (
            <Tooltip title={this.props.multiLang.administration.edit}>
              <IconButton>
                <EditIcon color='primary' />
              </IconButton>
            </Tooltip>
          );
          tableData.push([groupId, element.Name, element.Description, edit]);
        });
        this.setState({ groups: tableData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddGroup = () => {
    this.setState({ isAddGroupModalOpen: true });
  };

  handleCloseGroupModal = (value) => {
    this.setState({ isAddGroupModalOpen: value });
  };

  handleShowGroup = () => {
    this.setState({ isShowGroupModalOpen: true });
  };

  handleCloseShowGroupModal = (value) => {
    this.setState({ isShowGroupModalOpen: value });
  };

  handleEditGroup = () => {
    this.setState({ isAddUserModalOpen: true });
  };

  handleCloseEditGroupModal = (value) => {
    this.setState({ isAddUserModalOpen: value });
  };

  updateGroup = (groupId, description, name, projectid) => {
    let updateGroupsList = [...this.state.groups];
    let index = updateGroupsList.findIndex((project) => {
      return project[0].props.children[1] === groupId;
    });
    updateGroup(groupId, description, name, projectid)
      .then((data) => {
        updateGroupsList.splice(index, 1);
        let groupIdValue = <Typography color='primary'> {groupId}</Typography>;
        let edit = (
          <Tooltip title={this.props.multiLang.administration.edit}>
            <IconButton>
              {' '}
              <EditIcon color='primary' />{' '}
            </IconButton>
          </Tooltip>
        );
        updateGroupsList.push([groupIdValue, name, description, edit]);
        this.setState({ groups: updateGroupsList });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <Fragment>
        {this.state.isAddGroupModalOpen && (
          <AddGroup
            isOpen={this.state.isAddGroupModalOpen}
            onClose={this.handleCloseGroupModal}
            add={this.handleGroupCreation}
            projectid={this.state.project.Id}
          />
        )}
        {this.state.isShowGroupModalOpen && this.state.selectedRow && (
          <ShowGroupDetails
            isOpen={this.state.isShowGroupModalOpen}
            onClose={this.handleCloseShowGroupModal}
            group={this.state.selectedRow}
          />
        )}
        {this.state.isAddUserModalOpen && (
          <AddUserToGroup
            isOpen={this.state.isAddUserModalOpen}
            onClose={this.handleCloseEditGroupModal}
            group={this.state.selectedRow}
            projectid={this.state.project.Id}
            update={this.updateGroup}
          />
        )}
        <Typography style={{ color: '#757575', fontWeight: 'bold' }}>
          <span onClick={() => this.props.close()}
            style={{ cursor: 'pointer' }}>
            {this.props.multiLang.administration.projectManagement}{' '} &gt; {' '}
          </span>
          {this.props.multiLang.administration.groups}
        </Typography>
        <div style={{ marginBottom: '40px', padding: 20 }}>
          <FormControl className='formControl' fullWidth={true}>
            <InputLabel htmlFor='select-multiple-checkbox'>
              {this.props.multiLang.administration.projects}
            </InputLabel>
            <Select
              value={this.state.project}
              onChange={this.handleChange}
              input={<Input id='select-multiple-checkbox' />}
            >
              {this.state.projectList.map((project) => (
                <MenuItem key={project.Id} value={project}>
                  {project.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: '10px' }}>
          <MUIDataTable
            title={this.props.multiLang.administration.groups}
            data={this.state.groups}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(Groups);
