/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import { connect } from 'react-redux';

export class ProjectListCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            backgroundColor : '#FFF',
            titleColor: "#0070AD",
            descriptionColor: "#000",
            shadow: 0
        };
    }

  onMouseOver = () => this.setState({
    backgroundColor: '#12ABDB',
    titleColor: '#FFF',
    descriptionColor: '#FFF',
    shadow: 5
    });

  onMouseOut = () => this.setState({
    backgroundColor: '#FFF',
    titleColor: '#0070AD',
    descriptionColor: '#000',
    shadow: 0
    });
  
  render() {
    const cardTitle = (<Typography component="h1" style={{ color: this.state.titleColor, fontSize: 20 }}>
    {this.props.project.Name}
    </Typography>);

    return (
        <Card 
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            style={{backgroundColor: this.state.backgroundColor }}
            zdepth={this.state.shadow}
            className="card"
            onClick={this.props.onClick()}
        >
            <CardHeader title={cardTitle}/>
            <CardContent>
                <Typography component="h2" style={{ color: this.state.descriptionColor }}>
                    {this.props.project.Description}
                </Typography>
            </CardContent>
        </Card>
    );
  }

}

const mapStateToProps = state => ({
    projectId: state.project.projectId,
    projectName: state.project.name,
    snackBar: state.snackBar,
    multiLang: state.multiLang
});

export default connect(mapStateToProps)(ProjectListCard);