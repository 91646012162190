/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getContentCardFromTag } from "services/ObjectService";
import uuid from "uuidv4";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { hasRelatedItem } from "./property-card/Model-utils";
import ModelTab from "./property-card/ModelTab";
import RelatedTab from "./property-card/RelatedTab";
import DocumentTab from "./property-card/DocumentTab";
import IotTab from "./property-card/IotTab";
import IconProperties from "resources/svg/IconProperties";
import IconMonitoring from "resources/svg/IconMonitoring";
import IconGraph from "resources/svg/IconGraph";
import IconDocument from "resources/svg/IconDocument";
import Tooltip from "@material-ui/core/Tooltip";
import { constructTagLabel } from "utils/TagHelper";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import GpsFixed from "@material-ui/icons/GpsFixed";
import { mainViewerAction } from "../../actions/MainViewerAction";
const ASSET_VISIBLE_CLASS = "ASSET VISIBLE";
const EQUIPMENT_STATUS_DATASET = "Classification & libellés";
const EQUIPMENT_STATUS_ATTRIBUTE = "Statut d'équipement";
const OUT_OF_SERVICE_STATUS = "Sorti";
const REPLACED_BY_RELATION = "précède";

// import PredictiveMaintenance from './property-card/PredictiveMaintenance';
// import BuildIcon from '@material-ui/icons/Build';

class PropertyCardPanel extends Component {
  constructor() {
    super();
    this.state = {
      modelData: null,
      tag: null,
      tabSelected: 0,
      tabNames: [],
      tagIds: null,
      equipmentChecked: false
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.addTab = this.addTab.bind(this);
    this.idModels = new Map();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const selectedObjectIds = this.props.selectedObjectIds;
    // If data is ready and the equipment has not been checked, check its status
    if (
      !this.state.equipmentChecked &&
      selectedObjectIds &&
      this.state.tag &&
      selectedObjectIds.tag === this.state.tag
    ) {
      this.checkEquipmentAttached(
        selectedObjectIds.tagIds,
        this.state.modelData
      );
      this.setState({
        tagIds: selectedObjectIds.tagIds,
        equipmentChecked: true
      });
    }
    if (
      prevProps.options.showEmptyAttribute &&
      this.props.options.showEmptyAttribute !==
        prevProps.options.showEmptyAttribute
    ) {
      // Reinitialise model data to avoid scrollbar got disappeared bug
      let modelData = this.state.modelData;
      this.setState({ modelData: [] }, () => this.setState({ modelData }));
    }
  }

  refresh = () => {
    this.loadData();
  };

  addTab(name) {
    this.setState({ tabNames: [...this.state.tabNames, name] });
  }

  getTabName = (selectedTabNumber) => {
    if (!selectedTabNumber) {
      return null;
    }
    switch (selectedTabNumber) {
      case 0:
        return "Model";
      case 1:
        return "Graph";
      case 2:
        return "Documents";
      case 3:
        return "Monitoring";
      default:
        return null;
    }
  };

  loadData() {
    let project = this.props.project.projectId;
    let tagIds = this.props.data.tagIds;
    let selectedTab =
      this.getTabName(this.state.tabSelected) || this.props.data.selectedTab;
    let tag =
      this.props.data.tag ||
      (this.props.focusedObjectsTagNames
        ? this.props.focusedObjectsTagNames[0]
        : null);
    // If tag is null then close card and add a warning snack
    if (!tag) {
      this.props.snackBar.addSnackWarning(
        this.props.multiLang.propertyCard.unrelatedObject
      );
      this.props.options.close();
      return;
    }

    getContentCardFromTag(tag, project, (error, data) => {
      if (error) {
        console.error("[ERROR] [getContentCardFromTag] #1 : ", error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.propertyCard.unableOpenContentCard
        );
        this.props.options.close();
        return;
      }

      if (data && data !== {} && data["Related Items"] !== {}) {
        // add tab
        this.addTab(this.props.multiLang.propertyCard.properties);
        if (hasRelatedItem(data)) {
          this.addTab(this.props.multiLang.propertyCard.graph);
        }
        this.addTab(this.props.multiLang.propertyCard.documents);
        this.addTab(this.props.multiLang.propertyCard.monitoring);

        const currentTabNumber = this.getCurrentTabNumber(selectedTab);
        let equipmentChecked = this.state.equipmentChecked;

        if (data.tag) {
          this.props.options.setTitle(
            <Fragment>
              {" "}
              {constructTagLabel(data.tag, data.tagLabel)}
              <span style={{ color: "#757575", marginLeft: "10px" }}>
                ({data.tagClass})
              </span>
            </Fragment>
          );
          this.props.options.setTag({
            tagName: data.tag,
            tagClass: data.tagClass,
            tagLabel: data.tagLabel
          });
        }
        this.checkEquipmentStatus(data);
        // Check equipment attachement if it not already the case
        if (tagIds && !this.state.equipmentChecked) {
          this.checkEquipmentAttached(tagIds, data);
          equipmentChecked = true;
        }
        this.setState({
          modelData: data,
          tabSelected: currentTabNumber,
          equipmentChecked,
          tag,
          tagIds
        });
      }
    });
  }
  /**
   * Map tab name to a number
   * @param {*} selectedTab
   * @returns
   */
  getCurrentTabNumber = (selectedTab) => {
    // select current tab
    if (selectedTab) {
      switch (selectedTab) {
        case "Model":
          return 0;
        case "Graph":
          return 1;
        case "Documents":
          return 2;
        case "Monitoring":
          return 3;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  /**
   * Check equipment status
   * If equipment status is 'out of service' and the the equipment has not been replaced, show warning message
   */
  checkEquipmentStatus = (tagData) => {
    if (
      this.outOfServiceEquipment(tagData) &&
      !Object.keys(tagData["Related Items"]).includes(REPLACED_BY_RELATION)
    ) {
      this.props.snackBar.addSnackWarning(
        this.props.multiLang.propertyCard.nonRenewedEquipment
      );
    }
  };

  /**
   * Check if the equipment status is 'out of service' and still attched to an object in the model 3D,
   * although it has 'Replaced By' in its relations
   */
  checkEquipmentAttached = (tagIds, tagData) => {
    if (
      this.outOfServiceEquipment(tagData) &&
      tagIds &&
      tagIds.length > 0 &&
      Object.keys(tagData["Related Items"]).includes(REPLACED_BY_RELATION)
    ) {
      this.props.snackBar.addSnackWarning(
        this.props.multiLang.propertyCard.attachedOutOfServiceEquipment
      );
    }
  };

  /**
   * Test if it the asset is an equipment and if its status is 'out of service'
   * @param {*} tagData
   * @returns
   */
  outOfServiceEquipment = (tagData) => {
    return (
      tagData.tagClass === ASSET_VISIBLE_CLASS &&
      tagData[EQUIPMENT_STATUS_DATASET] &&
      tagData[EQUIPMENT_STATUS_DATASET][EQUIPMENT_STATUS_ATTRIBUTE] &&
      tagData[EQUIPMENT_STATUS_DATASET][EQUIPMENT_STATUS_ATTRIBUTE].value ===
        OUT_OF_SERVICE_STATUS
    );
  };

  handleTabChange(event, value) {
    this.setState({ tabSelected: value });
  }

  focusOn = () => {
    const tagIds = this.state.tagIds;
    let forgeViewers = this.props.viewers.filter(
      (v) => v.viewerType === "FORGE_VIEWER"
    );
    if (forgeViewers.length > 0) {
      sessionStorage.setItem("tagSelectionSource", "others");
      // Focus on elements with forg id
      if (tagIds) {
        forgeViewers[0].focusOnObjectsByIds(tagIds, forgeViewers[0].id);
      } else {
        forgeViewers[0].focusOnObjects(this.state.tag, forgeViewers[0].id);
      }
      // Set selected tag to the last selected element
      this.props.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: [this.state.tag],
        fromViewer: true
      });
    } else {
      this.props.snackBar.addSnackError(
        this.props.multiLang.search.focusOnObject
      );
    }
  };

  showOnly = () => {
    const tagIds = this.state.tagIds;
    let forgeViewers = this.props.viewers.filter(
      (v) => v.viewerType === "FORGE_VIEWER"
    );
    if (forgeViewers.length > 0) {
      sessionStorage.setItem("tagSelectionSource", "others");
      // Focus on elements with forg id
      if (tagIds) {
        forgeViewers[0].selectObjectsById(tagIds, forgeViewers[0].id);
      } else {
        forgeViewers[0].selectObject(this.state.tag, forgeViewers[0].id);
      }
      // Set selected tag to the last selected element
      this.props.dispatch({
        type: mainViewerAction.initTagNames,
        focusedObjectsTagNames: [this.state.tag],
        fromViewer: true
      });
    } else {
      this.props.snackBar.addSnackError(
        this.props.multiLang.search.focusOnObject
      );
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.tabSelected > -1 && (
          <Fragment>
            <div
              ref={(tabsRef) => (this.tabsRef = tabsRef)}
              style={{ display: "flex" }}
            >
              <Tabs
                value={this.state.tabSelected}
                onChange={this.handleTabChange}
                indicatorColor="primary"
              >
                <Tooltip title={this.props.multiLang.propertyCard.properties}>
                  <Tab
                    icon={<IconProperties />}
                    key={0}
                    style={{
                      textTransform: "initial",
                      minWidth: 60
                    }}
                  />
                </Tooltip>
                <Tooltip title={this.props.multiLang.propertyCard.relation}>
                  <Tab
                    icon={<IconGraph />}
                    key={1}
                    style={{
                      textTransform: "initial",
                      minWidth: 60
                    }}
                  />
                </Tooltip>
                <Tooltip title={this.props.multiLang.propertyCard.documents}>
                  <Tab
                    icon={<IconDocument />}
                    key={2}
                    style={{
                      textTransform: "initial",
                      minWidth: 60
                    }}
                  />
                </Tooltip>
                <Tooltip title={this.props.multiLang.propertyCard.monitoring}>
                  <Tab
                    icon={<IconMonitoring />}
                    key={3}
                    style={{
                      textTransform: "initial",
                      minWidth: 60
                    }}
                  />
                </Tooltip>
              </Tabs>
              <Tooltip title={this.props.multiLang.search.select}>
                <span style={{ minWidth: 60, textAlign: "center" }}>
                  <IconButton onClick={this.showOnly}>
                    <Visibility />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={this.props.multiLang.search.fitView}>
                <span style={{ minWidth: 60, textAlign: "center" }}>
                  <IconButton onClick={this.focusOn}>
                    <GpsFixed />
                  </IconButton>
                </span>
              </Tooltip>
              {/*
                <Tooltip title='Maintenance predictive'>
                  <Tab
                    icon={<BuildIcon />}
                    key={4}
                    style={{
                      textTransform: 'initial',
                      minWidth: 72
                    }}
                  />
                </Tooltip> */}
            </div>
            {this.state.tabSelected === 0 && (
              <ModelTab
                data={this.state.modelData}
                showEmptyAttribute={this.props.options.showEmptyAttribute}
              />
            )}
            {this.state.tabSelected === 1 && (
              <RelatedTab data={this.state.modelData} />
            )}
            {this.state.tabSelected === 2 && (
              <DocumentTab
                data={this.state.modelData}
                tag={this.state.tag}
                onRefresh={this.refresh}
                viewerId={this.props.data.viewerId}
              />
            )}
            {this.state.tabSelected === 3 && (
              <IotTab
                data={this.state.modelData}
                tag={this.state.tag}
                viewerId={this.props.data.viewerId}
              />
            )}
            {/*
            {this.state.tabSelected === 4 && <PredictiveMaintenance />} */}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  card: state.card,
  config: state.config,
  viewers: state.mainViewer.viewers,
  selectedObjectIds: state.mainViewer.selectedObjectIds,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames
});

export default connect(mapStateToProps)(PropertyCardPanel);
