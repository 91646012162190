/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";


function getDocumentUrl(tagId, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;
    axios.post(config.urlProjetAPI + "/document/url", {
        tagId: tagId
    },
        { headers: headers }
    ).then(function (response) {
        console.log(response);
        if (response.status === 404)
            return callback(null, {});
        return callback(null, response.data.url);
    })
        .catch(function (error) {
            callback(error);
        });
}


async function getSasToken(storageAccount, containerName) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;
    try {
        let response = await axios.get(`${config.urlFileAPI}/getBlobSasToken?containerName=${containerName}&permissions=${config.permissions}&storageAccount=${storageAccount}`, { headers: headers });
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

async function uploadFile(blobUri, file, containerName, blobName, metadata, sasToken, projectId) {

    let host = `${blobUri}/${containerName}/${blobName}?${sasToken}`;
    const headers = {
        "x-ms-blob-type": "BlockBlob",
        "x-ms-meta-projectId": projectId,
        "x-ms-meta-mappingId": metadata.datasetid,
        "x-ms-meta-userId": metadata.sub,
        "x-ms-meta-datasourcetype": metadata.datasourcetype,
        "x-ms-meta-fileId": metadata.fileid
    };
    // Add site name if it existes
    if (metadata.sitename) headers["x-ms-meta-siteName"] = metadata.sitename;

    let response = await axios.put(host, file, { headers: headers });
    if (response.status === 404)
        return {};
    return response;
}

async function downloadFile(storageAccountName, pathOfFile, token) {

    let uploadStorageUrl = `https://${storageAccountName}.blob.core.windows.net`;

    return `${uploadStorageUrl}/${pathOfFile}?${token}`;
}

export {
    uploadFile,
    getSasToken,
    downloadFile,
    getDocumentUrl
};