/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import AuthAzureADDB2C from './AuthAzureADDB2C';
import { store } from 'utils/store';
import axios from 'axios';

const AzureADDB2C = 'AzureADDB2C';

let initAuth = (callback) => {
  var config = store.getState().config;
  switch (config.authProvider) {
    case AzureADDB2C:
      AuthAzureADDB2C.initAuth(callback);
      break;
    default:
      console.error("Can't resolve Auth Provider");
  }

  addInterceptor();
};

let addInterceptor = () => {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && 401 === error.response.status) {
        console.error('interceptors.response ', error.response);
        handleUnauthorized();
      } else if (!error.response) {
        //no response => check if token expired
        handleTokenExpiration();
      } else {
        return Promise.reject(error);
      }
    }
  );
};

let isAuthenticated = () => {
  var config = store.getState().config;
  switch (config.authProvider) {
    case AzureADDB2C:
      AuthAzureADDB2C.isAuthenticated();
      break;
    default:
      console.error("Can't resolve Auth Provider");
  }
};

let goToLoginPage = () => {
  var config = store.getState().config;
  switch (config.authProvider) {
    case AzureADDB2C:
      AuthAzureADDB2C.goToLoginPage();
      break;
    default:
      console.error("Can't resolve Auth Provider");
  }
};

let handleUnauthorized = () => {
  var config = store.getState().config;
  switch (config.authProvider) {
    case AzureADDB2C:
      AuthAzureADDB2C.handleUnauthorized();
      break;
    default:
      console.error("Can't resolve Auth Provider");
  }
};

let handleTokenExpiration = () => {
  if (store.getState().config.authProvider === AzureADDB2C) {
    AuthAzureADDB2C.handleTokenExpiration();
  }
};

let logout = () => {
  var config = store.getState().config;
  switch (config.authProvider) {
    case AzureADDB2C:
      AuthAzureADDB2C.logout();
      break;
    default:
      console.error("Can't resolve Auth Provider");
  }
};

export default {
  initAuth,
  isAuthenticated,
  goToLoginPage,
  handleUnauthorized,
  logout
};
