/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import helpers from "./ForgeViewerHelper";
// eslint-disable-next-line
import ButtonGroupExtension from "./extensions/ButtonGroupExtension"; // NOSONAR
// eslint-disable-next-line
import IconMarkupExtension from "./extensions/IconMarkupExtension";
// eslint-disable-next-line
import MarkupExtension from "./extensions/MarkupExtension"; // NOSONAR
import theme from "theme/theme";
import "../main-viewer/viewer.css";
import "./ForgeViewerComponent.css";
import { mainViewerAction } from "../../../actions/MainViewerAction";

class ForgeViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valideURN: false,
      cursorValue: 0,
      viewerInitialState: {}
    };
  }

  componentDidMount() {
    if (this.props.data.forgeUrn) {
      this.setState({ valideURN: true });
      helpers.launchViewer(
        this.props.data.forgeUrn,
        this.props.tag,
        this.props.id,
        this.props.data.options
      );
      if (this.props.tag && this.props.selectedTab) {
        let data = {
          title: "Properties",
          tag: this.props.tag,
          selectedTab: this.props.selectedTab
        };
        this.props.card.openCard("PROPERTY_CARD", data, 150, 150, {
          id: this.props.tag
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewers.length > 0) {
      if (
        !_.isEqual(
          prevProps.focusedObjectsTagNames,
          this.props.focusedObjectsTagNames
        ) &&
        (this.props.synchronized || this.props.synchronized === undefined) &&
        !this.props.fromViewer
      ) {
        this.focusOnTag();
      }
      // focus on selected Tag if we switch the view
      if (
        !_.isEqual(this.props.viewers, prevProps.viewers) &&
        this.props.tagNames &&
        this.props.viewers.length > 0
      ) {
        // wait until the model is loaded
        setTimeout(() => this.focusOnTag(), 3000);
      }
    }
  }

  componentWillUnmount() {
    helpers.unloadViewer(this.props.id);
  }
  onCloseMarkup = () => {
    let viewer = this.props.viewers.find((v) => v.id === this.props.id).viewer;
    document.getElementById("markup-container").classList.remove("show");
    document.getElementById("markup-container").classList.add("hide");
    let buttonGroup = viewer.myCurrentViewer.toolbar.getControl(
      "MarkupExtension.ControlGroup3"
    );
    if (!buttonGroup) {
      viewer.myCurrentViewer
        .getExtension("Autodesk.Viewing.MarkupsCore")
        .hide(); //Hiding markups restore the toolbar
    }
    //restore the camera position
    if (this.props.selectedMarkup) {
      viewer.myCurrentViewer.restoreState(
        this.props.selectedMarkup.state,
        null,
        false
      );
    }
  };

  onCursorMove = (event) => {
    this.onSlider(event.target.value);
    this.setState({ cursorValue: event.target.value });
  };

  floorExplode = (scale, viewer) => {
    window.devicePixelRatio = 1.25;
    const THREE = window.THREE;
    let model = viewer.myCurrentViewer.model;
    var fragList = model.getFragmentList();
    var pt = new THREE.Vector3();

    //Input scale is in the range 0-1, where 0
    //means no displacement, and 1 maximum reasonable displacement.
    scale *= 2;

    var boxes = fragList.fragments.boxes;
    var nbFrags = fragList.getCount();
    for (var fragId = 0; fragId < nbFrags; ++fragId) {
      if (scale === 0) {
        fragList.updateAnimTransform(fragId);
      } else {
        var box_offset = fragId * 6;
        var cz = Math.floor(boxes[box_offset + 2] / 10) * 10;
        pt.z = cz * scale * 4;

        fragList.updateAnimTransform(fragId, null, null, pt);
      }
    }
  };

  onSlider = (val) => {
    let viewer = this.props.viewers.find((v) => v.id === this.props.id).viewer;
    this.floorExplode(val, viewer);
    viewer.myCurrentViewer.impl.sceneUpdated(true);
  };

  circ = (timeFraction) => {
    return 1 - Math.sin(Math.acos(timeFraction));
  };
  makeEaseOut = (timing) => {
    return function (timeFraction) {
      return 1 - timing(1 - timeFraction);
    };
  };
  animate = ({ timing, draw, duration }) => {
    let start = performance.now();
    requestAnimationFrame(function animate(time) {
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
      let progress = timing(timeFraction);
      draw(progress); // draw it
      if (timeFraction < 1) {
        requestAnimationFrame(animate);
      }
    });
  };

  focusOnTag = async () => {
    let viewers = this.props.viewers
      ? this.props.viewers
      : this.props.mainViewer.viewers
      ? this.props.mainViewer.viewers
      : null;
    let viewer = viewers ? viewers.find((v) => v.id === this.props.id) : null;
    if (!viewer) {
      console.error("Viewer non défini");
      return;
    }
    const focusedObjectsTagNames = this.props.focusedObjectsTagNames;
    if (focusedObjectsTagNames && focusedObjectsTagNames.length > 0) {
      sessionStorage.setItem("tagSelectionSource", "others");
      const tagIds = await viewer.focusOnObjects(
        focusedObjectsTagNames,
        this.props.id
      );
      this.props.dispatch({
        type: mainViewerAction.initObjectIds,
        selectedIds: { tag: focusedObjectsTagNames[0], tagIds }
      });
    } else {
      sessionStorage.setItem("tagSelectionSource", "others");
      viewer.focusOnObjects(null, this.props.id);
    }
  };

  resetView = () => {
    //window.NOP_VIEWER.restoreState(this.state.viewerInitialState);
    this.animate({
      timing: this.makeEaseOut(this.circ()),
      draw(progress) {
        this.onSlider(1 - progress);
      },
      duration: 1500
    });
  };

  render() {
    let renderComponent;
    if (this.state.valideURN) {
      renderComponent = (
        <Fragment>
          <div id={this.props.id} className="viewer-container "></div>
          <canvas id="myCanvas" hidden></canvas>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            id="sliderHorizontal"
            className="hide"
            value={this.state.cursorValue}
            onChange={(event) => this.onCursorMove(event)}
          />

          <div style={theme.markup} id="markup-container" className="hide">
            <img id="markup__screenshot" style={theme.imgMarkup} alt="" />
            <br />
            <Button
              style={theme.closeMarkUp}
              onClick={this.onCloseMarkup}
              variant="contained"
              color="primary"
            >
              {this.props.multiLang.forgeViewer.closeMarkup}
            </Button>
          </div>
        </Fragment>
      );
    } else {
      renderComponent = (
        <div id="errorUrn" className="jumbotron">
          <h1 className="display-4">
            {this.props.multiLang.forgeViewer.urnInvalide}
          </h1>
          <p className="lead">
            {this.props.multiLang.forgeViewer.urnInvalideMessage}
          </p>
          <hr className="my-4" />
        </div>
      );
    }
    return renderComponent;
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  project: state.project,
  selectedMarkup: state.selectMarkup,
  mainViewer: state.mainViewer,
  viewers: state.mainViewer.viewers,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  fromViewer: state.mainViewer.fromViewer,
  card: state.card
});

export default connect(mapStateToProps)(ForgeViewer);
