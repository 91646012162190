/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
import { store } from 'utils/store';

function constructTagLabel(tagId= "", tagLabel= ""){
    const config = store.getState().config;
    const tagTemplate = config.functional.tagLabel.template;
    const limit = config.functional.tagLabel.limit;
    const regexTagId = /%tagId%/gi;
    const regexTagLabel = /%tagLabel%/gi;

    tagLabel = tagLabel ? tagLabel : "";

    if(tagLabel.length > limit){
        tagLabel = tagLabel.substring(0,limit);
        tagLabel = tagLabel + '...';
    }

    let result = tagTemplate.replace(regexTagId, tagId);
    result = result.replace(regexTagLabel, tagLabel);

    if(!tagLabel || tagLabel === ""){
        result = result.slice(0, -2);
    }

    return result;

}

export {constructTagLabel};