/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from "utils/store";

function getAllUserRole(callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    console.log(headers);
    // PROJECT INSTEAD OF PROJET
    axios.get(config.urlProjectAPI + '/getUserRole', {
        headers: headers
    })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getUsers() {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.get(`${config.urlUserSettingAPI}/users/list`, { headers: headers });
}

function getUsersGroupsList(username) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.get(`${config.urlUserSettingAPI}/user/${username}/groups`, { headers: headers });
}

function deleteUserFromGroup(userId, groupId) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.delete(`${config.urlUserSettingAPI}/user/${userId}/group/${groupId}`, { headers: headers });
}

function addUserToGroup(userId, groupId) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    let url = `${config.urlUserSettingAPI}/user/${userId}/group/${groupId}`;

    return axios.put(url,
        {},
        { headers: headers }
    );
}

function enableUser(username) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.post(`${config.urlUserSettingAPI} /user/${username} /enable`, { headers: headers })
}

async function createUser(user, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    const login = user.login;
    delete user.login;

    for (const key of Object.keys(user)) {
        if (!user[key]) {
            delete user[key];
        }
    }

    try {
        let response = await axios.put(`${config.urlUserSettingAPI}/user/${login}`, user, { headers: headers });
        return callback(null, response);
    } catch (error) {
        return callback(error);
    }
}

function disableUser(username) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.post(`${config.urlUserSettingAPI}/user/${username}/disable`, { headers: headers })
}

function deleteUser(username, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    try {
        let response = axios.delete(`${config.urlUserSettingAPI}/users/${username}`, { headers: headers });
        return callback(null, response);
    } catch (error) {
        return callback(error);
    }
}

function updateUserAttributes(username, attributes) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    return axios.patch(`${config.urlUserSettingAPI}/user/${username}`, { attributes: attributes }, { headers: headers })
}

function getUserByFilter(filterName, filterValue, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(config.urlUserSettingAPI + '/users', { headers: headers, params: { filterName: filterName, filterValue: filterValue } })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getUserProjects(callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios.get(`${config.urlUserService}/user/projects`, { headers: headers })
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0)
                return callback("No data");
            return callback(null, response.data);
        })
        .catch(function (error) {
            return callback(error);
        });
}

function getUserAdminProject() {
    let headers = store.getState().auth.headers;
    let config = store.getState().config;
    return axios.get(`${config.urlUserService}/users/admin`, { headers: headers });
}

function changePassword(oldPassword, newPassword, accessToken, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.patch(`${config.urlUserSettingAPI}/user/changePassword`,
        { oldPassword: oldPassword, newPassword: newPassword, accessToken: accessToken },
        { headers: headers }
    );
}

async function getUserTagFavourites(userId, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;
    try {
        const response = await axios.get(`${config.urlUserSettingAPI}/users/${userId}/tag-favourites`, { headers: headers });
        return callback(null, response.data);
    }
    catch (error) {
        return callback(error);
    }
}

async function removeFavouriteTag(userId, tagId, callback) {
    const headers = store.getState().auth.headers;
    const config = store.getState().config;

    const encodedTagId = encodeURIComponent(tagId);
    try {
        await axios.delete(`${config.urlUserSettingAPI}/users/${userId}/tag-favourites/${encodedTagId}`, { headers: headers });
        return callback(null);
    }
    catch (error) {
        return callback(error);
    }
}

async function addUserTagFavourite(userId, tag, project, model, viewer, callback) {
    if (validInputParameters(userId, tag, project, model, viewer)) {
        const headers = store.getState().auth.headers;
        const config = store.getState().config;

        const favouriteTag = buildFavouriteTagObject(project, userId, tag, model, viewer);

        try {
            await axios.put(`${config.urlUserSettingAPI}/users/${userId}/tag-favourite`,
                favouriteTag,
                { headers: headers }
            );
            return callback(null);
        }
        catch (error) {
            return callback(error);
        }
    } else {
        return callback('Some input parameters are missing');
    }
}

function buildFavouriteTagObject(project, userId, tag, model, viewer) {
    return {
        "ProjectName": project.projectName,
        "UserId": userId,
        "ProjectId": project.projectId,
        "TagClass": tag.tagClass,
        "Tag": tag.tagName,
        "CompositeSortKey": project.projectId + '#' + tag.tagName,
        "FileName": model.modelFilename,
        "Model": model.modelId,
        "Viewer": viewer,
        "Revision": model.revision,
        "TagLabel": tag.tagLabel
    };
}

function validInputParameters(userId, tag, project, model, viewer) {
    // Added for OCC debug // TODO Remove later
    console.log('userId', userId);
    console.log('tag', tag);
    console.log('project', project);
    console.log('model', model);
    console.log('viewer', viewer);
    return (userId && tag && project && model && viewer);
}

export {
    getUserAdminProject,
    updateUserAttributes,
    changePassword,
    getAllUserRole,
    getUsers,
    getUsersGroupsList,
    deleteUserFromGroup,
    addUserToGroup,
    enableUser,
    disableUser,
    getUserByFilter,
    getUserProjects,
    getUserTagFavourites,
    removeFavouriteTag,
    addUserTagFavourite,
    createUser,
    deleteUser
};
