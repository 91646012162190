/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from 'react'

const IconMonitoring = props => (
  <svg width={24} height={24} viewBox="0 0 18 18" {...props}>
    <g fillRule="evenodd">
      <path d="M14.613 6.773a5.946 5.946 0 0 0-1.355-2.027l1.277-1.277c.95.949 1.602 2.02 1.961 3.215A8 8 0 0 1 16.824 9v.004c0 1.195-.234 2.293-.703 3.293a7.798 7.798 0 0 1-1.586 2.238l-1.277-1.281c.465-.469.84-.973 1.12-1.516.427-.824.642-1.738.638-2.734V9a5.966 5.966 0 0 0-.403-2.227zm0 0" />
      <path d="M12.363 5.66c.309.309.567.64.77.996h.004c.402.696.601 1.477.601 2.34V9c0 .898-.215 1.703-.648 2.422-.2.328-.445.637-.735.93v-.004l-.003.004-1.282-1.282c.086-.086.16-.175.23-.265.419-.516.626-1.117.63-1.805v-.004c0-.805-.285-1.496-.856-2.062l1.281-1.286c.004.004.008.008.008.012zm0 0M7.938 7.93a1.441 1.441 0 0 1 1.066-.438c.414-.004.77.145 1.066.438v.004c.293.293.438.648.438 1.062V9c0 .414-.145.77-.438 1.063-.004 0-.004 0-.004.003a1.46 1.46 0 0 1-1.062.434c-.418 0-.77-.145-1.063-.438h-.003A1.46 1.46 0 0 1 7.5 9.005v-.008c0-.414.145-.77.434-1.062.003 0 .003 0 .003-.004zm0 0M4.262 8.996c0-.059.004-.117.004-.172.039-1.234.5-2.293 1.382-3.176L6.93 6.93a2.813 2.813 0 0 0-.856 2.066v.008c0 .09.004.176.012.266a2.773 2.773 0 0 0 .848 1.8l-1.286 1.282c-.921-.926-1.382-2.043-1.386-3.348v-.008zm0 0" />
      <path d="M1.246 7.922c.215-1.703.957-3.188 2.223-4.453l1.277 1.277C3.72 5.777 3.141 6.988 3.012 8.391c-.02.199-.028.402-.028.613 0 1.656.586 3.074 1.758 4.25l.004.004-1.281 1.277c-1.527-1.527-2.29-3.37-2.285-5.531 0-.371.02-.734.066-1.082zm0 0" />
    </g>
  </svg>
)

export default IconMonitoring
