/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import { updateFileProperities } from "services/FileService";

class FileProperty extends React.Component {
  state = {
    siteName: ''
  };

  async componentDidMount() {
    this.setState({ siteName: this.props.siteName })
  }

  closeFilePropertyModal = () => {
    this.props.closeFilePropertyModal();
  }

  save = () => {
    updateFileProperities(
      this.props.projectId,
      this.props.selectedFile.fileId,
      {siteName: this.state.siteName}
    ).then(
      _ => {
        this.props.updateFileProperities();
        this.closeFilePropertyModal();
      },
      _ => {
        this.props.snackBar.addSnackError(
          this.props.multiLang.projectFile.errorWhileSavingSiteName
        );
      }
    );

  };

  render() {
    return (
      <Dialog
        open={this.props.filePropertyModalOpen}
        onClose={this.closeFilePropertyModal}
        maxWidth="md"
        style={{ flexGrow: 0 }}
      >
        <DialogTitle id="form-dialog-title">
          {this.props.multiLang.projectFile.update}&nbsp;{this.props.selectedFile.key}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper
            style={{ padding: 20, margin: 10, flexGrow: 0, flexWrap: "wrap" }}
          >
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              {this.props.multiLang.projectFile.fileProperties}
            </Typography>
            <div style={{ margin: "10px" }}>
              <TextField
                label={this.props.multiLang.projectFile.siteName}
                fullWidth={true}
                type='text'
                value={this.state.siteName}
                onChange={(event) => this.setState({ siteName: event.target.value })}
              />
            </div>
          </Paper>
        </div>
        <div style={{ margin: 10 }}>
          <DialogActions>
            <Button
              onClick={() => {
                this.closeFilePropertyModal();
              }}
              color="primary"
            >
              {this.props.multiLang.administration.cancel}
            </Button>
            <Button disabled={this.state.siteName.trim() === this.props.siteName.trim()}
              onClick={this.save} color="primary" variant="contained">
              {this.props.multiLang.administration.save}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(FileProperty);
