/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getSensorHistory,
  actuator,
  openConnection
} from 'services/IotService';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AddAlert from '@material-ui/icons/AddAlert';
import Fullscreen from '@material-ui/icons/Fullscreen';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVert from '@material-ui/icons/MoreVert';
import Router from '@material-ui/icons/Router';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import AddSenssorThresholdModal from './AddSensorThresholdModal';
import { CardActions } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import theme from 'theme/theme';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import More from '@material-ui/icons/More';
import './IotTab.css';

class IotTabChart extends Component {
  state = {
    anchorEl: null,
    isAddThresholdModalIsOpen: false,
    isAttachSensorModalIsOpen: false,
    startDate: moment().minute(moment().minute() - 20),
    sensorType: null,
    sensorUnit: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'minute',
              tooltipFormat: 'h:mm:ss a',
              displayFormats: {
                hour: 'MMM D, h:mm A'
              }
            },
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Date'
            }
          }
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              stepSize: 3,
              beginAtZero: true
            },
            scaleLabel: {
              display: false,
              labelString: ''
            }
          }
        ]
      }
    },
    initialized: false,
    sensorData: {
      datasets: [
        {
          fill: false,
          legend: {
            display: false,
            position: 'bottom'
          },
          lineTension: 0.2,
          backgroundColor: '#12ABBD',
          borderColor: '#0070AD',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#0070AD',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#0070AD',
          pointHoverBorderColor: '#ECECEC',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          borderWidth: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }
  };

  // instance of chartjs context (used for update chart)
  chartReference = {};

  async componentDidMount() {
    this.getSensorHistory();
    if (!this.connection) {
      this.connection = await openConnection(this.props.deviceId);
      let cloudConfig = this.props.cloudConfig;
      if (cloudConfig === 'AzureADDB2C') {
        this.connection.on('IoTUpdated', (data) => {
          console.log('IoTUpdated WS: ', data);
          if (data.deviceId === this.props.deviceId) {
            if (!this.state.initialized)
              this.initializeGraph(
                [data],
                data.type + (data.unit ? ' - ' + data.unit : ' '),
                data.type + (data.unit ? ' - ' + data.unit : ' '),
                data.type,
                data.unit
              );
            this.updateChartData([data]);
          }
        });
      } else {
        this.connection.onmessage = (evt) => {
          console.log('New WS: ' + JSON.stringify(evt.data));
          var data = JSON.parse(evt.data);
          if (!this.state.initialized)
            this.initializeGraph(
              [data],
              data.type + (data.unit ? ' - ' + data.unit : ' '),
              data.type + (data.unit ? ' - ' + data.unit : ' '),
              data.type,
              data.unit
            );
          else this.updateChartData([data]);
        };
      }
    }
  }

  componentWillUnmount() {
    if (this.props.config.cloud !== 'AZURE') {
      this.connection.close();
    }
  }
  /**
   * get sensor data from server
   */
  getSensorHistory = () => {
    getSensorHistory(
      this.props.deviceId,
      this.state.startDate.toISOString(),
      (error, data) => {
        if (error) {
          console.error('error : ', error);
          return;
        } else {
          if (data.length > 0) {
            this.initializeGraph(
              data,
              data[0].type + (data[0].unit ? ' - ' + data[0].unit : ' '),
              data[0].type + (data[0].unit ? ' - ' + data[0].unit : ' '),
              data[0].type,
              data[0].unit
            );
          }
        }
      }
    );
  };

  /**
   * initialize the graphe the first time we recieve data
   */
  initializeGraph = (data, title, yAxesLabel, sensorType, sensorUnit) => {
    var options = { ...this.state.options };
    if (!this.isDiscretType(sensorType)) {
      // use chart instance context to reset zoom
      //this.chartReference.chartInstance.resetZoom();

      //set chart title
      options.title.text = title;

      // set axes label
      options.scales.yAxes[0].scaleLabel.labelString = yAxesLabel;
      options.scales.yAxes[0].ticks.beginAtZero = false;
    }

    // Update label of graph
    var sensorData = { ...this.state.sensorData };
    sensorData.datasets[0].label = this.props.multiLang.propertyCard.sensorData;

    this.setState({
      initialized: true,
      sensorData,
      sensorType,
      sensorUnit,
      options
    });
    this.updateChartData(data);
  };

  updateChartData = (data) => {
    var sensorData = { ...this.state.sensorData };
    if (this.isDiscretType(data[0].type)) {
      // for discret data, only show last value
      // First initialization of data
      if (sensorData.datasets[0].data.length === 0) {
        sensorData.datasets[0].data[0] = {
          date: moment(data[0].date),
          state: Boolean(data[0].value)
        };
      }

      data.forEach((object) => {
        if (moment(object.date).isAfter(sensorData.datasets[0].data[0].date)) {
          sensorData.datasets[0].data[0] = {
            date: moment(object.date),
            state: Boolean(object.value)
          };
        }
      });
    } else {
      let parseData = [];
      // for time data, create each time value objet used by data chart
      data.forEach((object) => {
        parseData.push({ t: moment(object.date), y: object.value });
      });
      sensorData.datasets[0].data = sensorData.datasets[0].data
        .concat(parseData)
        .sort((a, b) => {
          return a.t.isAfter(b.t);
        });
    }

    this.setState({ sensorData });
  };

  isDiscretType = (value) => {
    if (value) return 'presence' === value;
    else return 'presence' === this.state.sensorType;
  };

  isActuativeType = () => {
    return this.state.sensorType === 'ConnectedPlug';
  };

  isDataPresent = () => {
    return (
      this.state.initialized &&
      this.state.sensorData.datasets[0].data.length > 0
    );
  };

  getCardTitle = () => {
    if (this.isDataPresent()) {
      return (
        <div class='copyDisplay'>
          {this.state.sensorType +
            (this.state.sensorUnit ? ' - ' + this.state.sensorUnit : ' ')}
          <FileCopyOutlinedIcon
            style={{
              marginLeft: '95%',
              marginTop: '-50px',
              color: theme.blueTextarea
            }}
            onClick={() => this.copyToClipboard()}
          />
        </div>
      );
    } else {
      return ' (' + this.props.multiLang.propertyCard.noDataSensor + ')';
    }
  };

  copyToClipboard = () => {
    return navigator.clipboard.writeText(
      this.state.sensorType +
        ' - ' +
        this.state.sensorData.datasets[0].data[
          this.state.sensorData.datasets[0].data.length - 1
        ].y +
        ' ' +
        this.state.sensorUnit
    );
  };

  toggleConnectedPlug = (action) => {
    actuator(action, (err, response) => {
      if (err) {
        this.props.snackBar.addSnackError(
          this.props.multiLang.propertyCard.errorToggleStateConnectedPlug
        );
      } else {
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.propertyCard.successToggleConnectedPlug
        );
      }
    });
  };

  openAction = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  closeAction = () => {
    this.setState({ anchorEl: null });
  };

  handelAddThresholdModal = (value) => {
    this.setState({ isAddThresholdModalIsOpen: value });
  };

  expandChart = () => {
    const marginX = 200;
    const marginY = 100;
    const size = this.props.mainViewer.size;

    var positionX;
    var positionY;
    const width = size.mainViewer.width - marginX;
    const height = size.mainViewer.height - marginY * 1.5;

    positionX = marginX / 2;
    positionY = size.navBar.height + marginY / 2;

    var data = {
      title: this.props.tag + ' - ' + this.props.deviceId,
      tag: this.props.tag,
      deviceId: this.props.deviceId,
      chartHeight: (height * 70) / 100 // 70% of card height
    };
    this.props.card.openCard('EXPAND_CHART_CARD', data, positionX, positionY, {
      height: height,
      width: width
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.isAddThresholdModalIsOpen && (
          <AddSenssorThresholdModal
            isOpen={this.state.isAddThresholdModalIsOpen}
            onClose={this.handelAddThresholdModal}
            tag={this.props.tag}
            deviceId={this.props.deviceId}
            viewerId={this.props.viewerId}
          />
        )}

        <Card
          className={
            'cardMargin ' + (this.isDataPresent() ? '' : 'noDataBorder')
          }
        >
          <CardHeader
            title={this.getCardTitle()}
            avatar={<Router style={{ marginTop: '-30px' }} />}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {!this.isDataPresent() ? (
                  ''
                ) : this.isDiscretType() ? (
                  <Fragment>
                    <Grid container justify='center' alignItems='center'>
                      <Grid item>
                        <div
                          style={{
                            color: this.state.sensorData.datasets[0].data[0]
                              .state
                              ? theme.color.green
                              : theme.color.red,
                            fontSize: 'x-large'
                          }}
                        >
                          {this.state.sensorData.datasets[0].data[0].state
                            ? 'ON'
                            : 'OFF'}
                        </div>
                      </Grid>
                    </Grid>

                    <div>
                      {this.state.sensorData.datasets[0].data[0].date.format(
                        'dddd, MMMM Do YYYY, h:mm:ss a'
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Line
                    height={200}
                    data={this.state.sensorData}
                    options={this.state.options}
                    ref={(reference) => (this.chartReference = reference)}
                  />
                )}
              </Grid>
            </Grid>
            <CardActions style={{ display: 'flex' }}>
              {this.isActuativeType() && (
                <Fragment>
                  <Fab
                    variant='extended'
                    style={{
                      background: theme.color.green,
                      color: 'white',
                      height: '23px',
                      border: 'none',
                      textAlign: 'center'
                    }}
                    onClick={() => this.toggleConnectedPlug('on')}
                  >
                    On
                  </Fab>
                  <Fab
                    variant='extended'
                    style={{
                      background: theme.color.red,
                      color: 'white',
                      height: '23px',
                      border: 'none',
                      textAlign: 'center'
                    }}
                    onClick={() => this.toggleConnectedPlug('off')}
                  >
                    Off
                  </Fab>
                </Fragment>
              )}
              <IconButton
                onClick={this.openAction}
                style={{ marginLeft: 'auto' }}
                aria-owns={
                  this.state.anchorEl ? 'monitoring-card-menu' : undefined
                }
                aria-haspopup='true'
              >
                <MoreVert />
              </IconButton>

              <Menu
                id='monitoring-card-menu'
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.closeAction}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                getContentAnchorEl={null}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                style={{ color: 'red' }}
                disableAutoFocusItem={true}
              >
                <MenuItem
                  className='menuItem'
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.card.openCard(
                      'PROPERTY_CARD',
                      { title: 'Properties', tag: this.props.deviceId },
                      150,
                      150,
                      { id: this.props.deviceId }
                    );
                    this.closeAction();
                  }}
                >
                  <ListItemIcon>
                    <More style={{ color: theme.color.lightBlue }} />
                  </ListItemIcon>
                  {this.props.multiLang.toolbar.details}
                </MenuItem>

                <MenuItem
                  className='menuItem'
                  onClick={() => {
                    this.handelAddThresholdModal(true);
                    this.closeAction();
                  }}
                >
                  <ListItemIcon>
                    <AddAlert style={{ color: theme.color.lightBlue }} />
                  </ListItemIcon>
                  {this.props.multiLang.propertyCard.addThreshold}
                </MenuItem>
                <MenuItem
                  disabled={!this.isDataPresent()}
                  className='menuItem'
                  onClick={(e) => {
                    e.stopPropagation();
                    this.expandChart();
                    this.closeAction();
                  }}
                >
                  <ListItemIcon>
                    <Fullscreen style={{ color: theme.color.lightBlue }} />
                  </ListItemIcon>
                  {this.props.multiLang.propertyCard.expand}
                </MenuItem>
                <MenuItem disabled>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  {this.props.multiLang.propertyCard.unAttach}
                </MenuItem>
              </Menu>
            </CardActions>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  card: state.card,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  mainViewer: state.mainViewer,
  config: state.config,
  cloudConfig: state.config.authProvider
});

export default connect(mapStateToProps)(IotTabChart);
