/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGroupsByProject } from 'services/ProjectService';
import { getClasses, getRelations } from 'services/CatalogueService';
import MUIDataTable from 'mui-datatables';
import {
  Grid,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Chip,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import './AddBreakdown.css';
import ScrollArea from 'react-scrollbar';

import InputFilterChips from './InputFilterChips';

class AddBreakdown extends Component {
  state = {
    breakdownName: '',
    breakdownDescription: '',
    groups: [],
    groupsSelected: [],
    classes: [],
    classesSelected: [],
    relationships: [],
    relationshipsSelected: [],
    relationShipLabelSelected: [],
    specificClass: '',
    specificRelationship: {},
    classExpansion: [],
    classExpansionSelected: [],
    inputClassValue: '',
    defaultBreakdownChecked: this.props.isDefault,
    rootTagFilterChecked: false,
    inputRelationValue: '',
    groupOpen: false
  };

  columnsIn = [
    {
      name: this.props.multiLang.administration.classes,
      label: this.props.multiLang.administration.classes,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: this.props.multiLang.administration.relationships,
      label: this.props.multiLang.administration.relationships,
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    onRowsDelete: (rowsDeleted) => {
      this.deleteClassExpansion(rowsDeleted);
    }
  };

  componentDidMount() {
    if (this.props.edit && this.props.breakdown) {
      this.setState({
        breakdownName: this.props.breakdown.breakdown.Name,
        breakdownDescription: this.props.breakdown.breakdown.Description,
        groupsSelected: this.props.breakdown.groups,
        rootTagFilterChecked: this.props.breakdown.breakdown.RootTagFilter || false,
        classesSelected: this.props.breakdown.breakdown.RootClass,
        relationshipsSelected: this.props.breakdown.breakdown.RootRelationship,
        relationShipLabelSelected: this.props.breakdown.breakdown.RootRelationship.map(
          (item) => {
            return item.label;
          }
        ),
        classExpansionSelected: this.props.breakdown.breakdown
          .SpecificClassExpansion,
        classExpansion: this.props.breakdown.breakdown.SpecificClassExpansion.map(
          (item) => {
            return [item.class, item.relation.label];
          }
        )
      });
    }

    getGroupsByProject(this.props.projectid)
      .then((data) => {
        this.setState({ groups: data.data.result });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleClasseChange = (item) => {
    let newSelectedItem = [...this.state.classesSelected];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    this.setState({ classesSelected: newSelectedItem, inputClassValue: '' });
  };

  handleRelationChange = (value) => {
    let newSelectedLabelItem = [...this.state.relationShipLabelSelected];
    let newSelectedItem = [...this.state.relationshipsSelected];

    if (newSelectedLabelItem.indexOf(value) === -1) {
      newSelectedLabelItem = [...newSelectedLabelItem, value];
      let realtionSelected = this.state.relationships.find(function (element) {
        return element.label === value;
      });
      newSelectedItem = [...newSelectedItem, realtionSelected];
    }
    this.setState({
      relationShipLabelSelected: newSelectedLabelItem,
      relationshipsSelected: newSelectedItem,
      inputRelationValue: ''
    });
  };

  handleClassDelete = (item) => {
    const newSelectedItem = [...this.state.classesSelected];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    this.setState({ classesSelected: newSelectedItem });
  };

  handlerelationDelete = (item) => {
    const newSelectedLabelItem = [...this.state.relationShipLabelSelected];
    newSelectedLabelItem.splice(newSelectedLabelItem.indexOf(item), 1);

    let newSelectedItem = [...this.state.relationshipsSelected];
    newSelectedItem = newSelectedItem.filter((value) => {
      return value.label !== item;
    });
    this.setState({
      relationShipLabelSelected: newSelectedLabelItem,
      relationshipsSelected: newSelectedItem
    });
  };

  onSelectClassChange = (event) => {
    let value = event ? event.target.value : '';
    this.setState({ inputClassValue: value });
    getClasses(this.props.projectid, value, (error, data) => {
      if (error) {
        console.error(error);
      } else {
        this.setState({ classes: data });
      }
    });
  };

  onSelectrelationChange = (event) => {
    let value = event ? event.target.value : '';
    this.setState({ inputRelationValue: value });
    getRelations(this.props.projectid, value, '', (error, data) => {
      if (error) {
        console.error(error);
      } else {
        this.setState({ relationships: data });
      }
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectChange = (event, name) => {
    this.setState({ [name]: event.target.value });
    // close group list after selection
    if(name === 'groupsSelected') this.setState({groupOpen :false});
  };

  addSpecificExpansion = () => {
    let tab = [...this.state.classExpansion];
    let updateClassExpansionSelected = [...this.state.classExpansionSelected];
    tab.push([this.state.specificClass, this.state.specificRelationship.label]);
    updateClassExpansionSelected.push({
      class: this.state.specificClass,
      relation: this.state.specificRelationship
    });
    this.setState({
      classExpansion: tab,
      classExpansionSelected: updateClassExpansionSelected,
      specificClass: '',
      specificRelationship: {}
    });
  };

  deleteClassExpansion = (classes) => {
    let indexesInReverseOrder = classes.data
      .map((element) => element.dataIndex)
      .reverse();

    let updateClassExpansion = [...this.state.classExpansion];
    let updateClassExpansionSelected = [...this.state.classExpansionSelected];

    for (let index of indexesInReverseOrder) {
      updateClassExpansion.splice(index, 1);
      updateClassExpansionSelected.splice(index, 1);
    }

    this.setState({
      classExpansion: updateClassExpansion,
      classExpansionSelected: updateClassExpansionSelected
    });
  };

  onSpecifiqueClassClicked = () => {
    getClasses(this.props.projectid, '', (error, data) => {
      if (error) {
        console.error(error);
      } else {
        this.setState({ classes: data });
      }
    });
  };

  onSpecifiqueRelationClicked = () => {
    getRelations(this.props.projectid, '', 100, (error, data) => {
      if (error) {
        console.error(error);
      } else {
        this.setState({ relationships: data });
      }
    });
  };

  handleDefaultBreakDownChange = (defaultBreakdownEvent) => {
    if (!this.props.isDefault) {
      this.setState({
        defaultBreakdownChecked: defaultBreakdownEvent.target.checked
      });
    }
  };

  handleRootTagFilterChange = (e) => {
    this.setState({
      rootTagFilterChecked: e.target.checked
    });
  }
  // unSelect group
  deleteGroup(group) {
    let groups = this.state.groupsSelected.filter((element) => element._id !== group._id);
    this.setState({groupsSelected : groups});
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth='md'
        style={{ flexGrow: 0 }}
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.edit
            ? this.props.multiLang.administration.editBreakdown
            : this.props.multiLang.administration.createBreakdown}
        </DialogTitle>
        <div style={{ margin: 10 }}>
          <Paper
            style={{ padding: 20, margin: 10, flexGrow: 0, flexWrap: 'wrap' }}
          >
            <form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 0 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={this.props.edit ? true : false}
                      value={this.state.breakdownName}
                      name='breakdownName'
                      id='standard-name'
                      label={this.props.multiLang.administration.name}
                      fullWidth={true}
                      type='text'
                      onChange={(event) => this.handleInputChange(event)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      value={this.state.breakdownDescription}
                      type='text'
                      name='breakdownDescription'
                      id='standard-name'
                      label={this.props.multiLang.administration.description}
                      fullWidth={true}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className='formControl' fullWidth={true} required>
                      <InputLabel htmlFor='select-multiple-chip'>
                        {this.props.multiLang.administration.groups}
                      </InputLabel>
                      <Select
                        multiple
                        open={this.state.groupOpen}
                        onOpen={()=> this.setState({groupOpen : true})}
                        onClose={()=> this.setState({groupOpen : false})}
                        value={this.state.groupsSelected}
                        onChange={(e) =>
                          this.handleSelectChange(e, 'groupsSelected')
                        }
                        input={<Input id='select-multiple-chip' />}
                        renderValue={(selected) => (
                          <div className='chips'>
                            {selected.map((value) => (
                              <Chip
                                key={value.ZoneId}
                                label={value.Name}
                                className='chip'
                                onDelete={() => this.deleteGroup(value)}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.groups.map((group) => (
                          <MenuItem key={group.ZoneId} value={group}>
                            {group.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            this.props.isDefault ||
                            this.state.defaultBreakdownChecked
                          }
                          onChange={(defaultBreakdownEvent) =>
                            this.handleDefaultBreakDownChange(
                              defaultBreakdownEvent
                            )
                          }
                          value='checkedB'
                          color='primary'
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography>
                          {
                            this.props.multiLang.administration
                              .defaultBreakdownStructure
                          }
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            this.state.rootTagFilterChecked
                          }
                          onChange={(e) =>
                            this.handleRootTagFilterChange(
                              e
                            )
                          }
                          value='checkedB'
                          color='primary'
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography>
                          {
                            this.props.multiLang.administration
                              .breakdownRootTagFilter
                          }
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputFilterChips
                      required
                      onchange={this.handleClasseChange}
                      inputValue={this.state.inputClassValue}
                      onInputchange={this.onSelectClassChange}
                      suggestions={this.state.classes}
                      onDelete={this.handleClassDelete}
                      itemSelected={this.state.classesSelected}
                      inputLabel={this.props.multiLang.administration.classes}
                      placeholder={
                        this.props.multiLang.administration.selectClass
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputFilterChips
                      required
                      inputValue={this.state.inputRelationValue}
                      onchange={this.handleRelationChange}
                      onInputchange={this.onSelectrelationChange}
                      suggestions={this.state.relationships.map((item) => {
                        return item.label;
                      })}
                      onDelete={this.handlerelationDelete}
                      itemSelected={this.state.relationShipLabelSelected}
                      inputLabel={
                        this.props.multiLang.administration.relationships
                      }
                      placeholder={
                        this.props.multiLang.administration
                          .relationshipsToFollow
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color='primary' style={{ fontWeight: 'bold' }}>
                      {' '}
                      {
                        this.props.multiLang.administration
                          .specificClassExpansion
                      }{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth={true} >
                      <InputLabel htmlFor='specific-class'>
                        {this.props.multiLang.administration.selectClass}
                      </InputLabel>
                      <Select
                        onOpen={this.onSpecifiqueClassClicked}
                        value={this.state.specificClass}
                        onChange={(e) =>
                          this.handleSelectChange(e, 'specificClass')
                        }
                        inputProps={{
                          id: 'specific-class'
                        }}
                      >
                        {this.state.classes.map((classValue) => (
                          <MenuItem key={classValue} value={classValue}>
                            {classValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor='specific-relationship'>
                        {this.props.multiLang.administration.selectRelationship}
                      </InputLabel>
                      <Select
                        onClick={this.onSpecifiqueRelationClicked}
                        value={this.state.specificRelationship}
                        onChange={(e) =>
                          this.handleSelectChange(e, 'specificRelationship')
                        }
                        inputProps={{
                          id: 'specific-relationship'
                        }}
                        renderValue={(v) => v.label}
                      >
                        {this.state.relationships.map((relation) => (
                          <MenuItem
                            key={relation.label}
                            value={relation}
                          >
                            {relation.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={
                        this.state.specificClass &&
                          Object.keys(this.state.specificRelationship).length !==
                          0
                          ? false
                          : true
                      }
                      onClick={() => this.addSpecificExpansion()}
                    >
                      <AddIcon />
                      {this.props.multiLang.administration.add}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
            <ScrollArea smoothScrolling>
              <div style={{ marginTop: 10 }}>
                <MUIDataTable
                  title=''
                  data={this.state.classExpansion}
                  columns={this.columnsIn}
                  options={this.options}
                />
              </div>
            </ScrollArea>
          </Paper>
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='float-right'
            onClick={
              this.props.edit
                ? () =>
                  this.props.update(
                    this.props.projectid,
                    this.state.breakdownDescription,
                    this.state.defaultBreakdownChecked,
                    this.state.classesSelected,
                    this.state.relationshipsSelected,
                    this.state.groupsSelected,
                    this.state.classExpansionSelected,
                    this.state.rootTagFilterChecked
                  )
                : () =>
                  this.props.add(
                    this.props.projectid,
                    this.state.breakdownName,
                    this.state.breakdownDescription,
                    this.state.defaultBreakdownChecked,
                    this.state.classesSelected,
                    this.state.relationshipsSelected,
                    this.state.groupsSelected,
                    this.state.classExpansionSelected,
                    this.state.rootTagFilterChecked
                  )
            }
            disabled={!this.state.breakdownName.trim() || !this.state.classesSelected.length > 0 || !this.state.relationshipsSelected.length > 0 || !this.state.groupsSelected.length > 0}
          >
            {this.props.edit
              ? this.props.multiLang.administration.edit
              : this.props.multiLang.propertyCard.create}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  userId: state.auth.userId,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(AddBreakdown);
