/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

function computeProgress(step) {
    let completedProgess;
    const stepTotal = 4;
    let stepName;
    // TODO traduction
    switch (step) {
        case "WAITING START CONVERSION":
            completedProgess = 100 * 0 / stepTotal;
            stepName = "WAITING START CONVERSION";
            break;
        case "CONVERTING":
            completedProgess = 100 * 1 / stepTotal;
            stepName = "CONVERTING";
            break;
        case "UPDATING INFOCARD":
            completedProgess = 100 * 3 / stepTotal;
            stepName = "UPDATING INFOCARD";
            break;
        case "FINISH":
        default:
            break;
    }

    return { stepName, completedProgess };
}


function getStepOrder(step) {
    switch(step) {
        case "DYNAMO_LOADER":
            return 30;
        case "COSMOS_LOADER":
            return 30;
        case "ELASTIC_LOADER":
            return 40;
        case "SVF_CONNECTOR":
            return 10;
        case "SYNC_CONNECTOR":
            return 20;
        default:
            return 200;
    }
}

export {
    computeProgress,
    getStepOrder
};