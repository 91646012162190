/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from "react";
import { connect } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";

class BreakdownFilterDetailRow extends React.Component {
  state = {
    newValue: this.props.previousValue
  };

  render() {
    return (
      <TableRow key={this.props.breakdown.Name}>
        <TableCell>
          {this.props.breakdown.Name}
        </TableCell>
        <TableCell>
          {this.props.breakdown.Description}
        </TableCell>
        <TableCell>
          {this.props.breakdown.Default
            ? this.props.multiLang.projectFile.yes
            : ""}
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            onChange={e => {
              this.setState({ newValue: e.target.value });
              this.props.updateRootTag(
                this.props.breakdown.Name,
                e.target.value
              );
            }}
            value={this.state.newValue}
          />
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = state => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(BreakdownFilterDetailRow);
