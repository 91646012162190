/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from 'utils/store';

class NavigationViewCardPanel {
  viewer;
  openCard;

  constructor(viewerId) {
    this.onPropertyClick = this.onPropertyClick.bind(this);

    this.idModels = new Map();

    this.viewer = store
      .getState()
      .mainViewer.viewers.find((v) => v.id === viewerId).viewer;
    this.openCard = store.getState().card.openCard;
    this.closeCard = store.getState().card.closeCard;

    this.loadData();

    //this.isVisible = false;
    this.toggleVisibility();
  }

  onPropertyClick(indexOfModel) {
    const viewables = this.viewer.bubble.search({ type: 'geometry' });
    this.viewer.selectItem(
      viewables[indexOfModel].data,
      () => {
        console.log('succes');
      },
      () => {
        console.log('error');
      }
    );
  }

  toggleVisibility() {
    if (this.isVisible) {
      this.closeCard(this.indexCard);
    } else {
      this.indexCard = this.openCard('RAW_DATA_CARD', this.data, 150, 150);
    }
    this.isVisible = !this.isVisible;
  }

  loadData() {
    const viewables = this.viewer.bubble.search({ type: 'geometry' });
    this.data = {
      title: store.getState().multiLang.navigationViewCard.modelsArchitectural,
      sections: []
    };

    var attributes3d = [];
    var attributes2d = [];

    viewables.forEach((element, i) => {
      switch (element.data.role) {
        case '3d':
          attributes3d.push({
            attribute: element.data.name,
            paramsAction: i,
            action: this.onPropertyClick
          });
          break;

        case '2d':
          attributes2d.push({
            attribute: element.data.name,
            paramsAction: i,
            action: this.onPropertyClick
          });
          break;

        default:
          console.warn('no viewables avaibles');
          break;
      }
    });

    if (attributes3d.length !== 0)
      this.data.sections.push({
        title: store.getState().multiLang.navigationViewCard.model3D,
        groupAttributes: [
          {
            /*title: "Group 1", */
            attributes: attributes3d
          }
        ]
      });

    if (attributes2d.length !== 0)
      this.data.sections.push({
        title: store.getState().multiLang.navigationViewCard.model2D,
        groupAttributes: [
          {
            /*title: "Group 1", */
            attributes: attributes2d
          }
        ]
      });
  }
}

export default NavigationViewCardPanel;
