/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Notifications from '@material-ui/icons/Notifications';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
  addSensorThreshold,
  getThresholds,
  deleteThreshold
} from 'services/IotService';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import moment from 'moment';
import { store } from 'utils/store';

class AddSenssorThresholdModal extends Component {
  columnsIn = [
    {
      name: store.getState().multiLang.propertyCard.sensorLabel,
      label: store.getState().multiLang.propertyCard.sensorLabel,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: store.getState().multiLang.propertyCard.tag,
      label: store.getState().multiLang.propertyCard.tag,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: store.getState().multiLang.propertyCard.comparator,
      label: store.getState().multiLang.propertyCard.comparator,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: store.getState().multiLang.propertyCard.value,
      label: store.getState().multiLang.propertyCard.value,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: store.getState().multiLang.propertyCard.timeLimit,
      label: store.getState().multiLang.propertyCard.timeLimit,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: store.getState().multiLang.propertyCard.creationDate,
      label: store.getState().multiLang.propertyCard.creationDate,
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  state = {
    addThresholdForm: {
      comparator: '',
      timeLimit: 0,
      value: ''
    },
    thresholdsList: []
  };

  // option for table
  options = {
    textLabels: {
      body: {
        noMatch: this.props.multiLang.projectFile.notMatchingRecord,
        toolTip: this.props.multiLang.projectFile.sort
      },
      pagination: {
        rowsPerPage: this.props.multiLang.projectFile.rowsPerPage
      },
      toolbar: {
        search: this.props.multiLang.projectFile.search,
        print: this.props.multiLang.projectFile.print,
        viewColumns: this.props.multiLang.projectFile.viewColumns,
        filterTable: this.props.multiLang.projectFile.filterTable,
        downloadCsv: this.props.multiLang.propertyCard.downloadCsv
      },
      filter: {
        all: this.props.multiLang.projectFile.all,
        title: this.props.multiLang.projectFile.filters,
        reset: this.props.multiLang.projectFile.reset
      },
      viewColumns: {
        title: this.props.multiLang.projectFile.showColumns
      },
      selectedRows: {
        delete: this.props.multiLang.projectFile.delete
      }
    },
    onRowsDelete: (rowsDeleted) => {
      this.deleteThresholds(rowsDeleted);
    } //  called when user try to delete an element in table
  };

  // update the state of the form
  handelInput = (event, inputId) => {
    const updatedForm = { ...this.state.addThresholdForm };
    updatedForm[inputId] = event.target.value;
    this.setState({ addThresholdForm: updatedForm });
  };

  // create threshold in tada base
  submitCreateThreshold = (event) => {
    let modelId = this.props.viewerId
      ? this.props.viewerId.split('#')[1]
      : this.props.project.modelId;
    let revision = this.props.viewerId
      ? this.props.viewerId.split('#')[2]
      : this.props.project.revision;
    let actualViewer = this.props.viewerId
      ? this.props.viewerId.split('#')[0]
      : this.props.project.actualViewer;
    event.preventDefault();
    addSensorThreshold(
      this.props.tag,
      this.props.deviceId,
      this.state.addThresholdForm.comparator,
      parseInt(this.state.addThresholdForm.timeLimit),
      parseInt(this.state.addThresholdForm.value),
      this.props.project.projectId,
      modelId,
      revision,
      actualViewer,
      (error, sensorData) => {
        if (error) {
          console.log('ERROR : ', error);
          this.props.snackBar.addSnackError(
            this.props.multiLang.propertyCard.errorAddingThreshold
          );
        } else {
          // add threshold to table
          this.props.snackBar.addSnackSuccess(
            this.props.multiLang.propertyCard.newThresholdCreated
          );
          let updateThresholdList = [...this.state.thresholdsList];
          updateThresholdList.push([
            this.props.deviceId,
            this.props.tag,
            this.state.addThresholdForm.comparator,
            parseInt(this.state.addThresholdForm.value),
            parseInt(this.state.addThresholdForm.timeLimit),
            moment().format('DD/MM/YYYY')
          ]);
          this.setState({ thresholdsList: updateThresholdList });
        }
      }
    );
  };

  componentDidMount() {
    this.getThresholdsDevice();
  }

  getThresholdsDevice = () => {
    getThresholds(this.props.deviceId, (error, thresholdsData) => {
      if (error) {
        console.log('ERROR : ', error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.propertyCard.errorWhenRetrThreshold
        );
      } else {
        this.parsethresholdsData(thresholdsData);
      }
    });
  };

  // structure the data in table
  parsethresholdsData = (data) => {
    let tableData = [];
    data.Items.forEach((element) => {
      console.log('element : ', element);
      tableData.push([
        element.DeviceId,
        element.Tag,
        element.ThresholdComparator,
        element.ThresholdValue,
        element.TimeLimit,
        element.CreationDate
      ]);
    });
    this.setState({ thresholdsList: tableData });
  };

  deleteThresholds = (thresholds) => {
    let updateThresholdList = [...this.state.thresholdsList];
    thresholds.data.forEach((element) => {
      let thresholdTodelete = updateThresholdList[element.dataIndex];

      deleteThreshold(
        thresholdTodelete[0],
        thresholdTodelete[2],
        thresholdTodelete[3],
        (error, data) => {
          if (error) {
            this.props.snackBar.addSnackError(
              this.props.multiLang.propertyCard.errorWhenDeletingThreshold
            );
          } else {
            this.props.snackBar.addSnackSuccess(
              this.props.multiLang.propertyCard.deleteThreshold
            );
            updateThresholdList.splice(element.dataIndex, 1);
            this.setState({ thresholdsList: updateThresholdList });
          }
        }
      );
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='simple-dialog-title'
        maxWidth={false}
      >
        <DialogTitle id='form-dialog-title'>
          <Notifications /> {this.props.multiLang.propertyCard.addThreshold}
        </DialogTitle>
        <Paper style={{ padding: 20, margin: 10 }}>
          <form
            style={{ display: 'flex', flexWrap: 'wrap' }}
            onSubmit={this.submitCreateThreshold}
          >
            <div style={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor='age-simple'>
                      {this.props.multiLang.propertyCard.triggerWhenValueIs}
                    </InputLabel>
                    <Select
                      style={{ verticalAlign: 'bottom' }}
                      value={this.state.addThresholdForm.comparator}
                      onChange={(event) =>
                        this.handelInput(event, 'comparator')
                      }
                    >
                      <MenuItem value='='>
                        {this.props.multiLang.propertyCard.equalTo}
                      </MenuItem>
                      <MenuItem value='>'>
                        {this.props.multiLang.propertyCard.greaterThan}
                      </MenuItem>
                      <MenuItem value='<'>
                        {this.props.multiLang.propertyCard.lowerThan}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={this.state.addThresholdForm.value}
                    onChange={(event) => this.handelInput(event, 'value')}
                    id='standard-name'
                    label={this.props.multiLang.propertyCard.value}
                    fullWidth={true}
                    type='number'
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={this.state.addThresholdForm.timeLimit}
                    onChange={(event) => this.handelInput(event, 'timeLimit')}
                    type='number'
                    min='0'
                    id='standard-name'
                    label={this.props.multiLang.propertyCard.duringLastMinute}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant='contained'
                    color='primary'
                    className='float-right'
                    type='submit'
                  >
                    {this.props.multiLang.propertyCard.create}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Paper>
        <div style={{ margin: 10 }}>
          <MUIDataTable
            title={this.props.multiLang.propertyCard.thresholds}
            data={this.state.thresholdsList}
            columns={this.columnsIn}
            options={this.options}
          />
        </div>

        <DialogActions>
          <Button onClick={() => this.props.onClose(false)} color='primary'>
            {this.props.multiLang.propertyCard.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  project: state.project
});

export default connect(mapStateToProps)(AddSenssorThresholdModal);
