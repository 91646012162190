/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import ExtendedSearchSelectAllModal from "./ExtendedSearchSelectAllModal";
import SidePanel from "components/generic-side-panel/SidePanel";
import Grid from "@material-ui/core/Grid";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import GpsFixed from "@material-ui/icons/GpsFixed";
import Visibility from "@material-ui/icons/Visibility";
import Subject from "@material-ui/icons/Subject";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import StarBorder from "@material-ui/icons/StarBorder";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "utils/store";
import { mainViewerAction } from "actions/MainViewerAction";

import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import { getObjectsFromKeyword, getIdfromTag } from "services/ObjectService";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { searchAction } from "actions/SearchAction";
import { semanticSearch } from "services/AdvancedSearchService";
import Button from "@material-ui/core/Button";
import { constructTagLabel } from "utils/TagHelper";
import _ from "lodash";

import theme from "theme/theme";

const MAX_ELEMENT_PER_PAGE = 7;
const MAX_SELECT_ALL_ELEMENT = 10;

class ExtendSearchPanel extends Component {
  state = {
    isExtendedSearchOpen: false,
    totalNbElements: 0,
    selectedTags: [],
    isSearchMinimized: false,
    selectAllDialogOpened: false,
    exportAllDialogOpened: false,
    retrieveCount: 0,
    selectionHasId: false
  };

  search = (event, newPage) => {
    if (this.props.searchParams.searchType) {
      this.advancedSearch(newPage);
    } else {
      this.simpleSearch(newPage);
    }
  };

  /**
   * Call avanced search service and put in state result
   * @param {int} newPage
   */
  advancedSearch(newPage) {
    const query = this.props.searchParams.searchValue;
    let pageNumber = newPage ? newPage : 0;

    semanticSearch(
      query,
      MAX_ELEMENT_PER_PAGE,
      ++pageNumber,
      this.handleSearchResultCallback
    );
  }

  /**
   * Call simple search service and put in state result
   * @param {int} newPage
   */
  simpleSearch = (newPage) => {
    const projectId = this.props.project.projectId;
    let pageNumber = newPage ? newPage : 0;
    if (!projectId || !this.props.searchParams.searchValue) {
      console.error(
        "There are parameters missing. Will not call the search API"
      );
      return;
    }
    getObjectsFromKeyword(
      projectId,
      this.props.searchParams.searchValue,
      MAX_ELEMENT_PER_PAGE,
      pageNumber,
      this.handleSearchResultCallback
    );
  };

  handleSearchResultCallback = (error, data) => {
    if (error) {
      console.error(error);
      return this.props.snackBar.addSnackError(
        this.props.multiLang.navBar.queryObjError
      );
    }

    this.setState({
      resultOfSearch: data.searchResult,
      totalNbElements: data.totalNbElements,
      pageNumber: data.pageNumber
    });
  };

  openExtendedSearch = () => {
    this.setState(
      {
        isExtendedSearchOpen: true,
        isSearchMinimized: false,
        resultOfSearch: [],
        selectedTags: [],
        pageNumber: 1,
        totalNbElements: 0
      },
      () => this.search()
    );
  };

  closeExtendedSearch = () => {
    this.setState({ isExtendedSearchOpen: false });
  };

  componentDidMount() {
    this.props.dispatch({
      type: searchAction.sidePanelAction,
      closeSidePanel: this.closeExtendedSearch,
      openSidePanel: this.openExtendedSearch
    });
  }

  /**
   * Call change row per page function from props
   * @param {any} event
   * @param {int} newPage
   */
  handleChangeRowsPerPage = (event) => {
    if (this.props.onChangeRowsPerPage) {
      this.props.onChangeRowsPerPage(event.target.value);
    }
  };

  addOrDeleteTagFromSelectedItem = async (event, tag) => {
    let updateSelectedTags = this.state.selectedTags.slice(0);
    // Check if tag is already selected
    let tagIndex = updateSelectedTags.findIndex((element) => {
      return element.tagId === tag.tagId;
    });
    if (tagIndex !== -1) {
      updateSelectedTags.splice(tagIndex, 1);
      this.setState({ selectedTags: updateSelectedTags });
    } else {
      if (this.props.viewers.length > 0) {
        let viewers = this.props.viewers.filter(
          (v) => v.viewerType === "FORGE_VIEWER"
        );
        if (viewers && viewers.length > 0) {
          let ids = [];
          for (const v of viewers) {
            var modelId = v.id.split("#")[1]
              ? v.id.split("#")[1]
              : this.props.project.modelId;
            var revision = v.id.split("#")[2]
              ? v.id.split("#")[2]
              : this.props.project.revision;
            var viewer = v.viewerType;
            let { data } = await getIdfromTag(
              this.props.project.projectId,
              modelId,
              revision,
              tag.tag,
              viewer
            );
            if (data.length > 0) {
              data.forEach((obj) => {
                if (obj.Id) {
                  ids.push(parseInt(obj.Id));
                }
              });
            }
          }
          tag.ids = ids;
        }
        updateSelectedTags.push(tag);
        // Detect if one of the selectd Tag has a forge Id
        let selectionHasId = updateSelectedTags.some(
          (element) => element.ids && element.ids.length > 0
        );
        this.setState({
          selectedTags: updateSelectedTags,
          selectionHasId: selectionHasId
        });
      }
    }
  };

  openInfoCard = (e, tag) => {
    console.log("tag", tag, this.state.selectedTags);
    this.props.openCard(
      "PROPERTY_CARD",
      {
        tag: tag.tag
      },
      150,
      150,
      { height: 500 }
    );
  };

  focusOn = () => {
    let selectedTags = this.state.selectedTags;
    if (this.props.viewers.length > 0) {
      let viewers = this.props.viewers.filter((v) => v.focusOnObjects !== null);
      if (viewers && viewers.length > 0) {
        let ids = [];
        selectedTags.forEach((element) =>
          element.ids ? element.ids.forEach((id) => ids.push(id)) : null
        );
        let forgeViewers = this.props.viewers.filter(
          (v) => v.viewerType === "FORGE_VIEWER"
        );
        // Focus on elements with forg id
        forgeViewers[0].focusOnObjectsByIds(ids, forgeViewers[0].id);
        // Set selected tag to the last selected element
        store.dispatch({
          type: mainViewerAction.initTagNames,
          focusedObjectsTagNames: [selectedTags[selectedTags.length - 1].tag],
          fromViewer: true
        });
      } else {
        this.props.snackBar.addSnackError(
          this.props.multiLang.search.focusOnObject
        );
      }
    }
  };

  showOnly = () => {
    let selectedTags = this.state.selectedTags;
    if (this.props.viewers.length > 0) {
      let viewers = this.props.viewers.filter((v) => v.isolateObject !== null);
      if (viewers && viewers.length > 0) {
        let ids = [];
        selectedTags.forEach((element) =>
          element.ids ? element.ids.forEach((id) => ids.push(id)) : null
        );
        // Isolate  elements with forg id
        let forgeViewers = this.props.viewers.filter(
          (v) => v.viewerType === "FORGE_VIEWER"
        );
        forgeViewers[0].isolateObjectsByIds(ids, forgeViewers[0].id);
      } else {
        this.props.snackBar.addSnackError(
          this.props.multiLang.search.isolateObject
        );
      }
    }
  };

  hideObjects = () => {
    let selectedTags = this.state.selectedTags;
    if (this.props.viewers.length > 0) {
      let viewers = this.props.viewers.filter((v) => v.hideObjects !== null);
      if (viewers && viewers.length > 0) {
        let ids = [];
        selectedTags.forEach((element) =>
          element.ids ? element.ids.forEach((id) => ids.push(id)) : null
        );
        // Hide  elements with forg id
        let forgeViewers = this.props.viewers.filter(
          (v) => v.viewerType === "FORGE_VIEWER"
        );
        forgeViewers[0].hideObjectsByIds(ids, forgeViewers[0].id);
      } else {
        this.props.snackBar.addSnackError(
          this.props.multiLang.search.hideObjects
        );
      }
    }
  };

  selectAll = () => {
    if (!this.props.project.projectId || !this.props.project.viewer) {
      return this.props.snackBar.addSnackError(
        this.props.multiLang.search.loadAllObject
      );
    }

    if (this.state.totalNbElements > MAX_SELECT_ALL_ELEMENT) {
      this.setState({ selectAllDialogOpened: true });
    } else {
      this.retrieveAllTags();
    }
  };

  retrieveAllTags = async (toExport = false) => {
    this.setState({ retrieveCount: 0, cancelled: false });

    // Simple search
    let currentPage = 0;
    let tags = [];
    while (
      currentPage * MAX_ELEMENT_PER_PAGE < this.state.totalNbElements &&
      !this.state.cancelled
    ) {
      let res = {};
      if (typeof this.props.searchParams.searchValue === "string") {
        // Simple search
        res = await getObjectsFromKeyword(
          this.props.project.projectId,
          this.props.searchParams.searchValue,
          MAX_ELEMENT_PER_PAGE,
          currentPage++
        );
      } else {
        // Advanced search
        res = await semanticSearch(
          this.props.searchParams.searchValue,
          MAX_ELEMENT_PER_PAGE,
          ++currentPage
        );
      }
      if (this.props.viewers.length > 0) {
        let viewers = this.props.viewers.filter(
          (v) => v.viewerType === "FORGE_VIEWER"
        );
        if (viewers && viewers.length > 0) {
          for (const v of viewers) {
            var modelId = v.id.split("#")[1]
              ? v.id.split("#")[1]
              : this.props.project.modelId;
            var revision = v.id.split("#")[2]
              ? v.id.split("#")[2]
              : this.props.project.revision;
            var viewer = v.viewerType;
            let { data } = await getIdfromTag(
              this.props.project.projectId,
              modelId,
              revision,
              res.data.searchResult.map((result) => result.tag),
              viewer
            );

            let tagsWithId = res.data.searchResult.map((element) => {
              let ids = [];
              data.forEach((obj) => {
                if (obj.Id && obj.Tag === element.tag) {
                  ids.push(parseInt(obj.Id));
                }
                element.ids = ids;
              });
              return element;
            });

            tags = tags.concat(tagsWithId);
          }
        }
      }
      this.setState({
        retrieveCount: this.state.retrieveCount + res.data.numberOfItems
      });
    }

    if (this.state.cancelled) {
      // selectAll was cancelled
      return;
    }

    if (toExport) {
      this.setState({ exportAllDialogOpened: false, retrieveCount: 0 });
      return tags;
    } else {
      // Detect if one of the selectd Tag has a forge Id
      let selectionHasId = tags.some(
        (element) => element.ids && element.ids.length > 0
      );
      this.setState({
        selectedTags: tags,
        selectAllDialogOpened: false,
        retrieveCount: 0,
        selectionHasId: selectionHasId
      });
    }
  };

  deselectAll = () => {
    this.setState({ selectedTags: [] });
  };

  minimizeSearch = () => {
    //document.getElementById("SidenavId").style.width = "50px";
    if (this.props.searchParams.searchType)
      this.props.searchParams.openSemanticSearchPanel();
    this.setState({ isSearchMinimized: true });
  };

  expandSearch = () => {
    this.setState({ isSearchMinimized: false });
  };

  exportAll = async () => {
    if (!this.props.project.projectId || !this.props.project.viewer) {
      return this.props.snackBar.addSnackError(
        this.props.multiLang.search.loadAllObject
      );
    }

    if (this.state.totalNbElements > MAX_SELECT_ALL_ELEMENT) {
      this.setState({ exportAllDialogOpened: true });
    } else {
      let tags = await this.retrieveAllTags(true);
      this.exportCsvFile(tags);
    }
  };

  exportCsvFile = (objects) => {
    // Flatten objects array to have one object contains tag properties for each tag;
    const render = (attribute) =>
      attribute.value ||
      attribute.attributeValueDate ||
      attribute.attributeValueNumber ||
      attribute.attributeValueString ||
      "";
    const tagProperties = objects.map((obj) =>
      obj.datasets.reduce((previousValue, currentDataset) => {
        return {
          ...previousValue,
          ...currentDataset.attributs.reduce(
            (previousResult, currentAttribute) => {
              return {
                ...previousResult,
                [currentAttribute.attributeName]: render(currentAttribute)
              };
            },
            {}
          )
        };
      }, {})
    );
    // Get unique properties names to add them as headers in the csv file
    const headers = tagProperties.reduce(
      (previousValue, currentValue) =>
        _.union(previousValue, Object.keys(currentValue)),
      []
    );
    // Add headers to csv string
    let str = "tag;tagClass;tagLabel;" + headers.join(";") + "\r\n";
    objects.forEach((object, index) => {
      // Add tag, tag class and tag Label values to the current line
      let line =
        object["tag"] +
        ";" +
        object["tagClass"] +
        ";" +
        (object["tagLabel"] || "");
      // Add tag properties values to the current line
      headers.forEach(
        (property) => (line += ";" + (tagProperties[index][property] || ""))
      );
      str += line + "\r\n";
    });
    //this trick will generate a temp "a" tag
    var link = document.createElement("a");
    link.id = "lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    // Create blob object from data
    const BOM = "\uFEFF";
    var csv = BOM + str;
    var blob = new Blob([csv], { type: "text/csv;;charset=utf-8" });
    var csvUrl = window.webkitURL.createObjectURL(blob);
    var filename = "SearchExport.csv";

    document.getElementById("lnkDwnldLnk").setAttribute("download", filename);
    document.getElementById("lnkDwnldLnk").setAttribute("href", csvUrl);
    document.getElementById("lnkDwnldLnk").click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <SidePanel
        open={this.state.isExtendedSearchOpen}
        minimize={this.state.isSearchMinimized}
        id="simpleSearchPanel"
      >
        {this.state.isSearchMinimized ? (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <IconButton onClick={this.expandSearch}>
                  <Search />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  writingMode: "vertical-rl",
                  paddingRight: 12,
                  color: theme.palette.primary.main
                }}
              >
                {this.props.multiLang.search.labelSimpleSearch}
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container>
              <Grid item xs={6} sm={3}>
                <Tooltip title={this.props.multiLang.search.minimize}>
                  <IconButton onClick={this.minimizeSearch}>
                    <ChevronRight />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={3} style={{ padding: 7 }}>
                <Typography
                  variant="h6"
                  style={{ color: theme.palette.primary.main }}
                >
                  {this.props.multiLang.search.labelSimpleSearch}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}></Grid>
              <Grid item xs={6} sm={3}>
                {/* <Tooltip title={this.props.multiLang.search.fullScreen}>
                    <IconButton>
                      <Fullscreen />
                    </IconButton>
                  </Tooltip> */}

                <Tooltip title={this.props.multiLang.search.close}>
                  <IconButton onClick={this.closeExtendedSearch}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Divider light />
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid
                item
                xs={6}
                sm={4}
                style={{ padding: 12, textAlign: "center" }}
              >
                <Button
                  color="primary"
                  onClick={this.selectAll}
                  style={{ fontSize: 12 }}
                >
                  {this.props.multiLang.search.selectAll}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                style={{ padding: 12, textAlign: "center" }}
              >
                <Button
                  color="primary"
                  onClick={this.deselectAll}
                  style={{ fontSize: 12 }}
                >
                  {this.props.multiLang.search.deselectAll}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                style={{ padding: 12, textAlign: "center" }}
              >
                <Button
                  color="primary"
                  onClick={this.exportAll}
                  style={{ fontSize: 12 }}
                >
                  {this.props.multiLang.search.exportAll}
                </Button>
              </Grid>
            </Grid>

            <Grid container style={{ height: 400, overflow: "auto" }}>
              <Grid item xs={12}>
                <Table>
                  <TableBody>
                    {this.state.resultOfSearch &&
                      this.state.resultOfSearch.map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              this.state.selectedTags.findIndex(function (
                                element
                              ) {
                                return element.tagId === row.tagId;
                              }) !== -1
                                ? theme.palette.primary.main
                                : ""
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              width: 400,
                              color:
                                this.state.selectedTags.findIndex(function (
                                  element
                                ) {
                                  return element.tagId === row.tagId;
                                }) !== -1
                                  ? "white"
                                  : "",
                              paddingRight: 0
                            }}
                            onClick={(e) =>
                              this.addOrDeleteTagFromSelectedItem(e, row)
                            }
                          >
                            {constructTagLabel(row.tag, row.tagLabel)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              width: 280,
                              color:
                                this.state.selectedTags.findIndex(function (
                                  element
                                ) {
                                  return element.tagId === row.tagId;
                                }) !== -1
                                  ? "white"
                                  : "",
                              textAlign: "end"
                            }}
                          >
                            <Subject
                              onClick={(e) => this.openInfoCard(e, row)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        style={{ paddingRight: 0 }}
                        rowsPerPageOptions={[MAX_ELEMENT_PER_PAGE]}
                        colSpan={2}
                        count={this.state.totalNbElements}
                        rowsPerPage={MAX_ELEMENT_PER_PAGE}
                        page={this.state.pageNumber - 1}
                        SelectProps={{
                          native: true
                        }}
                        onChangePage={this.search}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Grid>
            </Grid>
            {this.state.selectedTags.length !== 0 && (
              <div>
                <Grid container alignItems={"center"} justify={"center"}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                      color: theme.palette.primary.main
                    }}
                  >
                    {this.state.selectedTags.length}{" "}
                    {this.props.multiLang.search.itemsSelected}
                  </Grid>
                </Grid>
                <Grid container alignItems={"center"} justify={"center"}>
                  <Grid item xs={6} sm={2}>
                    <IconButton disabled>
                      <StarBorder />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Tooltip title={this.props.multiLang.search.fitView}>
                      <span>
                        <IconButton
                          disabled={!this.state.selectionHasId}
                          onClick={this.focusOn}
                        >
                          <GpsFixed />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Tooltip title={this.props.multiLang.search.showOnly}>
                      <span>
                        <IconButton
                          disabled={!this.state.selectionHasId}
                          onClick={this.showOnly}
                        >
                          <Visibility />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Tooltip title={this.props.multiLang.search.hide}>
                      <span>
                        <IconButton
                          disabled={!this.state.selectionHasId}
                          onClick={this.hideObjects}
                        >
                          <VisibilityOff />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <IconButton disabled>{/* <Share /> */}</IconButton>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
        <ExtendedSearchSelectAllModal
          open={this.state.selectAllDialogOpened}
          key={"select" + this.state.selectAllDialogOpened}
          title={this.props.multiLang.search.confirmSelectAllTitle}
          progress={
            (this.state.retrieveCount * 100) / this.state.totalNbElements
          }
          handleClose={() =>
            this.setState({ selectAllDialogOpened: false, cancelled: true })
          }
          elementCount={this.state.totalNbElements}
          retrieveAllTags={this.retrieveAllTags}
        />
        <ExtendedSearchSelectAllModal
          open={this.state.exportAllDialogOpened}
          key={"export" + this.state.exportAllDialogOpened}
          title={this.props.multiLang.search.confirmExportAllTitle}
          progress={
            (this.state.retrieveCount * 100) / this.state.totalNbElements
          }
          handleClose={() => {
            this.setState({ exportAllDialogOpened: false, cancelled: true });
          }}
          elementCount={this.state.totalNbElements}
          retrieveAllTags={async () => {
            let tags = await this.retrieveAllTags(true);
            this.exportCsvFile(tags);
          }}
        />
      </SidePanel>
    );
  }
}

const mapStateToProps = (state) => ({
  searchParams: state.searchParams,
  project: state.project,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  viewers: state.mainViewer.viewers,
  openCard: state.card.openCard,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames
});

export default connect(mapStateToProps)(ExtendSearchPanel);
