/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React from 'react'

const IconDocument = props => (
  <svg width={20} height={20} {...props}>
    <g fillRule="evenodd">
      <path d="M15.746 3.645a.43.43 0 0 0-.121-.297L12.398.125A.406.406 0 0 0 12.102 0H3.14a.89.89 0 0 0-.891.887v16.226c0 .489.398.887.887.887h11.722a.89.89 0 0 0 .891-.887V3.648c0-.003-.004-.003-.004-.003zm-3.223-2.208l1.786 1.79h-1.743a.043.043 0 0 1-.043-.043zm2.383 15.676c0 .024-.02.043-.047.043H3.141c-.028 0-.047-.02-.047-.043V.887c0-.024.02-.043.047-.043h8.539v2.34c0 .488.398.886.886.886h2.34zm0 0" />
      <path d="M12.73 5.766H5.27a.423.423 0 0 0 0 .843h7.46a.423.423 0 0 0 0-.843zm0 0M12.73 8.309H5.27a.423.423 0 0 0 0 .843h7.46a.423.423 0 0 0 0-.843zm0 0M12.73 10.852H5.27a.423.423 0 0 0 0 .844h7.46a.423.423 0 0 0 0-.844zm0 0M9 13.395H5.27a.423.423 0 0 0 0 .844H9a.423.423 0 0 0 0-.844zm0 0" />
    </g>
  </svg>
)

export default IconDocument
