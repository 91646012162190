/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

// Generated automatically by nearley, version 2.16.0
// http://github.com/Hardmath123/nearley
(function() {
  function id(x) {
    return x[0];
  }
  var grammar = {
    Lexer: undefined,
    ParserRules: [
      { name: "_$ebnf$1", symbols: [] },
      {
        name: "_$ebnf$1",
        symbols: ["_$ebnf$1", "wschar"],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "_",
        symbols: ["_$ebnf$1"],
        postprocess: function(d) {
          return null;
        }
      },
      { name: "__$ebnf$1", symbols: ["wschar"] },
      {
        name: "__$ebnf$1",
        symbols: ["__$ebnf$1", "wschar"],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "__",
        symbols: ["__$ebnf$1"],
        postprocess: function(d) {
          return null;
        }
      },
      { name: "wschar", symbols: [/[ \t\n\v\f]/], postprocess: id },
      { name: "dqstring$ebnf$1", symbols: [] },
      {
        name: "dqstring$ebnf$1",
        symbols: ["dqstring$ebnf$1", "dstrchar"],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "dqstring",
        symbols: [{ literal: '"' }, "dqstring$ebnf$1", { literal: '"' }],
        postprocess: function(d) {
          return d[1].join("");
        }
      },
      { name: "sqstring$ebnf$1", symbols: [] },
      {
        name: "sqstring$ebnf$1",
        symbols: ["sqstring$ebnf$1", "sstrchar"],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "sqstring",
        symbols: [{ literal: "'" }, "sqstring$ebnf$1", { literal: "'" }],
        postprocess: function(d) {
          return d[1].join("");
        }
      },
      { name: "btstring$ebnf$1", symbols: [] },
      {
        name: "btstring$ebnf$1",
        symbols: ["btstring$ebnf$1", /[^`]/],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "btstring",
        symbols: [{ literal: "`" }, "btstring$ebnf$1", { literal: "`" }],
        postprocess: function(d) {
          return d[1].join("");
        }
      },
      { name: "dstrchar", symbols: [/[^\\"\n]/], postprocess: id },
      {
        name: "dstrchar",
        symbols: [{ literal: "\\" }, "strescape"],
        postprocess: function(d) {
          return JSON.parse('"' + d.join("") + '"');
        }
      },
      { name: "sstrchar", symbols: [/[^\\'\n]/], postprocess: id },
      {
        name: "sstrchar",
        symbols: [{ literal: "\\" }, "strescape"],
        postprocess: function(d) {
          return JSON.parse('"' + d.join("") + '"');
        }
      },
      {
        name: "sstrchar$string$1",
        symbols: [{ literal: "\\" }, { literal: "'" }],
        postprocess: function joiner(d) {
          return d.join("");
        }
      },
      {
        name: "sstrchar",
        symbols: ["sstrchar$string$1"],
        postprocess: function(d) {
          return "'";
        }
      },
      { name: "strescape", symbols: [/["\\/bfnrt]/], postprocess: id },
      {
        name: "strescape",
        symbols: [
          { literal: "u" },
          /[a-fA-F0-9]/,
          /[a-fA-F0-9]/,
          /[a-fA-F0-9]/,
          /[a-fA-F0-9]/
        ],
        postprocess: function(d) {
          return d.join("");
        }
      },
      { name: "expression$ebnf$1", symbols: ["word"], postprocess: id },
      {
        name: "expression$ebnf$1",
        symbols: [],
        postprocess: function(d) {
          return null;
        }
      },
      {
        name: "expression",
        symbols: [
          "className",
          { literal: "." },
          "attribute",
          "__",
          "operator",
          "__",
          "expression$ebnf$1"
        ],
        postprocess: data => {
          return {
            className: data[0],
            hasDot: true,
            attribute: data[2],
            operator: data[4],
            value: data[6]
          };
        }
      },
      {
        name: "expression",
        symbols: [
          "className",
          { literal: "." },
          "attribute",
          "__",
          "operator",
          "_"
        ],
        postprocess: data => {
          return {
            className: data[0],
            hasDot: true,
            attribute: data[2],
            operator: data[4],
            value: null
          };
        }
      },
      {
        name: "expression",
        symbols: ["className", { literal: "." }, "attribute", "_"],
        postprocess: data => {
          return {
            className: data[0],
            hasDot: true,
            attribute: data[2],
            operator: null,
            value: null
          };
        }
      },
      {
        name: "expression$ebnf$2",
        symbols: [{ literal: "." }],
        postprocess: id
      },
      {
        name: "expression$ebnf$2",
        symbols: [],
        postprocess: function(d) {
          return null;
        }
      },
      {
        name: "expression",
        symbols: ["className", "expression$ebnf$2"],
        postprocess: data => {
          return {
            className: data[0],
            hasDot: !!data[1],
            attribute: null,
            operator: null,
            value: null
          };
        }
      },
      { name: "operator", symbols: [{ literal: "=" }] },
      { name: "operator", symbols: [{ literal: "<" }] },
      { name: "operator", symbols: [{ literal: ">" }] },
      { name: "operator", symbols: ["word"] },
      { name: "className", symbols: ["dqstring"] },
      { name: "className", symbols: ["word"] },
      { name: "attribute", symbols: ["dqstring"] },
      { name: "attribute", symbols: ["word"] },
      { name: "value", symbols: ["number"] },
      { name: "value", symbols: ["dqstring"] },
      { name: "value", symbols: ["word"] },
      { name: "word$ebnf$1", symbols: [/[\w]/] },
      {
        name: "word$ebnf$1",
        symbols: ["word$ebnf$1", /[\w]/],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      {
        name: "word",
        symbols: ["word$ebnf$1"],
        postprocess: data => data[0].join("")
      },
      { name: "number$ebnf$1", symbols: [/[0-9]/] },
      {
        name: "number$ebnf$1",
        symbols: ["number$ebnf$1", /[0-9]/],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        }
      },
      { name: "number", symbols: ["number$ebnf$1"] }
    ],
    ParserStart: "expression"
  };
  if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports = grammar;
  } else {
    window.grammar = grammar;
  }
})();
