/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import {Paper, Chip, MenuItem, TextField} from '@material-ui/core';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';


class InputFilterChips extends Component {

    state = {
        inputValue: ''

    }
    renderInput = (inputProps) => {
        const { InputProps, ref, ...other } = inputProps;
        return (
            <TextField
                onClick={this.props.onChange}
                InputProps={{
                    inputRef: ref,
                    ...InputProps,
                }}
                {...other}
            />
        );
    };

    getSuggestions = (value, { showEmpty = false } = {}) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = this.props.inputValue.length;
        let count = 0;

        return inputLength === 0 && !showEmpty ? [] : this.props.suggestions.filter(suggestion => {
                const keep = count < 5 && suggestion.slice(0, inputLength).toLowerCase() === inputValue;
                if (keep) {
                    count += 1;
                }
                return keep;
            });
    };

    renderSuggestion = (suggestionProps) => {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

        return (
            <MenuItem
                {...itemProps}
                key={suggestion}
                selected={isHighlighted}
                component="div"
                style={{fontWeight: isSelected ? 'bold' : 'normal'}}
            >
                {suggestion}
            </MenuItem>
        );
    }

    render(){
        return(
            <Downshift
                id="downshift-multiple"
                inputValue={this.props.inputValue}
                onChange={this.props.onchange}
                selectedItem={this.props.itemSelected}
            >
                {({
                      getInputProps,
                      getItemProps,
                      getLabelProps,
                      isOpen,
                      inputValue: value,
                      selectedItem: item,
                      highlightedIndex,
                  }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        placeholder: this.props.placeholder
                    });
                    return (
                        <div >
                            {this.renderInput({
                                fullWidth: true,
                                label: this.props.inputLabel,
                                InputLabelProps: getLabelProps(),
                                InputProps: {
                                    startAdornment: this.props.itemSelected.map((item) => {
                                        return(
                                            <Chip
                                                key={item}
                                                tabIndex={-1}
                                                label={item}
                                                onDelete={() => this.props.onDelete(item)}
                                            />
                                        )
                                    }),
                                    onBlur,
                                    onChange: event => {
                                        this.props.onInputchange(event);
                                        onChange(event);
                                    },
                                    onFocus,
                                },
                                inputProps,
                                required: this.props.required
                            })}
                            {isOpen ? (
                                <Paper square>
                                    {this.getSuggestions(value).map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem: item,
                                        }),
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    );
                }}
            </Downshift>
        )
    }

}

export default InputFilterChips;
