/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CssBaseline,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Divider,
  Drawer
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import FolderIcon from '@material-ui/icons/Folder';
import ScrollArea from 'react-scrollbar';
import './DatasourcesRootPage.css';
import ProjectFileList from './ProjectFileList';
import ImportList from './ImportList';
import ImportStatusList from './ImportStatusList';
import { getProject, getProjectById } from 'services/ProjectService';
import { projectAction } from 'actions/ProjectAction';
import theme from 'theme/theme';
import { isAuthorize } from 'services/AuthorizationService';

const DATASOURCE_LIST = 'datasourceList';
const IMPORT_LIST = 'importList';

const monitoringImport = {
  rightName: 'MonitoringImport'
};

class DatasourcesRootPage extends Component {
  state = {
    page: DATASOURCE_LIST,
    folderTree: {},
    opened: {},
    lastfolder: '', // Last folder opened by the user
    rootOpened: true
  };

  handleMenuClick(page) {
    this.setState({ page });
  }

  componentDidMount() {
    let { projectId } = this.props.match.params;

    // Temporary !!!
    if (this.props.config.cloud === 'AZURE') {
      getProjectById(projectId, (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.mainViewer.projectDetailsError
          );
        }
        // add project informations to store
        this.props.dispatch({
          type: projectAction.initProject,
          projectId: projectId,
          isProjectOwner: data.ProjectOwner,
          name: data.Name,
          viewer: data.Viewer
        });
        this.setState({ projectId: projectId });
      });
      return;
    }
    getProject(projectId, (error, data) => {
      if (error) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.mainViewer.projectDetailsError
        );
      }
      // add project informations to store
      this.props.dispatch({
        type: projectAction.initProject,
        projectId: projectId,
        isProjectOwner: data.ProjectOwner,
        name: data.Name,
        viewer: data.Viewer
      });
      this.setState({ projectId: projectId });
    });
  }

  renderFolderTree = (folderTree, willDisplay, level = 0) => {
    if (!folderTree || !willDisplay || Object.keys(folderTree).length === 0)
      return null;

    return (
      <Fragment>
        {Object.keys(folderTree).map((label) => {
          let hasChildFolder = Object.keys(folderTree[label]).length > 0;
          //

          return (
            <div
              key={label + level}
              style={{ display: willDisplay ? 'block' : 'none' }}
            >
              <ListItem
                button
                onClick={() => {
                  this.toggleOpen(label + level);
                  this.setState({ lastfolder: label + level });
                  this.handleMenuClick(DATASOURCE_LIST);
                }}
              >
                <ListItemText
                  disableTypography
                  style={{ paddingLeft: 10 + level * 10 + 'px' }}
                  primary={label}
                />
                {hasChildFolder && !this.isOpen(label + level) && (
                  <ExpandMore />
                )}
                {hasChildFolder && this.isOpen(label + level) && <ExpandLess />}
              </ListItem>
              {this.renderFolderTree(
                folderTree[label],
                this.isOpen(label + level),
                level + 1
              )}
            </div>
          );
        })}
      </Fragment>
    );
  };

  isOpen = (key) => {
    if (!key) return this.state.rootOpened;
    return this.state.opened[key];
  };

  toggleOpen = (key) => {
    if (!key) this.setState({ rootOpened: !this.state.rootOpened });
    else
      this.setState({
        opened: Object.assign({}, this.state.opened, {
          [key]: !this.state.opened[key]
        })
      });
  };

  render() {
    return (
      <ScrollArea>
        <div className='datasources_main root'>
          <CssBaseline />
          <Drawer
            className='drawer'
            variant='permanent'
            classes={{
              paper: 'drawerPaper'
            }}
            PaperProps={{ style: { position: 'relative' } }}
            ModalProps={{
              container: document.getElementById('root'),
              style: { position: 'absolute' }
            }}
            anchor='left'
          >
            <div className='toolbar' />
            <Divider />
            <List>
              <ListItem
                button
                onClick={() => {
                  this.toggleOpen();
                }}
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Link to={{ pathname: `${this.props.match.url}/files` }}>
                      <Typography
                        style={{
                          color:
                            this.state.page === DATASOURCE_LIST
                              ? theme.palette.primary.main
                              : 'black'
                        }}
                      >
                        {
                          this.props.multiLang.datasources
                            .datasourceListPageTitle
                        }
                      </Typography>
                    </Link>
                  }
                />
                <ListItemIcon>
                  {this.isOpen() ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              </ListItem>
              {this.renderFolderTree(this.state.folderTree, this.isOpen())}
              <ListItem
                disabled={
                  !isAuthorize(monitoringImport.rightName, this.props.projectId)
                }
                button
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Link to={{ pathname: `${this.props.match.url}/imports` }}>
                      <Typography
                        style={{
                          color:
                            this.state.page === IMPORT_LIST
                              ? theme.palette.primary.main
                              : 'black'
                        }}
                      >
                        {this.props.multiLang.datasources.importListPageTitle}
                      </Typography>
                    </Link>
                  }
                />
              </ListItem>
            </List>
          </Drawer>
          <main className='content'>
            {!this.state.projectId && (
              <CircularProgress
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '100px'
                }}
              />
            )}

            {this.state.projectId && (
              <Fragment>
                <Route
                  path={`${this.props.match.url}/files`}
                  component={ProjectFileList}
                  folder={this.state.lastfolder}
                  location={this.props.location}
                  projectId={this.state.projectId}
                ></Route>
                <Route
                  exact
                  path={`${this.props.match.url}/imports`}
                  component={ImportList}
                  projectId={this.state.projectId}
                ></Route>
                <Route
                  path={`${this.props.match.url}/imports/:importId`}
                  component={ImportStatusList}
                ></Route>
              </Fragment>
            )}
          </main>
        </div>
      </ScrollArea>
    );
  }
}

const mapStateToProps = (state) => ({
  projectId: state.project.projectId,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  config: state.config
});

export default connect(mapStateToProps)(DatasourcesRootPage);
