/* 
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *   
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice. 
 *   
 *  Unauthorized copying of this file or any part thereof, via any medium 
 *  is strictly prohibited, and may not be provided or otherwise 
 *  made available to any third party without Capgemini Technology Services 
 *  consent. 
 *   
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *   
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR 
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, 
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT. 
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR 
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import { store } from 'utils/store';

function getModelById(projectId, modelId, revision) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  let promise = axios.get(config.urlProjectAPI + '/getFileMetadata', {
    params: {
      projectId: projectId,
      modelId: modelId,
      revisionNumber: revision
    },
    headers: headers
  });
  return promise;
}

function getAllRevisions(projectId, modelId, callback) {
  var headers = store.getState().auth.headers;
  var config = store.getState().config;

  axios
    .get(config.urlProjectAPI + '/getAllRevisions', {
      params: {
        projectId: projectId,
        modelId: modelId
      },
      headers: headers
    })
    .then(response => {
      if (!response.data || Object.keys(response.data).length === 0)
        return callback('No data');
      return callback(null, response.data);
    })
    .catch(error => {
      return callback(error);
    });
}

export { getModelById, getAllRevisions };
