/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import axios from 'axios';
import {store} from 'utils/store';

const catalogLimit = 1000;

function getClasses(projectId, beginWith, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios
        .get(config.urlCatalogAPI + '/classes', {
            headers: headers,
            params: {
                projectId: projectId,
                beginWith: beginWith,
                limit: catalogLimit
            }
        })
        .then(function (response) {
            if (!response.data) {
                console.error('error no data');
                return callback('No data');
            }
            callback(null, response.data);
        })
        .catch(function (error) {
            console.error('error :' + JSON.stringify(error));
            callback(error);
        });
}

function getDatasets(projectId, tagClassName, beginWith, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios
        .get(config.urlCatalogAPI + '/datasets', {
            /// + "/getCatalog"
            params: {
                projectId: projectId,
                tagClassName: tagClassName,
                beginWith: beginWith,
                limit: catalogLimit
            },
            headers: headers
        })
        .then(function (response) {
            if (!response.data) return callback('No data');
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getAttributes(
    projectId,
    tagClassName,
    datasetName,
    contains
) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios
        .get(config.urlCatalogAPI + '/classes/' + tagClassName + "/attributes", {
            params: {
                projectId: projectId,
                datasetName: datasetName ? datasetName : null,
                beginsWith: contains ? contains : '',
                limit: catalogLimit
            },
            headers: headers
        })
}

function getRelations(projectId, beginWith, limit, callback) {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    axios
        .get(config.urlCatalogAPI + '/relations', {
            params: {
                projectId: projectId,
                beginWith: beginWith,
                limit: limit
            },
            headers: headers
        })
        .then(function (response) {
            if (!response.data) return callback('No data');
            callback(null, response.data);
        })
        .catch(function (error) {
            callback(error);
        });
}

function getInterventionTypes() {
    var headers = store.getState().auth.headers;
    var config = store.getState().config;

    return axios.get(config.urlCatalogAPI + '/interventionTypes', {
        headers: headers
    });
}

export {
    getClasses,
    getDatasets,
    getAttributes,
    getRelations,
    getInterventionTypes
};
