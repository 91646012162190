/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { parseModelDataForDataSet } from './Model-utils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Divider from '@material-ui/core/Divider';
import { extentionForDocument } from 'utils/ModelHelper';
import md5 from 'js-md5';
import theme from 'theme/theme';
import Popover from '@material-ui/core/Popover';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import More from '@material-ui/icons/More';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FileUploadPropertyCardPanel from 'components/file/upload-file-property-card/FileUploadPropertyCardPanel';
import { getDocumentUrl } from 'services/DocumentService';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { openConnection, getImportByTagId } from 'services/ImportService';
import { isAuthorize } from 'services/AuthorizationService';
import { store } from 'utils/store';
import azure from 'azure-storage';
import Axios from 'axios';

import LinearProgress from '@material-ui/core/LinearProgress';
import { computeProgress } from 'components/file/ProgressBarHelper';

import './DocumentTab.css';

const documentService = require('../../../services/DocumentService');

const uploadMedia = {
  rightName: 'UploadMedia'
};

/* PopUP */

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: '20px 0 0 0',
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose}
        >
          X
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2
  }
}))(MuiDialogContent);

/* End of popup*/

class DocumentTab extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      percent: 0,
      datasetDocument: 'DOCUMENT',
      selectedFile: null,
      datasetMappingDocument: '',
      files: [],
      fileInput: [],
      displayInputfile: null,
      documentMapping: null,
      anchorEl: null
    };

    this.uploadHandler = this.uploadHandler.bind(this);
  }

  async componentDidMount() {
    getImportByTagId(
      this.props.project.projectId,
      this.props.tag,
      this.loadImports
    );
    console.log('ImportsUpdated:', this.props.project.projectId);
    this.connection = await openConnection(this.props.project.projectId);
    let cloudConfig = this.props.cloudConfig;
    if (cloudConfig === 'AzureADDB2C') {
      this.connection.on('ImportsUpdated', (data) => {
        console.log('ImportsUpdated: ', data);
        this.handleUpdateImport(data);
      });
    } else {
      this.connection.onmessage = (evt) => {
        console.log('New WS: ' + JSON.stringify(evt.data));
        var data = JSON.parse(evt.data);
        this.handleUpdateImport(data);
      };
    }
  }

  handleUpdateImport = (newImport) => {
    let isNewImport = true;
    const importInProgress = [...this.state.importInProgress];
    importInProgress.forEach((element) => {
      if (element.importId === newImport.importId) {
        element.step = newImport.step;
        element.name = newImport.name;
        isNewImport = false;
      }
    });

    if (isNewImport) {
      importInProgress.push(newImport);
    }
    this.setState({ importInProgress: importInProgress });
    if (!this.props.onRefresh) {
      console.error('No refresh fonction was pass for DocumentTab');
    }
    this.props.onRefresh();
  };

  loadImports = (error, data) => {
    if (error) {
      this.props.snackBar.addSnackError(
        this.props.multiLang.propertyCard.unableToOpenDocument
      );
    }

    this.setState({ importInProgress: data });
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /* End open Modal */

  handleClickDocument(tag, download) {
    getDocumentUrl(this.props.project.projectId + '#' + tag, (err, data) => {
      if (err) {
        this.props.snackBar.addSnackError(
          this.props.multiLang.propertyCard.unableToOpenDocument
        );
      } else {
        var link = document.createElement('a');
        link.href = download ? data.download : data.view;
        link.target = download ? '_self' : '_blank';
        document.body.appendChild(link);
        link.click();
      }
    });
  }

  acceptFilesList() {
    return '.' + extentionForDocument.join(',.');
  }

  getHashNameFile(fileInputName) {
    return md5(fileInputName);
  }

  buildParams() {
    /**
     *  Create an array of document params.
     *  Each  item of this array will be
     *  uploaded to the S3.
     */

    var paramsDocument = [];
    let modelId = this.props.viewerId
      ? this.props.viewerId.split('#')[1]
      : this.props.project.modelId;
    let revision = this.props.viewerId
      ? this.props.viewerId.split('#')[2]
      : this.props.project.revision;
    if (this.state.fileInput) {
      Array.from(this.state.fileInput).forEach((file, index) => {
        paramsDocument.push({
          Bucket: this.props.config.bucket,
          Key:
            this.getHashNameFile(file.name) +
            '/' +
            this.props.project.projectId +
            '/' +
            this.state.datasetDocument +
            '/' +
            file.name,
          Body: file,
          ACL: 'private',
          Metadata: {
            datasetid: this.state.datasetDocument,
            modelid: modelId,
            revision: revision,
            project: this.props.project.projectId,
            sub: this.props.userId,
            fileid: this.props.fileId ? this.props.fileId : 'new_file',

            tagobject: this.state.titleObject,

            // attributes for the dataset
            attributesdocumentname: file.name,
            attributeslocation:
              this.getHashNameFile(file.name) +
              '/' +
              this.props.project.projectId +
              '/' +
              this.state.datasetDocument +
              '/' +
              file.name
          },
          Tagging:
            'dataset=' +
            this.state.datasetDocument +
            '&project=' +
            this.props.projectId
        });
      });
    }
    return paramsDocument;
  }

  setPercent(progress) {
    this.setState({ percent: progress });
  }

  uploadHandler() {
    if (
      this.state.fileInput &&
      this.state.datasetDocument &&
      Array.isArray(this.buildParams())
    ) {
      let cloudConfig = this.props.config.authProvider;
      switch (cloudConfig) {
        case 'AzureADDB2C':
          this.uploadAzureFile();
          break;
        case 'AWSCognito':
          this.uploadAwsFile();
          break;
        default:
          console.error('Cloud is not defined');
      }
    }
  }

  uploadAwsFile = () => {
    this.buildParams().forEach((param) => {
      console.log(param);
      this.props.s3
        .upload(
          param,
          function (err, data) {
            if (err) {
              this.props.snackBar.addSnackError(
                this.props.multiLang.projectFile.uploadfileError
              );
              console.log(err);
            } else {
              this.props.snackBar.addSnackSuccess(
                this.props.multiLang.projectFile.uploadOf +
                  'file' +
                  this.props.multiLang.projectFile.uploadComplete
              );
              this.setState({ displayInputfile: null });
            }
          }.bind(this)
        )
        .on(
          'httpUploadProgress',
          function (evt) {
            var progress = Math.round((evt.loaded / evt.total) * 100);
            this.setPercent(progress);
          }.bind(this)
        );
    });
  };

  uploadAzureFile = async () => {
    this.buildParams().forEach(async (param) => {
      console.log(param);
      var config = store.getState().config;
      const props = this.props;
      const fileName = param.attributesdocumentname;
      // get sas token
      let result = await documentService.getSasToken(
        config.uploadStorageAccountName,
        config.uploadContainerName
      );
      let blobService = azure.createBlobServiceWithSas(
        config.uploadStorageUri,
        result.token
      );
      let resultData = await documentService.uploadFile(
        config.uploadStorageUri,
        param.body,
        config.uploadContainerName,
        param.Key,
        param.Metadata,
        result.token
      );
      if (resultData.status === 201) {
        blobService.setBlobMetadata(
          config.uploadContainerName,
          param.Key,
          param.Metadata,
          (err, result, Response) => {
            if (result) {
              props.snackBar.addSnackSuccess(
                props.multiLang.projectFile.uploadOf +
                  fileName +
                  props.multiLang.projectFile.uploadComplete
              );
              props.onClose();
            } else {
              props.snackBar.addSnackError(
                props.multiLang.projectFile.uploadfileError
              );
            }
          }
        );
      }
      //TODO: this be use with API management
      var headers = store.getState().auth.headers;
      Axios.post(
        config.masterLogicAppUrl,
        {
          blobName: param.Key
        },
        { headers: headers }
      );
    });
  };
  /**
   * close action menu
   */
  closeFileAction = () => {
    this.setState({ anchorEl: null, selectedFile: null });
  };

  /**
   * open detail menu of document
   * @param event
   */
  openFileAction = (event, file) => {
    console.log(file);
    event.stopPropagation();

    this.setState({ anchorEl: event.currentTarget, selectedFile: file });
  };

  render() {
    const data = parseModelDataForDataSet(this.props.data, 'Documents');

    return (
      <Fragment>
        <div className='tab-content'>
          <Fragment>
            <List>
              {data &&
                data.map((file, index) => (
                  <div key={file.Name}>
                    <ListItem>
                      <ListItemText primary={file.Name.split('#').pop()} />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label={this.props.multiLang.projectFile.open}
                          onClick={() =>
                            this.handleClickDocument(file.Name, false)
                          }
                        >
                          <OpenInNew />
                        </IconButton>
                        <IconButton
                          aria-label={this.props.multiLang.projectFile.more}
                          onClick={(event) => this.openFileAction(event, file)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                    <Popover
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.closeFileAction}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <MenuList>
                        {this.props.data.tagClass !== 'Document' && (
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              this.props.card.openCard(
                                'PROPERTY_CARD',
                                {
                                  title: 'Properties',
                                  tag: this.state.selectedFile.Name
                                },
                                150,
                                150,
                                { id: this.state.selectedFile.Name }
                              );
                              this.closeFileAction();
                            }}
                          >
                            <ListItemIcon>
                              <More style={{ color: theme.color.lightBlue }} />
                            </ListItemIcon>
                            <ListItemText
                              inset
                              primary={this.props.multiLang.toolbar.details}
                            />
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            this.handleClickDocument(
                              this.state.selectedFile.Name,
                              true
                            );
                            this.closeFileAction();
                          }}
                        >
                          <ListItemIcon>
                            <VerticalAlignBottom
                              style={{ color: theme.color.lightBlue }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.props.multiLang.projectFile.download}
                          />
                        </MenuItem>
                      </MenuList>
                    </Popover>
                  </div>
                ))}

              {this.state.importInProgress &&
                this.state.importInProgress.map((importWating, index) => {
                  let { completedProgess, stepName } = computeProgress(
                    importWating.step
                  );
                  console.log(theme.color.grey);

                  if (!completedProgess) {
                    return <Fragment key={index} />;
                  }
                  return (
                    <div key={index}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <div> {importWating.name} </div>
                              <LinearProgress
                                variant='determinate'
                                value={completedProgess}
                              />
                              <div
                                style={{
                                  color: theme.color.grey,
                                  fontSize: '70%',
                                  fontStyle: 'italic'
                                }}
                              >
                                {stepName}
                              </div>
                            </div>
                          }
                        />
                      </ListItem>
                    </div>
                  );
                })}
            </List>
            <Divider />
          </Fragment>

          <Grid
            container
            direction='row'
            justify='flex-end'
            style={{ height: 'inherit' }}
          >
            <Grid item>
              <Fab
                disabled={
                  !isAuthorize(
                    uploadMedia.rightName,
                    this.props.project.projectId
                  )
                }
                onClick={() => this.handleClickOpen()}
                size='small'
              >
                <svg
                  viewBox='0 0 32 32'
                  fit=''
                  height='50%'
                  width='50%'
                  preserveAspectRatio='xMidYMid meet'
                  focusable='false'
                >
                  <path d='m 15.677734,2.8133138 a 0.66673335,0.66673335 0 0 0 -0.65625,0.6757812 v 12.15625 H 3.5 a 0.66673335,0.66673335 0 1 0 0,1.332031 h 11.521484 v 11.511719 a 0.66673335,0.66673335 0 1 0 1.332032,0 V 16.977376 H 28.5 a 0.66673335,0.66673335 0 1 0 0,-1.332031 H 16.353516 V 3.489095 A 0.66673335,0.66673335 0 0 0 15.677734,2.8133138 Z'></path>
                </svg>
              </Fab>
            </Grid>
          </Grid>
        </div>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby='customized-dialog-title'
          open={this.state.open}
          className='popup-document'
        >
          <DialogTitle
            className='title-dialog'
            id='customized-dialog-title'
            onClose={this.handleClose}
          >
            <label className='uploadFiles'>
              {this.props.multiLang.projectFile.documentsUpload}
            </label>
          </DialogTitle>
          <DialogContent className='popup-upload'>
            <FileUploadPropertyCardPanel
              files={this.state.filesToUpload}
              filesToAttach={this.state.files}
              uploadToModel={this.state.uploadToModel}
              wasDrag={this.state.wasDrag}
              close={this.handleClose}
              tag={this.props.tag}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  s3: state.auth.s3,
  config: state.config,
  storeBucket: state.config.storeBucket,
  multiLang: state.multiLang,
  snackBar: state.snackBar,
  card: state.card,
  cloudConfig: state.config.authProvider
});

export default connect(mapStateToProps)(DocumentTab);
