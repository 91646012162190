/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SplitViewer from "components/viewers/split-viewer/SplitViewerComponent";
import ForgeViewer from "components/viewers/forge-viewer/ForgeViewerComponent";
import EmptyViewer from "components/viewers/main-viewer/EmptyViewerComponent";
import DashboardViewer from "components/viewers/dashboard-viewer/DashboardViewerComponent";
import { projectAction } from "actions/ProjectAction";
import { getProjectById } from "services/ProjectService";
import { getModelById } from "services/ModelService";
import { getReview } from "services/ReviewService";
import { getAllRevisions } from "services/ModelService";
import uuid from "uuidv4";
import { store } from "utils/store";
import { mainViewerAction } from "actions/MainViewerAction";
import "./viewer.css";
import ExtendSearchPanel from "components/nav-search/extend-search-panel/ExtendSearchPanel";
import SemanticSearchPanel from "components/nav-search/semantic-search-panel/SemanticSearchPanel";
import { getFileList } from "services/FileService";

class MainViewer extends Component {
  modelTagName = null;

  constructor(props) {
    super(props);
    this.state = {
      prjOwner: false,
      files: [],
      revisions: [],
      value: "",
      tag: "",
      selectedTab: "",
      notes: []
    };

    this.addMessageListener.bind(this);
  }

  getRevisions(project, model) {
    if (project && model) {
      getAllRevisions(project, model, (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.forgeViewer.getRevisionError
          );
        }
        this.setState({ revisions: data });
      });
    }
  }

  components = {
    EMPTY_VIEWER: EmptyViewer,
    FORGE_VIEWER: ForgeViewer,
    DASHBOARD_VIEWER: DashboardViewer
  };

  viewer = {
    emptyViewer: "EMPTY_VIEWER",
    forgeViewer: "FORGE_VIEWER",
    dashboardViewer: "DASHBOARD_VIEWER",
    splitViewer: "SPLIT_VIEWER"
  };

  viewerSrc = {
    FORGE_VIEWER: "images/ForgeView.jpg"
  };

  getProject(projectId) {
    if (projectId) {
      getProjectById(projectId, (error, data) => {
        if (error) {
          console.error(error);
          return this.props.snackBar.addSnackError(
            this.props.multiLang.mainViewer.projectDetailsError
          );
        }
        this.setState({ prjOwner: data.ProjectOwner });
        this.props.dispatch({
          type: projectAction.initProject,
          projectId: projectId,
          isProjectOwner: data.ProjectOwner,
          name: data.Name,
          viewer: data.Viewer
        });
      });
    }
  }

  componentDidMount() {
    this.loadModel();
    this.addMessageListener();
  }

  /**
   * returns the right component to render depending on the type of viewer
   */
  buildViewer() {
    switch (this.state.viewer) {
      case this.viewer.forgeViewer:
        const params = new URLSearchParams(this.props.location.search);
        var modelId = params.get("modelId");
        var revision = params.get("revision");
        var id = this.state.viewer + "#" + modelId + "#" + revision;
        return (
          <ForgeViewer
            id={id}
            data={this.state.viewerMetadata}
            tagNames={this.props.focusedObjectsTagNames}
            tag={this.state.tag}
            selectedTab={this.state.selectedTab}
          />
        );
      case this.viewer.splitViewer:
        return (
          <SplitViewer
            tagNames={this.props.focusedObjectsTagNames}
            tag={this.state.tag}
            selectedTab={this.state.selectedTab}
            workspaceConfig={this.props.location.state.workspaceConfig}
          />
        );
      default:
        return <EmptyViewer data={this.state.viewerMetadata} />;
    }
  }

  async loadModel() {
    const params = new URLSearchParams(this.props.location.search);
    const projectId = params.get("projectId");
    const viewer = params.get("viewer");
    const modelId = params.get("modelId");
    const revision = params.get("revision");
    const type = params.get("type");
    const tag = params.get("tag");
    const selectedTab = params.get("selectedTab");
    const project = this.props.project;

    this.setState({ viewer: viewer, urlLoad: this.props.location.search });
    this.getProject(projectId);
    // When switching view, init file properties in redux store
    if (modelId !== project.modelId || revision !== project.revision) {
      // Get file to update model name and site name
      if (!project.modelName) {
        getFileList(projectId, (error, data) => {
          if (error) {
            return this.props.snackBar.addSnackError(
              this.props.multiLang.projectFile.loadFileError
            );
          }
          let selectedFile = data.find((file) => file.modelId === modelId);
          this.props.dispatch({
            type: projectAction.initFile,
            modelId: modelId,
            revision: revision,
            actualViewer: viewer,
            modelName: selectedFile.key,
            siteName: selectedFile.siteName,
            fileId: selectedFile.fileId
          });
        });
      }
    }

    if (type === "review") {
      store.dispatch({
        type: mainViewerAction.openMarkUpCard,
        openViewMarkupCard: {
          open: true,
          projectId: projectId,
          modelId: modelId,
          revision: revision
        },
        openViewMarkupCardFunction: null,
        unmountViewMarkupCardFunction: null
      });
    }

    if (type === "iot") {
      this.setState({ tag: tag, selectedTab: selectedTab });
    }
    if (viewer !== this.viewer.splitViewer) {
      let response = await getModelById(projectId, modelId, revision);
      let data = response.data;
      if (!data) {
        return this.props.snackBar.addSnackError(
          this.props.multiLang.mainViewer.modelDetailsError
        );
      }
      this.setState({ viewerMetadata: data, loadUUID: uuid() });

      if (this.props.modelTagName) {
        data = Object.assign({}, data, {
          previousModelTagName: this.props.modelTagName
        });
      }
      this.props.dispatch({
        type: projectAction.initMetadata,
        metadata: data
      });
      if (data && data.modelTagName) {
        this.modelTagName = data.modelTagName;
      }
    }
    this.getRevisions(projectId, modelId);
  }

  processReceivedMessage = (event) => {
    if (
      event.origin !==
      window.location.protocol + "//" + window.location.host
    ) {
      // something from an unknown domain, let's ignore it
      return;
    }
    if (event.data.source === "react-devtools-bridge") return;

    var tag;
    var dataParse;

    try {
      dataParse = JSON.parse(event.data);
    } catch (e) {
      dataParse = event.data;
    }
    if (dataParse.type === "geoServer") {
      tag = dataParse.fullId.split("#")[1];
    } else {
      tag = dataParse.fullId;
    }
    var tagNames = [];
    tagNames.push(tag);
    store.dispatch({
      type: mainViewerAction.initTagNames,
      focusedObjectsTagNames: tagNames
    });

    var data = {
      title: "Properties",
      tag: tag
    };

    if (Object.values(this.viewer).includes(this.state.viewer)) {
      this.props.card.openCard("PROPERTY_CARD", data, 150, 150, { id: tag });
    }
  };

  componentWillUnmount() {
    this.props.closeAllCard();
    this.props.dispatch({
      type: mainViewerAction.removeViewer,
      viewerToRemove: "All"
    });
    window.removeEventListener("message", this.processReceivedMessage);
  }

  componentDidUpdate() {
    if (!this.state.reviewAlreadyLoad) {
      this.getReview();
    }

    if (this.urlHasChange()) {
      this.loadModel();
    }
  }

  urlHasChange() {
    if (this.state.urlLoad !== this.props.location.search) {
      this.setState({ viewerMetadata: null });
      return true;
    }
    return false;
  }

  addMessageListener() {
    window.addEventListener("message", this.processReceivedMessage);
  }

  getReview() {
    if (
      !this.props.project ||
      !this.props.project.projectId ||
      !this.props.project.revision ||
      !this.props.project.modelId
    ) {
      return;
    }
    const project = this.props.project;
    getReview(
      project.projectId,
      project.modelId,
      project.revision,
      (error, data) => {
        if (error) {
          console.error(error);
          return this.props.snackBar.addSnackError(
            this.props.multiLang.mainViewer.reviewDetailsError
          );
        }
        var review = data.Items[0];
        this.props.dispatch({
          type: projectAction.initReview,
          review: review
        });
        this.setState({ reviewAlreadyLoad: true });
      }
    );
  }

  render() {
    if (
      !this.state.viewerMetadata &&
      this.state.viewer !== this.viewer.splitViewer
    ) {
      return (
        <Fragment>{this.props.multiLang.mainViewer.viewerLoading}</Fragment>
      );
    }
    return (
      <Fragment>
        <div>
          <ExtendSearchPanel />
          <SemanticSearchPanel />
          {this.buildViewer()}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  closeAllCard: state.card.closeAllCard,
  snackBar: state.snackBar,
  multiLang: state.multiLang,
  card: state.card,
  focusedObjectsTagNames: state.mainViewer.focusedObjectsTagNames,
  selectedObjectIds: state.mainViewer.selectedObjectIds,
  modelTagName: state.project.metadata
    ? state.project.metadata.modelTagName
    : ""
});

export default connect(mapStateToProps)(MainViewer);
