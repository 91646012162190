/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Fragment, Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

class MarkupDetailsBasic extends Component {
  render() {
    return (
      <Fragment>
        <div
          style={{ padding: "10px 30px", borderBottom: "1px solid #f2f2f2" }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography style={{ fontSize: "0.75rem" }}>
                {this.props.multiLang.viewMarkup.creationDate}
              </Typography>
              <Typography style={{ color: "#9b9b9b", fontSize: "0.6875rem" }}>
                {this.props.data.creationDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography style={{ fontSize: "0.6875rem" }}>
                {" "}
                {this.props.multiLang.viewMarkup.creator}
              </Typography>
              <Typography style={{ color: "#9b9b9b", fontSize: "0.6875rem" }}>
                {this.props.data.creator}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography style={{ fontSize: "0.6875rem" }}>
                {this.props.multiLang.viewMarkup.typeLabel}
              </Typography>
              <Typography style={{ color: "#9b9b9b", fontSize: "0.6875rem" }}>
                {this.props.data.topicType}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography style={{ fontSize: "0.6875rem" }}>
                {this.props.multiLang.viewMarkup.statusLabel}
              </Typography>
              <Typography style={{ color: "#9b9b9b", fontSize: "0.6875rem" }}>
                {this.props.data.priority}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography style={{ fontSize: "0.6875rem" }}>
                {this.props.multiLang.viewMarkup.labelLabel}
              </Typography>
              <Typography style={{ color: "#9b9b9b", fontSize: "0.6875rem" }}>
                {this.props.data.topicLabel}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="card__comments-wrapper">
          <div className="card__comment-row">
            <div className="card__comment">{this.props.data.description}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang
});

export default connect(mapStateToProps)(MarkupDetailsBasic);
